import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import { useAccessPage } from "customHooks/useAccess";
import programStatusColor from "util/ProgramStatusColor";
import permissionSlug from "assets/data/permissionSlug.json";
import sanitizedDigits from "util/sanitizedDigits";
export default function ListingCard({ program }) {
    const { can } = useAccessPage();
    return (
        <>
            <Grid
                xs={12}
                sm={6}
                lg={4}
                sx={{ padding: { xs: "0px 10px", xl: "0px 15px" }, mb: "30px" }}
            >
                <Card
                    sx={{
                        background: "rgba(2, 53, 60, 0.06)",
                        borderRight: "8px solid rgba(2, 53, 60, 0.15)",
                        boxShadow: "none",
                        padding: { xs: "15px", xl: "25px" },
                        textAlign: "left",
                        minHeight: "100%",
                    }}
                >
                    <Link
                        to={
                            can(permissionSlug[0].programSlug.program_details)
                                ? `/program/${program.id}/detail`
                                : ``
                        }
                    >
                        <CardContent sx={{ padding: 0 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    component="div"
                                    sx={{
                                        flex: "1",
                                        textAlign: "left",
                                        pr: 2,
                                        fontFamily: "Inter",
                                        mb: 0,
                                        color: "#02353C",
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {capitalizeFirstLetter(
                                        program?.companyName
                                    )}
                                </Typography>
                                <Box sx={{ display: "flex" }}>
                                    <Box
                                        component="span"
                                        sx={{
                                            backgroundColor:
                                                programStatusColor[
                                                    program?.program_status
                                                ],
                                            borderRadius: "40px",
                                            boxShadow: "none",
                                            fontSize: "14px",
                                            textTransform: "none",
                                            color: "#fff",
                                            padding: "1px 11px 1px 11px",
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                width: "16px",
                                                marginRight: "2px",
                                            }}
                                        />
                                        {capitalizeFirstLetter(
                                            program.program_status
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Typography
                                gutterBottom
                                sx={{
                                    color: "#000",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginTop: "6px",
                                }}
                            >
                                {capitalizeFirstLetter(program.program_name)}
                            </Typography>
                            <Typography
                                gutterBottom
                                sx={{
                                    color: "#000",
                                    fontSize: "16px",
                                    marginTop: "6px",
                                }}
                            >
                                Type
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                    }}
                                >
                                    {" "}
                                    {capitalizeFirstLetter(
                                        program.program_type
                                    )}
                                </Typography>
                            </Typography>
                            <Typography
                                gutterBottom
                                sx={{
                                    color: "#000",
                                    fontSize: "16px",
                                    marginTop: "6px",
                                }}
                            >
                                Standard
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                    }}
                                >
                                    {" "}
                                    {program.program_standard}
                                </Typography>
                            </Typography>
                        </CardContent>
                        <CardActions
                            sx={{ padding: "0px", flexWrap: "wrap", mt: "8px" }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "15px",
                                    mb: "7px",
                                }}
                            >
                                <Box component="div" sx={{ minWidth: "90px" }}>
                                    <Box
                                        component="span"
                                        sx={{
                                            maxWidth: "105px",
                                            borderRadius: "40px",
                                            boxShadow: "none",
                                            fontSize: "13px",
                                            padding: "1px 16px",
                                            color: "#fff",
                                            fontWeight: "600",
                                            letterSpacing: "0.65px",
                                            textTransform: "uppercase",
                                            backgroundColor: "#1ED800",
                                        }}
                                    >
                                        Issued
                                    </Box>
                                </Box>
                                <Box
                                    component="span"
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "12px",
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        sx={{
                                            color: "#02353C",
                                            fontWeight: "600",
                                            fontSize: {
                                                xs: "14px",
                                                md: "16px",
                                            },
                                        }}
                                    >
                                        {sanitizedDigits(
                                            program?.total_issued_volume
                                        )}{" "}
                                    </Typography>
                                    <Typography
                                        variant="span"
                                        sx={{
                                            color: "#000",
                                            fontWeight: "600",
                                            fontSize: {
                                                xs: "14px",
                                                md: "16px",
                                            },
                                        }}
                                    >
                                        {program?.program_unit}{" "}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "15px",
                                    ml: "0 !important",
                                }}
                            >
                                <Box component="div" sx={{ minWidth: "90px" }}>
                                    <Box
                                        component="span"
                                        sx={{
                                            maxWidth: "105px",
                                            backgroundColor: "#02353C",
                                            borderRadius: "40px",
                                            boxShadow: "none",
                                            fontSize: "13px",
                                            padding: "1px 16px",
                                            color: "#fff",
                                            fontWeight: "600",
                                            letterSpacing: "0.65px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Retired
                                    </Box>
                                </Box>
                                <Box
                                    component="span"
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "12px",
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        sx={{
                                            color: "#02353C",
                                            fontWeight: "600",
                                            fontSize: {
                                                xs: "14px",
                                                md: "16px",
                                            },
                                        }}
                                    >
                                        {sanitizedDigits(
                                            program?.total_retired_volume
                                        )}{" "}
                                    </Typography>
                                    <Typography
                                        variant="span"
                                        sx={{
                                            color: "#000",
                                            fontWeight: "600",
                                            fontSize: {
                                                xs: "14px",
                                                md: "16px",
                                            },
                                        }}
                                    >
                                        {program?.program_unit}{" "}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardActions>
                    </Link>
                </Card>
            </Grid>
        </>
    );
}
