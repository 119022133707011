import { Button, Checkbox, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as yup from "yup";
import CancelButton from "common/CancelButton";
import Address from "components/program/form/Address";
import Company from "components/program/form/Company";
import Contact from "components/program/form/Contact";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createFarmer, editFarmers } from "redux/farmersSlice";
import { useEffect } from "react";
import { useAccessPage } from "customHooks/useAccess";
// import Company from "components/farmer/form/Company";
// import Contact from "components/farmer/form/Contact";
// import Address from "components/farmer/form/Address";

export default function FarmersFormContainer({ edit = false, farmers }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isRoles } = useAccessPage();
    const otherUser = isRoles("other_user");
    const { isLoading, fieldErrors } = useSelector((state) => state.farmers);
    const initialValues = {
        farmerExternalId: "",
        farmerCompanyName: "",
        farmerOfficialId: "",
        farmerEuVatId: "",
        farmerEuCapId: "",
        farmerFirstname: "",
        farmerLastname: "",
        farmerPhone: "",
        farmerEmail: "",
        farmerFullAddress: "",
        farmerAddressXY: "POINT(1 1)",
        farmerStreet: "",
        farmerStreet2: "",
        farmerZipcode: "",
        farmerZipcode2: "",
        farmerCity: "",
        farmerRegion: "",
        farmerCountry: "",
        companyId: "",
    };

    if (edit) {
        initialValues.user_share_farmers_data = "";
    }

    const validationSchema = yup.object().shape({
        farmerExternalId: yup.string().required("Farmer Ref is Required"),
        farmerCompanyName: yup.string().required("Company name is Required"),
        farmerOfficialId: yup.string().required("Official Id is Required"),
        farmerEuVatId: yup.string().required("Vat number is Required"),
        farmerEuCapId: yup.string().required("Europeancap Id is Required"),
        companyId: yup.string().required("Company is Required"),
        farmerFirstname: otherUser
            ? yup.string()
            : yup.string().required("Firstname is Required"),
        farmerLastname: otherUser
            ? yup.string()
            : yup.string().required("Lastname is required"),
        farmerPhone: yup.string(),
        farmerEmail: otherUser
            ? yup.string()
            : yup
                  .string()
                  .matches(
                      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g,
                      "Invalid Email Address"
                  ),
        farmerStreet: yup.string().required("Street is required"),
        farmerZipcode: yup.string().required("Zip code is required"),
        farmerRegion: yup.string().required("Region is required"),
        farmerCity: yup.string().required("City is required"),
        farmerCountry: yup.string().required("Country is required"),
    });
    if (otherUser) {
        delete initialValues.farmerFirstname;
        delete initialValues.farmerLastname;
        delete initialValues.farmerPhone;
        delete initialValues.farmerEmail;
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!edit) {
                dispatch(createFarmer(values)).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate(`/farmers`);
                        formik.resetForm();
                    }
                });
            } else {
                dispatch(
                    editFarmers({ id: farmers?.id, payload: values })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate(`/farmer/${farmers.id}/details`);
                        formik.resetForm();
                    }
                });
            }
        },
    });

    useEffect(() => {
        if (farmers) {
            const currentValues = {
                farmerExternalId: farmers?.farmerExternalId,
                farmerCompanyName: farmers?.farmerCompanyName,
                farmerOfficialId: farmers?.farmerOfficialId,
                farmerEuVatId: farmers?.farmerEuVatId,
                farmerEuCapId: farmers?.farmerEuCapId,
                // farmerFirstname: farmers?.farmerFirstname,
                // farmerLastname: farmers?.farmerLastname,
                // farmerPhone: farmers?.farmerPhone,
                // farmerEmail: farmers?.farmerEmail,
                farmerFullAddress: "",
                farmerAddressXY: "POINT(1 1)",
                farmerStreet: farmers?.farmerStreet,
                farmerStreet2: farmers?.farmerStreet2,
                farmerZipcode: farmers?.farmerZipcode,
                farmerZipcode2: "",
                farmerCity: farmers?.farmerCity,
                farmerRegion: farmers?.farmerRegion,
                farmerCountry: farmers?.farmerCountry,
                companyId: farmers?.company_id,
            };
            if (!otherUser) {
                currentValues.farmerFirstname = farmers?.farmerFirstname || "";
                currentValues.farmerLastname = farmers?.farmerLastname || "";
                currentValues.farmerPhone = farmers?.farmerPhone || "";
                currentValues.farmerEmail = farmers?.farmerEmail || "";
            }
            if (edit) {
                currentValues.user_share_farmers_data =
                    farmers?.user_share_farmers_data;
            }
            formik.setValues(currentValues);
        }
    }, [farmers, edit]);
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                maxWidth: 530,
            }}
        >
            <Typography variant="h4">Company</Typography>
            <Company formik={formik} fieldErrors={fieldErrors} type="farmer" />
            <Contact formik={formik} fieldErrors={fieldErrors} type="farmer" />
            <Address formik={formik} fieldErrors={fieldErrors} type="farmer" />
            {edit && (
                <>
                    <Typography variant="h4">GDPR</Typography>
                    {/* // <Grid container rowSpacing="10px" columnSpacing="30px">  */}

                    <Grid
                        // key={index}
                        container
                        spacing={1}
                        alignItems="center"
                        // margin={res?.alignItems}
                        sx={{
                            margin: "13px -13px 0",
                            lineHeight: "1.2",
                            fontSize: {
                                xs: "14px",
                                lg: "16px",
                            },
                        }}
                    >
                        <Grid
                            item
                            sx={{
                                textAlign: {
                                    xs: "left",
                                    lg: "right",
                                },
                                padding: "0px 12px !important",
                                maxWidth: {
                                    xs: "100%",
                                    sm: "220px",
                                    lg: "180px",
                                },
                                minWidth: {
                                    xs: "100%",
                                    sm: "220px",
                                    lg: "180px",
                                },
                                mb: { xs: "5px", sm: 0 },
                            }}
                        >
                            Sharing personal data
                        </Grid>
                        <Grid
                            item
                            sx={{
                                textAlign: "left",
                                fontWeight: "700",
                                padding: "0px 12px !important",
                                width: {
                                    xs: "100%",
                                    sm: "calc(100% - 220px)",
                                    lg: "calc(100% - 180px)",
                                },
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    maxWidth: {
                                        xs: "100%",
                                        xl: "70%",
                                    },
                                }}
                            >
                                <Box sx={{}}>
                                    <Checkbox
                                        defaultChecked={Boolean(
                                            farmers?.user_share_farmers_data
                                        )}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                "user_share_farmers_data",
                                                e.target.checked
                                            )
                                        }
                                        sx={{ p: 0 }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}

            {/* </Grid> */}
            <Stack direction="row" spacing={2} mt={{ xs: "29px", md: "39px" }}>
                <CancelButton
                    address={
                        edit ? `/farmer/${farmers.id}/details` : `/farmers`
                    }
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="buttonPrimary"
                    disabled={isLoading}
                >
                    {isLoading ? "Processing..." : edit ? "Submit" : "Save"}
                </Button>
            </Stack>
        </Box>
    );
}
