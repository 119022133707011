import { useEffect, useState } from "react";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Tabs from "common/Tabs";
import Box from "@mui/material/Box";
import Details from "components/farmer/detail/Details";
import DetailHeader from "components/farmer/detail/DetailHeader";
import DetailTitle from "components/farmer/detail/DetailTitle";
import Projects from "components/farmer/project/Projects";
import ProjectHeader from "components/farmer/project/ProjectHeader";
import ProjectTitle from "components/farmer/project/ProjectTitle";
import Fields from "components/farmer/field/Fields";
import FieldHeader from "components/farmer/field/FieldHeader";
import FieldTitle from "components/farmer/field/FieldTitle";
import Map from "components/farmer/map/Map";
import MapHeader from "components/farmer/map/MapHeader";
import MapTitle from "components/farmer/map/MapTitle";
import { useParams, useNavigate } from "react-router-dom";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const FarmerDetails = () => {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("detail");

    let { tab_id: activeRoute, id: farmer_id } = useParams();

    useEffect(() => {
        if (activeRoute) {
            setActiveTab(activeRoute);
        }
    }, [activeRoute]);
    const { can, cannot } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].farmer_slug.list_farmers),
        unAuthUserRedirectTo: "/401",
    };

    const ShowActiveExpand = () => {
        switch (activeTab) {
            case "details":
                return {
                    header: <DetailHeader />,
                    body: <Details farmer_id={farmer_id} />,
                    pagetitle: <DetailTitle />,
                };
            case "projects":
                return {
                    header: <ProjectHeader />,
                    body: <Projects farmer_id={farmer_id} />,
                    pagetitle: <ProjectTitle />,
                };

            case "fields":
                return {
                    header: <FieldHeader />,
                    body: <Fields />,
                    pagetitle: <FieldTitle />,
                };
            case "fieldsmap":
                return {
                    header: <MapHeader />,
                    body: <Map />,
                    pagetitle: <MapTitle />,
                };
            default:
                navigate("/404");
                return {
                    header: "",
                    body: "",
                    pagetitle: "",
                };
        }
    };

    const { header, body, pagetitle } = ShowActiveExpand();

    const handleTabChange = (routeName) => {
        navigate(routeName);
    };
    //

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", mb: "10px" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        {header}
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        // alignItems={"center"}
                        sx={{ mt: 0 }}
                    >
                        {pagetitle}
                        <Grid item xs={12} md="auto">
                            <Tabs
                                tabItem={[
                                    {
                                        name: "Details",
                                        route: `/farmer/${farmer_id}/details`,
                                    },
                                    {
                                        name: "Projects",
                                        route: `/farmer/${farmer_id}/projects`,
                                        disableTabs: cannot(
                                            permissionSlug[0].project_slug
                                                .list_projects
                                        ),
                                    },
                                    {
                                        name: "Fields list",
                                        route: `/farmer/${farmer_id}/fields`,
                                        disableTabs: cannot(
                                            permissionSlug[0].fields_slug
                                                .list_fields
                                        ),
                                    },
                                    {
                                        name: "Fields map",
                                        route: `/farmer/${farmer_id}/fieldsmap`,
                                        disableTabs: cannot(
                                            permissionSlug[0].fields_slug
                                                .fields_map
                                        ),
                                    },
                                ]}
                                onTabChange={handleTabChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {body}
            </CheckpageAuth>
        </Layout>
    );
};

export default FarmerDetails;
