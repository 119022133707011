import { styled } from "@mui/material/styles";
import { Stack, Grid } from "@mui/material";
import CustomTable from "common/CustomTable";
import UnderlineText from "common/UnderlineText";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Searchbar from "common/Searchbar";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { ReactComponent as RetirementIcon } from "assets/icons/retirementIcon.svg";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchRegistryCollections } from "redux/registrySlices";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import sanitizedDigits from "util/sanitizedDigits";
import CollectionsStatusColor from "util/CollectionsStatusColor";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "2px 0px",
    fontWeight: "400",
    width: "110px",
}));

const headCells = [
    {
        id: "program_code",
        numeric: true,
        label: "Program code",
    },
    {
        id: "program",
        numeric: true,
        label: "Program",
    },
    {
        id: "methodology",
        numeric: true,
        label: "Methodology",
    },
    {
        id: "vintage",
        numeric: true,
        label: "Vintage",
    },
    {
        id: "registry",
        numeric: true,
        label: "Registry",
    },
    {
        id: "volume",
        numeric: true,
        label: "Volume",
    },
    {
        id: "status",
        numeric: true,
        label: "Status",
    },
    {
        id: "retirement",
        numeric: true,
        label: "Retirement",
    },
];

const CollectionScc = () => {
    const { can } = useAccessPage();
    const dispatch = useDispatch();
    // const initialQuery = useRef({ programId: id, page: 1, limit: 10 });
    const [query, setQuery] = useState({ page: 1, limit: 10, search: "" });
    const { collections, isLoading, collectionsCount } = useSelector(
        (state) => state.registery,
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchRegistryCollections(query));
    }, [query]);
    const tabsAccess = can(permissionSlug[0].scc_slug.list_SCC);

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };

    const sanitazidTabledData =
        collections &&
        collections?.map((item) => ({
            program_code: (
                <TableCell>
                    <Link to={`/program/${item.program_id}/detail`}>
                        <UnderlineText>
                            {item.program_program_code}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            program: (
                <TableCell>
                    <Link to={`/program/${item.program_id}/detail`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(item.program_program_name)}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            methodology: item.program_program_methodology,
            vintage: item.field_field_harvest_year,
            registry: item.program_program_registry,
            volume: item.Volume
                ? `${sanitizedDigits(item.Volume)} ${item.program_program_unit}`
                : "-",
            status: (
                <TableCell>
                    <StyledChip
                        label={item.field_field_status}
                        sx={{
                            backgroundColor:
                                CollectionsStatusColor[item.field_field_status],
                            color: "primary.white",
                        }}
                    />
                </TableCell>
            ),
            retirement:
                item.field_field_status.toLowerCase() === "issued" ? (
                    <TableCell>
                        <Link to={`/registry/retirement/${item.program_id}`}>
                            <RetirementIcon />
                        </Link>
                    </TableCell>
                ) : (
                    <TableCell></TableCell>
                ),
        }));
    return tabsAccess ? (
        <>
            <Grid
                item
                xs={12}
                sm={12}
                sx={{
                    width: { xs: "100%" },
                    mt: { xs: "0" },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="10px"
                        sx={{
                            justifyContent: { xs: "center", md: "flex-start" },
                            width: { xs: "100%", md: "auto" },
                        }}
                    >
                        <Searchbar
                            placeholdertext="Search Fields ,Land-use.Location"
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
            </Grid>
            <Box
                sx={{
                    mt: "30px",
                }}
            >
                <CustomTable
                    headCells={headCells}
                    loadingList={isLoading}
                    rows={sanitazidTabledData ? sanitazidTabledData : []}
                    query={query}
                    noDataFound="No collections found"
                    totalRowCount={collectionsCount}
                    setQuery={setQuery}
                />
            </Box>
        </>
    ) : (
        <Box
            sx={{
                mt: "30px",
            }}
        >
            You are not Authorized to view Collections SCC
        </Box>
    );
};

export default CollectionScc;
