import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CertificationFormContainer from "components/program/certification/CertificationFormContainer";
import { useParams } from "react-router-dom";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const Addcertification = () => {
    let { id: program_id } = useParams();

    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(
            permissionSlug[0].certification_slug.create_certificate
        ),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={{ md: 0, xs: "15px" }}
                    mb="10px"
                    minHeight="38px"
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Breadcrumbs
                        sources={[
                            {
                                label: "programs",
                                link: "/programs",
                                canNavigate: true,
                            },
                            {
                                label: "projects",
                                link: `/program/${program_id}/certifications`,
                                canNavigate: true,
                            },
                            {
                                label: "add certificate",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "center",
                                sm: "flex-end",
                            },
                        }}
                    ></Grid>
                </Grid>

                <Typography variant="h2">
                    Add a certification{" "}
                    <Box
                        component="span"
                        sx={{
                            fontWeight: "normal",
                        }}
                    >
                        {" "}
                        to Program {program_id}
                    </Box>
                </Typography>
                <Typography variant="body1" mt={1} mb={3}>
                    Please fill the following form to add a new program.
                </Typography>
                <Typography variant="h4" mb={2}>
                    Certificate information
                </Typography>
                {can(
                    permissionSlug[0].certification_slug.create_certificate
                ) && <CertificationFormContainer />}
                {/* <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    maxWidth: 810,
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <FormControl variant="filled" required fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">
                                Validator
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={age}
                                onChange={handleChange}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                            id="certificate-reference"
                            label="Certificate reference"
                            variant="filled"
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                sx={{
                                    width: "100%",
                                }}
                                label="1st certification"
                                required
                                slotProps={{
                                    textField: {
                                        variant: "filled",
                                        helperText: "DD/MM/YYYY",
                                        required: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                sx={{
                                    width: "100%",
                                }}
                                label="Crediting period start date"
                                required
                                slotProps={{
                                    textField: {
                                        variant: "filled",
                                        helperText: "DD/MM/YYYY",
                                        required: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                sx={{
                                    width: "100%",
                                }}
                                label="Crediting period end date"
                                slotProps={{
                                    textField: {
                                        variant: "filled",
                                        helperText: "DD/MM/YYYY",
                                        required: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={4}>
                        <FileInputNoPreview
                            label="Certificate file"
                            name="Certificate file"
                        />
                    </Grid>
                </Grid>

                <Stack direction="row" spacing={1.5} mt={{ md: "19px" }}>
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Add</Button>
                </Stack>
            </Box> */}
            </CheckpageAuth>
        </Layout>
    );
};

export default Addcertification;
