import { Box, Typography } from "@mui/material";

import Layout from "Layout";
import comingSoon from "assets/images/coming_soon.gif";

const ComingSoon = () => {
    return (
        <Layout>
            <Box
                sx={{
                    background: `url(${comingSoon})`,
                    backgroundSize: "cover",
                    backgroundOrigin: "center",
                    backgroundPosition: "top",
                }}
            >
                <Box
                    height={"85vh"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                        background:
                            "linear-gradient(90deg, #02353ccc 0%, #2eaf7dc7 100%)",
                    }}
                >
                    <Box>
                        <Typography
                            variant="h1"
                            textAlign="center"
                            fontWeight="600"
                            color="#fff"
                            sx={{
                                fontSize: { sm: "42px", lg: "54px" },
                            }}
                        >
                            Coming Soon
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};
export default ComingSoon;
