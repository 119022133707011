import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import UserContact from "components/admin/form/UserContact";
import UserProfile from "components/admin/form/UserProfile";
import UserPreferences from "components/admin/form/UserPreferences";
import UserAddress from "components/admin/form/UserAddress";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";

// import { useSelector } from "react-redux";
// import { editAccountDetails } from "redux/accountSlice";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { AddUser } from "redux/users";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function UserAddFormContainer({ companyData }) {
    const [isLoading, setLoding] = useState(false);
    const [sameAddress, setSameAddress] = useState(false);
    // const { userRole } = useSelector((state) => state.usersList);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const validationSchema = yup.object().shape({
        user_firstname: yup.string().required("First Name is required"),
        user_lastname: yup.string().required("Last Name is required"),
        user_phone: yup.mixed().required("Phone number is required"),
        user_email: yup
            .string()
            .required("Email is required")
            .email("Invalid email"),
        user_job: yup.string().required("Job is required"),
        user_account_type: yup.string().required("Account Type is required"),
        user_status: yup.string().required("Status is required"),
        user_wallet_manager: yup
            .string()
            .required("Wallet Manager is required"),
        user_timezone: yup.string().required("Timezone is required"),
        user_dateformat: yup.string().required("Date Format is required"),
        user_measurement: yup.string().required("Measurement is required"),
        user_language: yup.string().required("Language is required"),
        user_street: yup.string().required("Street is required"),
        user_street_2: yup.string(),
        user_zipcode: yup.string().required("Zip code is required"),
        user_region: yup.string().required("Region is required"),
        user_city: yup.string().required("City is required"),
        user_country: yup.string().required("Country is required"),
        user_id_company: yup.string().required("Company ID is required"),
        // Add validation for other fields as needed...
    });

    const formik = useFormik({
        initialValues: {
            user_firstname: "",
            user_lastname: "",
            user_phone: "",
            user_email: "",
            user_job: "",
            user_account_type: "",
            user_status: "",
            user_wallet_manager: "",
            user_timezone: "",
            user_dateformat: "",
            user_measurement: "",
            user_language: "",
            user_street: "",
            user_street_2: "",
            user_zipcode: "",
            user_region: "",
            user_city: "",
            user_country: "",
            user_id_company: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //sent roleid here
            // const selectedRole = userRole?.find(
            //     (option) => option.name === values.user_account_type
            // );
            const role_id = values.user_account_type;
            values.role_id = role_id ? role_id : null;
            setLoding(true);
            dispatch(AddUser(values)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    setLoding(false);
                    // dispatch(usersList());
                    navigate("/users");
                    formik.resetForm();
                }
                setLoding(false);
            });
        },
    });
    useEffect(() => {
        formik.setFieldValue("user_id_company", id);
    }, []);

    const handleSameAddress = (e) => {
        const checked = e.target.checked;
        setSameAddress(checked);

        formik.setValues({
            ...formik.values,
            user_street: checked ? companyData?.companyStreet || "" : "",
            user_street_2: checked ? companyData?.companyStreet2 || "" : "",
            user_zipcode: checked ? companyData?.companyZipcode || "" : "",
            user_region: checked ? companyData?.companyRegion || "Region" : "",
            user_city: checked ? companyData?.companyCity || "" : "",
            user_country: checked ? companyData?.companyCountry || "" : "",
        });
    };

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
            onSubmit={formik.handleSubmit}
        >
            <UserContact formik={formik} />
            <UserProfile formik={formik} edit={false} />
            <UserPreferences formik={formik} />
            <UserAddress
                formik={formik}
                handleSameAddress={handleSameAddress}
                sameAddress={sameAddress}
            />
            <Stack direction="row" spacing={2} mt={{ xs: "29px", md: "39px" }}>
                <Link to={`/companies/detail/${id}`}>
                    <Button variant="outlined">Cancel</Button>
                </Link>
                <Button type="submit" variant="contained" disabled={isLoading}>
                    {!isLoading ? "Submit" : "Processing ..."}
                </Button>
            </Stack>
        </Box>
    );
}
