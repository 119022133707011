import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage, showSuccessMessage } from "./toastSlice";
import Services from "services";
import { resetStateHander } from "helpers/sanitizedFunction";

export const fetchCertifications = createAsyncThunk(
    "certifications/fetchCertifications",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getCertifications(paramss);
            if (response.data.data.certifications) {
                return { id: paramss.programId, response: response.data.data };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const addCertifications = createAsyncThunk(
    "program/addCertifications",
    async (payload, thunkAPI) => {
        try {
            const response = await Services.createCertifications(payload);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("Certification Added Successfully!")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage(
                    error.message || error.name || error.response.data.message
                )
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    certifications: [],
    count: 0,
    error: null,
    isLoading: false,
    isLoaded: false,
    previousProgramId: null,
};
export const certificationsSlice = createSlice({
    name: "certifications",
    initialState,
    reducers: {
        refetchCertifications: (state) => {
            state.isLoaded = false;
        },
        resetCertification: (state) => {
            resetStateHander(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCertifications.pending, (state) => {
                state.isLoading = true;
                state.count = null;
                state.certifications = [];
            })
            .addCase(fetchCertifications.fulfilled, (state, action) => {
                // Add user to the state array
                state.certifications = action.payload.response.certifications;
                state.count = action.payload.response.count;
                state.previousProgramId = action.payload.id;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(fetchCertifications.rejected, (state) => {
                state.isLoading = false;
                state.isLoaded = false;
            })
            .addCase(addCertifications.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addCertifications.fulfilled, (state) => {
                state.error = null;
                state.isLoading = false;
                state.isLoaded = false;
            })
            .addCase(addCertifications.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
                state.isLoaded = false;
            });
    },
});
export const { refetchCertifications, resetCertification } =
    certificationsSlice.actions;
export default certificationsSlice.reducer;
