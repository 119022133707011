import Login from "pages/Login";
import NotAuthorise from "pages/NotAuthorise";
import About from "pages/About";
import NotFound from "pages/NotFound";
import Program from "pages/program/Program";
import ProgramDetails from "pages/program/ProgramDetails";
import NewProgram from "pages/program/NewProgram";
import AddProject from "pages/projects/AddProject";
import ImportResult from "pages/ImportResult";
import Addcertification from "pages/certifications/Addcertification";
import FarmerDetails from "pages/farmers/FarmerDetails";
import AddFarmer from "pages/farmers/AddFarmer";
import FarmerList from "pages/farmers/FarmerList";
import Field from "pages/fieldmodule/Field";
import FieldDetails from "pages/fieldmodule/FieldDetails";
import Registry from "pages/Registry";
import Admin from "pages/Admin";
import CompanyDetail from "pages/comapny/CompanyDetail";
import RegistryUcoDetails from "pages/RegistryUcoDetails";
import RegistrySccDetails from "pages/RegistrySccDetails";
import MyAccount from "pages/account/MyAccount";
import CreateCompany from "pages/comapny/CreateCompany";
import CreateAccount from "pages/CreateAccount";
import ModifyProgram from "pages/program/ModifyProgram";
import ModifyCompany from "pages/comapny/ModifyCompany";
import ModifyFarmer from "pages/farmers/ModifyFarmer";
import ModifyAccount from "pages/account/ModifyAccount";
import ComingSoon from "pages/ComingSoon";
import EmailVerify from "pages/EmailVerify";
import UserDetails from "pages/user/UserDetails";
import ModifyUser from "pages/user/ModifyUser";
import AddUser from "pages/user/AddUser";
import ProjectDetail from "pages/projects/ProjectDetail";
import ModifyProject from "pages/projects/ModifyProject";
import Roles from "pages/roles/Roles";
import Permissions from "pages/Permissions";
import CollectionSccDetails from "pages/collection/CollectionSccDetails";
const pages = {
    NotFound,
    NotAuthorise,
    Login,
    About,
    Program,
    ProgramDetails,
    NewProgram,
    AddProject,
    ImportResult,
    Addcertification,
    FarmerDetails,
    AddFarmer,
    FarmerList,
    Registry,
    Admin,
    CompanyDetail,
    RegistryUcoDetails,
    RegistrySccDetails,
    CollectionSccDetails,
    MyAccount,
    CreateCompany,
    Field,
    FieldDetails,
    CreateAccount,
    ModifyProgram,
    ModifyCompany,
    ModifyFarmer,
    ModifyAccount,
    ComingSoon,
    EmailVerify,
    UserDetails,
    ModifyUser,
    AddUser,
    ProjectDetail,
    ModifyProject,
    Roles,
    Permissions,
};

export default pages;
