import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import FarmersFormContainer from "components/farmer/form/FarmersFormContainer";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const AddFarmer = () => {
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].farmer_slug.create_farmer),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb="10px"
                >
                    <Breadcrumbs
                        sm="auto"
                        sources={[
                            {
                                label: "farmers",
                                link: "/farmers",
                                canNavigate: true,
                            },
                            {
                                label: "new farmer",
                                link: "",
                            },
                        ]}
                    />
                </Grid>

                <Typography
                    variant="h2"
                    sx={{
                        textAlign: { xs: "center", sm: "left" },
                    }}
                >
                    Add a farmer
                </Typography>
                <Typography
                    variant="body1"
                    mt={1}
                    mb={3}
                    sx={{
                        textAlign: { xs: "center", sm: "left" },
                    }}
                >
                    Please fill the following form to add a new farmer to your
                    famers list.
                </Typography>

                <FarmersFormContainer />
            </CheckpageAuth>
        </Layout>
    );
};

export default AddFarmer;
