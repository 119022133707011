import "./App.css";
import { Provider } from "react-redux";
import { store } from "redux/store";
import Router from "routes";
import { ThemeProvider } from "@mui/material/styles";
import { myTheme } from "Layout/CreateTheme";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
    return (
        <ThemeProvider theme={myTheme}>
            <CssBaseline />
            <div className="App">
                <Provider store={store}>
                    <Router />
                </Provider>
            </div>
        </ThemeProvider>
    );
}

export default App;
