import Grid from "@mui/material/Grid";
import { Skeleton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import OpenPreviousPage from "common/OpenPreviousPage";

export default function DetailsTitle() {
    const { farmersDetails: { farmerCompanyName } = {}, isLoading } =
        useSelector((state) => state.farmers);
    return (
        <>
            <Grid
                item
                xs={12}
                sx={{ pt: "2px !important", flex: "1 !important" }}
            >
                <Typography
                    variant="h2"
                    display="flex"
                    alignItems="center"
                    sx={{
                        fontSize: { xs: "1rem", sm: "1.2rem", lg: "1.5rem" },
                        justifyContent: { xs: "center", md: "flex-start" },
                        textAlign: { xs: "center", md: "left" },
                        alignItems: "flex-start",
                    }}
                >
                    <OpenPreviousPage />
                    {""}
                    {!isLoading ? (
                        `Farmer ${farmerCompanyName}`
                    ) : (
                        <Skeleton
                            variant="text"
                            sx={{ fontSize: "3rem", width: "20rem" }}
                        />
                    )}
                </Typography>
            </Grid>
        </>
    );
}
