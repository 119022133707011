import Layout from "Layout";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Skeleton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ProgramProjectDetail } from "redux/projectSlice";
// import dayjs from "dayjs";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import PdfIcon from "common/PdfIcon";
import { useDateFormatter } from "customHooks/useDateFormatter";
import OpenPreviousPage from "common/OpenPreviousPage";

const ProjectDetail = () => {
    const { id } = useParams();
    const { formatDates } = useDateFormatter();
    const navigate = useNavigate();
    const { projectDetail, isloading } = useSelector(
        (state) => state.project,
        shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ProgramProjectDetail(id));
    }, [id]);
    const farmer = [
        {
            title: "Farmer ID",
            info: projectDetail?.farmer?.id,
        },
        {
            title: "Farmer Ref",
            info: projectDetail?.farmer?.farmerExternalId,
        },
        {
            title: "Farmer Company Name",
            info: capitalizeFirstLetter(
                projectDetail?.farmer?.farmerCompanyName
            ),
        },
        {
            title: "Official ID",
            info: projectDetail?.farmer?.farmerOfficialId,
        },
        {
            title: "VAT number",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerEuVatId),
        },
        {
            title: "European CAP ID",
            info: projectDetail?.farmer?.farmerEuCapId,
        },
    ];
    const contact = [
        {
            title: "First name",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerFirstname),
        },
        {
            title: "Last name",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerLastname),
        },
        {
            title: "Phone number",
            info: projectDetail?.farmer?.farmerPhone,
        },
        {
            title: "Email",
            info: projectDetail?.farmer?.farmerEmail,
        },
    ];
    const address = [
        {
            title: "Street",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerStreet),
        },
        {
            title: "Address complement",
            info:
                capitalizeFirstLetter(projectDetail?.farmer?.farmerStreet2) ||
                "---",
        },
        {
            title: "Zip code",
            info: projectDetail?.farmer?.farmerZipcode,
        },
        {
            title: "City",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerCity),
        },
        {
            title: "Region",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerRegion),
        },
        {
            title: "Country",
            info: capitalizeFirstLetter(projectDetail?.farmer?.farmerCountry),
        },
    ];
    const contract = [
        {
            title: "Reference ID",
            info: projectDetail?.projectReferenceExternalId,
        },
        {
            title: "Start date",
            info: formatDates(projectDetail?.projectStartDate),
        },
        {
            title: "End date",
            info: formatDates(projectDetail?.projectEndDate),
        },
        {
            title: "Signature date",
            info: formatDates(projectDetail?.projectSignatureDate),
        },
        {
            title: "Contract pdf",
            info: projectDetail?.projectPdfFile ? (
                <PdfIcon address={projectDetail?.projectPdfFile} />
            ) : (
                "-"
            ),
        },
        {
            title: "Reference year",
            info: projectDetail?.projectReferenceYear,
        },
        {
            title: projectDetail?.projectActiveYears?.includes(",")
                ? "Active years"
                : "Active year",

            info: projectDetail?.projectActiveYears || "-",
        },
    ];
    return (
        <Layout>
            <Box sx={{ width: "100%", backgroundColor: "primary" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb="10px"
                >
                    <Breadcrumbs
                        sm="auto"
                        sources={[
                            {
                                label: "programs",
                                link: "/programs",
                                canNavigate: true,
                            },
                            {
                                label: "projects",
                                link: `/program/${projectDetail.projectIdProgram}/projects`,
                                canNavigate: true,
                            },
                            {
                                label: "details",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-end" },
                            pt: "2px !important",
                            mb: { xs: "10px", sm: 0 },
                        }}
                    >
                        <Button
                            onClick={() => navigate(`/project-edit/${id}`)}
                            variant="contained"
                            sx={{
                                padding: { xs: "4px 18px", md: "4px 23px" },
                                backgroundColor: "buttonSecondary.main",
                                border: "1px solid #000",
                                fontWeight: "500",
                                my: { xs: "5px", md: "0" },
                                "&:hover": {
                                    color: "#fff",
                                },
                                mr: "10px",
                            }}
                        >
                            Modify
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid item xs={12} md="auto">
                {isloading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "40rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        <OpenPreviousPage />{" "}
                        {capitalizeFirstLetter(projectDetail?.companyName)}{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                // "&:hover": {
                                //     color: "grey",
                                //     cursor: "pointer",
                                // },
                            }}
                        >
                            {" "}
                            #{projectDetail?.projectReferenceExternalId}
                        </Box>
                    </Typography>
                )}
            </Grid>

            <Box sx={{ width: "100%" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    sx={{ mt: 0 }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Farmer
                        </Typography>
                        {farmer.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                alignItems={res?.alignItems}
                                margin={res?.alignItems}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "16px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: { xs: "left", lg: "right" },
                                        padding: "0px 12px !important",
                                        maxWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                        minWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                        mb: { xs: "5px", sm: 0 },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                            lg: "calc(100% - 180px)",
                                        },
                                    }}
                                >
                                    {res.button ? (
                                        <Box component="div">
                                            <Box
                                                component="span"
                                                sx={{
                                                    maxWidth: "78px",
                                                    background: "#1ED800",
                                                    borderRadius: "40px",
                                                    boxShadow: "none",
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    color: "#fff",
                                                    padding: "1px 11px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {res.iconPath} {res.button}
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box
                                            component="div"
                                            sx={{
                                                maxWidth: {
                                                    xs: "100%",
                                                    xl: "70%",
                                                },
                                            }}
                                        >
                                            {isloading ? (
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem" }}
                                                />
                                            ) : (
                                                res.info
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Contact
                        </Typography>
                        {contact.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "16px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: { xs: "left", lg: "right" },
                                        padding: "0px 12px !important",
                                        maxWidth: { xs: "100%", sm: "220px" },
                                        minWidth: { xs: "100%", sm: "220px" },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                        },
                                        maxWidth: { xs: "100%", xl: "70%" },
                                    }}
                                >
                                    {isloading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: "1rem" }}
                                        />
                                    ) : (
                                        res.info
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Address
                        </Typography>
                        {address.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "16px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: { xs: "left", lg: "right" },
                                        padding: "0px 12px !important",
                                        maxWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                        minWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                            lg: "calc(100% - 180px)",
                                        },
                                        maxWidth: { xs: "100%", lg: "70%" },
                                    }}
                                >
                                    {isloading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: "1rem" }}
                                        />
                                    ) : (
                                        res.info
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Contract
                        </Typography>
                        {contract &&
                            contract.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    alignItems={res?.alignItems}
                                    margin={res?.alignItems}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "16px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            minWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            mb: { xs: "5px", sm: 0 },
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: {
                                                xs: "100%",
                                                sm: "calc(100% - 220px)",
                                                lg: "calc(100% - 180px)",
                                            },
                                        }}
                                    >
                                        {res.button ? (
                                            <Box component="div">
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        maxWidth: "78px",
                                                        background: "#1ED800",
                                                        borderRadius: "40px",
                                                        boxShadow: "none",
                                                        fontSize: "14px",
                                                        textTransform: "none",
                                                        color: "#fff",
                                                        padding: "1px 11px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {res.iconPath} {res.button}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                component="div"
                                                sx={{
                                                    maxWidth: {
                                                        xs: "100%",
                                                        xl: "70%",
                                                    },
                                                }}
                                            >
                                                {isloading ? (
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{
                                                            fontSize: "1rem",
                                                        }}
                                                    />
                                                ) : (
                                                    res.info
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};

export default ProjectDetail;
