import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
// import CompanyInformation from "components/admin/form/CompanyInfomation";
// import CompanyProfile from "components/admin/form/CompanyProfile";
// import CompanyAddress from "components/admin/form/CompanyAddress";
import FormContainer from "components/admin/company/FormContainer";

// import { useEffect } from "react";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
import { useParams } from "react-router-dom";
const ModifyCompany = () => {
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].company_slug.edit_company),
        unAuthUserRedirectTo: "/401",
    };
    const { id } = useParams();

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb="10px"
                >
                    <Breadcrumbs
                        sources={[
                            {
                                label: "admin",
                                link: "/companies",
                                canNavigate: true,
                            },
                            // {
                            //     label: "company",
                            //     link: "/companies",
                            //     canNavigate: true,
                            // },
                            {
                                label: "company",
                                link: `/companies/detail/${id}`,
                                canNavigate: true,
                            },
                            {
                                label: "modify company",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "center",
                                sm: "flex-end",
                            },
                            pt: "2px !important",
                        }}
                    ></Grid>
                </Grid>

                <Typography variant="h2">Modify company details</Typography>
                <Typography variant="body1" mt={1} mb={3}>
                    Please fill the following form to modify company details.
                </Typography>
                <Box
                    noValidate
                    autoComplete="off"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                >
                    <FormContainer edit={true} />
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default ModifyCompany;
