import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
function OpenPreviousPage() {
    const navigate = useNavigate();

    return (
        <>
            <ArrowBackIosIcon
                onClick={() => navigate(-1)}
                sx={{
                    fontSize: { xs: "16px", xl: "20px", marginTop: "4px" },
                    "&:hover": {
                        color: "gray",
                        cursor: "pointer",
                        fontWeight: "bold",
                    },
                }}
            />
        </>
    );
}
export default OpenPreviousPage;
