import CustomTable from "common/CustomTable";
import PdfIcon from "common/PdfIcon";
// import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import Searchbar from "common/Searchbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getProgramAllProjects, refetchProject } from "redux/projectSlice";
// import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import UnderlineText from "common/UnderlineText";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import ConvertJsonToCsv from "common/ExportJson";
import { useDateFormatter } from "customHooks/useDateFormatter";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const headCells = [
    {
        id: "refproject",
        numeric: true,
        label: "Ref. project",
    },
    {
        id: "farmername",
        numeric: true,
        label: "Farmer name",
    },
    {
        id: "zipcode",
        numeric: true,
        label: "Zip Code",
    },
    {
        id: "city",
        numeric: true,
        label: "City",
    },
    {
        id: "reffarmer",
        numeric: false,
        label: "Ref. farmer",
    },
    {
        id: "start",
        numeric: true,
        label: "Start",
    },
    {
        id: "end",
        numeric: true,
        label: "End",
    },
    {
        id: "refyear",
        numeric: true,
        label: "Ref. year",
    },
    {
        id: "contract",
        numeric: true,
        label: "Contract",
    },
];

export default function Detail() {
    const { formatDates } = useDateFormatter();
    const { id } = useParams();
    const initialQuery = useRef({ page: 1, limit: 10, search: "" });
    const [query, setQuery] = useState(initialQuery.current);
    const dispatch = useDispatch();
    const { project } = useSelector(
        (state) => ({
            project: state?.project,
        }),
        shallowEqual
    );
    //vairable to store list and loading state
    const AllProjects = project?.projectList?.data?.projects;
    const loading = project?.isloading;
    const totalProgramProjects = project?.projectList?.data?.count;
    const previousProjectID = project?.previousProjectID;
    // const isProjectLoaded = project?.projectLoaded;
    useEffect(() => {
        if (project?.projectLoaded === false) {
            dispatch(getProgramAllProjects({ query, id })).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    initialQuery.current = query;
                }
            });
        }
    }, [project?.projectLoaded]);

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };

    useEffect(() => {
        if (
            initialQuery.current.page !== query.page ||
            initialQuery.current.limit !== query.limit ||
            initialQuery.current.search !== query.search ||
            Number(id) !== Number(previousProjectID)
        ) {
            dispatch(refetchProject());
        }
    }, [query]);

    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].project_slug.list_projects),
        unAuthUserRedirectTo: "/401",
    };

    const sanitizedProjectData =
        AllProjects &&
        AllProjects?.map((item) => ({
            refproject: (
                <TableCell>
                    <Link to={`/projectdetail/${item.id}`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(
                                item.projectReferenceExternalId
                            )}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            farmername: (
                <TableCell>
                    <Link to={`/farmer/${item?.farmer?.id}/details`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(
                                item.farmer?.farmerCompanyName
                            )}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            zipcode: item.farmer?.farmerZipcode,
            city: capitalizeFirstLetter(item.farmer?.farmerCity),

            reffarmer: (
                <TableCell>
                    <Link to={`/farmer/${item?.farmer?.id}/projects`}>
                        <UnderlineText>
                            {item.farmer?.farmerExternalId}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            start: formatDates(item.projectStartDate),
            end: formatDates(item.projectEndDate),
            refyear: item.projectReferenceYear,
            contract: can(
                permissionSlug[0].project_slug.view_project_contract
            ) ? (
                <TableCell>
                    {/* {item.projectPdfFileFile} */}
                    <PdfIcon address={item?.projectPdfFile} />
                </TableCell>
            ) : (
                "-"
            ),
        }));
    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        flexWrap: { xs: "wrap", sm: "inherit" },
                        justifyContent: { xs: "center", sm: "space-between" },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="18px"
                        sx={{ width: "100%", md: "auto" }}
                    >
                        <Searchbar
                            placeholdertext="Search Fields, Land-use, Location, ..."
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    <CustomTable
                        headCells={headCells}
                        rows={sanitizedProjectData ? sanitizedProjectData : []}
                        loadingList={loading}
                        query={query}
                        setQuery={setQuery}
                        totalRowCount={totalProgramProjects}
                        exportbutton={
                            can(
                                permissionSlug[0].project_slug.export_projects
                            ) && (
                                <ConvertJsonToCsv
                                    jsonData={AllProjects?.map((item) => {
                                        return {
                                            "Ref. farmer":
                                                item.farmer?.farmerExternalId,
                                            "Farmer name":
                                                item.farmer?.farmerFirstname,
                                            "Zip Code":
                                                item.farmer?.farmerZipcode,
                                            City: item.farmer?.farmerCity,
                                            "Ref. project":
                                                item.projectReferenceExternalId,
                                            Start: formatDates(
                                                item.projectStartDate
                                            ),
                                            End: formatDates(
                                                item.projectEndDate
                                            ),
                                            "Ref. year":
                                                item.projectReferenceYear,
                                            Contract: item.projectPdfFile,
                                        };
                                    })}
                                />
                            )
                        }
                        noDataFound="No projects found"
                    />
                </Box>
            </CheckpageAuth>
        </>
    );
}
