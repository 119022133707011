import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import FarmersFormContainer from "components/farmer/form/FarmersFormContainer";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getFarmers } from "redux/farmersSlice";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const ModifyFarmer = () => {
    const { id: farmer_id } = useParams();
    const dispatch = useDispatch();
    const { farmersDetails } = useSelector((state) => state.farmers);

    useEffect(() => {
        if (Object.keys(farmersDetails).length === 0) {
            dispatch(getFarmers(farmer_id));
        }
    }, [farmersDetails]);
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].farmer_slug.edit_farmer),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Breadcrumbs
                        sources={[
                            {
                                label: "farmers",
                                link: "/farmers",
                                canNavigate: true,
                            },
                            {
                                label: "details",
                                link: `/farmer/${farmer_id}/details`,
                                canNavigate: true,
                            },
                            {
                                label: "modify farmer",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "center",
                                sm: "flex-end",
                            },
                            pt: "2px !important",
                        }}
                    ></Grid>
                </Grid>

                <Typography variant="h2">Modify farmer details</Typography>
                <Typography variant="body1" mt={1} mb={3}>
                    Please fill the following form to modify farmer details.
                </Typography>
                <FarmersFormContainer edit={true} farmers={farmersDetails} />
            </CheckpageAuth>
        </Layout>
    );
};

export default ModifyFarmer;
