import useAxios from "helpers/useAxios";
import protectedRequest from "helpers/protectedRequest.js";
const getAccount = async () => {
    return await protectedRequest(() => useAxios.get(`auth/account`));
};

const editAccount = async (payload) => {
    return await protectedRequest(() =>
        useAxios.patch(`auth/update/user`, payload)
    );
};

const editAdminAccount = async (payload) => {
    return await protectedRequest(() =>
        useAxios.patch(`auth/update/admin`, payload)
    );
};

const toggleSharingOptions = async (payload) => {
    return await protectedRequest(() =>
        useAxios.patch(`users/shareoption/${payload.id}`, payload.data)
    );
};
export { getAccount, editAccount, editAdminAccount, toggleSharingOptions };
