import Breadcrumbs from "common/Breadcrumb";
import { useParams } from "react-router-dom";

export default function FieldHistoryHeader() {
    const { id } = useParams();
    return (
        <>
            <Breadcrumbs
                sources={[
                    {
                        label: "fields",
                        link: "/field/list",
                        canNavigate: true,
                    },
                    {
                        label: `field #${id}`,
                        link: "",
                    },
                ]}
            />
        </>
    );
}
