import Layout from "Layout";
const About = () => {
    return (
        <Layout>
            <div>About</div>
        </Layout>
    );
};

export default About;
