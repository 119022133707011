import protectedRequest from "helpers/protectedRequest";
import useAxios from "helpers/useAxios.js";
const updateWalletAddress = async (params) => {
    return await protectedRequest(() =>
        useAxios.patch(`company/update/wallet/${params.companyId}`, {
            wallet_public_key: params.address,
            wallet_email: params.email,
        })
    );
};
export { updateWalletAddress };
