import useAxios from "helpers/useAxios";
import protectedRequest from "helpers/protectedRequest.js";

const getFarmers = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`farmer/all`, {
            params: {
                page: paramss.page,
                limit: paramss.limit,
                search: paramss.search,
                ...(paramss.id && { companyId: paramss.id }),
            },
        })
    );
};

const createFarmer = async (payload) => {
    return await protectedRequest(() =>
        useAxios.post(`farmer/create`, payload)
    );
};

const getFarmersDetails = async (id) => {
    return await protectedRequest(() => useAxios.get(`farmer/${id}`));
};

const editFarmers = async (params) => {
    return await protectedRequest(() =>
        useAxios.patch(`farmer/${params.id}`, params.payload)
    );
};

const getProjectsByFarmers = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(
            `farmer/all/projects?id=${paramss.id}&page=${paramss.page}&limit=${paramss.limit}`
        )
    );
};

const uploadProjectFile = async (payload) => {
    return await protectedRequest(() =>
        useAxios.post(`projects/upload/${payload.projectID}`, payload.payload)
    );
};

const uploadFarmersCsvFile = async (payload) => {
    return await protectedRequest(() =>
        useAxios.post(`farmer/upload`, payload)
    );
};
const uploadFinalFarmersCsvToDb = async (payload) => {
    return await protectedRequest(() =>
        useAxios.post(`farmer/create/valid_farmer`, { data: payload })
    );
};
export {
    getFarmers,
    createFarmer,
    getFarmersDetails,
    editFarmers,
    getProjectsByFarmers,
    uploadProjectFile,
    uploadFarmersCsvFile,
    uploadFinalFarmersCsvToDb,
};
