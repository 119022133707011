import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormContainerProject from "components/project/FormContainerProject";
// import Stack from "@mui/material/Stack";

import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProgramProjectDetail } from "redux/projectSlice";

const ModifyProject = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { projectDetail } = useSelector(
        (state) => state.project,
        shallowEqual
    );

    useEffect(() => {
        dispatch(ProgramProjectDetail(id));
    }, []);
    return (
        <Layout>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 1 }}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb="10px"
            >
                <Breadcrumbs
                    sources={[
                        {
                            label: "programs",
                            link: "/programs",
                            canNavigate: true,
                        },
                        {
                            label: "projects",
                            link: `/program/${projectDetail.projectIdProgram}/projects`,
                            canNavigate: true,
                        },
                        {
                            label: "details",
                            link: `/projectdetail/${id}`,
                            canNavigate: true,
                        },
                        {
                            label: "modify project",
                            link: "",
                        },
                    ]}
                />
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: "flex",
                        justifyContent: {
                            xs: "center",
                            sm: "flex-end",
                        },
                        pt: "2px !important",
                    }}
                ></Grid>
            </Grid>

            <Typography variant="h2">Modify project details</Typography>
            <Typography variant="body1" mt={1} mb={3}>
                Please fill the following form to modify project details.
            </Typography>
            <Box
                noValidate
                autoComplete="off"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <FormContainerProject
                    edit={true}
                    projectDetail={projectDetail}
                />
            </Box>
        </Layout>
    );
};

export default ModifyProject;
