import {
    Box,
    Button,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Modal from "common/Modal";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cancelSCCs } from "redux/registrySlices";

const customStyle = {
    maxWidth: { xs: "90%", sm: "450px" },
};
function CancelsSCC({
    balance,
    setRefetchings,
    openCancelsModal,
    setOpenCancelsModal,
    setRefetch,
    cancellingSCCs,
}) {
    const [status, setStatus] = useState(null);
    const [sCCsValues, setSCCsValues] = useState(null);
    const { field_id, program_id } = useParams();

    const currentStatusMaxValue =
        balance.find((balance) => balance.status === status)?.value || 0;
    const dispatch = useDispatch();
    const handleCancelClicks = () => {
        setOpenCancelsModal(false);
        setSCCsValues(null);
        setStatus(null);
    };
    const handleValuesChange = (e) => {
        setSCCsValues(e.target.value);
    };
    const handleCancel = () => {
        dispatch(
            cancelSCCs({
                old_status: status,
                fieldId: field_id,
                value: sCCsValues,
                programId: program_id,
            })
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                setOpenCancelsModal(false);
                setRefetchings((valuess) => {
                    return !valuess;
                });
                setRefetch((fetchss) => !fetchss);
                setStatus(null);
                setSCCsValues(null);
            }
        });
    };

    const isInvalidValue = () => {
        let inValid = false;

        const intsMax = parseFloat(currentStatusMaxValue);
        const intsValues = parseFloat(sCCsValues);
        if (intsValues > intsMax) {
            inValid = true;
        }
        return inValid;
    };
    const SCCsStatus = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    fullWidth
                    required
                    id="SCCsStatus"
                    label="Status"
                    variant="filled"
                    select
                    name="SCCsStatus"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    {balance
                        ?.filter((item) => item.status !== "Cancelled")
                        ?.map((option) => (
                            <MenuItem key={option.status} value={option.status}>
                                {option.status}
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
        );
    }, [balance, status, setStatus]);

    const SCCsValuess = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="SCCsValuess"
                    label="Value"
                    variant="filled"
                    name="SCCsValuess"
                    disabled={status === null}
                    value={sCCsValues}
                    onChange={(e) => handleValuesChange(e)}
                    error={isInvalidValue()}
                    helperText={
                        isInvalidValue() &&
                        `Maximum value for the status ${status} is ${currentStatusMaxValue}`
                    }
                />
            </Grid>
        );
    }, [status, sCCsValues]);
    return (
        <Modal
            customStyle={customStyle}
            title={
                <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                        Are you sure you want to cancel this SCC ?
                    </Typography>
                </Box>
            }
            body={
                <>
                    <Grid sx={{ paddingBottom: "18px", paddingTop: "30px" }}>
                        {SCCsStatus}
                    </Grid>
                    {SCCsValuess}
                </>
            }
            footer={
                <Stack
                    direction="row"
                    spacing={1}
                    mt="20px"
                    justifyContent="center"
                >
                    <Button
                        variant="outlined"
                        onClick={() => handleCancelClicks()}
                        sx={{ fontWeight: "500" }}
                        disabled={cancellingSCCs}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="buttonPrimary"
                        onClick={() => handleCancel()}
                        disabled={
                            cancellingSCCs ||
                            status === null ||
                            isInvalidValue() ||
                            sCCsValues <= 0
                        }
                    >
                        {cancellingSCCs ? "Processing..." : "Confirm"}
                    </Button>
                </Stack>
            }
            open={openCancelsModal}
        />
    );
}

export default CancelsSCC;
