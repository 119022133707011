import { useEffect, useState } from "react";
import permissionSlug from "assets/data/permissionSlug.json";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Tabs from "../../common/Tabs";
import Box from "@mui/material/Box";
import Details from "components/program/detail/Details";
import DetailHeader from "components/program/detail/DetailHeader";
import DetailTitle from "components/program/detail/DetailTitle";
import Projects from "components/program/project/Projects";
import ProjectHeader from "components/program/project/ProjectHeader";
import ProjectTitle from "components/program/project/ProjectTitle";
import Fields from "components/program/field/Fields";
import FieldHeader from "components/program/field/FieldHeader";
import FieldTitle from "components/program/field/FieldTitle";
import Certifications from "components/program/certification/Certification";
import CertificationHeader from "components/program/certification/CertificationHeader";
import CertificationTitle from "components/program/certification/CertificationTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams, useNavigate } from "react-router-dom";
import { useAccessPage } from "customHooks/useAccess";

const ProgramDetails = () => {
    const navigate = useNavigate();
    let { tab_id: activeRoute, id: program_id } = useParams();

    const [activeTab, setActiveTab] = useState(activeRoute);

    useEffect(() => {
        if (activeRoute) {
            setActiveTab(activeRoute);
        }
    }, [activeRoute]);

    const { cannot } = useAccessPage();

    const ShowActiveExpand = () => {
        switch (activeTab) {
            case "detail":
                return {
                    header: <DetailHeader />,
                    body: <Details program_id={program_id} />,
                    pagetitle: <DetailTitle />,
                };
            case "projects":
                return {
                    header: <ProjectHeader />,
                    body: <Projects />,
                    pagetitle: <ProjectTitle />,
                };

            case "fields":
                return {
                    header: <FieldHeader />,
                    body: <Fields />,
                    pagetitle: <FieldTitle />,
                };

            case "certifications":
                return {
                    header: <CertificationHeader />,
                    body: <Certifications />,
                    pagetitle: <CertificationTitle />,
                };

            default:
                navigate("/404");
                return {
                    header: "",
                    body: "",
                    pagetitle: "",
                };
        }
    };

    const { header, body, pagetitle } = ShowActiveExpand();

    const handleTabChange = (routeName) => {
        navigate(routeName);
    };
    //for certif. on mobile view
    const isMobile = useMediaQuery("(max-width:350px)");

    return (
        <Layout>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Grid
                    container
                    rowSpacing={{ md: 0, xs: "15px" }}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb="10px"
                >
                    {header}
                </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ mt: 0, flexWrap: { xs: "wrap", md: "nowrap" } }}
                >
                    {pagetitle}
                    <Grid item xs={12} md="auto">
                        <Tabs
                            tabItem={[
                                {
                                    name: "Details",
                                    route: `/program/${program_id}/detail`,
                                    disableTabs: cannot(
                                        permissionSlug[0].programSlug
                                            .program_details
                                    ),
                                },

                                {
                                    name: "Projects",
                                    route: `/program/${program_id}/projects`,
                                    disableTabs: cannot(
                                        permissionSlug[0].project_slug
                                            .list_projects
                                    ),
                                },
                                {
                                    name: "Fields",
                                    route: `/program/${program_id}/fields`,
                                    disableTabs: cannot(
                                        permissionSlug[0].fields_slug
                                            .list_fields
                                    ),
                                },

                                {
                                    name: isMobile
                                        ? "Certif."
                                        : "Certifications",
                                    route: `/program/${program_id}/certifications`,
                                    disableTabs: cannot(
                                        permissionSlug[0].certification_slug
                                            .list_certificates
                                    ),
                                },
                            ]}
                            onTabChange={handleTabChange}
                        />
                    </Grid>
                </Grid>
            </Box>

            {body}
        </Layout>
    );
};

export default ProgramDetails;
