import Cookies from "js-cookie";

const setToken = (token) => {
    Cookies.set("token", token);
};

const getToken = () => {
    const token = Cookies.get("token");
    return token;
};

const removeToken = () => {
    Cookies.remove("token");
};

const setBlockChainToken = (token) => {
    Cookies.set("blockchain_token", token);
};

const getBlockChainToken = () => {
    const token = Cookies.get("blockchain_token");
    return token;
};

const removeBlockChainToken = () => {
    Cookies.remove("blockchain_token");
};

export {
    setToken,
    getToken,
    removeToken,
    getBlockChainToken,
    setBlockChainToken,
    removeBlockChainToken,
};
