import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useMemo } from "react";
import AutocompleteAddress from "common/AutocompleteAddress";

const UserAddress = ({ formik, handleSameAddress, sameAddress }) => {
    const onAddressSelect = (address) => {
        const farmerFullAddress = {
            user_zipcode: address.zip_code,
            user_city: address.city,
            user_country: address.country,
            user_street: address.street,
            user_region: address.region,
            user_street_2: address.street_two,
        };
        formik.setValues((prev) => ({ ...prev, ...farmerFullAddress }));
    };
    const autoCompeletionAddress = useMemo(() => {
        return (
            <Box
                sx={{
                    "@media (min-width: 768px)": {
                        gridColumn: "span 2",
                    },
                }}
            >
                <AutocompleteAddress
                    formik={formik}
                    name="autocompletionAddress"
                    onAddressSelect={onAddressSelect}
                    sameAddress={sameAddress}
                />
            </Box>
        );
    }, [formik.values.autocompletionAddress, sameAddress]);

    const user_street = useMemo(() => {
        return (
            <Box>
                <TextField
                    required={!sameAddress}
                    fullWidth
                    id="user_street"
                    name="user_street"
                    label="Street"
                    variant="filled"
                    value={formik.values.user_street}
                    onChange={formik.handleChange}
                    disabled={sameAddress}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.user_street &&
                        !!formik.errors.user_street
                    }
                    helperText={
                        formik.touched.user_street && formik.errors.user_street
                    }
                />
            </Box>
        );
    }, [
        formik.values.user_street,
        sameAddress,
        formik.errors.user_street,
        formik.touched.user_street,
    ]);

    const user_street_2 = useMemo(() => {
        return (
            <Box>
                <TextField
                    fullWidth
                    id="user_street_2"
                    name="user_street_2"
                    label="Address complement"
                    variant="filled"
                    value={formik.values.user_street_2}
                    onChange={formik.handleChange}
                    disabled={sameAddress}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.user_street_2 &&
                        !!formik.errors.user_street_2
                    }
                    helperText={
                        formik.touched.user_street_2 &&
                        formik.errors.user_street_2
                    }
                />
            </Box>
        );
    }, [
        formik.values.user_street_2,
        sameAddress,
        formik.errors.user_street_2,
        formik.touched.user_street_2,
    ]);

    const user_zipcode = useMemo(() => {
        return (
            <Box>
                <TextField
                    required={!sameAddress}
                    fullWidth
                    id="user_zipcode"
                    name="user_zipcode"
                    label="ZipCode"
                    variant="filled"
                    type="number"
                    value={formik.values.user_zipcode}
                    onChange={formik.handleChange}
                    disabled={sameAddress}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.user_zipcode &&
                        !!formik.errors.user_zipcode
                    }
                    helperText={
                        formik.touched.user_zipcode &&
                        formik.errors.user_zipcode
                    }
                />
            </Box>
        );
    }, [
        formik.values.user_zipcode,
        sameAddress,
        formik.errors.user_zipcode,
        formik.touched.user_zipcode,
    ]);

    const user_region = useMemo(() => {
        return (
            <Box>
                <TextField
                    required={!sameAddress}
                    fullWidth
                    id="user_region"
                    name="user_region"
                    label="Region"
                    variant="filled"
                    value={formik.values.user_region}
                    onChange={formik.handleChange}
                    disabled={sameAddress}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.user_region &&
                        !!formik.errors.user_region
                    }
                    helperText={
                        formik.touched.user_region && formik.errors.user_region
                    }
                />
            </Box>
        );
    }, [
        formik.values.user_region,
        sameAddress,
        formik.errors.user_region,
        formik.touched.user_region,
    ]);

    const user_city = useMemo(() => {
        return (
            <Box>
                <TextField
                    required={!sameAddress}
                    fullWidth
                    id="user_city"
                    name="user_city"
                    label="City"
                    variant="filled"
                    value={formik.values.user_city}
                    onChange={formik.handleChange}
                    disabled={sameAddress}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.user_city && !!formik.errors.user_city
                    }
                    helperText={
                        formik.touched.user_city && formik.errors.user_city
                    }
                />
            </Box>
        );
    }, [
        formik.values.user_city,
        sameAddress,
        formik.errors.user_city,
        formik.touched.user_city,
    ]);

    const user_country = useMemo(() => {
        return (
            <Box>
                <TextField
                    required={!sameAddress}
                    fullWidth
                    id="user_country"
                    name="user_country"
                    label="Country"
                    variant="filled"
                    value={formik.values.user_country}
                    onChange={formik.handleChange}
                    disabled={sameAddress}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.user_country &&
                        !!formik.errors.user_country
                    }
                    helperText={
                        formik.touched.user_country &&
                        formik.errors.user_country
                    }
                />
            </Box>
        );
    }, [
        formik.values.user_country,
        sameAddress,
        formik.errors.user_country,
        formik.touched.user_country,
    ]);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                maxWidth="530px"
                mt={1}
                sx={{
                    fontSize: "20px",
                    "@media (max-width: 540px)": {
                        display: "block",
                    },
                }}
            >
                <Typography variant="h4">Address</Typography>
                <Box
                    display="flex"
                    justifyContent={"end"}
                    alignItems="center"
                    sx={{
                        fontSize: "20px",
                        "@media (max-width: 540px)": {
                            justifyContent: "space-between",
                        },
                    }}
                >
                    <Typography variant="span" mr={1}>
                        Check if same as the company address
                    </Typography>
                    <Checkbox
                        onChange={handleSameAddress}
                        checked={sameAddress}
                    />
                </Box>
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {/* <Box
                    sx={{
                        "@media (min-width: 768px)": {
                            gridColumn: "span 2",
                        },
                    }}
                >
                    <TextField
                        fullWidth
                        id="address"
                        label="Autocompletion address"
                        variant="filled"
                    />
                </Box> */}
                {autoCompeletionAddress}
                {user_street}
                {user_street_2}
                {user_zipcode}
                {user_region}
                {user_city}
                {user_country}
            </Box>
        </>
    );
};

export default UserAddress;
