import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import UserContact from "components/admin/form/UserContact";
import UserProfile from "components/admin/form/UserProfile";
import UserPreferences from "components/admin/form/UserPreferences";
import UserAddress from "components/admin/form/UserAddress";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { editUser } from "redux/users";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCompanyDetaill } from "redux/companiesSlice";
// import { logDOM } from "@testing-library/react";

export default function UserEditFormContainer({ userDetail, edit }) {
    const [sameAddress, setSameAddress] = useState(false);
    const [isLoading, setLoding] = useState(false);
    // const { userRole } = useSelector((state) => state.usersList);
    useEffect(() => {
        if (edit) {
            dispatch(getCompanyDetaill(userDetail?.company?.id));
        }
    }, [userDetail?.company?.id]);
    const {
        id,
        user_firstname,
        user_lastname,
        user_phone,
        user_email,
        user_job,
        // user_account_type,
        // role: { slug },
        user_status,
        user_wallet_manager,
        user_timezone,
        user_dateformat,
        user_measurement,
        user_language,
        user_street,
        user_street_2,
        user_zipcode,
        user_region,
        user_city,
        user_country,
        user_id_company,
    } = userDetail;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validationSchema = yup.object().shape({
        user_firstname: yup.string().required("First Name is required"),
        user_lastname: yup.string().required("Last Name is required"),
        user_phone: yup.mixed().required("Phone number is required"),
        user_email: yup
            .string()
            .test("user_email", "Please enter valid email", (value) => {
                if (value?.includes("XXXXXX")) {
                    return true;
                } else {
                    return /^\S+@\S+\.\S+$/.test(value);
                }
            }),

        user_job: yup.string().required("Job is required"),
        user_account_type: yup.string().required("Account Type is required"),
        user_status: yup.string().required("Status is required"),
        user_wallet_manager: yup
            .string()
            .required("Wallet Manager is required"),
        user_timezone: yup.string().required("Timezone is required"),
        user_dateformat: yup.string().required("Date Format is required"),
        user_measurement: yup.string().required("Measurement is required"),
        user_language: yup.string().required("Language is required"),
        user_street: yup.string().required("Street is required"),
        user_street_2: yup.string(),
        user_zipcode: yup.string().required("Zip Code is required"),
        user_region: yup.string(),
        user_city: yup.string().required("City is required"),
        user_country: yup.string().required("Country is required"),
        user_id_company: yup.string().required("Company ID is required"),
        // Add validation for other fields as needed...
    });

    const formik = useFormik({
        initialValues: {
            user_firstname: "",
            user_lastname: "",
            user_phone: "",
            user_email: "",
            user_job: "",
            user_account_type: "",
            user_status: "",
            user_wallet_manager: "",
            user_timezone: "",
            user_dateformat: "",
            user_measurement: "",
            user_language: "",
            user_street: "",
            user_street_2: "",
            user_zipcode: "",
            user_region: "",
            user_city: "",
            user_country: "",
            user_id_company: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // const selectedRole = userRole.find(
            //     (option) => option.slug === values.user_account_type
            // );
            setLoding(true);
            const checkSharePersonalData = userDetail?.user_share_personal_data;
            var hiddenEmailFormat = "xxxxxx@gmail.com";
            values.user_email =
                checkSharePersonalData === 0
                    ? hiddenEmailFormat
                    : values.user_email;
            const role_id = values.user_account_type;
            values.role_id = role_id ? role_id : null;
            dispatch(editUser(values)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    // dispatch(usersList());
                    setLoding(false);
                    navigate("/users");
                }
                setLoding(false);
            });
        },
    });

    useEffect(() => {
        if (userDetail) {
            formik.setValues({
                id,
                user_firstname,
                user_lastname,
                user_phone,
                user_email,
                user_job,
                // user_account_type: slug,
                user_status,
                user_wallet_manager,
                user_timezone,
                user_dateformat,
                user_measurement,
                user_language,
                user_street,
                user_street_2,
                user_zipcode,
                user_region,
                user_city,
                user_country,
                user_id_company,
            });
        }
        formik.setFieldValue("user_account_type", userDetail.role.id);
    }, [userDetail]);
    const handleSameAddress = (e) => {
        const checked = e.target.checked;
        setSameAddress(checked);

        formik.setValues({
            ...formik.values,
            user_street: checked ? userDetail.company?.companyStreet || "" : "",
            user_street_2: checked
                ? userDetail.company?.companyStreet2 || ""
                : "",
            user_zipcode: checked
                ? userDetail.company?.companyZipcode || ""
                : "",
            user_region: checked ? userDetail.company?.companyRegion || "" : "",
            user_city: checked ? userDetail.company?.companyCity || "" : "",
            user_country: checked
                ? userDetail.company?.companyCountry || ""
                : "",
        });
    };
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
            onSubmit={formik.handleSubmit}
        >
            <UserContact formik={formik} />
            <UserProfile formik={formik} edit={true} />
            <UserPreferences formik={formik} />
            <UserAddress
                formik={formik}
                handleSameAddress={handleSameAddress}
                sameAddress={sameAddress}
            />
            <Stack direction="row" spacing={2} mt={{ xs: "29px", md: "39px" }}>
                <Link to={`/users/detail/${userDetail?.id}`}>
                    <Button variant="outlined">Cancel</Button>
                </Link>
                <Button type="submit" variant="contained" disabled={isLoading}>
                    {!isLoading ? "Submit" : "Processing ..."}
                </Button>
            </Stack>
        </Box>
    );
}
