import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage } from "./toastSlice";
import Services from "services";
import errorHandler from "helpers/errorhandler";
import { resetStateHander } from "helpers/sanitizedFunction";
export const globalSearch = createAsyncThunk(
    "globalSearchSlice/globalSearch",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.searchAllData(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
const initialState = {
    searchData: [],
    error: null,
    isLoading: false,
    isLoaded: false,
};
export const searchAllSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        resetAllSearch: (state) => {
            resetStateHander(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(globalSearch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(globalSearch.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.searchData = payload;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(globalSearch.rejected, (state) => {
                state.isLoading = false;
            });
    },
});
export const { resetAllSearch } = searchAllSlice.actions;
export default searchAllSlice.reducer;
