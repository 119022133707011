import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FarmLogo from "../assets/images/logofarm.svg";
// import UserImg from "../assets/images/username.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "redux/authentication";

import { fetchAccountDetails } from "redux/accountSlice";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import AvatarFirstLetter from "util/AccountFirstletter";
import HeaderSearchbar from "common/HeaderSearchbar";
import { globalSearch, resetAllSearch } from "redux/globalSearchSlice";
import { ClickAwayListener, Popper } from "@mui/material";

function Header() {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [openPopper, setOpenPopper] = useState(false);
    const navigate = useNavigate();
    const { accountDetails: user, isLoaded } = useSelector(
        (state) => state.account
    );

    const { searchData } = useSelector((state) => state.globalSearch);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!isLoaded) {
            dispatch(fetchAccountDetails());
        }
    }, [isLoaded]);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleAccount = () => {
        navigate("/my-account");
    };

    const handleLogout = () => {
        dispatch(LogoutUser());
        navigate("/login");
    };

    const handleSearch = (search) => {
        if (search !== "") {
            dispatch(globalSearch(search)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    setOpenPopper(true);
                }
            });
        } else {
            dispatch(resetAllSearch());
            setOpenPopper(false);
        }
    };

    const handleClose = () => {
        setOpenPopper(false);
        dispatch(resetAllSearch());
    };
    return (
        <AppBar
            sx={{
                background: "linear-gradient(90deg, #02353C 0%, #2EAF7D 100%)",
                boxShadow: "none",
                padding: "18px 30px",
                borderBottom: "4px solid #3FD0C9",
            }}
        >
            <Toolbar
                disableGutters
                sx={{
                    justifyContent: { xs: "", sm: "space-between" },
                    minHeight: "auto !important",
                    flexWrap: "wrap",
                }}
            >
                <Box
                    onClick={() => navigate("/programs")}
                    component="img"
                    src={FarmLogo}
                    sx={{
                        cursor: "pointer",
                        width: { xs: "150px", md: "193px" },
                    }}
                ></Box>
                <Box
                    sx={{
                        order: { xs: 3, md: "initial" },
                        mt: { xs: 2, md: 0 },
                        width: { xs: "100%", md: "auto" },
                        display: { xs: "none", md: "block" },
                        position: "relative",
                    }}
                >
                    <HeaderSearchbar
                        handleSearch={(search) => {
                            handleSearch(search);
                        }}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <Box sx={{ position: "absolute" }}>
                        <Popper
                            id={2}
                            open={openPopper && searchValue !== ""}
                            style={{
                                position: "fixed",
                                top: "68px",
                                width: "25em",
                                zIndex: 9999,
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                            sx={{
                                "@media (max-width:480px)": {
                                    top: "97px !important",
                                    left: "50% !important",
                                    width: "92% !important",
                                },
                            }}
                        >
                            {({ TransitionProps }) => (
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Box
                                        {...TransitionProps}
                                        sx={{
                                            p: 2,
                                            bgcolor: "#f7f7f7",
                                            boxShadow:
                                                "0px 0px 15px rgba(0,0,0,0.2)",
                                            borderRadius: 2,
                                            maxHeight: "266px",
                                            overflowY: "scroll",
                                            scrollbarWidth: "thin",
                                            "&::-webkit-scrollbar": {
                                                width: "8px",
                                            },
                                            "&::-webkit-scrollbar-track": {
                                                background: "#f4f4f4",
                                            },
                                            "&::-webkit-scrollbar-thumb": {
                                                background: "#e0e0e0",
                                                borderRadius: "8px",
                                            },
                                        }}
                                    >
                                        {Object.values(searchData).every(
                                            (array) => array.length === 0
                                        ) ? (
                                            "No Data Found"
                                        ) : (
                                            <>
                                                {searchData?.farmers?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                            }}
                                                        >
                                                            Farmers
                                                        </Typography>
                                                        {searchData.farmers.map(
                                                            (farmer) => {
                                                                return (
                                                                    <Link
                                                                        to={`/farmer/${farmer.id}/details`}
                                                                        key={
                                                                            farmer.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {`${capitalizeFirstLetter(
                                                                                farmer.farmerFirstname
                                                                            )} ${capitalizeFirstLetter(
                                                                                farmer.farmerLastname
                                                                            )}`}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                                {searchData?.fields?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                                paddingTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Fields
                                                        </Typography>
                                                        {searchData.fields.map(
                                                            (farmer) => {
                                                                return (
                                                                    <Link
                                                                        to={`/field/${farmer.id}/details`}
                                                                        key={
                                                                            farmer.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {capitalizeFirstLetter(
                                                                                farmer.fieldName
                                                                            )}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}

                                                {searchData?.programs?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                                paddingTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Programs
                                                        </Typography>
                                                        {searchData.programs.map(
                                                            (program) => {
                                                                return (
                                                                    <Link
                                                                        to={`/program/${program.id}/detail`}
                                                                        key={
                                                                            program.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {capitalizeFirstLetter(
                                                                                program.program_name
                                                                            )}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}

                                                {searchData?.projects?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                                paddingTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Projects
                                                        </Typography>
                                                        {searchData.projects.map(
                                                            (project) => {
                                                                return (
                                                                    <Link
                                                                        to={`/projectdetail/${project.id}`}
                                                                        key={
                                                                            project.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {capitalizeFirstLetter(
                                                                                project.projectReferenceExternalId
                                                                            )}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </Box>
                </Box>

                <Box
                    sx={{
                        flexGrow: 0,
                        mr: { xs: "30px", sm: "50px", md: "0" },
                        ml: { xs: "auto", sm: "0" },
                    }}
                >
                    <Tooltip>
                        <IconButton
                            disableRipple
                            onClick={handleOpenUserMenu}
                            sx={{
                                p: 0,
                                fontWeight: "600",
                                fontSize: { xs: "15px", lg: "16px" },
                                color: "#fff",
                                "&:hover": {
                                    background: "none",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    mr: { xs: "0", sm: "12px", lg: "12px" },
                                }}
                            >
                                {/* <Avatar
                                    alt="User Image"
                                    // src={UserImg}
                                    sx={{ border: "2px solid #fff" }}
                                /> */}
                                <Avatar>
                                    {AvatarFirstLetter(user?.user_firstname)}
                                </Avatar>
                                {/* <Box
                                    component="span"
                                    sx={{
                                        width: "14px",
                                        height: "14px",
                                        background: "#F00",
                                        borderRadius: "14px",
                                        border: "2px solid #fff",
                                        position: "absolute",
                                        right: "-4px",
                                        bottom: "0",
                                    }}
                                ></Box> */}
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", sm: "block" },
                                }}
                                component="span"
                            >
                                {capitalizeFirstLetter(
                                    user && user?.user_firstname
                                )}{" "}
                                {user?.user_lastname}
                                {/* Sébastien Hugot */}
                            </Box>
                            <ExpandMoreIcon
                                sx={{
                                    ml: { xs: 1, lg: 2 },
                                    display: { xs: "none", sm: "block" },
                                    height: { xs: "21px", lg: "1em" },
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{
                            mt: { xs: "0", md: "30px" },
                            minWidth: "200px",
                            "&.MuiMenuItem-root:focus-visible": {
                                backgroundColor: "transparent",
                            },
                        }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem onClick={handleAccount}>
                            <Typography textAlign="center">Account</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
