import Box from "@mui/material/Box";

const UnderlineText = ({ children }) => (
    <Box
        component="span"
        sx={{ textDecoration: "underline", textUnderlineOffset: "2px" }}
    >
        {children}
    </Box>
);

export default UnderlineText;
