import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Breadcrumbs from "common/Breadcrumb";
import { useParams, useNavigate } from "react-router-dom";
import { useAccessPage } from "customHooks/useAccess";

export default function DetailsHeader() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isRoles } = useAccessPage();
    const otherUser = isRoles("other_user");
    return (
        <>
            <Breadcrumbs
                sm="auto"
                sources={[
                    {
                        label: "farmers",
                        link: "/farmers",
                        canNavigate: true,
                    },
                    {
                        label: `farmer  #${id}`,
                        link: "",
                    },
                ]}
            />

            <Grid
                item
                xs={12}
                sm
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" },
                }}
            >
                <Button
                    onClick={() => navigate(`/farmer/edit/${id}`)}
                    variant="contained"
                    sx={{
                        padding: { xs: "4px 18px", md: "4px 23px" },
                        backgroundColor: "buttonSecondary.main",
                        border: otherUser
                            ? "1px solid rgba(0, 0, 0, 0.12)"
                            : "1px solid #000",
                        fontWeight: "500",
                        my: { xs: "5px", md: "0" },
                        "&:hover": {
                            color: "#fff",
                        },
                    }}
                    disabled={otherUser}
                >
                    Modify
                </Button>
            </Grid>
        </>
    );
}
