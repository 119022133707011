import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";

import { ReactComponent as Nextarrowicon } from "assets/icons/arrowicon.svg";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import { Box } from "@mui/material";

export default function CustomSeparator({ sources, otherContent }) {
    const navigate = useNavigate();
    const handleBreadCrumpNavigate = (link) => {
        navigate(link);
    };
    return (
        <Grid item xs={12} sm>
            <Stack
                spacing={2}
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm: "flex-start" },
                }}
            >
                <Breadcrumbs
                    separator={
                        <Nextarrowicon sx={{ width: "12px", height: "12px" }} />
                    }
                    aria-label="breadcrumb"
                    color="#000"
                    sx={{
                        lineHeight: "18px",
                        "& a": {
                            color: "inherit",
                        },
                        fontSize: "16px",
                        justifyContent: {
                            md: "flex-start",
                        },
                        display: "flex",
                        minHeight: "38px",
                        alignItems: "center",
                    }}
                >
                    {sources?.map((breadcrumb, index) => {
                        return (
                            <>
                                <Box
                                    key={index}
                                    component="span"
                                    onClick={() =>
                                        handleBreadCrumpNavigate(
                                            breadcrumb.link
                                        )
                                    }
                                    color="inherit"
                                    sx={{
                                        marginRight: "6px",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "none",
                                            color:
                                                breadcrumb.canNavigate &&
                                                "#02353C",
                                        },
                                        "& ol": {
                                            justifyContent: "center",
                                            margin: "auto",
                                        },
                                        cursor:
                                            breadcrumb.canNavigate && "pointer",
                                        fontWeight:
                                            breadcrumb.canNavigate && "bold",
                                    }}
                                >
                                    {capitalizeFirstLetter(breadcrumb.label)}
                                </Box>
                            </>
                        );
                    })}
                </Breadcrumbs>
                {otherContent}
            </Stack>
        </Grid>
    );
}
