import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage, showSuccessMessage } from "redux/toastSlice";
import errorHandler from "helpers/errorhandler";
import { resetStateHander } from "helpers/sanitizedFunction";

export const getProgramAllProjects = createAsyncThunk(
    "projects/getProgramAllProjects",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.getProjectsList(params);
            return { id: params.id, data: data };
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const createProgramProjects = createAsyncThunk(
    "projects/createProgramProjects",
    async (params, thunkAPI) => {
        try {
            const response = await Services.projectsCreate(params);
            if (response?.data?.data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Project Created Successfully!" || response.message
                    )
                );
            }
            return response.data.data;
        } catch (error) {
            // const parsedData = JSON.parse(error.response.data.message);
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(errorHandler(error).data);
        }
    }
);
export const ProgramProjectDetail = createAsyncThunk(
    "projects/ProgramProjectDetail",
    async (params, thunkAPI) => {
        try {
            const response = await Services.projectDetail(params);
            return response?.data.data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const editProgramProject = createAsyncThunk(
    "projects/editProgramProject",
    async (params, thunkAPI) => {
        try {
            const response = await Services.editProjects(params);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("Project updated Successfully!")
                );
            }
            return response?.data.data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const uploadNewProjects = createAsyncThunk(
    "fields/uploadNewProjects",
    async (params, thunkAPI) => {
        try {
            const response = await Services.uploadProjectsCsvFile(params);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Projects uploaded Successfully!" || response.message
                    )
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            // console.log(error.response.data.data);
            // const backendErrorss = Object.values(
            //     error.response.data?.errors || {}
            // );
            // const err = error.response.data.data.errors[0];
            // thunkAPI.dispatch(showErrorMessage(err ?? backendErrorss));
            // return thunkAPI.rejectWithValue(error.response.data);

            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));

            if (error.response.status === 406) {
                return thunkAPI.rejectWithValue(error.response.data.data);
            } else if (
                error.response.status === 422 ||
                error.response.status === 400
            ) {
                return thunkAPI.rejectWithValue(error.response.data.message);
            } else {
                return thunkAPI.rejectWithValue(error.response.data.data);
            }
        }
    }
);
const initialState = {
    isloading: false,
    isUploadings: false,
    projectList: [],
    createProject: [],
    projectDetail: {},
    updateProject: [],
    error: null,
    uploadError: [],
    projectLoaded: false,
    previousProjectID: null,
    farmerAddErrors: {},
};
const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        refetchProject: (state) => {
            state.projectLoaded = false;
        },
        resetBackendErrors: (state, params) => {
            state.farmerAddErrors[params.payload] = "";
        },
        resetProjects: (state) => {
            resetStateHander(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProgramAllProjects.pending, (state) => {
                state.isloading = true;
                state.error = null;
            })
            .addCase(getProgramAllProjects.fulfilled, (state, { payload }) => {
                state.isloading = false;
                state.projectList = payload.data;
                state.previousProjectID = payload.id;
                state.projectLoaded = true;
                state.error = null;
            })
            .addCase(getProgramAllProjects.rejected, (state, { payload }) => {
                state.isloading = false;
                state.projectLoaded = false;
                showErrorMessage(payload);
            })
            .addCase(createProgramProjects.pending, (state) => {
                state.isloading = true;
                state.error = null;
            })
            .addCase(createProgramProjects.fulfilled, (state, payload) => {
                state.isloading = false;
                state.createProject = payload;
                state.farmerAddErrors = {};
                state.error = null;
            })
            .addCase(createProgramProjects.rejected, (state, { payload }) => {
                state.isloading = false;
                state.farmerAddErrors = payload;
                showErrorMessage(payload);
            })
            .addCase(ProgramProjectDetail.pending, (state) => {
                state.isloading = true;
                state.error = null;
            })
            .addCase(ProgramProjectDetail.fulfilled, (state, { payload }) => {
                state.isloading = false;
                state.projectDetail = payload;
                state.error = null;
            })
            .addCase(ProgramProjectDetail.rejected, (state, { payload }) => {
                state.isloading = false;
                showErrorMessage(payload);
            })
            .addCase(editProgramProject.pending, (state) => {
                state.error = null;
                state.isloading = true;
            })
            .addCase(editProgramProject.fulfilled, (state, payload) => {
                state.updateProject = payload;
                state.error = null;
                state.isloading = false;
            })
            .addCase(editProgramProject.rejected, (state, { payload }) => {
                showErrorMessage(payload);
                state.isloading = false;
            })
            .addCase(uploadNewProjects.pending, (state) => {
                state.isUploadings = true;
                state.error = null;
            })
            .addCase(uploadNewProjects.fulfilled, (state) => {
                state.isUploadings = false;
                state.error = null;
            })
            .addCase(uploadNewProjects.rejected, (state, { payload }) => {
                state.isUploadings = false;
                state.uploadError = payload;
                showErrorMessage(payload);
            });
    },
});
export const { refetchProject, resetProjects, resetBackendErrors } =
    projectSlice.actions;
export default projectSlice.reducer;
