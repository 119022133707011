import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAccessPage } from "customHooks/useAccess";
import { useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { resetBackendcsvErrors } from "redux/farmersSlice";
import { resetBackendErrors } from "redux/projectSlice";
const Contact = ({ formik, fieldErrors, type }) => {
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };
    const dispatch = useDispatch();
    const { isRoles } = useAccessPage();
    const handleResetErrors = (key) => {
        if (fieldErrors?.[key]) {
            type == "farmer"
                ? dispatch(resetBackendcsvErrors(key))
                : dispatch(resetBackendErrors(key));
        }
    };

    const farmerFirstname = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerFirstname"
                    label="First name"
                    name="farmerFirstname"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerFirstname}
                    error={showError("farmerFirstname")}
                    helperText={showError("farmerFirstname")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerFirstname,
        formik.touched.farmerFirstname,
        formik.errors.farmerFirstname,
    ]);
    const farmerLastname = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerLastname"
                    label="Last name"
                    name="farmerLastname"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerLastname}
                    error={showError("farmerLastname")}
                    helperText={showError("farmerLastname")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerLastname,
        formik.touched.farmerLastname,
        formik.errors.farmerLastname,
    ]);
    const farmerEmail = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    id="farmerEmail"
                    label="Email"
                    name="farmerEmail"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerEmail}
                    error={showError("farmerEmail")}
                    helperText={showError("farmerEmail")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerEmail,
        formik.touched.farmerEmail,
        formik.errors.farmerEmail,
    ]);
    const farmerPhone = useMemo(() => {
        if (fieldErrors?.farmerPhone) {
            formik.setFieldError("farmerPhone", fieldErrors?.farmerPhone);
        }
        return (
            <Grid item xs={12} sm={6}>
                <PhoneInput
                    fullWidth
                    variant="filled"
                    name="farmerPhone"
                    id="farmerPhone"
                    country={"fr"}
                    sx={{ mb: "5px" }}
                    value={formik.values.farmerPhone}
                    enableSearch={true}
                    // onChange={(farmerPhone, dialCode, e, value) => {
                    //     formik.setFieldValue("farmerPhone", value);
                    // }}
                    onChange={(farmerPhone, dialCode, e, value) => {
                        formik.handleChange(value);
                        handleResetErrors("farmerPhone");
                    }}
                    inputProps={{
                        style: { width: "100%", height: "57px" },
                        name: "farmerPhone",
                        required: true,
                        placeholder: "Phone number",
                    }}
                    countryCodeEditable={false}
                />
                {formik.errors.farmerPhone && formik.touched.farmerPhone && (
                    <div style={{ color: "red" }}>
                        {formik.errors.farmerPhone}
                    </div>
                )}
            </Grid>
        );
    }, [
        formik.values.farmerPhone,
        formik.touched.farmerPhone,
        formik.errors.farmerPhone,
    ]);

    return (
        <>
            {isRoles("other_user") || formik.values.checkFarmerIds ? null : (
                <>
                    <Typography variant="h4">Contact</Typography>
                    <Grid container rowSpacing="10px" columnSpacing="30px">
                        {farmerFirstname}
                        {farmerLastname}
                        {farmerPhone}
                        {farmerEmail}
                    </Grid>
                </>
            )}
        </>
    );
};

export default Contact;
