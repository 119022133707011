import CustomTable from "common/CustomTable";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Searchbar from "common/Searchbar";
import UnderlineText from "common/UnderlineText";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { Link, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "redux/users";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import ConvertJsonToCsv from "common/ExportJson";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
const StyledChipStatus = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    minWidth: "91px",
    fontSize: "14px",
}));
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    fontSize: "14px",
}));
const headCells = [
    {
        id: "userid",
        label: "User ID",
    },
    {
        id: "firstname",
        label: "First name",
    },
    {
        id: "lastname",
        label: "Last name",
    },
    {
        id: "company",
        label: "Company",
    },
    {
        id: "Email",
        label: "Email",
    },
    {
        id: "phone",
        label: "Phone",
    },
    {
        id: "jobtitle",
        label: "Job title",
    },
    {
        id: "status",
        label: "Status",
    },
    {
        id: "type",
        label: "Type",
    },
];

function createData(
    userid,
    firstname,
    lastname,
    company,
    Email,
    phone,
    jobtitle,
    status,
    type
) {
    return {
        userid,
        firstname,
        lastname,
        company,
        Email,
        phone,
        jobtitle,
        status,
        type,
    };
}

export default function Users() {
    const theme = useTheme();
    const { id } = useParams();
    const { can } = useAccessPage();
    const tabsAccess = can(permissionSlug[0].users_slug.list_users);
    const [tableRows, setTableRows] = useState([]);
    const [query, setQuery] = useState({ page: 1, limit: 10, search: "" });
    const { users, loading, count } = useSelector((state) => state?.usersList);
    const response = useSelector((state) => state?.usersList);
    const dispatch = useDispatch();
    useEffect(() => {
        if (tabsAccess) {
            dispatch(usersList({ ...query, ...(id && { id: id }) }));
        }
    }, [dispatch, tabsAccess, query]);
    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };
    useEffect(() => {
        setTableRows([]);
        if (users) {
            users?.forEach((item) => {
                const tableData = createData(
                    can(permissionSlug[0].users_slug.user_details) ? (
                        <TableCell>
                            <Link to={`/users/detail/${item?.id}`}>
                                <UnderlineText>{item.id}</UnderlineText>
                            </Link>
                        </TableCell>
                    ) : (
                        item.id
                    ),
                    capitalizeFirstLetter(item?.user_firstname),
                    capitalizeFirstLetter(item?.user_lastname),
                    can(permissionSlug[0].company_slug.company_details) ? (
                        <TableCell>
                            <Link to={`/companies/detail/${item?.company?.id}`}>
                                <UnderlineText>
                                    {capitalizeFirstLetter(
                                        item.company?.companyName
                                    )}
                                </UnderlineText>
                            </Link>
                        </TableCell>
                    ) : (
                        capitalizeFirstLetter(item.company?.companyName)
                    ),
                    can(permissionSlug[0].users_slug.list_users) ? (
                        <TableCell>
                            <Link to={`/users/detail/${item?.id}`}>
                                <UnderlineText>{item.user_email}</UnderlineText>
                            </Link>
                        </TableCell>
                    ) : (
                        item.user_email
                    ),
                    <TableCell sx={{ minWidth: "173px" }}>
                        {item.user_phone}
                    </TableCell>,
                    capitalizeFirstLetter(item?.user_job),

                    // <TableCell>
                    //     <Link to="/admin/company/detail">
                    //         <UnderlineText>0xD38C...F53d80</UnderlineText>
                    //     </Link>
                    // </TableCell>,
                    <TableCell>
                        <StyledChipStatus
                            label={capitalizeFirstLetter(item.user_status)}
                            icon={
                                <DoneIcon
                                    color="primary.white"
                                    sx={{ fontSize: "16px" }}
                                />
                            }
                            sx={{
                                backgroundColor:
                                    theme.palette.statusColors[
                                        item.user_status
                                    ],
                                color: "primary.white",
                            }}
                        />
                    </TableCell>,

                    <TableCell>
                        <StyledChip
                            label={item.user_account_type
                                .charAt(0)
                                .toUpperCase()}
                            sx={{
                                backgroundColor:
                                    theme.palette.statusColors[
                                        item.user_status
                                    ],
                                color: "primary.white",
                            }}
                        />
                    </TableCell>
                );
                setTableRows((prev) => [...prev, tableData]);
            });
        }
    }, [response]);
    return tabsAccess ? (
        <>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 1 }}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ mt: 1 }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                    sx={{
                        width: "100%",
                        md: "auto",
                        justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                            lg: "space-between",
                            xl: "space-between",
                        },
                    }}
                >
                    <Searchbar
                        placeholdertext="Search a user"
                        handleSearch={(search) => {
                            handleSearch(search);
                        }}
                    />
                </Stack>
            </Grid>
            <Box sx={{ mt: "30px" }}>
                <CustomTable
                    headCells={headCells}
                    exportbutton={
                        can(permissionSlug[0].users_slug.export_users) && (
                            <ConvertJsonToCsv
                                jsonData={users?.map((item) => {
                                    return {
                                        "User ID": item.id,
                                        "First name": item.user_firstname,
                                        "Last name": item.user_lastname,
                                        Company: item.company?.companyName,
                                        Email: item.user_email,
                                        Phone: item.user_phone,
                                        "Job title": item.user_job,
                                        Status: item.user_status,
                                        Type: item.user_account_type,
                                    };
                                })}
                            />
                        )
                    }
                    loadingList={loading}
                    rows={tableRows ? tableRows : []}
                    setQuery={setQuery}
                    query={query}
                    totalRowCount={count}
                    itsuser={true}
                    noDataFound="No data found"
                />
            </Box>
        </>
    ) : (
        <Box
            sx={{
                mt: "30px",
            }}
        >
            You are not Authorized
        </Box>
    );
}
