import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Breadcrumbs from "common/Breadcrumb";
import { useAccessPage } from "customHooks/useAccess";
import { useParams, useNavigate } from "react-router-dom";
import permissionSlug from "assets/data/permissionSlug.json";
import { getProgram } from "redux/programSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export default function CertificationHeader() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { cannot } = useAccessPage();
    const { programDetails } = useSelector((state) => state.program);
    const dispatch = useDispatch();
    useEffect(() => {
        if (Number(id) !== Number(programDetails.id)) {
            dispatch(getProgram(id));
        }
    }, [id]);

    if (!programDetails?.program_status) {
        return null;
    }

    return (
        <>
            <Breadcrumbs
                xs="auto"
                sources={[
                    {
                        label: "programs",
                        link: "/programs",
                        canNavigate: true,
                    },
                    {
                        label: `program  #${id}`,
                        link: `/program/${id}/detail`,
                        canNavigate: true,
                    },
                    {
                        label: "all certifications",
                        link: "",
                    },
                ]}
            />
            <Grid
                item
                xs="auto"
                md={6}
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                <Button
                    variant="contained"
                    disabled={
                        cannot(
                            permissionSlug[0].certification_slug
                                .create_certificate
                        ) ||
                        programDetails?.program_status === "rejected" ||
                        programDetails?.program_status === "withdrawn"
                    }
                    color="buttonPrimary"
                    onClick={() => navigate(`/add-certification/${id}`)}
                >
                    Add a certificate
                </Button>
            </Grid>
        </>
    );
}
