import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FileInput from "common/FileInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useMemo } from "react";
import {
    Box,
    Button,
    FormHelperText,
    ListItemText,
    Stack,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { leadCompaniesList } from "redux/programSlice";
import fieldColors from "assets/data/fieldColors.json";
import MultipleSelectChip from "../detail/ProgramCompaniesSelect";
import Modal from "common/Modal";
import { ReactComponent as Warning } from "assets/icons/warning.svg";
// import { useParams } from "react-router-dom";
// import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
const ProgramInformation = ({ formik, edit, company }) => {
    const [openModal, setOpenModal] = useState(false);
    const { role } = useSelector(
        (state) => state.account?.accessibility,
        shallowEqual
    );

    // const { id } = useParams()
    const [selectedNames, setSelectedNames] = useState([]);
    const [slaveCompId, setSlavecompId] = useState([]);
    const dispatch = useDispatch();
    const { leadCompanies, programDetails } = useSelector(
        (state) => state.program
    );
    useEffect(() => {
        dispatch(leadCompaniesList());
    }, []);
    const handleSelectedCompaniesChange = (selectedCompanies) => {
        setSelectedNames(selectedCompanies);
        const selectedCompany = selectedCompanies?.map((item) => item.id);
        setSlavecompId(selectedCompany);
        formik.setFieldValue("slaveCompanyName", [...selectedCompany]);
    };
    const handleCancel = () => {
        setOpenModal(false);
        formik.setFieldValue("program_status", "");
    };
    let programStatus = [
        { id: 1, name: "Under Validation", value: "under-validation" },
        { id: 2, name: "Registered", value: "registered" },
        { id: 3, name: "On Hold", value: "on-hold" },
        { id: 4, name: "Rejected", value: "rejected" },
        { id: 5, name: "Withdrawn", value: "withdrawn" },
    ];

    const handleStatus = (value) => {
        formik.setFieldValue("program_status", value);
        if (value === "withdrawn") {
            setOpenModal(true);
        }
    };
    const filteredSlaveComp = leadCompanies.filter(
        (item) => !slaveCompId.includes(item.id)
    );
    // if (edit && role === "admin") {
    //     if (programDetails?.program_status === "under-validation") {
    //         programStatus = programStatus.filter(
    //             (status) => status.value !== "under-validation" && status.value !== "withdrawn"
    //         );
    //     } else if (programDetails?.program_status === "on-hold") {
    //         programStatus = programStatus.filter(
    //             (status) => status.value !== "on-hold" && status.value !== "withdrawn" && status.value !== "rejected"
    //         );
    //     } else if (programDetails?.program_status === "registered") {
    //         programStatus = programStatus.filter(
    //             (status) => status.value !== "registered" && status.value !== "under-validation" && status.value !== "rejected"
    //         );
    //     }
    // }

    // if (role !== "admin") {
    //     programStatus = programStatus.filter(
    //         (status) => status.value === "under-validation"
    //     );
    // }
    const shouldShowProgramStatus = (currunetProgramStatus, programStatus) => {
        if (role === "admin" && edit) {
            //for edit
            if (
                currunetProgramStatus === "under-validation" &&
                [
                    "on-hold",
                    "registered",
                    "rejected",
                    "under-validation",
                ].includes(programStatus)
            ) {
                return true;
            } else if (
                currunetProgramStatus === "on-hold" &&
                ["under-validation", "registered", "on-hold"].includes(
                    programStatus
                )
            ) {
                return true;
            } else if (
                currunetProgramStatus === "registered" &&
                ["withdrawn", "on-hold", "registered"].includes(programStatus)
            ) {
                return true;
            }
        } else if (role == "admin" && !edit) {
            // for adding
            return true;
        } else if (role !== "admin" && edit) {
            return currunetProgramStatus === programStatus;
        } else if (role !== "admin" && !edit) {
            return programStatus === "under-validation";
        }

        return false;
    };

    const program_status = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    fullWidth
                    required
                    id="program_status"
                    label="Status"
                    variant="filled"
                    select
                    name="program_status"
                    value={formik.values.program_status}
                    onChange={(e) => handleStatus(e.target.value)}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_status &&
                        !!formik.errors.program_status
                    }
                >
                    {role &&
                        programStatus?.map(
                            (option) =>
                                shouldShowProgramStatus(
                                    programDetails?.program_status,
                                    option.value
                                ) && (
                                    <MenuItem
                                        key={option.id}
                                        disabled={
                                            edit &&
                                            programDetails?.program_status ===
                                                option.value
                                        }
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                )
                        )}
                </TextField>
                {formik.touched.program_status &&
                    formik.errors.program_status && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_status}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_status,
        formik.errors.program_status,
        formik.touched.program_status,
        programStatus,
    ]);

    const program_name = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_name"
                    label="Program Name"
                    variant="filled"
                    name="program_name"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_name &&
                        !!formik.errors.program_name
                    }
                    helperText={
                        formik.touched.program_name &&
                        formik.errors.program_name
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_name,
        formik.touched.program_name,
        formik.errors.program_name,
    ]);

    const program_code = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_code"
                    label="Program code"
                    variant="filled"
                    name="program_code"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_code &&
                        !!formik.errors.program_code
                    }
                    helperText={
                        formik.touched.program_code &&
                        formik.errors.program_code
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_code,
        formik.touched.program_code,
        formik.errors.program_code,
    ]);

    const program_type = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_type"
                    label="Type"
                    variant="filled"
                    select
                    name="program_type"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_type &&
                        !!formik.errors.program_type
                    }
                >
                    <MenuItem value="regenerative agriculture">
                        Regenerative Agriculture
                    </MenuItem>
                </TextField>
                {formik.touched.program_type && formik.errors.program_type && (
                    <FormHelperText
                        sx={{
                            color: "#F00",
                            fontSize: "14px",
                        }}
                    >
                        {formik.errors.program_type}
                    </FormHelperText>
                )}
            </Grid>
        );
    }, [
        formik.values.program_type,
        formik.errors.program_type,
        formik.touched.program_type,
    ]);

    const program_developer = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl
                    required
                    variant="filled"
                    fullWidth
                    // sx={{ mb: "20px" }}
                    label="Company lead developer"
                >
                    <InputLabel id={`${"program_developer"}-label`}>
                        Company lead developer
                    </InputLabel>
                    <Select
                        labelId={`program_developer`}
                        id="program_developer"
                        name="program_developer"
                        disabled={
                            edit ||
                            (edit &&
                                programDetails?.program_status === "registered")
                        }
                        value={formik.values.program_developer}
                        onChange={formik.handleChange}
                        variant="filled"
                    >
                        {filteredSlaveComp?.map((item) => (
                            <MenuItem key={item.id} value={item?.companyName}>
                                {item?.companyName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {formik.touched.program_developer &&
                    formik.errors.program_developer && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_developer}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_developer,
        formik.touched.program_developer,
        formik.errors.program_developer,
        leadCompanies,
        formik.values.slaveCompanyName,
    ]);

    const slave_companies = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <MultipleSelectChip
                    formik={formik}
                    selectedNames={selectedNames}
                    setSelectedNames={setSelectedNames}
                    handleSelectedCompaniesChange={
                        handleSelectedCompaniesChange
                    }
                    company={company}
                    programDetails={programDetails}
                    edit={edit}
                />
            </Grid>
        );
    }, [
        formik.values.slaveCompanyName,
        formik.touched.slaveCompanyName,
        formik.errors.slaveCompanyName,
        formik.values.program_developer,
        selectedNames,
    ]);
    const program_external_id = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    fullWidth
                    id="program_external_id"
                    label="Program external ID"
                    variant="filled"
                    name="program_external_id"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_external_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_external_id &&
                        !!formik.errors.program_external_id
                    }
                    helperText={
                        formik.touched.program_external_id &&
                        formik.errors.program_external_id
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_external_id,
        formik.touched.program_external_id,
        formik.errors.program_external_id,
    ]);

    const program_region = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_region"
                    label="Region"
                    variant="filled"
                    select
                    name="program_region"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_region}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_region &&
                        !!formik.errors.program_region
                    }
                >
                    <MenuItem value="Western Europe">Western Europe</MenuItem>
                    <MenuItem value="Estern Europe">Estern Europe</MenuItem>
                    <MenuItem value="Africa">Africa</MenuItem>
                    <MenuItem value="Asia">Asia</MenuItem>
                    <MenuItem value="North America">North America</MenuItem>
                    <MenuItem value="South America">South America</MenuItem>
                    <MenuItem value="Central America">Central America</MenuItem>
                    <MenuItem value="Oceania">Oceania</MenuItem>
                </TextField>
                {formik.touched.program_region &&
                    formik.errors.program_region && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_region}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_region,
        formik.errors.program_region,
        formik.touched.program_region,
    ]);

    const program_country = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_country"
                    label="Country"
                    variant="filled"
                    select
                    name="program_country"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_country &&
                        !!formik.errors.program_country
                    }
                >
                    <MenuItem value="France">France</MenuItem>
                </TextField>
                {formik.touched.program_country &&
                    formik.errors.program_country && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_country}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_country,
        formik.errors.program_country,
        formik.touched.program_country,
    ]);

    const program_location = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_location"
                    label="Location"
                    variant="filled"
                    name="program_location"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_location &&
                        !!formik.errors.program_location
                    }
                    helperText={
                        formik.touched.program_location &&
                        formik.errors.program_location
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_location,
        formik.touched.program_location,
        formik.errors.program_location,
    ]);
    const FieldColorProgram = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    fullWidth
                    required
                    id="programFieldColor"
                    label="Field color"
                    variant="filled"
                    select
                    name="programFieldColor"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.programFieldColor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.programFieldColor &&
                        !!formik.errors.programFieldColor
                    }
                >
                    {fieldColors.map((color) => (
                        <MenuItem
                            key={color.light}
                            value={color.light}
                            sx={{
                                backgroundColor: `${color.light} !important`,
                                height: "30px",
                                "&:hover": {
                                    backgroundColor: `${color.light} !important`,
                                },
                                borderBottom: "2px solid white",
                            }}
                        >
                            <ListItemText
                                sx={{
                                    backgroundColor: color.light,
                                    height: "15px",
                                    width: "60px",
                                }}
                            >
                                <span style={{ display: "none" }}>
                                    {color.light}
                                </span>
                            </ListItemText>
                        </MenuItem>
                    ))}
                </TextField>
                {formik.touched.programFieldColor &&
                    formik.errors.programFieldColor && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.programFieldColor}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.programFieldColor,
        formik.errors.programFieldColor,
        formik.touched.programFieldColor,
        fieldColors,
    ]);

    const program_description = useMemo(() => {
        return (
            <Grid item xs={12} md={6} lg={8}>
                <TextField
                    sx={{
                        "& .MuiFormHelperText-root": {
                            display: "flex",
                            justifyContent: "space-between",
                        },
                    }}
                    id="program_description"
                    label="Program description"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    required
                    multiline
                    fullWidth
                    resize="auto"
                    rows={2}
                    variant="filled"
                    name="program_description"
                    value={formik.values.program_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_description &&
                        !!formik.errors.program_description
                    }
                    helperText={
                        <React.Fragment>
                            <Typography
                                variant="caption"
                                component="span"
                                color="textSecondary"
                                align="left"
                            >
                                {(formik.touched.program_description &&
                                    formik.errors.program_description) ||
                                    "Maximum 200 characters"}
                            </Typography>
                            <Typography
                                variant="caption"
                                component="span"
                                color="textSecondary"
                                align="right"
                            >
                                {formik.values.program_description?.length}/200
                            </Typography>
                        </React.Fragment>
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_description,
        formik.touched.program_description,
        formik.errors.program_description,
    ]);
    const customStyle = {
        maxWidth: { xs: "90%", sm: "450px" },
    };

    return (
        <>
            <Grid item container spacing="30px" sx={{ paddingBottom: "26px" }}>
                {program_status}
            </Grid>
            <Grid container spacing="30px">
                {program_name}
                {program_code}
                {program_type}
                <Grid item container spacing="30px">
                    {program_developer}
                    {program_external_id}
                </Grid>

                {program_region}
                {program_country}
                {program_location}
                {FieldColorProgram}
                <Grid item xs={12} md={6} lg={8}>
                    <FileInput
                        label="Program image"
                        name="program_image"
                        imagePreview={true}
                        fileName="File format .jpg, .png, .gif, .svg"
                        formik={formik}
                        required={!edit}
                    />
                </Grid>
                {slave_companies}
                {program_description}
            </Grid>
            <Modal
                customStyle={customStyle}
                title={
                    <Box sx={{ textAlign: "center" }}>
                        <span>
                            <Warning />
                        </span>
                        <Typography
                            sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                            You have selected status{" "}
                            <span style={{ color: "#757575" }}>withdrawn</span>
                        </Typography>
                    </Box>
                }
                body={
                    <>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 1, textAlign: "center" }}
                        >
                            <Typography sx={{ mb: "25px", fontSize: "16px" }}>
                                Once the program status is withdrawn, you{" "}
                                {`won't`} be able to make any further changes to
                                it.
                            </Typography>
                        </Typography>
                    </>
                }
                footer={
                    <Stack
                        direction="row"
                        spacing={1}
                        mt="20px"
                        justifyContent="center"
                    >
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            sx={{ fontWeight: "500" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="buttonPrimary"
                            onClick={() => setOpenModal(false)}
                        >
                            Confirm
                        </Button>
                    </Stack>
                }
                open={openModal}
                // close={() => setOpenModal(false)}
            />
        </>
    );
};

export default ProgramInformation;
