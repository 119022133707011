import Layout from "Layout";
import { Button, Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import UserContact from "components/admin/form/UserContact";
import UserProfile from "components/admin/form/UserProfile";
import UserPreferences from "components/admin/form/UserPreferences";
import UserAddress from "components/admin/form/UserAddress";

const CreateAccount = () => {
    return (
        <Layout>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 1 }}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Breadcrumbs />
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: "flex",
                        justifyContent: {
                            xs: "center",
                            sm: "flex-end",
                        },
                        pt: "2px !important",
                    }}
                ></Grid>
            </Grid>

            <Typography variant="h2" mt={2}>
                Add a user{" "}
                <Box component="span" sx={{ fontWeight: "normal" }}>
                    on{" "}
                </Box>
                <Box
                    component="span"
                    sx={{
                        fontWeight: "normal",
                        textDecoration: "underline",
                        textDecorationThickness: "1px",
                    }}
                >
                    Program #0001
                </Box>
            </Typography>
            <Typography variant="body1" mt={1} mb={3}>
                Please fill the following form to add a new user and attach it
                to a company.
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <UserContact />
                <UserProfile />
                <UserPreferences />
                <UserAddress />
                <Stack
                    direction="row"
                    spacing={2}
                    mt={{ xs: "29px", md: "39px" }}
                >
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Save</Button>
                </Stack>
            </Box>
        </Layout>
    );
};

export default CreateAccount;
