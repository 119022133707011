import { useMemo } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
    Checkbox,
    ListItemText,
    FormControl,
    Chip,
    // Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";

export default function ProgramDropdown({ programs, setProgram }) {
    const programOptions = useSelector(
        (state) => state.maps.programOptions,
        shallowEqual
    );
    const handleProgramChanged = (value) => {
        setProgram(value);
    };
    const handleDeleteProgram = (program) => () => {
        setProgram((programs) =>
            programs.filter((year) => year.id !== program.id)
        );
    };
    const programInput = useMemo(() => {
        const programsIDSS = programs.map((pr) => Number(pr.id));
        return (
            // <Grid item xs={12} sm={6} lg={4}>
            <FormControl
                required
                variant="filled"
                multiple
                fullWidth
                sx={{
                    "&.MuiFormControl-root": {
                        maxWidth: {
                            sm: "250px",
                            md: "100%",
                            lg: "250px",
                        },
                    },
                }}
                label="Program"
            >
                <InputLabel id="demo-simple-select-filled-label">
                    Program
                </InputLabel>
                <Select
                    fullWidth
                    multiple
                    value={programs}
                    onChange={(e) => handleProgramChanged(e.target.value)}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((value) => (
                                <Chip
                                    key={value.id}
                                    label={value.name}
                                    onDelete={handleDeleteProgram(value)}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) =>
                                                event.stopPropagation()
                                            }
                                        />
                                    }
                                />
                            ))}
                        </Stack>
                    )}
                >
                    {programOptions.map((program) => (
                        <MenuItem key={program.id} value={program}>
                            <Checkbox
                                checked={
                                    programsIDSS.indexOf(Number(program.id)) >
                                    -1
                                }
                            />
                            <ListItemText primary={program.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            // </Grid>
        );
    }, [programOptions, programs]);

    return programInput;
}
