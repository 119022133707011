import Layout from "Layout";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CustomTable from "common/CustomTable";
import UnderlineText from "common/UnderlineText";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Modal from "common/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldImportedStatusColor from "util/FieldImportedStatusColor";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    deleteFieldImportData,
    uploadCsvFieldsImportResult,
} from "redux/fieldsSlice";
import { getProgram, refetchProgramFields } from "redux/programSlice";
import ConvertJsonToCsv from "common/ExportJson";
import { useTheme } from "@mui/material/styles";
import PaginationForImportResult from "common/PaginationForImportResult";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import sanitizedDigits from "util/sanitizedDigits";
import LoaderOverlay from "common/LoaderOverlay";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    minWidth: "91px",
}));

const headCells = [
    {
        id: "fieldsimported",
        label: "Ref field imported",
    },
    {
        id: "harvest",
        label: "Harvest",
    },
    {
        id: "landuse",
        label: "Land-use",
    },
    {
        id: "area",
        label: "Area (ha)",
    },

    {
        id: "importstatus",
        label: "Import status",
    },
    {
        id: "dedup",
        label: "Dedup.",
    },
    {
        id: "map",
        label: "Map",
    },
    {
        id: "fieldsregistered",
        label: "[Harvest : Field registered - % deduplication]",
        background: "#eee",
        maxWidth: { lg: "500px !important" },
        minWidth: "500px",
    },
];

const ImportResult = () => {
    const [query, setQuery] = useState({ page: 1, limit: 10 });
    const [selectedItem, setSelectedItem] = useState(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();
    const { can } = useAccessPage();
    const [openModal, setOpenModal] = useState(false);
    const {
        csvFieldsUploadData,
        importResultData,
        totalFields,
        totalCountTocehck,
        ucoCreatingLoading,
    } = useSelector((state) => state.field);
    const { programDetails } = useSelector((state) => state.program);
    const navigate = useNavigate();
    const handleImportResultFields = () => {
        if (programDetails) {
            if (can(permissionSlug[0].fields_slug.upload_fields)) {
                dispatch(
                    uploadCsvFieldsImportResult({
                        companyId: programDetails?.program_id_company_KO,
                        programId: programDetails?.id,
                        importResultData,
                        type: "program-page",
                    })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate(
                            `/program/${searchParams.get("programId")}/fields`
                        );
                        dispatch(refetchProgramFields());
                    }
                });
            }
        }
    };

    const tableCellDefaultMinWidth =
        theme.components.MuiTableCell.styleOverrides.root.minWidth;
    theme.components.MuiTableCell.styleOverrides.root.minWidth = "70px";
    useEffect(() => {
        return () => {
            theme.components.MuiTableCell.styleOverrides.root.minWidth =
                tableCellDefaultMinWidth;
        };
    }, []);
    useEffect(() => {
        if (csvFieldsUploadData.length === 0) {
            if (programDetails?.id) {
                navigate(`/program/${searchParams.get("programId")}/fields`);
            } else {
                navigate(`/programs`);
            }
        }
    }, [csvFieldsUploadData, programDetails?.id]);

    useEffect(() => {
        if (searchParams.get("programId")) {
            dispatch(getProgram(searchParams.get("programId")));
        }
    }, []);
    const handleMapButtonClick = (value) => {
        const mapImage = value?.map_url;
        if (mapImage) {
            setSelectedItem(mapImage);
        }
        setOpenModal(true);
    };

    //function to navigate back to fields upload page on cancel button click
    const handleDeleteImportFields = () => {
        navigate(`/program/${programDetails?.id}/fields`);
    };

    //function to delete deduplication csv field data during import result below on page leave
    useEffect(() => {
        return () => dispatch(deleteFieldImportData());
    }, []);
    const sanitazedTabledData =
        csvFieldsUploadData?.length &&
        csvFieldsUploadData
            ?.map((item) => ({
                fieldsimported: (
                    <TableCell>
                        <UnderlineText>
                            {item?.id_field_to_import}
                        </UnderlineText>
                    </TableCell>
                ),
                harvest: item?.field_harvest_year,
                landuse: item?.land_use,
                area: sanitizedDigits(item?.field_area),
                importstatus: (
                    <TableCell>
                        <StyledChip
                            label={item?.deduplication_status}
                            sx={{
                                background:
                                    FieldImportedStatusColor[
                                        item?.deduplication_status
                                    ],
                                color: "primary.white",
                            }}
                        />
                    </TableCell>
                ),
                dedup: item?.deduplication_percentage,
                map: (
                    <TableCell>
                        <RemoveRedEyeRoundedIcon
                            color="primary.white"
                            sx={{ fontSize: "22px", color: "#3FD0C9" }}
                            onClick={() => handleMapButtonClick(item)}
                        />
                    </TableCell>
                ),
                fieldsregistered: (
                    <TableCell
                        sx={{
                            background: "#eee",
                            color: "#7e7e7e !important",
                            maxWidth: "100px",
                        }}
                    >
                        {`[${item?.harvest_year_concerned} : ${
                            item?.fields_list_concerned?.length > 0
                                ? item?.fields_list_concerned.map(
                                      (values) =>
                                          `${values.id} - ${values["N/R"]} %`
                                  )
                                : []
                        }]`}
                    </TableCell>
                ),
            }))
            .slice(
                query.limit * query.page - query.limit,
                query.limit * query.page
            );

    return (
        <Layout>
            <Box sx={{ width: "100%", backgroundColor: "primary" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Breadcrumbs
                        sources={[
                            {
                                label: "program",
                                link: "/programs",
                                canNavigate: true,
                            },

                            {
                                label: "field",
                                link: `/program/${searchParams.get(
                                    "programId"
                                )}/fields`,
                                canNavigate: true,
                            },
                            {
                                label: "fields import results",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            display: "flex",
                            justifyContent: { xs: "center", lg: "flex-end" },
                            pt: "2px !important",
                            mb: "20px",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                padding: { xs: "4px 18px", md: "4px 23px" },
                                backgroundColor: "buttonSecondary.main",
                                border: "1px solid #000",
                                lineHeight: { xs: "16px", sm: 1.75 },
                                my: { xs: "5px", md: "0" },
                                mr: "10px",
                                "&:hover": {
                                    color: "#fff",
                                },
                            }}
                            onClick={handleDeleteImportFields}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="buttonPrimary"
                            disabled={ucoCreatingLoading}
                            sx={{
                                my: {
                                    xs: "5px",
                                    md: "0",
                                },
                                lineHeight: { xs: "16px", sm: 1.75 },
                            }}
                            onClick={handleImportResultFields}
                        >
                            {ucoCreatingLoading
                                ? "Processing..."
                                : "Create UCO on ‘single’"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Typography variant="h2">
                {totalFields &&
                    `${totalFields} ${
                        Number(totalFields) > 1 ? "fields" : "field"
                    } uploaded, ${totalCountTocehck} ${
                        Number(totalCountTocehck) > 1 ? "fields" : "field"
                    } to check`}
            </Typography>
            <Typography variant="p">
                Please export and check the issued fields. Then import again
                once corrected.
            </Typography>
            <Box sx={{ mt: "15px" }}>
                <CustomTable
                    headCells={headCells}
                    rows={sanitazedTabledData ? sanitazedTabledData : []}
                    noDataFound="No Data Found"
                    importResult={true}
                />
                <PaginationForImportResult
                    setQuery={setQuery}
                    query={query}
                    totalRowCount={csvFieldsUploadData?.length}
                    // setRowsPerPage={setRowsPerPage}
                    // page={page}
                    // setPage={setPage}
                    // rowsPerPage={rowsPerPage}
                    exportbutton={
                        can(permissionSlug[0].fields_slug.export_results) && (
                            <ConvertJsonToCsv
                                jsonData={csvFieldsUploadData?.map((item) => {
                                    return {
                                        "Ref field imported":
                                            item.id_field_to_import,
                                        Harvest: item.harvest_year_concerned,
                                        "Land-use": item.land_use,
                                        "Area (ha)": item.field_area,
                                        "Dedup.":
                                            item?.deduplication_percentage ||
                                            "-",
                                        Map: item.companyStatus,
                                        "Import status":
                                            item.deduplication_status,
                                        "[Harvest : Field registered - % deduplication]": `${
                                            item.harvest_year_concerned
                                        },  ${
                                            item?.fields_list_concerned
                                                ?.length > 0
                                                ? item?.fields_list_concerned.map(
                                                      (values) =>
                                                          `${values.id} - ${values["N/R"]} %`
                                                  )
                                                : []
                                        }`,
                                    };
                                })}
                            />
                        )
                    }
                />
            </Box>
            {ucoCreatingLoading && (
                <LoaderOverlay
                    message="Your patience is greatly appreciated! We are processing
                        your data..."
                />
            )}

            <Modal
                sx={{
                    "& .MuiBox-root": {
                        padding: 0,
                        overflow: "visible",
                    },
                }}
                body={
                    <>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenModal(false)}
                            sx={{
                                position: "absolute",
                                right: -40,
                                top: -60,
                                color: "#fff",
                                zIndex: 1,
                                "@media (max-width:768px)": {
                                    right: -20,
                                },
                            }}
                        >
                            <HighlightOffIcon
                                sx={{
                                    fontSize: 40,
                                }}
                            />
                        </IconButton>
                        <Box
                            sx={{
                                position: "relative",
                                paddingTop: "75% !important",
                                "& iframe": {
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    left: 0,
                                    top: 0,
                                },
                            }}
                        >
                            {selectedItem && (
                                <iframe
                                    width="100%"
                                    src={selectedItem}
                                    title="description"
                                />
                            )}
                        </Box>
                    </>
                }
                open={openModal}
                close={() => setOpenModal(false)}
            />
        </Layout>
    );
};

export default ImportResult;
