import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFarmers } from "redux/farmersSlice";
import MapContainer from "./MapContainer";
import CheckpageAuth from "auth/CheckPageAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { useAccessPage } from "customHooks/useAccess";
import UnderlineText from "common/UnderlineText";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import { toggleSharingOptions } from "redux/accountSlice";
export default function Detail({ farmer_id }) {
    const dispatch = useDispatch();
    const { farmersDetails, isLoading } = useSelector((state) => state.farmers);

    const { id: farmersIDS } = useParams();
    useEffect(() => {
        if (farmersDetails?.id !== Number(farmer_id)) {
            dispatch(getFarmers(farmer_id));
        }
    }, [farmer_id]);

    const { can, isRoles } = useAccessPage();
    // const otherUser = isRoles("other_user");
    const routeAccess = {
        routeAccess: can(permissionSlug[0].farmer_slug.farmer_details),
        unAuthUserRedirectTo: "/401",
    };
    // const handleSharing = (type) => (e) => {
    //     const checked = e.target.checked;
    //     // console.log(checked, type);

    // };
    const {
        id,
        user_share_farmers_data,
        farmerOfficialId,
        farmerCompanyName,
        farmerEuCapId,
        farmerFirstname,
        farmerLastname,
        farmerPhone,
        farmerEmail,
        farmerStreet,
        farmerStreet2,
        farmerZipcode,
        farmerRegion,
        farmerCity,
        farmerCountry,
    } = farmersDetails;

    const programInfo = [
        {
            title: "ID",
            info: id,
        },
        {
            title: "Farmer company name",
            info: farmerCompanyName,
        },
        {
            title: "Official ID",
            info: farmerOfficialId,
        },
        {
            title: "European CAP ID",
            info: farmerEuCapId,
        },
    ];

    const contactInfo = [
        {
            title: "First name",
            info: farmerFirstname,
        },
        {
            title: "Last name",
            info: farmerLastname,
        },
        {
            title: "Phone number",
            info: farmerPhone || "-",
        },
        {
            title: "Email",
            info: farmerEmail || "-",
        },
    ];

    const addressInfo = [
        {
            title: "Street",
            info: farmerStreet,
        },
        {
            title: "Address complement",
            info: farmerStreet2 || "-",
        },
        {
            title: "Region",
            info: farmerRegion,
        },
        {
            title: "Zip code",
            info: farmerZipcode,
        },
        {
            title: "City",
            info: farmerCity,
        },
        {
            title: "Country",
            info: farmerCountry,
        },
    ];

    const handleSharing = (type) => (e) => {
        const checked = e.target.checked;
        if (can(permissionSlug[0].farmer_slug.edit_farmer)) {
            dispatch(
                toggleSharingOptions({
                    id: farmersIDS,
                    data: { type: type, value: checked },
                })
            );
        }
    };
    const GDPR = [
        {
            title: "Sharing personal data",
            info: (
                <Box sx={{}}>
                    <Checkbox
                        defaultChecked={Boolean(user_share_farmers_data)}
                        onChange={handleSharing("farmers")}
                        sx={{ p: 0 }}
                    />
                </Box>
            ),
        },
        {
            title: null,
            info: <UnderlineText>Data protection policy</UnderlineText>,
        },
    ];
    return (
        <CheckpageAuth auth={routeAccess}>
            <Box sx={{ width: "100%" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    sx={{ mt: "20px" }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{ gap: "30px" }}
                        display="flex"
                        flexDirection="column"
                    >
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Company
                            </Typography>
                            {programInfo.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "16px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: "180px",
                                            minWidth: "180px",
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: "calc(100% - 180px)",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {res.button ? (
                                            <Box component="div">
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        maxWidth: "110px",
                                                        background: "#1ED800",
                                                        borderRadius: "40px",
                                                        boxShadow: "none",
                                                        fontSize: "14px",
                                                        textTransform: "none",
                                                        color: "#fff",
                                                        padding: "1px 11px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {res.iconPath} {res.button}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                component="div"
                                                sx={{
                                                    maxWidth: {
                                                        xs: "100%",
                                                        xl: "70%",
                                                    },
                                                }}
                                            >
                                                {isLoading ? (
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{
                                                            fontSize: "1rem",
                                                        }}
                                                    />
                                                ) : (
                                                    res.info
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                        {isRoles("other_user") ? (
                            ""
                        ) : (
                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Contact
                                </Typography>

                                {contactInfo.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: "180px",
                                                minWidth: "180px",
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: "calc(100% - 180px)",
                                                wordWrap: "break-word",
                                                maxWidth: {
                                                    xs: "100%",
                                                    lg: "70%",
                                                },
                                            }}
                                        >
                                            {isLoading ? (
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem" }}
                                                />
                                            ) : (
                                                res.info
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        )}

                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Address
                            </Typography>

                            {addressInfo.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "16px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: "180px",
                                            minWidth: "180px",
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: "calc(100% - 180px)",
                                            wordWrap: "break-word",
                                            maxWidth: { xs: "100%", lg: "70%" },
                                        }}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem" }}
                                            />
                                        ) : (
                                            res.info
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                        {can(permissionSlug[0].farmer_slug.edit_farmer) && (
                            <Box mt={4}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    GDPR
                                </Typography>
                                {GDPR.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        alignItems="center"
                                        margin={res?.alignItems}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: {
                                                    xs: "100%",
                                                    sm: "220px",
                                                    lg: "180px",
                                                },
                                                minWidth: {
                                                    xs: "100%",
                                                    sm: "220px",
                                                    lg: "180px",
                                                },
                                                mb: { xs: "5px", sm: 0 },
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: {
                                                    xs: "100%",
                                                    sm: "calc(100% - 220px)",
                                                    lg: "calc(100% - 180px)",
                                                },
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize:
                                                                    "1rem",
                                                            }}
                                                        />
                                                    ) : (
                                                        res.info
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        )}
                    </Grid>
                    <MapContainer fullHeights={false} />
                </Grid>
            </Box>
        </CheckpageAuth>
    );
}
