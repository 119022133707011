import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage, showSuccessMessage } from "./toastSlice";
import Services from "services";
// import errorHandler from "helpers/errorhandler";
import { resetStateHander } from "helpers/sanitizedFunction";
import errorHandler from "helpers/errorhandler";

export const fetchFarmers = createAsyncThunk(
    "farmers/fetchAllFarmers",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getFarmers(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createFarmer = createAsyncThunk(
    "farmers/createFarmer",
    async (payload, thunkAPI) => {
        try {
            const response = await Services.createFarmer(payload);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("Farmer Created Successfully!")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data.data);
        }
    }
);

export const getFarmers = createAsyncThunk(
    "farmers/getFarmers",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getFarmersDetails(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const editFarmers = createAsyncThunk(
    "farmers/editFarmers",
    async (params, thunkAPI) => {
        try {
            const response = await Services.editFarmers(params);
            thunkAPI.dispatch(
                showSuccessMessage("Farmer updated successfully!")
            );
            return response.data.data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }

            if (!error.response) {
                throw error;
            }

            // thunkAPI.dispatch(
            //     showErrorMessage(error.response.data.message || error.message)
            // );

            return thunkAPI.rejectWithValue(error.response.data.data);
        }
    }
);

export const uploadCsvFarmersFiles = createAsyncThunk(
    "farmers/uploadCsvFarmersFiles",
    async (params, thunkAPI) => {
        try {
            const response = await Services.uploadFarmersCsvFile(params);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Farmers Created Successfully!" || response.message
                    )
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            // thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data.data);
        }
    }
);
export const fetchAllFarmerFields = createAsyncThunk(
    "fields/fetchAllFarmerFields",
    async (params, thunkAPI) => {
        try {
            const response = await Services.getFarmerFields(params);
            if (response.data.data) {
                return { id: params.id, response: response.data.data };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
//SEND FINAL DATA IN DB
export const UploadFarmerDatainDB = createAsyncThunk(
    "farmers/UploadFarmerDatainDB",
    async (params, thunkAPI) => {
        try {
            const response = await Services.uploadFinalFarmersCsvToDb(params);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Farmers Created Successfully!" || response.message
                    )
                );
                return { id: params.id, response: response.data.data };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
const initialState = {
    farmers: [],
    error: [],
    farmersDetails: {},
    isLoading: false,
    isLoaded: false,
    count: null,
    fieldErrors: {},
    fields: [],
    farmerFieldCount: null,
    previousFarmerId: null,
};
export const farmersSlice = createSlice({
    name: "farmers",
    initialState,
    reducers: {
        resetFarmers: (state) => {
            resetStateHander(state, initialState);
        },
        resetCsvError: (state) => {
            state.error = [];
        },
        resetBackendcsvErrors: (state, params) => {
            state.fieldErrors[params.payload] = "";
        },
        refetchFarmers: (state) => {
            state.isLoaded = false;
        },
        refetchFarmersFields: (state) => {
            state.isLoaded = false;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchFarmers.pending, (state) => {
                state.isLoading = true;
                state.farmers = [];
                state.count = null;
            })
            .addCase(fetchFarmers.fulfilled, (state, action) => {
                // Add user to the state array
                state.farmers = action.payload.farmers;
                state.count = action.payload.count;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(fetchFarmers.rejected, (state) => {
                state.isLoading = false;
                state.isLoaded = false;
            })
            .addCase(createFarmer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createFarmer.fulfilled, (state) => {
                state.fieldErrors = {};
                state.isLoading = false;
                state.isLoaded = false;
            })
            .addCase(createFarmer.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.fieldErrors = payload;

                state.isLoaded = false;
            })
            .addCase(getFarmers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFarmers.fulfilled, (state, action) => {
                // Add user to the state array
                state.farmersDetails = action.payload;
                state.error = null;
                state.isLoading = false;
            })
            .addCase(getFarmers.rejected, (state) => {
                state.isLoading = false;
                state.farmersDetails = {};
            })
            .addCase(editFarmers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editFarmers.fulfilled, (state, action) => {
                state.farmersDetails = action.payload;
                state.error = null;
                state.fieldErrors = {};
                state.isLoading = false;
                state.isLoaded = false;
            })
            .addCase(editFarmers.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
                state.fieldErrors = payload;
                state.isLoaded = false;
            })
            .addCase(uploadCsvFarmersFiles.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploadCsvFarmersFiles.fulfilled, (state) => {
                state.isLoading = false;
                state.error = [];
            })
            .addCase(uploadCsvFarmersFiles.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            })
            .addCase(fetchAllFarmerFields.pending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addCase(fetchAllFarmerFields.fulfilled, (state, { payload }) => {
                state.isLoaded = true;
                state.fields = payload.response.fields;
                state.previousFarmerId = payload.id;
                state.farmerFieldCount = payload.response.count;
                state.error = null;
                state.isLoading = false;
            })
            .addCase(fetchAllFarmerFields.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isLoaded = false;
                showErrorMessage(payload);
            })
            //
            .addCase(UploadFarmerDatainDB.pending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addCase(UploadFarmerDatainDB.fulfilled, (state, { payload }) => {
                state.isLoaded = true;
                state.fields = payload.response.fields;
                // state.previousFarmerId = payload.id;
                // state.farmerFieldCount = payload.response.count;
                state.error = null;
                state.isLoading = false;
            })
            .addCase(UploadFarmerDatainDB.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isLoaded = false;
                showErrorMessage(payload);
            });
    },
});
export const {
    resetFarmers,
    refetchFarmers,
    resetCsvError,
    resetBackendcsvErrors,
    refetchFarmersFields,
} = farmersSlice.actions;
export default farmersSlice.reducer;
