import Breadcrumbs from "common/Breadcrumb";
export default function CollectionSccHeader() {
    return (
        <>
            <Breadcrumbs
                md="auto"
                sources={[
                    {
                        label: "registry",
                        link: "registry/collections",
                    },
                    // {
                    //     label: "collection",
                    // },
                    {
                        label: "all collection",
                        link: "",
                    },
                ]}
            />
        </>
    );
}
