// import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CustomTable from "common/CustomTable";
import UnderlineText from "common/UnderlineText";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Link, useLocation, useParams } from "react-router-dom";
// import { ReactComponent as CheckBoxes } from "assets/icons/checkboxes.svg";
// import { ReactComponent as CheckboxeChecked } from "assets/icons/checkboxeChecked.svg";
import Searchbar from "common/Searchbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllFields } from "redux/fieldsSlice";
import ConvertJsonToCsv from "common/ExportJson";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
// import DoneIcon from "@mui/icons-material/Done";
// import Chip from "@mui/material/Chip";
// import { styled } from "@mui/material/styles";
import sanitizedString from "util/sanitizedString";
import sanitizedDigits from "util/sanitizedDigits";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
// import CheckpageAuth from "auth/CheckPageAccess";
// const StyledChip = styled(Chip)(() => ({
//     height: "28px",
//     padding: "1px 0px",
//     minWidth: "91px",
//     fontSize: "14px",
// }));
const headCells = [
    {
        id: "Program_lead",
        label: "Program lead",
    },
    {
        id: "Program_code",
        label: "Program code",
    },
    {
        id: "farmer",
        label: "Farmer",
    },
    {
        id: "field",
        label: "Field",
    },
    {
        id: "location",
        label: "Location",
    },
    {
        id: "harvest",
        label: "Harvest",
    },
    {
        id: "land_use",
        label: "Land-use",
    },
    {
        id: "area",
        label: "Area (ha)",
    },
    {
        id: "uco",
        label: "UCO",
    },
    {
        id: "scc",
        label: "SCC",
    },
    {
        id: "value",
        label: "Value",
    },
];

const List = () => {
    const { can } = useAccessPage();

    // const routeAccess = {
    //     routeAccess: can(permissionSlug[0].fields_slug.list_fields),
    //     unAuthUserRedirectTo: "/401",
    // };
    const tabsAccess = can(permissionSlug[0].fields_slug.list_fields);
    const location = useLocation();
    let { id } = useParams();
    const initialQuery = { page: 1, limit: 10, search: "" };
    const [query, setQuery] = useState(initialQuery);
    const dispatch = useDispatch();

    const { fields, isLoading, count } = useSelector((state) => state.field);

    useEffect(() => {
        if (tabsAccess) {
            if (query) {
                dispatch(fetchAllFields({ ...query, ...(id && { id: id }) }));
            }
        }
    }, [query, location.pathname]);

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };
    const sanitizedfieldData =
        fields &&
        fields?.map((item) => ({
            Program_lead: item.project?.program?.program_developer || "-",
            Program_code: can(permissionSlug[0].farmer_slug.farmer_details) ? (
                <TableCell>
                    {item.project?.program?.program_code ? (
                        <Link
                            to={`/program/${item.project?.program?.id}/detail`}
                        >
                            <UnderlineText>
                                {item.project?.program?.program_code}
                            </UnderlineText>
                        </Link>
                    ) : (
                        "-"
                    )}
                </TableCell>
            ) : (
                item.project?.program?.program_code
            ),
            farmer: can(permissionSlug[0].farmer_slug.farmer_details) ? (
                <TableCell>
                    {item.project && item.project.farmer ? (
                        <Link
                            to={`/farmer/${
                                item.project &&
                                item.project.farmer &&
                                item.project.farmer.id
                            }/details`}
                        >
                            <UnderlineText>
                                {capitalizeFirstLetter(
                                    item.project.farmer?.farmerCompanyName
                                )}
                            </UnderlineText>
                        </Link>
                    ) : (
                        "-"
                    )}
                </TableCell>
            ) : (
                capitalizeFirstLetter(item.project?.farmer?.farmerCompanyName)
            ),
            field: can(permissionSlug[0].fields_slug.field_details) ? (
                <TableCell>
                    <Link to={`/field/${item.id}/details`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(item?.fieldName)}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ) : (
                capitalizeFirstLetter(item?.fieldName)
            ),
            location: `${sanitizedString(
                `${item.fieldCitycodeInsee} ${item?.fieldCityInsee}`
            )}`,
            harvest: item?.fieldHarvestYear,
            land_use: item?.fieldLandUse,
            area: sanitizedDigits(item?.fieldshapefileArea),
            uco: (
                <TableCell>
                    {item?.fieldUcoTokenId ? (
                        can(permissionSlug[0].uco_slug.UCO_details) ? (
                            <Link
                                to={`/registry/uco/${item.project?.program?.program_uco_address}/${item.id}/${item.project?.program?.id}/details`}
                            >
                                {/* truncate uco  values pending */}
                                <UnderlineText>
                                    {item?.fieldUcoTokenId}
                                </UnderlineText>
                            </Link>
                        ) : (
                            item?.fieldUcoTokenId
                        )
                    ) : (
                        "-"
                    )}
                </TableCell>
            ),

            scc: (
                <TableCell>
                    {item?.fieldSccTokenId ? (
                        can(permissionSlug[0].scc_slug.SCC_details) ? (
                            <Link
                                to={`/registry/scc/${item.project?.program?.program_scc_address}/${item.id}/${item.project?.program?.id}/details`}
                            >
                                {/* truncate scc  values pending */}
                                <UnderlineText>
                                    {item?.fieldSccTokenId}
                                </UnderlineText>
                            </Link>
                        ) : (
                            item?.fieldSccTokenId
                        )
                    ) : (
                        "-"
                    )}
                </TableCell>
            ),
            value: item?.fieldValue
                ? `${sanitizedDigits(item?.fieldValue)}${
                      item?.project?.program?.program_unit
                  }`
                : "-",
        }));

    return tabsAccess ? (
        <>
            {/* <CheckpageAuth auth={routeAccess}> */}
            <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                    flexWrap: { xs: "wrap", sm: "inherit" },
                    justifyContent: { xs: "center", sm: "space-between" },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                    sx={{
                        width: "100%",
                        md: "auto",
                        justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                            lg: "space-between",
                            xl: "space-between",
                        },
                    }}
                >
                    <Searchbar
                        placeholdertext="Search Fields, Land-use, Harvest..."
                        handleSearch={(search) => {
                            handleSearch(search);
                        }}
                    />
                </Stack>
            </Stack>
            <Box
                sx={{
                    mt: "30px",
                }}
            >
                <CustomTable
                    headCells={headCells}
                    rows={sanitizedfieldData ? sanitizedfieldData : []}
                    loadingList={isLoading}
                    query={query}
                    setQuery={setQuery}
                    totalRowCount={count}
                    exportbutton={
                        can(permissionSlug[0].fields_slug.export_fields) && (
                            <ConvertJsonToCsv
                                jsonData={fields?.map((item) => {
                                    return {
                                        Farmer:
                                            item.project &&
                                            item.project.farmer &&
                                            item.project.farmer
                                                ?.farmerCompanyName,
                                        Field: item.fieldName,
                                        Location: item.fieldCityInsee,
                                        Harvest: item.fieldHarvestYear,
                                        "Land-use": item.fieldLandUse,
                                        area: item.fieldArea,
                                        Value: item.fieldValue,
                                    };
                                })}
                            />
                        )
                    }
                    noDataFound="No fields found"
                />
            </Box>
            {/* </CheckpageAuth> */}
        </>
    ) : (
        <Box
            sx={{
                mt: "30px",
            }}
        >
            You are not Authorized
        </Box>
    );
};

export default List;
