import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const UserContact = ({ formik, disable = false }) => {
    // const [phone, setPhone] = useState("");
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };
    const user_firstname = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    disabled={disable}
                    fullWidth
                    id="user_firstname"
                    label="First Name"
                    name="user_firstname"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.user_firstname}
                    error={showError("user_firstname")}
                    helperText={showError("user_firstname")}
                />
            </Box>
        );
    }, [
        formik.values.user_firstname,
        formik.touched.user_firstname,
        formik.errors.user_firstname,
    ]);

    const user_lastname = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    disabled={disable}
                    fullWidth
                    id="user_lastname"
                    label="Last Name"
                    variant="filled"
                    name="user_lastname"
                    onChange={formik.handleChange}
                    value={formik.values.user_lastname}
                    onBlur={formik.handleBlur}
                    error={showError("user_lastname")}
                    helperText={showError("user_lastname")}
                />
            </Box>
        );
    }, [
        formik.values.user_lastname,
        formik.errors.user_lastname,
        formik.touched.user_lastname,
    ]);

    const user_phone = useMemo(() => {
        return (
            <Box>
                <PhoneInput
                    required
                    disabled={disable}
                    fullWidth
                    variant="filled"
                    name="user_phone"
                    id="user_phone"
                    country={"fr"}
                    value={formik.values.user_phone}
                    enableSearch={true}
                    onChange={(phone, dialCode, e, value) => {
                        formik.setFieldValue("user_phone", value);
                    }}
                    error={Boolean(showError("user_phone"))}
                    helperText={showError("user_phone")}
                    inputProps={{
                        style: { width: "100%", height: "57px" },
                        name: "user_phone",
                        required: true,
                        placeholder: "Phone number",
                    }}
                    countryCodeEditable={false}
                />
                {formik.errors.user_phone && formik.touched.user_phone && (
                    <div style={{ color: "red" }}>
                        {formik.errors.user_phone}
                    </div>
                )}
            </Box>
        );
    }, [
        formik.values.user_phone,
        formik.errors.user_phone,
        formik.touched.user_phone,
    ]);

    const user_email = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    disabled={disable}
                    fullWidth
                    id="user_email"
                    label="Email"
                    variant="filled"
                    name="user_email"
                    onChange={formik.handleChange}
                    value={formik.values.user_email}
                    onBlur={formik.handleBlur}
                    error={showError("user_email")}
                    helperText={showError("user_email")}
                />
            </Box>
        );
    }, [
        formik.values.user_email,
        formik.errors.user_email,
        formik.touched.user_email,
    ]);

    return (
        <>
            <Typography variant="h4">Contact</Typography>

            <Box
                display="grid"
                gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {user_firstname}
                {user_lastname}
                {user_phone}
                {user_email}
            </Box>
        </>
    );
};

export default UserContact;
