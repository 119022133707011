import { useEffect, useState } from "react";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Tabs from "../common/Tabs";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import SccHeader from "components/registry/scc/SccHeader";
import SmartCarbonCredit from "components/registry/scc/SmartCarbonCredit";
import SccTitle from "components/registry/scc/SccTitle";
import UcoHeader from "components/registry/uco/UcoHeader";
import UniqueCertificateOrigin from "components/registry/uco/UniqueCertificateOrigin";
import UcoTitle from "components/registry/uco/UcoTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CollectionSccHeader from "components/registry/collection/CollectionSccHeader";
import CollectiosccTitle from "components/registry/collection/CollectionSccTitle";
import CollectionScc from "components/registry/collection/SccCollection";

const mobtabposition = {
    "@media (max-width:600px)": {
        ".MuiBox-root": {
            justifyContent: "center",
        },
    },
};
const ProgramDetails = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:599px)");
    let { id: activeRoute } = useParams();
    const [selects, setSelects] = useState([]);
    const [activeTab, setActiveTab] = useState("collectionscc");

    const [refetch, setRefetch] = useState(true);

    const { cannot } = useAccessPage();

    useEffect(() => {
        if (activeRoute) {
            setActiveTab(activeRoute);
        }
    }, [activeRoute]);

    const ShowActiveExpand = () => {
        switch (activeTab) {
            case "collections":
                return {
                    header: <CollectionSccHeader />,
                    body: <CollectionScc />,
                    pagetitle: <CollectiosccTitle />,
                };
            case "uco":
                return {
                    header: (
                        <UcoHeader
                            selects={selects}
                            setSelects={setSelects}
                            setRefetch={setRefetch}
                        />
                    ),
                    body: (
                        <UniqueCertificateOrigin
                            selects={selects}
                            refetch={refetch}
                            setSelects={setSelects}
                        />
                    ),
                    pagetitle: <UcoTitle />,
                };
            case "scc":
                return {
                    header: (
                        <SccHeader
                            selects={selects}
                            setSelects={setSelects}
                            setRefetch={setRefetch}
                        />
                    ),
                    body: (
                        <SmartCarbonCredit
                            selects={selects}
                            refetch={refetch}
                            setSelects={setSelects}
                        />
                    ),
                    pagetitle: <SccTitle />,
                };
            default:
                navigate("/404");
                return {
                    header: "",
                    body: "",
                    pagetitle: "",
                };
        }
    };

    const { header, body, pagetitle } = ShowActiveExpand();

    const handleTabChange = (routeName) => {
        setSelects([]);
        navigate(routeName);
    };

    return (
        <Layout>
            <Box sx={{ width: "100%", mb: "10px" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    {header}
                </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ mt: 0 }}
                >
                    {pagetitle}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            order: { xs: "3", md: "auto" },
                        }}
                    >
                        <Box className="testtab" sx={mobtabposition}>
                            <Tabs
                                tabItem={[
                                    {
                                        name: "Collection",
                                        route: "/registry/collections",
                                    },
                                    {
                                        name: isMobile ? "UCO" : "UCO",
                                        route: "/registry/uco",
                                        disableTabs: cannot(
                                            permissionSlug[0].uco_slug.list_UCO
                                        ),
                                    },
                                    {
                                        name: isMobile ? "SCC" : "SCC",
                                        route: "/registry/scc",
                                        disableTabs: cannot(
                                            permissionSlug[0].scc_slug.list_SCC
                                        ),
                                    },
                                ]}
                                onTabChange={handleTabChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ width: "100%" }}>{body}</Box>
        </Layout>
    );
};

export default ProgramDetails;
