import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// import { red } from "@mui/material/colors";
import { ReactComponent as IconFileupload } from "../../src/assets/icons/fileupload.svg";
import { ReactComponent as ImageIcon } from "../../src/assets/icons/imageicon.svg";
import FormHelperText from "@mui/material/FormHelperText";

const UploadButtons = ({ label, fileName, formik, name, required = true }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [imageName, setImageName] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            };
            setImageName(file.name);
            reader.readAsDataURL(file);
            formik.setFieldValue(name, file);
        } else {
            setImageName(null);
            setImageUrl(null);
            formik.setFieldValue(name, "");
        }
    };

    function isBinaryFile(data) {
        return (
            data instanceof Blob ||
            data instanceof File ||
            data instanceof ArrayBuffer
        );
    }
    useEffect(() => {
        if (formik.values[name] && !isBinaryFile(formik.values[name])) {
            setImageUrl(formik.values[name]);
        }
    }, [formik.values[name]]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                }}
            >
                <Box
                    component="label"
                    htmlFor="upload-image"
                    sx={{
                        minHeight: 56,
                        flex: 1,
                        maxWidth: "250px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #666",
                        backgroundColor: "#F5F5F5",
                        paddingRight: "12px",
                        paddingLeft: "12px",
                        "@media (max-width:1200px)": {
                            maxWidth: "100%",
                        },
                        "& .MuiButtonBase-root": {
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.4375em",
                        },
                        "& .MuiButton-root": {
                            px: 0,
                        },
                    }}
                >
                    <Button
                        variant="text"
                        component="span"
                        sx={{
                            p: 0,
                            textTransform: "none",
                        }}
                    >
                        {imageName ? (
                            <span
                                style={{
                                    fontWeight: "bold",
                                    maxWidth: "200px",
                                    fontSize: "15px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {imageName}
                            </span>
                        ) : (
                            label
                        )}
                        {required && (
                            <Box sx={{ color: "red", ml: "2px" }}>*</Box>
                        )}
                    </Button>
                    <IconFileupload />
                    <input
                        id="upload-image"
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleFileUpload}
                    />
                    {formik.errors[name] && formik.touched[name] && (
                        <FormHelperText
                            sx={{ color: "red", display: "inline" }}
                        >
                            {formik.errors[name]}
                        </FormHelperText>
                    )}
                </Box>

                <Box
                    component="div"
                    sx={{
                        ml: "0 !important",
                        width: 56,
                        height: 56,
                        borderRadius: "5px",
                        backgroundColor: "#F5F5F5",
                    }}
                >
                    {imageUrl ? (
                        <Box
                            component="img"
                            src={imageUrl}
                            alt="Uploaded Image"
                            sx={{
                                height: "100%",
                                width: "100%",
                                objectFit: "container",
                            }}
                        />
                    ) : (
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px dashed #666",
                                borderRadius: "5px",
                            }}
                        >
                            {" "}
                            <ImageIcon />
                        </Box>
                    )}
                </Box>
            </Box>
            {fileName && (
                <Box
                    component="div"
                    sx={{
                        fontSize: "0.875rem",
                        color: "rgba(0, 0, 0, 0.6)",
                        mt: "3px",
                    }}
                >
                    {fileName}
                </Box>
            )}
        </>
    );
};

export default UploadButtons;
