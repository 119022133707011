import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import UserContact from "components/admin/form/UserContact";
import UserProfile from "components/admin/form/UserProfile";
import UserPreferences from "components/admin/form/UserPreferences";
import UserAddress from "components/admin/form/UserAddress";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { editAccountDetails } from "redux/accountSlice";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import CancelButton from "common/CancelButton";

import { useAccessPage } from "customHooks/useAccess";
export default function FormContainer() {
    const [sameAddress, setSameAddress] = useState(false);
    const { accountDetails: user, isLoading } = useSelector(
        (state) => state.account
    );

    const { isRoles } = useAccessPage();
    const isAdmin = isRoles("admin");
    const {
        user_firstname,
        user_lastname,
        user_phone,
        user_email,
        user_job,
        user_account_type,
        user_status,
        user_wallet_manager,
        user_timezone,
        user_dateformat,
        user_measurement,
        user_language,
        user_street,
        user_street_2,
        user_zipcode,
        user_region,
        user_city,
        user_country,
    } = user;
    const roleIDS = user?.role.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        user_firstname: isAdmin
            ? yup.string().required("First Name is required")
            : yup.string(),
        user_lastname: isAdmin
            ? yup.string().required("Last Name is required")
            : yup.string(),
        user_phone: isAdmin
            ? yup.mixed().required("Phone is Required")
            : yup.string(),
        user_email: isAdmin
            ? yup
                  .string()
                  .required("Email is required")
                  .matches(
                      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g,
                      "Invalid Email Address"
                  )
            : yup.string(),
        user_job: yup.string(),
        user_account_type: yup.string(),
        user_status: yup.string(),
        user_wallet_manager: yup.string(),
        user_timezone: yup.string().required("Timezone is required"),
        user_dateformat: yup.string().required("Date Format is required"),
        user_measurement: yup.string().required("Measurement is required"),
        user_language: yup.string().required("Language is required"),
        // user_timezone:
        //     userRole !== "admin"
        //         ? yup.string().required("Timezone is required")
        //         : yup.string(),
        // user_dateformat:
        //     userRole !== "admin"
        //         ? yup.string().required("Date Format is required")
        //         : yup.string(),
        // user_measurement:
        //     userRole !== "admin"
        //         ? yup.string().required("Measurement is required")
        //         : yup.string(),
        // user_language:
        //     userRole !== "admin"
        //         ? yup.string().required("Language is required")
        //         : yup.string(),
        user_street: !isAdmin
            ? yup.string().required("Street is required")
            : yup.string(),
        user_street_2: yup.string(),
        user_zipcode: !isAdmin
            ? yup.string().required("Zip Code is required")
            : yup.string(),
        user_region: yup.string(),
        user_city: !isAdmin
            ? yup.string().required("City is required")
            : yup.string(),
        user_country: !isAdmin
            ? yup.string().required("Country is required")
            : yup.string(),
        // Add validation for other fields as needed...
    });

    const initialValues = {
        user_firstname: "",
        user_lastname: "",
        user_phone: "",
        user_email: "",
        user_job: "",
        user_account_type: "",
        user_status: "",
        user_wallet_manager: "",
        user_timezone: "",
        user_dateformat: "",
        user_measurement: "",
        user_language: "",
        user_street: "",
        user_street_2: "",
        user_zipcode: "",
        user_region: "",
        user_city: "",
        user_country: "",
    };

    if (isAdmin) {
        delete initialValues.user_job;
        delete initialValues.user_account_type;
        delete initialValues.user_status;
        delete initialValues.user_wallet_manager;
        // delete initialValues.user_timezone;
        // delete initialValues.user_dateformat;
        // delete initialValues.user_measurement;
        // delete initialValues.user_language;
        delete initialValues.user_street;
        delete initialValues.user_zipcode;
        delete initialValues.user_city;
        delete initialValues.user_country;
        delete initialValues.user_street_2;
        delete initialValues.user_region;
    }
    //  else {
    //     delete initialValues.user_firstname;
    //     delete initialValues.user_lastname;
    //     delete initialValues.user_phone;
    //     delete initialValues.user_email;
    //     delete initialValues.user_job;
    //     delete initialValues.user_account_type;
    //     delete initialValues.user_status;
    // }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!isAdmin) {
                values.user_firstname = user_firstname;
                values.user_lastname = user_lastname;
                values.user_phone = user_phone;
                values.user_email = user_email;
                values.user_job = user_job;
                values.user_account_type = roleIDS;
                values.user_status = user_status;
                values.user_wallet_manager = user_wallet_manager;
                values.role_id = roleIDS;
                dispatch(editAccountDetails({ user: true, values })).then(
                    (res) => {
                        if (
                            res.meta.requestStatus === "fulfilled" &&
                            !res.error
                        ) {
                            navigate("/my-account");
                        }
                    }
                );
            } else {
                dispatch(editAccountDetails({ user: false, values })).then(
                    (res) => {
                        if (
                            res.meta.requestStatus === "fulfilled" &&
                            !res.error
                        ) {
                            navigate("/my-account");
                        }
                    }
                );
            }
        },
    });

    useEffect(() => {
        if (!isAdmin) {
            formik.setValues({
                user_firstname,
                user_lastname,
                user_phone,
                user_email,
                user_timezone,
                user_dateformat,
                user_measurement,
                user_language,
                user_job,
                user_account_type,
                user_status,
                user_wallet_manager,
                user_street,
                user_street_2,
                user_zipcode,
                user_region,
                user_city,
                user_country,
            });
        } else if (isAdmin) {
            formik.setValues({
                user_firstname,
                user_lastname,
                user_phone,
                user_email,
                user_timezone,
                user_dateformat,
                user_measurement,
                user_language,
            });
        }
    }, [user]);
    const handleSameAddress = (e) => {
        const checked = e.target.checked;
        setSameAddress(checked);

        formik.setValues({
            ...formik.values,
            user_street: checked ? user.company?.companyStreet || "" : "",
            user_street_2: checked ? user.company?.companyStreet2 || "" : "",
            user_zipcode: checked ? user.company?.companyZipcode || "" : "",
            user_region: checked ? user.company?.companyRegion || "" : "",
            user_city: checked ? user.company?.companyCity || "" : "",
            user_country: checked ? user.company?.companyCountry || "" : "",
        });
    };
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
            onSubmit={formik.handleSubmit}
        >
            <UserContact disable={!isAdmin} formik={formik} />

            {!isAdmin && <UserProfile disable={true} formik={formik} />}
            <UserPreferences formik={formik} />
            {!isAdmin && (
                <UserAddress
                    formik={formik}
                    handleSameAddress={handleSameAddress}
                    sameAddress={sameAddress}
                />
            )}

            <Stack direction="row" spacing={2} mt={{ xs: "29px", md: "39px" }}>
                <CancelButton address="/my-account" />
                <Button type="submit" variant="contained" disabled={isLoading}>
                    {!isLoading ? "Submit" : "Processing ..."}
                </Button>
            </Stack>
        </Box>
    );
}
