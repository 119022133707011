import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import loginBackgroundImage from "../assets/images/loginimage.png";
// import ToastNotification from "common/ToastMessage";
import { useLocation } from "react-router-dom";
import Toaastify from "common/ToastNotification";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToastMessage } from "redux/toastSlice";

const StyledBox = styled(Box)(({ theme }) => ({
    "&.loginbox": {
        position: "relative",
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "350px",
            background: "#02353C",
            [theme.breakpoints.down("md")]: {
                height: "260px",
            },
        },
        "&:after": {
            content: '""',
            height: "calc(100% - 350px)",
            position: "absolute",
            background: `url(${loginBackgroundImage})`,
            bottom: 0,
            width: "100%",
            backgroundSize: "cover",
            [theme.breakpoints.down("md")]: {
                height: "calc(100% - 260px)",
            },
        },
    },
}));
// import LoaderForData from "common/Spinner";
const Layout = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { error, success, showToast } = useSelector(
        (state) => state.toastMessage
    );
    const { accountDetails: user } = useSelector((state) => state.account);

    return (
        <>
            {location.pathname == "/login" ? (
                <>
                    <StyledBox
                        className="loginbox"
                        sx={{
                            minHeight: "100vh",
                            backgroundSize: "cover",
                            backgroundPosition: {
                                xs: "50% 10%",
                                xl: "50% -150px",
                            },
                            padding: { xs: "15px 0px", md: "90px 0px" },
                            display: "flex",
                            flexWrap: "wrap",
                            alighItems: { xs: "center", md: "auto" },
                        }}
                    >
                        {children}
                    </StyledBox>
                </>
            ) : (
                <>
                    <Header />
                    <Box
                        sx={{
                            display: "flex",
                            mt: { xs: "85px", md: "95px" },
                        }}
                    >
                        <Sidebar />
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                p: { xs: "15px", md: "35px" },
                                mx: { md: "35px" },
                                background: "#fff",
                                minHeight: "100vh",
                                width: "100%",
                                minWidth: 0,
                            }}
                        >
                            {user?.role ? children : ""}
                        </Box>
                    </Box>
                </>
            )}
            {showToast && (
                <Toaastify
                    title={error || success}
                    close={() => {
                        dispatch(hideToastMessage());
                    }}
                    variant={error ? "error" : "standard"}
                />
            )}
            {/* <LoaderForData loading={true} /> */}
        </>
    );
};

Layout.propTypes = {
    // :white_check_mark: type of prop is now set correctly
    children: PropTypes.node,
};
export default Layout;
