import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ProgramFormContainer from "components/program/form/ProgramFormContainer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProgram } from "redux/programSlice";
import { useEffect } from "react";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const ModifyProgram = () => {
    const { id, id: program_id } = useParams();
    const dispatch = useDispatch();
    const { programDetails } = useSelector((state) => state.program);

    useEffect(() => {
        if (id) {
            dispatch(getProgram(id));
        }
    }, [id]);

    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].programSlug.edit_program),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box
                    sx={{
                        maxWidth: 810,
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <Grid
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 1 }}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Breadcrumbs
                                sources={[
                                    {
                                        label: "programs",
                                        link: "/programs",
                                        canNavigate: true,
                                    },
                                    {
                                        label: "program",
                                        link: `/program/${program_id}/detail`,
                                        canNavigate: true,
                                    },
                                    {
                                        label: "modify program",
                                        link: "",
                                    },
                                ]}
                            />
                        </Grid>
                    </Box>
                    <Box sx={{ mt: "10px" }}>
                        <Typography variant="h2">Modify program</Typography>
                        <Typography variant="body1" mt={1} mb={3}>
                            Please fill the following form to modify program.
                        </Typography>
                        <Typography variant="h4" mb={3}>
                            Program information
                        </Typography>

                        <ProgramFormContainer
                            edit={true}
                            company={programDetails}
                        />
                    </Box>
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default ModifyProgram;
