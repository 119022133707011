import pages from "routes/components";
import { Navigate } from "react-router-dom";

const AdminPage = pages.Admin;

const routes = [
    {
        path: "/login",
        Component: pages.Login,
        isProtected: false,
    },
    {
        path: "/about",
        Component: pages.About,
        isProtected: false,
    },
    {
        path: "/verifyemail",
        Component: pages.EmailVerify,
        isProtected: false,
    },
    {
        path: "/",
        Component: () => {
            return <Navigate to="/programs" replace />;
        },
        isProtected: true,
    },
    {
        path: "/companies/:id/programs",
        Component: pages.Program,
        isProtected: true,
    },
    {
        path: "*",
        Component: pages.NotFound,
        isProtected: true,
    },
    {
        path: "/coming-soon",
        Component: pages.ComingSoon,
        isProtected: true,
    },
    {
        path: "/programs",
        Component: pages.Program,
        isProtected: true,
    },
    {
        path: "/program/:id/:tab_id",
        Component: pages.ProgramDetails,
        isProtected: true,
    },
    {
        path: "/new-program",
        Component: pages.NewProgram,
        isProtected: true,
    },
    {
        path: "/program/edit/:id",
        Component: pages.ModifyProgram,
        isProtected: true,
    },
    {
        path: "/add-project/:id",
        Component: pages.AddProject,
        isProtected: true,
    },
    {
        path: "/projectdetail/:id",
        Component: pages.ProjectDetail,
        isProtected: true,
    },
    {
        path: "/project-edit/:id",
        Component: pages.ModifyProject,
        isProtected: true,
    },
    {
        path: "/import-result",
        Component: pages.ImportResult,
        isProtected: true,
    },
    {
        path: "/add-certification/:id",
        Component: pages.Addcertification,
        isProtected: true,
    },
    {
        path: "/farmer/:id/:tab_id",
        Component: pages.FarmerDetails,
        isProtected: true,
    },
    {
        path: "/add-farmer",
        Component: pages.AddFarmer,
        isProtected: true,
    },
    {
        path: "/farmer/edit/:id",
        Component: pages.ModifyFarmer,
        isProtected: true,
    },
    {
        path: "/farmers",
        Component: pages.FarmerList,
        isProtected: true,
    },
    {
        path: "/companies/:id/farmers",
        Component: pages.FarmerList,
        isProtected: true,
    },
    {
        path: "/field/:tab_id",
        Component: pages.Field,
        isProtected: true,
    },
    {
        path: "/companies/:id/fields",
        Component: pages.Field,
        isProtected: true,
    },
    {
        path: "/field/:id/:tab_id",
        Component: pages.FieldDetails,
        isProtected: true,
    },
    {
        path: "/registry/:id",
        Component: pages.Registry,
        isProtected: true,
    },

    {
        path: "/companies",
        Component: () => <AdminPage activeTab={"companies"} />,
        isProtected: true,
    },
    {
        path: "/users",
        Component: () => <AdminPage activeTab={"users"} />,
        isProtected: true,
    },
    {
        path: "/companies/:id/users",
        Component: () => <AdminPage activeTab={"users"} />,
        isProtected: true,
    },
    {
        path: "/companies/detail/:id",
        Component: pages.CompanyDetail,
        isProtected: true,
    },
    {
        path: "/companies/edit/:id",
        Component: pages.ModifyCompany,
        isProtected: true,
    },
    {
        path: "/registry/uco/:address/:field_id/:program_id/details",
        Component: pages.RegistryUcoDetails,
        isProtected: true,
    },
    {
        path: "/registry/scc/:address/:field_id/:program_id/details",
        Component: pages.RegistrySccDetails,
        isProtected: true,
    },
    {
        path: "/registry/retirement/:id",
        Component: pages.CollectionSccDetails,
        isProtected: true,
    },
    {
        path: "/my-account",
        Component: pages.MyAccount,
        isProtected: true,
    },
    {
        path: "/create-company",
        Component: pages.CreateCompany,
        isProtected: true,
    },
    {
        path: "/add/account",
        Component: pages.CreateAccount,
        isProtected: true,
    },
    {
        path: "/my-account/edit",
        Component: pages.ModifyAccount,
        isProtected: true,
    },
    {
        path: "/users/detail/:id",
        Component: pages.UserDetails,
        isProtected: true,
    },
    {
        path: "/users/edit/:id",
        Component: pages.ModifyUser,
        isProtected: true,
    },
    {
        path: "/users/add/:id",
        Component: pages.AddUser,
        isProtected: true,
    },
    {
        path: "/roles",
        Component: pages.Roles,
        isProtected: true,
    },
    {
        path: "/roles/manage-permissions/:id",
        Component: pages.Permissions,
        isProtected: true,
    },
    {
        path: "/401",
        Component: pages.NotAuthorise,
        isProtected: true,
    },
];

export default routes;
