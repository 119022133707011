import { useEffect } from "react";
import { verifyEmailLinkDetail } from "redux/authentication";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FarmLogo from "../assets/images/logofarm.svg";

const EmailVerify = () => {
    const { login } = useSelector((state) => state);
    let isLogin = login.loginData?.token;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogin) {
            dispatch(verifyEmailLinkDetail());
        }
    }, [isLogin]);

    useEffect(() => {
        if (login.emailLinkVerified && isLogin) {
            navigate("/programs");
        } else if (login.error) {
            navigate("/login");
        }
    }, [login]);

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    "&.MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3) !important",
                    },
                }}
                open={open}
                onClick={handleClose}
            >
                <Box
                    sx={{
                        background:
                            "linear-gradient(-180deg, #02353C 30%, #2EAF7D 100%)",
                        borderRadius: "10px",
                        padding: "40px",
                        minHeight: "200px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "primary.white",
                        fontSize: "20px",
                    }}
                >
                    <Box
                        component="img"
                        src={FarmLogo}
                        sx={{
                            cursor: "pointer",
                            width: { xs: "150px", md: "193px" },
                        }}
                    ></Box>
                    <CircularProgress color="inherit" /> Authentication in
                    Progress
                </Box>
            </Backdrop>
        </div>
    );
};
export default EmailVerify;
