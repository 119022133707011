import protectedRequest from "helpers/protectedRequest";
import useAxios from "helpers/useAxios.js";
const getCompanyList = async (params) => {
    return await protectedRequest(() =>
        useAxios.get(
            `company/all?page=${params?.page}&limit=${params?.limit}&search=${params?.search}`
        )
    );
};
const createdCompany = async (formData) => {
    return await protectedRequest(() =>
        useAxios.post("company/create", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    );
};
const getCompanyDetail = async (id) => {
    return await protectedRequest(() => useAxios.get(`company/${id}`));
};
const companyUpdate = async (params) => {
    return await protectedRequest(() =>
        useAxios.patch(`company/update/${params.id}`, params.formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    );
};
export { getCompanyList, createdCompany, getCompanyDetail, companyUpdate };
