import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import OpenPreviousPage from "common/OpenPreviousPage";
import { useEffect, useState } from "react";
import { fetchAllFarmerFields, getFarmers } from "redux/farmersSlice";
import { useDispatch, useSelector } from "react-redux";
import sanitizedString from "util/sanitizedString";
export default function DetailsTitle() {
    const { id, id: farmer_id } = useParams();
    const { farmersDetails } = useSelector((state) => state.farmers);
    const initialQuery = { page: 1, limit: 10, search: "" };
    const [query] = useState(initialQuery);
    const dispatch = useDispatch();

    const { isLoading, isLoaded, farmerFieldCount } = useSelector(
        (state) => state.farmers
    );

    useEffect(() => {
        if (Object.keys(farmersDetails).length === 0) {
            dispatch(getFarmers(id));
        }
    }, [farmersDetails]);

    useEffect(() => {
        if (!farmerFieldCount && !isLoaded) {
            dispatch(fetchAllFarmerFields({ query, id: farmer_id }));
        }
    }, [farmerFieldCount]);

    return (
        <>
            <Grid item xs={12} md="auto">
                {isLoading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "40rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        <OpenPreviousPage /> {farmerFieldCount} Field
                        {farmerFieldCount !== 1 ? "s" : ""}{" "}
                        <span style={{ fontWeight: "normal" }}> on</span>{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Link
                                to={`/farmer/${id}/details`}
                                sx={{
                                    display: "inline-block",
                                }}
                            >
                                {" "}
                                {sanitizedString(
                                    farmersDetails?.farmerCompanyName
                                )}
                            </Link>
                        </Box>
                    </Typography>
                )}
            </Grid>
        </>
    );
}
