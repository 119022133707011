import { useEffect, useState } from "react";
import Layout from "Layout";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography, Box, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import CustomTable from "common/CustomTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import UnderlineText from "common/UnderlineText";
import { Link } from "react-router-dom";
import Modal from "common/Modal";
import CustomDropzone from "common/CustomDropzone";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import Searchbar from "common/Searchbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    UploadFarmerDatainDB,
    fetchFarmers,
    resetCsvError,
    uploadCsvFarmersFiles,
} from "redux/farmersSlice";

// import dayjs from "dayjs";
import ConvertJsonToCsv from "common/ExportJson";
import CloseIcon from "@mui/icons-material/Close";
import csvFarmersFile from "assets/data/upload_farmers_file.csv";
import OpenPreviousPage from "common/OpenPreviousPage";
import { getCompanyDetaill } from "redux/companiesSlice";
import { useDateFormatter } from "customHooks/useDateFormatter";
import sanitizedString from "util/sanitizedString";
//
import { useAccessPage } from "customHooks/useAccess";
import CheckpageAuth from "auth/CheckPageAccess";
import permissionSlug from "assets/data/permissionSlug.json";
const headCells = [
    {
        id: "reference",
        numeric: false,
        label: "Reference",
    },
    {
        id: "farmer",
        numeric: true,
        label: "Farmer",
    },
    {
        id: "name",
        numeric: true,
        label: "Name",
    },
    {
        id: "zipcode",
        numeric: true,
        label: "Zip code",
    },
    {
        id: "city",
        numeric: true,
        label: "City",
    },
    {
        id: "country",
        numeric: true,
        label: "Country",
    },
    {
        id: "capid",
        numeric: true,
        label: "CAP ID",
    },
    {
        id: "creationdate",
        numeric: true,
        label: "Creation date",
    },
];

const FarmerList = () => {
    const { id } = useParams();
    const { formatDates } = useDateFormatter();
    const [openFarmerModal, setOpenFarmerModal] = useState();
    const [file, setFile] = useState([]);
    const [csvErrors, setCsvErrors] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //
    const { can, cannot } = useAccessPage();
    const { companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
        }),
        shallowEqual
    );
    useEffect(() => {
        if (id) {
            dispatch(getCompanyDetaill(id));
        }
    }, [id]);
    const initialQuery = { page: 1, limit: 10, search: "" };
    const [query, setQuery] = useState(initialQuery);
    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };
    const { farmers, isLoading, count, error } = useSelector(
        (state) => state.farmers,
        shallowEqual
    );
    useEffect(() => {
        dispatch(fetchFarmers({ ...query, ...(id && { id: id }) }));
    }, [query, location.pathname]);

    const routeAccess = {
        routeAccess: can(permissionSlug[0].farmer_slug.list_farmers),
        unAuthUserRedirectTo: "/401",
    };

    const sanitazidTabledData =
        farmers &&
        farmers?.map((item) => ({
            reference: item.farmerExternalId,
            farmer: (
                <TableCell sx={{ fontWeight: "bold" }}>
                    {can(permissionSlug[0].farmer_slug.farmer_details) ? (
                        <Link to={`/farmer/${item.id}/details`}>
                            <UnderlineText>
                                {sanitizedString(item?.farmerCompanyName)}
                            </UnderlineText>
                        </Link>
                    ) : (
                        sanitizedString(item?.farmerCompanyName)
                    )}
                </TableCell>
            ),
            name: `${item.farmerFirstname} ${item.farmerLastname}`,
            zipcode: item?.farmerZipcode || "-",
            city: item?.farmerCity || "-",
            country: item?.farmerCountry || "-",
            capid: item.farmerEuCapId,
            creationdate: formatDates(item?.farmerCreatedAt),
        }));
    const handleFiles = (res) => {
        // if (res[0].type !== "text/csv") {
        //     setCsvErrors("Only csv allowed");
        // } else {
        setFile(res);
        setCsvErrors(null);
        dispatch(resetCsvError());
        // }
        // Hello World
    };
    const handleRemoved = (event) => {
        event.stopPropagation();
        setFile("");
        setCsvErrors(null);
        dispatch(resetCsvError());
    };
    const handleCancelUpload = () => {
        setOpenFarmerModal(false);
        setFile("");
        setCsvErrors(null);
        dispatch(resetCsvError());
    };
    const closeModal = () => {
        setOpenFarmerModal(false);
        setFile("");
        setCsvErrors(null);
        dispatch(resetCsvError());
    };
    const handleCscFileUpload = () => {
        const formData = new FormData();

        if (!file[0]) {
            setCsvErrors("Please select file");
        } else {
            const keys = Object.keys(error);
            if (
                file[0] &&
                keys.includes("successfulInserts") &&
                error?.successfulInserts &&
                error?.successfulInserts.length > 0
            ) {
                dispatch(UploadFarmerDatainDB(error.successfulInserts)).then(
                    (res) => {
                        if (
                            res.meta.requestStatus === "fulfilled" &&
                            !res.errors
                        ) {
                            setOpenFarmerModal(false);
                            setFile("");
                            setCsvErrors("");
                            dispatch(fetchFarmers(query));
                            dispatch(resetCsvError());
                        }
                        // else {
                        //     setCsvErrors(res.payload.errors);
                        // }
                    }
                );
            } else {
                formData.append("file", file[0]);
                dispatch(uploadCsvFarmersFiles(formData)).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.errors) {
                        setOpenFarmerModal(false);
                        setFile("");
                        setCsvErrors("");
                        dispatch(fetchFarmers(query));
                        dispatch(resetCsvError());
                    }
                    // else {
                    //     setCsvErrors(res.payload.errors);
                    // }
                });
            }
        }
    };

    const shoulDisable = () => {
        let disabled = false;
        if (!error) {
            disabled = false;
        } else {
            const keys = Object.keys(error);
            if (isLoading || csvErrors) {
                disabled = true;
            } else if (
                keys.includes("successfulInserts") &&
                error?.successfulInserts &&
                error?.successfulInserts.length > 0
            ) {
                disabled = false;
            } else if (error.errors && error.errors.length > 0) {
                disabled = true;
            } else if (cannot(permissionSlug[0]?.farmer_slug?.upload_farmer)) {
                disabled = true;
            }
        }
        return disabled;
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", backgroundColor: "primary" }}>
                    <Grid
                        container
                        rowSpacing={{ md: 0, xs: "15px" }}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb="10px"
                    >
                        {id ? (
                            <Breadcrumbs
                                sources={[
                                    {
                                        label: "all farmers",
                                        link: "/farmers",
                                        canNavigate: true,
                                    },
                                    {
                                        label: "companies farmers",
                                        link: "",
                                    },
                                ]}
                            />
                        ) : (
                            <Breadcrumbs
                                sources={[
                                    {
                                        label: "farmers",
                                        link: "",
                                    },
                                    {
                                        label: "all farmers",
                                        link: "",
                                    },
                                ]}
                            />
                        )}
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    md: "flex-end",
                                },
                                gap: { xs: "18px", lg: "28px" },
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => setOpenFarmerModal(true)}
                                color="buttonPrimary"
                                sx={{
                                    my: {
                                        xs: "5px",
                                        md: "0",
                                        textTransform: "none",
                                    },
                                }}
                                disabled={cannot(
                                    permissionSlug[0].farmer_slug.upload_farmer
                                )}
                            >
                                Upload new farmers
                            </Button>
                            <Button
                                onClick={() => navigate("/add-farmer")}
                                variant="contained"
                                color="buttonPrimary"
                                sx={{
                                    my: {
                                        xs: "5px",
                                        md: "0",
                                        textTransform: "none",
                                    },
                                }}
                                disabled={cannot(
                                    permissionSlug[0].farmer_slug.create_farmer
                                )}
                            >
                                Add a farmer
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal
                        title={
                            <Typography variant="h2">
                                Farmers batch import
                            </Typography>
                        }
                        body={
                            <>
                                <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 5 }}
                                >
                                    <Typography sx={{ lineHeight: "18px" }}>
                                        Upload farmers by using .csv or .xls
                                        file.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            lineHeight: "18px",
                                            "& a": {
                                                color: "#5282B6",
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        Template File:{" "}
                                        <a
                                            href={csvFarmersFile}
                                            target="_blank"
                                            rel="noreferrer"
                                            download="csv file"
                                            style={{ color: "#5282B6" }}
                                        >
                                            upload_farmers_file.csv
                                        </a>
                                    </Typography>
                                </Typography>
                                <Box sx={{ margin: "35px auto" }}>
                                    <CustomDropzone
                                        onChange={(res) => handleFiles(res)}
                                        files={file}
                                        content={
                                            <>
                                                {file && file[0]?.name ? (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",

                                                            color: "rgb(63, 208, 201)",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                maxWidth:
                                                                    "200px",
                                                                fontSize:
                                                                    "15px",
                                                                whiteSpace:
                                                                    "nowrap",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                        >
                                                            {file[0].name}
                                                        </span>
                                                        <CloseIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                marginLeft:
                                                                    "5px",
                                                                color: "grey",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={
                                                                handleRemoved
                                                            }
                                                        />
                                                    </Box>
                                                ) : (
                                                    <>
                                                        <Box
                                                            component="p"
                                                            sx={{ mt: 0 }}
                                                        >
                                                            Drop your .csv file
                                                            here <br />
                                                            or upload it from
                                                            your computer
                                                        </Box>
                                                        <Button
                                                            sx={{
                                                                backgroundColor:
                                                                    "#f5f5f5",
                                                                border: "1px solid #000",
                                                                borderRadius:
                                                                    "20px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            <IconFileupload />
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    ml: "10px",
                                                                }}
                                                            >
                                                                {" "}
                                                                Upload
                                                            </Box>
                                                        </Button>
                                                        <Box
                                                            component="p"
                                                            sx={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {csvErrors}
                                                        </Box>
                                                    </>
                                                )}
                                            </>
                                        }
                                    />
                                </Box>
                            </>
                        }
                        footer={
                            <>
                                {error?.successfulInserts?.length >= 1 ? (
                                    <ul
                                        style={{
                                            color: "green",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <li>
                                            {/* {error?.successfulInserts?.length}{" "} */}
                                            {error?.successfulInserts?.length >
                                            2
                                                ? `Data provided for ${error?.successfulInserts?.length} farmers are accurate`
                                                : `Data provided for ${error?.successfulInserts?.length} farmer is accurate`}{" "}
                                        </li>
                                    </ul>
                                ) : (
                                    ""
                                )}

                                {error?.errors?.length >= 1 ? (
                                    <ul
                                        style={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <li>
                                            {/* {error?.errors?.length}{" "} */}
                                            {error?.errors?.length > 1
                                                ? `Data provided for ${error?.errors?.length} farmers are inaccurate`
                                                : `Data provided for ${error?.errors?.length} farmer is inaccurate`}{" "}
                                        </li>
                                    </ul>
                                ) : (
                                    ""
                                )}

                                {error?.errors?.length >= 1 ? (
                                    <Box
                                        component="p"
                                        sx={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {" "}
                                        <ul>
                                            {error?.errors &&
                                                error?.errors?.map(
                                                    (err, index) => (
                                                        <li key={index}>
                                                            {err}
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </Box>
                                ) : (
                                    ""
                                )}

                                <Stack
                                    direction="row"
                                    spacing={1}
                                    mt="44px"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={handleCancelUpload}
                                        sx={{ fontWeight: "500" }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="buttonPrimary"
                                        // backgroundColor={error?.successfulInserts?.length > 0 ? "#d0d2d6" : ""}
                                        disabled={shoulDisable() || csvErrors}
                                        onClick={handleCscFileUpload}
                                    >
                                        {isLoading
                                            ? "Processing..."
                                            : shoulDisable() ||
                                              error?.successfulInserts?.length >
                                                  0
                                            ? "Creation"
                                            : "Check file"}
                                    </Button>
                                </Stack>
                            </>
                        }
                        open={openFarmerModal}
                        close={closeModal}
                    />
                </Box>
                {count === null ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "20rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        {id ? <OpenPreviousPage /> : ""}
                        {!id && ""}
                        {count} {count > 1 ? " Farmers" : " Farmer"}{" "}
                        {id ? "on" : " "}{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Link
                                to={`/companies/detail/${id}`}
                                sx={{
                                    display: "inline-block",
                                }}
                            >
                                {" "}
                                {id ? `${companylist?.companyName}` : ""}
                            </Link>
                        </Box>{" "}
                    </Typography>
                )}
                <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        flexWrap: { xs: "wrap", sm: "inherit" },
                        justifyContent: { xs: "center", sm: "space-between" },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="10px"
                        sx={{
                            width: "100%",
                            md: "auto",
                            justifyContent: {
                                xs: "center",
                                sm: "center",
                                md: "space-between",
                                lg: "space-between",
                                xl: "space-between",
                            },
                        }}
                    >
                        <Searchbar
                            placeholdertext="Search a farmer"
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        mt: "30px",
                    }}
                >
                    <CustomTable
                        headCells={headCells}
                        rows={sanitazidTabledData ? sanitazidTabledData : []}
                        exportbutton={
                            <ConvertJsonToCsv
                                jsonData={farmers?.map((item) => {
                                    return {
                                        "Farmer ID": item.id,
                                        "Farmer External ID":
                                            item.farmerExternalId,
                                        "Farmer SIREN": item.farmerOfficialId,
                                        "Company Name": item.farmerCompanyName,
                                        "Postal Code": item.farmerZipcode,
                                        City: item.farmerCity,
                                        Country: item.farmerCountry,
                                        "Farmer EU CAP ID": item.farmerEuCapId,
                                        "Creation date": formatDates(
                                            item.farmerCreatedAt
                                        ),
                                    };
                                })}
                            />
                        }
                        loadingList={isLoading}
                        setQuery={setQuery}
                        query={query}
                        totalRowCount={count}
                        noDataFound="No farmers found"
                    />
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default FarmerList;
