import { useSelector, shallowEqual } from "react-redux";
export function useAccessPage() {
    const { permissions, role } = useSelector(
        (state) => state.account?.accessibility,
        shallowEqual
    );
    const can = (pageName) => {
        return permissions.find((item) => item.slug === pageName)?.permission;
    };
    const cannot = (pageName) => {
        return !permissions.find((item) => item.slug === pageName)?.permission;
    };
    const isRoles = (slug) => {
        return role === slug;
    };

    return { can, cannot, isRoles };
}
