import { useEffect, useState } from "react";
import { Typography, Button, Box, Grid } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Modal from "common/Modal";
import CustomDropzone from "common/CustomDropzone";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import {
    resetCsvFieldErrors,
    uploadCsvFieldFiles,
    uploadValuesFieldFiles,
    resetSccValueUploadErrors,
    resetsValuesErrors,
} from "redux/fieldsSlice";
import { refetchProgramFields } from "redux/programSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import csvFieldsFile from "assets/data/upload_parcels_shapefile.csv";
import sccCsvValueFile from "assets/data/upload_value_model.csv";
import { useNavigate, useParams } from "react-router-dom";
import { getProgram } from "redux/programSlice";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import UnderlineText from "common/UnderlineText";
import { getBlockChainToken } from "util/token";
// import { validateDate } from "@mui/x-date-pickers/internals";

import BlockchainProcessingModalsMessages from "common/BlockchainProcessingModalsMessages";
import LoaderOverlay from "common/LoaderOverlay";
export default function FieldHeader() {
    const { can, cannot } = useAccessPage();
    const { isLoading, uploadingsValues } = useSelector((state) => state.field);
    const [openFieldModal, setOpenFieldModal] = useState();
    const [openValueModal, setOpenValueModal] = useState();
    const [error, setError] = useState(null);
    const [file, setFile] = useState([]);
    const [harvestyear, setHarvestyear] = useState("2019");

    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const { fieldCsvErrors, fieldsValuesErrors } = useSelector(
        (state) => state.field
    );
    const valuesErrors = fieldsValuesErrors?.errors || [];
    const valuesSuccess = fieldsValuesErrors?.UpdatedFields;

    useEffect(() => {
        if (valuesSuccess && valuesSuccess.length > 0) {
            const timerId = setTimeout(() => {
                // Your task to be executed after 40 seconds
                dispatch(refetchProgramFields());
                dispatch(resetsValuesErrors());
                setOpenValueModal(false);
                setFile("");
                setError("");
            }, 3000); // 40 seconds in milliseconds

            // Clear the timeout if the component unmounts or if you want to cancel it for some reason
            return () => clearTimeout(timerId);
        }
    }, [valuesSuccess]);

    const { programDetails } = useSelector((state) => state.program);
    const errorInArrayFormat = fieldCsvErrors?.errorMessages || [];
    const statusShouldNotRegistered =
        programDetails?.program_status !== "registered";

    const handleChange = (event) => {
        setHarvestyear(event.target.value);
        dispatch(resetCsvFieldErrors());
    };

    const handleValuesFileUpload = () => {
        // if (can(permissionSlug[0].fields_slug.upload_fields)) {
        const formData = new FormData();
        if (!file[0]) {
            setError("Please select file");
        } else {
            const blockchainToken = getBlockChainToken();
            formData.append("file", file[0]);
            formData.append("programId", id);
            formData.append("blockchain_token", blockchainToken);
            dispatch(uploadValuesFieldFiles(formData)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    dispatch(refetchProgramFields());
                    setOpenValueModal(false);
                    setFile("");
                    setError("");
                    // navigate(`/import-result?programId=${id}`);
                }
            });
        }
        // }
    };

    const handleCscFileUpload = () => {
        if (can(permissionSlug[0].fields_slug.upload_fields)) {
            const formData = new FormData();
            if (!file[0]) {
                setError("Please select file");
            } else {
                formData.append("fieldHarvestYear", harvestyear);
                formData.append("file", file[0]);
                formData.append(
                    "companyId",
                    programDetails?.program_id_company_KO
                );
                formData.append("programId", programDetails?.id);

                dispatch(
                    uploadCsvFieldFiles({
                        formData,
                        csvFile: file[0],
                        year: harvestyear,
                        finalClick: false,
                        type: "import-page",
                    })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        setOpenFieldModal(false);
                        setFile("");
                        setHarvestyear("2019");
                        setError("");
                        navigate(`/import-result?programId=${id}`);
                    }
                });
            }
        }
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        setFile("");
        setHarvestyear("2019");
        dispatch(resetCsvFieldErrors());
    };

    const handleCancelUpload = () => {
        setOpenFieldModal(false);
        setFile("");
        setHarvestyear("2019");
        setError(null);
        dispatch(resetCsvFieldErrors());
    };
    const closeModal = () => {
        setOpenFieldModal(false);
        setFile("");
        setError(null);
        dispatch(resetCsvFieldErrors());
    };
    const handleCompanyID = () => {
        setOpenFieldModal(true);
        // dispatch(getProgram(id));
    };
    //func for scc value upload below:
    const handleCloseValueFile = (event) => {
        event.stopPropagation();
        setFile("");
        dispatch(resetSccValueUploadErrors());
    };
    const handleCancelValueModal = () => {
        setOpenValueModal(false);
        setFile("");
        dispatch(resetSccValueUploadErrors());
    };
    const closeSccValueModal = () => {
        setOpenValueModal(false);
        setFile("");
        dispatch(resetSccValueUploadErrors());
    };
    useEffect(() => {
        dispatch(getProgram(id));
    }, []);

    if (!programDetails?.program_status) {
        return null;
    }
    const shoulDisable = () => {
        let disabled = false;
        if (isLoading || error) {
            disabled = true;
        } else if (errorInArrayFormat?.length > 0) {
            disabled = true;
        } else if (typeof fieldCsvErrors === "string") {
            disabled = true;
        } else if (fieldCsvErrors?.errorMessage) {
            disabled = true;
        } else if (cannot(permissionSlug[0].fields_slug.upload_fields)) {
            disabled = true;
        }
        return disabled;
    };

    return (
        <>
            <Breadcrumbs
                sources={[
                    {
                        label: "programs",
                        link: "/programs",
                        canNavigate: true,
                    },
                    {
                        label: `program #${id}`,
                        link: `/program/${id}/detail`,
                        canNavigate: true,
                    },
                    {
                        label: "all fields",
                        link: "",
                    },
                ]}
            />
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                <Button
                    variant="contained"
                    disabled={
                        cannot(permissionSlug[0].fields_slug.upload_fields) ||
                        statusShouldNotRegistered
                    }
                    color="buttonPrimary"
                    onClick={handleCompanyID}
                    sx={{
                        mr: "20px",
                    }}
                >
                    Add fields
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        cannot(permissionSlug[0].fields_slug.upload_values) ||
                        statusShouldNotRegistered
                    }
                    color="buttonPrimary"
                    onClick={() => setOpenValueModal(true)}
                >
                    Add value
                </Button>
            </Grid>
            {/* addfieldmodal */}
            {can(permissionSlug[0].fields_slug.upload_fields) && (
                <Modal
                    title={
                        <Typography variant="h2">
                            Boundaries fields batch import
                        </Typography>
                    }
                    body={
                        <>
                            <Typography
                                id="modal-modal-description"
                                sx={{
                                    mt: { xs: "23px", md: "43px" },
                                    lineHeight: "18px",
                                }}
                            >
                                <Typography sx={{ lineHeight: 1.2 }}>
                                    Upload boundaries by using the .csv file.{" "}
                                    <br />
                                    Template file :
                                    <a
                                        href={csvFieldsFile}
                                        target="_blank"
                                        rel="noreferrer"
                                        download="csv file"
                                        style={{ color: "#5282B6" }}
                                    >
                                        upload_parcels_shapefile.csv
                                    </a>
                                </Typography>
                                <Typography sx={{ mt: "5px" }}>
                                    <Box component="b">Note:</Box> Farmers must
                                    have been created before importing.
                                </Typography>
                            </Typography>
                            <FormControl
                                variant="filled"
                                required
                                fullWidth
                                sx={{
                                    "&.MuiFormControl-root": {
                                        maxWidth: {
                                            xs: "250px",
                                        },
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "30px auto 30px",
                                    },
                                }}
                            >
                                <InputLabel id="demo-simple-select-filled-label">
                                    Harvest
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={harvestyear}
                                    onChange={handleChange}
                                    className="harvestselect"
                                >
                                    <MenuItem value={2019}>2019</MenuItem>
                                    <MenuItem value={2020}>2020</MenuItem>
                                    <MenuItem value={2021}>2021</MenuItem>
                                    <MenuItem value={2022}>2022</MenuItem>
                                    <MenuItem value={2023}>2023</MenuItem>
                                    <MenuItem value={2024}>2024</MenuItem>
                                </Select>
                            </FormControl>
                            <CustomDropzone
                                onChange={(res) => {
                                    if (res[0]) {
                                        setFile(res);
                                        setError(null);
                                    }
                                    // if (res[0] && res[0].type === "text/csv") {
                                    //     setFile(res);
                                    //     setError(null);
                                    // } else if (
                                    //     res[0] &&
                                    //     res[0].type !== "text/csv"
                                    // ) {
                                    //     setFile(null);
                                    //     setError("Only allow csv file");
                                    // }

                                    dispatch(resetCsvFieldErrors());
                                }}
                                files={file}
                                content={
                                    <>
                                        {file && file[0]?.name ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",

                                                    color: "rgb(63, 208, 201)",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        maxWidth: "200px",
                                                        fontSize: "15px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                >
                                                    {file[0].name}
                                                </span>
                                                <CloseIcon
                                                    style={{
                                                        fontSize: "20px",
                                                        marginLeft: "5px",
                                                        color: "grey",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={handleCancel}
                                                />
                                            </Box>
                                        ) : (
                                            <>
                                                <Box
                                                    component="p"
                                                    sx={{ mt: 0 }}
                                                >
                                                    Drop your .csv file here{" "}
                                                    <br />
                                                    or upload it from your
                                                    computer
                                                </Box>
                                                <Button
                                                    sx={{
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                        border: "1px solid #000",
                                                        borderRadius: "20px",
                                                        color: "#000000",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <IconFileupload />
                                                    <Box
                                                        component="span"
                                                        sx={{ ml: "10px" }}
                                                    >
                                                        {" "}
                                                        Upload
                                                    </Box>
                                                </Button>
                                                <Box
                                                    component="p"
                                                    sx={{ color: "red" }}
                                                >
                                                    {error}
                                                </Box>
                                            </>
                                        )}
                                    </>
                                }
                            />
                        </>
                    }
                    footer={
                        <>
                            {fieldCsvErrors?.errorMessage ? (
                                <Box
                                    component="p"
                                    sx={{
                                        color: "red",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {" "}
                                    <ul>
                                        <li style={{ fontSize: "16px" }}>
                                            {fieldCsvErrors?.errorMessage} You
                                            can download{" "}
                                            <a
                                                rel="noreferrer"
                                                download="csv file"
                                                style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                }}
                                                href={
                                                    fieldCsvErrors?.errorCsvlink
                                                }
                                            >
                                                {" "}
                                                <UnderlineText>
                                                    fields_errors_csv
                                                </UnderlineText>
                                                .
                                            </a>
                                        </li>
                                    </ul>
                                </Box>
                            ) : (
                                ""
                            )}
                            {typeof fieldCsvErrors === "string" && (
                                <Box
                                    sx={{
                                        color: "red",
                                        textAlign: "center",
                                    }}
                                >
                                    {fieldCsvErrors}
                                </Box>
                            )}
                            {errorInArrayFormat &&
                                errorInArrayFormat.length > 0 && (
                                    <Box
                                        sx={{
                                            color: "red",
                                            textAlign: "center",
                                        }}
                                    >
                                        {errorInArrayFormat?.map(
                                            (item, index) => (
                                                <ul key={index}>
                                                    <li> {item}</li>
                                                </ul>
                                            )
                                        )}
                                    </Box>
                                )}

                            <Stack
                                direction="row"
                                spacing={1}
                                mt="44px"
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleCancelUpload}
                                    sx={{ fontWeight: "500" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color={isLoading ? "grey" : "buttonPrimary"}
                                    disabled={shoulDisable()}
                                    onClick={handleCscFileUpload}
                                >
                                    {isLoading
                                        ? "Processing..."
                                        : shoulDisable()
                                        ? "Import"
                                        : "Check file"}
                                </Button>
                            </Stack>
                        </>
                    }
                    open={openFieldModal}
                    close={closeModal}
                />
            )}
            {/* addvaluemodal */}
            {can(permissionSlug[0].fields_slug.upload_values) && (
                <Modal
                    title={
                        <Typography variant="h2">
                            Value batch import + SCC creation
                        </Typography>
                    }
                    body={
                        <>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: { xs: "23px", md: "43px" } }}
                            >
                                <Typography sx={{ lineHeight: "18px" }}>
                                    Upload values (ex : GHG value) by fields by
                                    using the .csv import. <br />
                                    Template file :
                                    <a
                                        href={sccCsvValueFile}
                                        target="_blank"
                                        rel="noreferrer"
                                        download="csv file"
                                        style={{ color: "#5282B6" }}
                                    >
                                        upload_value.csv
                                    </a>
                                    <br />
                                    SCC token will be created automatically.
                                </Typography>
                                <Typography
                                    sx={{ mt: "15px", lineHeight: "18px" }}
                                >
                                    <Box component="b">Note: </Box>Farmers and
                                    fields must have been created before
                                    importing.
                                    <br />
                                    <Box component="b">Note: </Box>Use field_id
                                    displayed by FarmRegistry.
                                </Typography>
                            </Typography>
                            <CustomDropzone
                                onChange={(res) => setFile(res)}
                                files={file}
                                content={
                                    <>
                                        {file && file[0]?.name ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",

                                                    color: "rgb(63, 208, 201)",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        maxWidth: "200px",
                                                        fontSize: "15px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                >
                                                    {file[0].name}
                                                </span>
                                                <CloseIcon
                                                    style={{
                                                        fontSize: "20px",
                                                        marginLeft: "5px",
                                                        color: "grey",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={
                                                        handleCloseValueFile
                                                    }
                                                />
                                            </Box>
                                        ) : (
                                            <>
                                                <Box
                                                    component="p"
                                                    sx={{ mt: 0 }}
                                                >
                                                    Drop your .csv file here{" "}
                                                    <br />
                                                    or upload it from your
                                                    computer
                                                </Box>
                                                <Button
                                                    sx={{
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                        border: "1px solid #000",
                                                        borderRadius: "20px",
                                                        color: "#000000",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <IconFileupload />
                                                    <Box
                                                        component="span"
                                                        sx={{ ml: "10px" }}
                                                    >
                                                        {" "}
                                                        Upload
                                                    </Box>
                                                </Button>
                                            </>
                                        )}
                                    </>
                                }
                            />
                        </>
                    }
                    footer={
                        <>
                            {/* {typeof fieldCsvErrors === "string" && (
                                <Box
                                    sx={{
                                        color: "red",
                                        textAlign: "center",
                                    }}
                                >
                                    {fieldCsvErrors}
                                </Box>
                            )} */}
                            {valuesSuccess && valuesSuccess.length > 0 && (
                                <Box
                                    sx={{
                                        color: "green",
                                        textAlign: "center",
                                    }}
                                >
                                    {/* {valuesSuccess?.map((item, index) => ( */}
                                    <ul>
                                        <li>{`${valuesSuccess.length} ${
                                            valuesSuccess.length < 2
                                                ? "field"
                                                : "fields"
                                        } updated successfully`}</li>
                                    </ul>
                                    {/* ))} */}
                                </Box>
                            )}
                            {valuesErrors && valuesErrors.length > 0 && (
                                <Box
                                    sx={{
                                        color: "red",
                                        textAlign: "center",
                                    }}
                                >
                                    {valuesErrors?.map((item, index) => (
                                        <ul key={index}>
                                            <li> {item}</li>
                                        </ul>
                                    ))}
                                </Box>
                            )}
                            {/* <CircularProgress /> */}
                            {uploadingsValues && (
                                <BlockchainProcessingModalsMessages />
                            )}
                            <Stack
                                direction="row"
                                spacing={1}
                                mt="44px"
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleCancelValueModal}
                                    sx={{ fontWeight: "500" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={uploadingsValues}
                                    color="buttonPrimary"
                                    // color={isLoading ? "grey" : "buttonPrimary"}
                                    // disabled={shoulDisable()}
                                    onClick={handleValuesFileUpload}
                                >
                                    {uploadingsValues
                                        ? "Processing..."
                                        : "Save"}
                                </Button>
                            </Stack>
                        </>
                    }
                    open={openValueModal && !uploadingsValues}
                    close={closeSccValueModal}
                />
            )}
            {uploadingsValues && (
                <LoaderOverlay
                    message="Your patience is greatly appreciated! We are processing
                        your data..."
                />
            )}
        </>
    );
}
