import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import OpenPreviousPage from "common/OpenPreviousPage";

export default function DetailsTitle() {
    const { id } = useParams();
    const programNumber = id;
    const { project } = useSelector(
        (state) => ({
            project: state?.project,
        }),
        shallowEqual
    );
    const loading = project?.isloading;
    const totalProjectsOnProgram = project?.projectList?.data?.count;
    return (
        <>
            <Grid item xs={12} md={7}>
                {loading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "20rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        <OpenPreviousPage /> {totalProjectsOnProgram}{" "}
                        {totalProjectsOnProgram > 1
                            ? "Projects on"
                            : "Project on"}{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Link
                                to={`/program/${programNumber}/detail`}
                                sx={{
                                    display: "inline-block",
                                }}
                            >
                                Program #{programNumber}
                            </Link>
                        </Box>
                    </Typography>
                )}
            </Grid>
        </>
    );
}
