import { Typography, Grid, Skeleton, Box } from "@mui/material";
import OpenPreviousPage from "common/OpenPreviousPage";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getCompanyDetaill } from "redux/companiesSlice";

export default function UsersTitle() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
        }),
        shallowEqual
    );
    useEffect(() => {
        if (id) {
            dispatch(getCompanyDetaill(id));
        }
    }, [id]);
    const { count, loading } = useSelector((state) => state?.usersList);
    return (
        <>
            <Grid item xs={12} md={6}>
                {loading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "40rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        {id ? <OpenPreviousPage /> : ""}
                        {!id && ""}
                        {count}
                        {count === 0 || count === 1 ? " User " : " Users "}
                        {id ? "on " : ""}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Link
                                to={`/companies/detail/${id}`}
                                sx={{
                                    display: "inline-block",
                                }}
                            >
                                {/* {id ? `${count}  Users on ${companylist?.companyName}` : `${count} Users`} */}{" "}
                                {id ? `${companylist?.companyName}` : ""}
                            </Link>
                        </Box>
                    </Typography>
                )}
            </Grid>
        </>
    );
}
