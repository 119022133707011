import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
export default function ProjectHeader() {
    const navigate = useNavigate();
    const { cannot } = useAccessPage();
    return (
        <>
            <Breadcrumbs
                xs="auto"
                sources={[
                    {
                        label: "admin",
                        link: "",
                    },
                    {
                        label: "all companies",
                        link: "",
                    },
                ]}
            />
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    gap: { xs: "18px", lg: "28px" },
                }}
            >
                <Button
                    onClick={() => navigate("/create-company")}
                    disabled={cannot(
                        permissionSlug[0].company_slug.create_company
                    )}
                    variant="contained"
                    color="buttonPrimary"
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                    }}
                >
                    Add a company
                </Button>
            </Grid>
        </>
    );
}
