import { auth, provider } from "../../src/firebaseconfig/firebaseconfig.js";
import {
    sendSignInLinkToEmail,
    signInWithPopup,
    // fetchSignInMethodsForEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
} from "firebase/auth";
import useAxios from "helpers/useAxios.js";
import useBlockChainAxios from "helpers/useBlockChainAxios.js";

// const initiateEmailSignIn = async (email) => {
//     return await fetchSignInMethodsForEmail(auth, email);
// };
const initiateEmailSignIn = async (email) => {
    return await useAxios.post("users/checkUser", { user_email: email.email });
};

const sendEmailLink = async (email) => {
    const actionCodeSettings = {
        url: `${process.env.REACT_APP_BASE_URL}/verifyemail`,
        handleCodeInApp: true,
    };
    return await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(
        () => {
            localStorage.setItem("emailForSignIn", email);
        }
    );
};

//verifying email
const verifySignInLink = async () => {
    return await isSignInWithEmailLink(auth, window.location.href);
};
const verifyAuthEmailLink = async (storedEmail) => {
    return await signInWithEmailLink(auth, storedEmail, window.location.href);
};

//function to add google login
const handleGoogleLogin = async () => {
    return await signInWithPopup(auth, provider);
};
// function for final login
const loginUser = async (token) => {
    return await useAxios.post("auth/login", { token });
};
const BlockChainLogin = async () => {
    const token = process.env.REACT_APP_BLOCKCHAIN_APP_KEY;
    return await useBlockChainAxios.post("auth/service-login", {
        apiKey: token,
    });
};
const logoutUser = async (token) => {
    return await useAxios.post("auth/login", { token });
};
export {
    initiateEmailSignIn,
    sendEmailLink,
    handleGoogleLogin,
    verifySignInLink,
    verifyAuthEmailLink,
    loginUser,
    BlockChainLogin,
    logoutUser,
};
