import Breadcrumbs from "common/Breadcrumb";

export default function ListHeader({ companyId }) {
    if (companyId) {
        return (
            <>
                <Breadcrumbs
                    sources={[
                        {
                            label: "fields",
                            link: "",
                        },
                        {
                            label: "Companies",
                            link: "",
                        },
                        {
                            label: "all fields",
                            link: "",
                        },
                    ]}
                />
            </>
        );
    }
    return (
        <>
            <Breadcrumbs
                sources={[
                    {
                        label: "fields",
                        link: "",
                    },
                    {
                        label: "list",
                        link: "",
                    },
                ]}
            />
        </>
    );
}
