import MapContainer from "../detail/MapContainer";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const Map = () => {
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].fields_slug.fields_map),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <MapContainer fullHeights={true} />
            </CheckpageAuth>
        </>
    );
};
export default Map;
