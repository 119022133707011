import SDG1 from "assets/icons/sustainable-goals/SDG_1.svg";
import SDG2 from "assets/icons/sustainable-goals/SDG_2.svg";
import SDG3 from "assets/icons/sustainable-goals/SDG_3.svg";
import SDG4 from "assets/icons/sustainable-goals/SDG_4.svg";
import SDG5 from "assets/icons/sustainable-goals/SDG_5.svg";
import SDG6 from "assets/icons/sustainable-goals/SDG_6.svg";
import SDG7 from "assets/icons/sustainable-goals/SDG_7.svg";
import SDG8 from "assets/icons/sustainable-goals/SDG_8.svg";
import SDG9 from "assets/icons/sustainable-goals/SDG_9.svg";
import SDG10 from "assets/icons/sustainable-goals/SDG_10.svg";
import SDG11 from "assets/icons/sustainable-goals/SDG_11.svg";
import SDG12 from "assets/icons/sustainable-goals/SDG_12.svg";
import SDG13 from "assets/icons/sustainable-goals/SDG_13.svg";
import SDG14 from "assets/icons/sustainable-goals/SDG_14.svg";
import SDG15 from "assets/icons/sustainable-goals/SDG_15.svg";
import SDG16 from "assets/icons/sustainable-goals/SDG_16.svg";
import SDG17 from "assets/icons/sustainable-goals/SDG_17.svg";

export const goals = [
    {
        goalImage: SDG1,
        goal_id: "1",
        selected: false,
    },
    {
        goalImage: SDG2,
        goal_id: "2",
        selected: false,
    },
    {
        goalImage: SDG3,
        goal_id: "3",
        selected: false,
    },
    {
        goalImage: SDG4,
        goal_id: "4",
        selected: false,
    },
    {
        goalImage: SDG5,
        goal_id: "5",
        selected: false,
    },
    {
        goalImage: SDG6,
        goal_id: "6",
        selected: false,
    },
    {
        goalImage: SDG7,
        goal_id: "7",
        selected: false,
    },
    {
        goalImage: SDG8,
        goal_id: "8",
        selected: false,
    },
    {
        goalImage: SDG9,
        goal_id: "9",
        selected: false,
    },
    {
        goalImage: SDG10,
        goal_id: "10",
        selected: false,
    },
    {
        goalImage: SDG11,
        goal_id: "11",
        selected: false,
    },
    {
        goalImage: SDG12,
        goal_id: "12",
        selected: false,
    },
    {
        goalImage: SDG13,
        goal_id: "13",
        selected: false,
    },
    {
        goalImage: SDG14,
        goal_id: "14",
        selected: false,
    },
    {
        goalImage: SDG15,
        goal_id: "15",
        selected: false,
    },
    {
        goalImage: SDG16,
        goal_id: "16",
        selected: false,
    },
    {
        goalImage: SDG17,
        goal_id: "17",
        selected: false,
    },
];
