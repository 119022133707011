import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMemo } from "react";
import { useSelector } from "react-redux";
// import sanitizedDigits from "util/sanitizedDigits";

const Fees = ({ formik, edit }) => {
    const { programDetails } = useSelector((state) => state.program);
    const handleValueChange = (key, value) => {
        const rawValue = value;
        if (rawValue.includes(".")) {
            if (rawValue.length > 5) {
                return;
            }
        } else {
            if (rawValue.length > 3) {
                return;
            } else if (rawValue.length > 1 && rawValue[0] === "0") {
                return;
            } else if (rawValue.includes("000")) {
                return;
            }
        }
        const formattedValue =
            rawValue?.indexOf(".") >= 0
                ? rawValue?.slice(0, rawValue?.indexOf(".") + 3)
                : rawValue;
        formik.setFieldValue(key, formattedValue);
    };
    const program_farmer_fees = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_farmer_fees"
                    label="Farmer fees"
                    variant="filled"
                    name="program_farmer_fees"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_farmer_fees}
                    onChange={(event) =>
                        handleValueChange(
                            "program_farmer_fees",
                            event.target.value
                        )
                    }
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_farmer_fees &&
                        !!formik.errors.program_farmer_fees
                    }
                    helperText={
                        formik.touched.program_farmer_fees &&
                        formik.errors.program_farmer_fees
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_farmer_fees,
        formik.touched.program_farmer_fees,
        formik.errors.program_farmer_fees,
    ]);

    const program_developer_fees = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_developer_fees"
                    label="Developer Fees"
                    variant="filled"
                    name="program_developer_fees"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_developer_fees}
                    onChange={(event) =>
                        handleValueChange(
                            "program_developer_fees",
                            event.target.value
                        )
                    }
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_developer_fees &&
                        !!formik.errors.program_developer_fees
                    }
                    helperText={
                        formik.touched.program_developer_fees &&
                        formik.errors.program_developer_fees
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_developer_fees,
        formik.touched.program_developer_fees,
        formik.errors.program_developer_fees,
    ]);

    const program_registry_fees = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_registry_fees"
                    label="Registry Fees"
                    variant="filled"
                    name="program_registry_fees"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_registry_fees}
                    onChange={(event) =>
                        handleValueChange(
                            "program_registry_fees",
                            event.target.value
                        )
                    }
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_registry_fees &&
                        !!formik.errors.program_registry_fees
                    }
                    helperText={
                        formik.touched.program_registry_fees &&
                        formik.errors.program_registry_fees
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_registry_fees,
        formik.touched.program_registry_fees,
        formik.errors.program_registry_fees,
    ]);
    return (
        <>
            <Box
                sx={{
                    "& .MuiInputLabel-asterisk": {
                        color: "red",
                    },
                }}
            >
                <Typography variant="h4" mb={3}>
                    Fees
                </Typography>
                <Grid container spacing="30px">
                    {program_farmer_fees}
                    {program_developer_fees}
                    {program_registry_fees}
                </Grid>
            </Box>
        </>
    );
};

export default Fees;
