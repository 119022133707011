import Layout from "Layout";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Checkbox, Typography } from "@mui/material";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import { Link, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GetUser } from "redux/users";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
import UnderlineText from "common/UnderlineText";
import { toggleSharingOptions } from "redux/accountSlice";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    minWidth: "91px",
    fontSize: "14px",
}));
const UserDetail = () => {
    const { id: userIDS } = useParams();
    const dispatch = useDispatch();

    const { can, cannot, isRoles } = useAccessPage();
    const isAdmin = isRoles("admin");
    const isPrincipal = isRoles("principal");
    const routeAccess = {
        routeAccess: can(permissionSlug[0].users_slug.user_details),
        unAuthUserRedirectTo: "/401",
    };
    useEffect(() => {
        dispatch(GetUser(userIDS));
    }, []);
    const { usersList, loading: isLoading } = useSelector(
        (state) => ({
            usersList: state.usersList.GetUser.data || {},
            loading: state?.usersList?.loading,
        }),
        shallowEqual
    );

    const theme = useTheme();
    const companyDetails = usersList?.company || {};
    const {
        user_firstname,
        user_lastname,
        user_phone,
        user_email,
        user_job,
        id: users_id,
        role: { name: user_profile_type } = {},
        user_wallet_manager,
        user_timezone,
        user_dateformat,
        user_measurement,
        user_language,
        user_share_personal_data,
        user_status,
        user_street,
        user_street_2,
        user_zipcode,
        user_region,
        user_city,
        user_country,
    } = usersList;

    const {
        companyProfile: companyProfileTypes,
        id: company_id,
        companyName,
        companyWebsite,
        companyPhone,
        companyEmail,
        companyStreet,
        companyStreet2,
        companyZipcode,
        companyRegion,
        companyCity,
        companyCountry,
        wallet,
    } = companyDetails;

    const myProfile = [
        {
            title: "First name",
            info: user_firstname || "",
        },
        {
            title: "Last name",
            info: user_lastname || "",
        },
        {
            title: "Phone number",
            info: user_phone || "",
        },
        {
            title: "Email",
            info: <UnderlineText>{user_email || ""}</UnderlineText>,
        },
        {
            title: "Job title",
            info: user_job || "-",
        },
        {
            title: "User ID",
            info: users_id,
        },
        {
            title: "Profile type",
            info: user_profile_type,
        },
        {
            title: "Wallet Manager",
            info: user_wallet_manager ? "Yes" : "No",
        },
    ];

    const preferences = [
        {
            title: "Timezone",
            info: user_timezone,
        },
        {
            title: "Date format",
            info: user_dateformat,
        },
        {
            title: "Measurement units",
            info: user_measurement,
        },
        {
            title: "Language",
            info: user_language,
        },
    ];

    const myAddress = [
        {
            title: "Street",
            info: user_street,
        },
        {
            title: "Street 2",
            info: user_street_2 || "---",
        },
        {
            title: "Zip code",
            info: user_zipcode,
        },
        {
            title: "Region",
            info: user_region || "---",
        },
        {
            title: "City",
            info: user_city,
        },
        {
            title: "Country",
            info: user_country,
        },
    ];

    const companyProfile = [
        {
            title: "Profile",
            info: companyProfileTypes || "---",
        },
        {
            title: "Wallet address",
            info: wallet?.wallet_public_key || "---",
        },
    ];

    const company = [
        {
            title: "ID",
            info: company_id || "---",
        },
        {
            title: "Company name",
            info: companyName || "---",
        },
        {
            title: "Web site",
            info: <UnderlineText>{companyWebsite || "---"}</UnderlineText>,
        },
        {
            title: "Phone",
            info: companyPhone || "---",
        },
        {
            title: "Email",
            info: <UnderlineText>{companyEmail || "---"}</UnderlineText>,
        },
        {
            title: "Street",
            info: companyStreet || "---",
        },
        {
            title: "Street 2",
            info: companyStreet2 || "---",
        },
        {
            title: "Zip code",
            info: companyZipcode || "---",
        },
        {
            title: "City",
            info: companyCity || "---",
        },
        {
            title: "Region",
            info: companyRegion || "---",
        },
        {
            title: "Country",
            info: companyCountry || "---",
        },
    ];
    const handleSharing = (type) => (e) => {
        const checked = e.target.checked;
        dispatch(
            toggleSharingOptions({
                id: users_id,
                data: { type: type, value: checked },
            })
        );
    };
    const GDPR = [
        {
            title: "Sharing personal data",
            info: (
                <Box sx={{}}>
                    <Checkbox
                        defaultChecked={Boolean(user_share_personal_data)}
                        onChange={handleSharing("personal")}
                        sx={{ p: 0 }}
                    />
                </Box>
            ),
        },
        // {
        //     title: "Sharing farmers data",
        //     info: (
        //         <Box sx={{}}>
        //             <Checkbox
        //                 defaultChecked={Boolean(user_share_farmers_data)}
        //                 onChange={handleSharing("farmers")}
        //                 sx={{ p: 0 }}
        //             />
        //         </Box>
        //     ),
        // },
        {
            title: null,
            info: <UnderlineText>Data protection policy</UnderlineText>,
        },
    ];

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", backgroundColor: "primary" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb="10px"
                    >
                        <Breadcrumbs
                            sm="auto"
                            sources={[
                                {
                                    label: "users",
                                    link: "/users",
                                    canNavigate: true,
                                },
                                {
                                    label: `user ${userIDS}`,
                                    link: "",
                                },
                            ]}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-end",
                                },
                                pt: "2px !important",
                                mb: { xs: "10px", sm: 0 },
                            }}
                        >
                            <Link
                                to={`/users/edit/${userIDS}`}
                                state={{ userData: userIDS }}
                            >
                                <Button
                                    variant="contained"
                                    disabled={cannot(
                                        permissionSlug[0].users_slug.edit_user
                                    )}
                                    sx={{
                                        padding: {
                                            xs: "4px 18px",
                                            md: "4px 23px",
                                        },
                                        backgroundColor: "buttonSecondary.main",
                                        border: "1px solid #000",
                                        fontWeight: "500",
                                        my: { xs: "5px", md: "0" },
                                        "&:hover": {
                                            color: "#fff",
                                        },
                                        mr: "10px",
                                    }}
                                >
                                    Modify
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                {isLoading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "20rem" }}
                    />
                ) : (
                    <Typography variant="h2">
                        {`${capitalizeFirstLetter(
                            user_firstname
                        )} ${capitalizeFirstLetter(user_lastname)}`}
                        <StyledChip
                            label={user_status || "  "}
                            icon={
                                <DoneIcon
                                    color="primary.white"
                                    sx={{ fontSize: "16px" }}
                                />
                            }
                            sx={{
                                backgroundColor:
                                    theme.palette.statusColors[user_status],
                                color: "primary.white",
                                ml: { xs: "10px", sm: "20px" },
                            }}
                        />
                    </Typography>
                )}
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        sx={{ mt: 0 }}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                My account
                            </Typography>
                            {myProfile.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    alignItems={res?.alignItems}
                                    margin={res?.alignItems}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "16px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            minWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            mb: { xs: "5px", sm: 0 },
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: {
                                                xs: "100%",
                                                sm: "calc(100% - 220px)",
                                                lg: "calc(100% - 180px)",
                                            },
                                        }}
                                    >
                                        {res.button ? (
                                            <Box component="div">
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        maxWidth: "78px",
                                                        background: "#1ED800",
                                                        borderRadius: "40px",
                                                        boxShadow: "none",
                                                        fontSize: "14px",
                                                        textTransform: "none",
                                                        color: "#fff",
                                                        padding: "1px 11px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {res.iconPath} {res.button}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                component="div"
                                                sx={{
                                                    maxWidth: {
                                                        xs: "100%",
                                                        xl: "70%",
                                                    },
                                                }}
                                            >
                                                {isLoading ? (
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{
                                                            fontSize: "1rem",
                                                        }}
                                                    />
                                                ) : (
                                                    res.info
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}

                            <>
                                <Box mt={6}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: "left",
                                            color: "#02353C",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "16px",
                                                md: "18px",
                                                lg: "20px",
                                            },
                                        }}
                                    >
                                        Preferences
                                    </Typography>
                                    {preferences.map((res, index) => (
                                        <Grid
                                            key={index}
                                            container
                                            spacing={1}
                                            alignItems={"center"}
                                            margin={res?.alignItems}
                                            sx={{
                                                margin: "13px -13px 0",
                                                lineHeight: "1.2",
                                                fontSize: {
                                                    xs: "14px",
                                                    lg: "16px",
                                                },
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: {
                                                        xs: "left",
                                                        lg: "right",
                                                    },
                                                    padding:
                                                        "0px 12px !important",
                                                    maxWidth: {
                                                        xs: "100%",
                                                        sm: "220px",
                                                        lg: "180px",
                                                    },
                                                    minWidth: {
                                                        xs: "100%",
                                                        sm: "220px",
                                                        lg: "180px",
                                                    },
                                                    mb: {
                                                        xs: "5px",
                                                        sm: 0,
                                                    },
                                                }}
                                            >
                                                {res.title}
                                            </Grid>
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: "left",
                                                    fontWeight: "700",
                                                    padding:
                                                        "0px 12px !important",
                                                    width: {
                                                        xs: "100%",
                                                        sm: "calc(100% - 220px)",
                                                        lg: "calc(100% - 180px)",
                                                    },
                                                }}
                                            >
                                                {res.button ? (
                                                    <Box component="div">
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                maxWidth:
                                                                    "78px",
                                                                background:
                                                                    "#1ED800",
                                                                borderRadius:
                                                                    "40px",
                                                                boxShadow:
                                                                    "none",
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "none",
                                                                color: "#fff",
                                                                padding:
                                                                    "1px 11px",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            {res.iconPath}{" "}
                                                            {res.button}
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        component="div"
                                                        sx={{
                                                            maxWidth: {
                                                                xs: "100%",
                                                                xl: "70%",
                                                            },
                                                        }}
                                                    >
                                                        {isLoading ? (
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        "1rem",
                                                                }}
                                                            />
                                                        ) : (
                                                            res.info
                                                        )}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                                <Box mt={6}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: "left",
                                            color: "#02353C",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "16px",
                                                md: "18px",
                                                lg: "20px",
                                            },
                                        }}
                                    >
                                        My address
                                    </Typography>
                                    {myAddress.map((res, index) => (
                                        <Grid
                                            key={index}
                                            container
                                            spacing={1}
                                            alignItems={res?.alignItems}
                                            margin={res?.alignItems}
                                            sx={{
                                                margin: "13px -13px 0",
                                                lineHeight: "1.2",
                                                fontSize: {
                                                    xs: "14px",
                                                    lg: "16px",
                                                },
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: {
                                                        xs: "left",
                                                        lg: "right",
                                                    },
                                                    padding:
                                                        "0px 12px !important",
                                                    maxWidth: {
                                                        xs: "100%",
                                                        sm: "220px",
                                                        lg: "180px",
                                                    },
                                                    minWidth: {
                                                        xs: "100%",
                                                        sm: "220px",
                                                        lg: "180px",
                                                    },
                                                    mb: {
                                                        xs: "5px",
                                                        sm: 0,
                                                    },
                                                }}
                                            >
                                                {res.title}
                                            </Grid>
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: "left",
                                                    fontWeight: "700",
                                                    padding:
                                                        "0px 12px !important",
                                                    width: {
                                                        xs: "100%",
                                                        sm: "calc(100% - 220px)",
                                                        lg: "calc(100% - 180px)",
                                                    },
                                                }}
                                            >
                                                {res.button ? (
                                                    <Box component="div">
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                maxWidth:
                                                                    "78px",
                                                                background:
                                                                    "#1ED800",
                                                                borderRadius:
                                                                    "40px",
                                                                boxShadow:
                                                                    "none",
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "none",
                                                                color: "#fff",
                                                                padding:
                                                                    "1px 11px",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            {res.iconPath}{" "}
                                                            {res.button}
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        component="div"
                                                        sx={{
                                                            maxWidth: {
                                                                xs: "100%",
                                                                xl: "70%",
                                                            },
                                                        }}
                                                    >
                                                        {isLoading ? (
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        "1rem",
                                                                }}
                                                            />
                                                        ) : (
                                                            res.info
                                                        )}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            </>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                pt: "2px !important",
                                mt: { xs: "25px" },
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Company&apos;s Profile
                            </Typography>
                            {companyProfile.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: {
                                            xs: "14px",
                                            lg: "16px",
                                        },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                            },
                                            minWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                            },
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: {
                                                xs: "100%",
                                                sm: "calc(100% - 220px)",
                                            },
                                            maxWidth: {
                                                xs: "100%",
                                                xl: "70%",
                                            },
                                        }}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem" }}
                                            />
                                        ) : (
                                            res.info
                                        )}
                                    </Grid>
                                </Grid>
                            ))}

                            <Box mt={6}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Company
                                </Typography>
                                {company.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        alignItems={res?.alignItems}
                                        margin={res?.alignItems}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: {
                                                    xs: "100%",
                                                    sm: "220px",
                                                    lg: "180px",
                                                },
                                                minWidth: {
                                                    xs: "100%",
                                                    sm: "220px",
                                                    lg: "180px",
                                                },
                                                mb: { xs: "5px", sm: 0 },
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: {
                                                    xs: "100%",
                                                    sm: "calc(100% - 220px)",
                                                    lg: "calc(100% - 180px)",
                                                },
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize:
                                                                    "1rem",
                                                            }}
                                                        />
                                                    ) : (
                                                        res.info
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                            {!isAdmin && !isPrincipal ? (
                                <Box mt={4}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: "left",
                                            color: "#02353C",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "16px",
                                                md: "18px",
                                                lg: "20px",
                                            },
                                        }}
                                    >
                                        GDPR
                                    </Typography>
                                    {GDPR.map((res, index) => (
                                        <Grid
                                            key={index}
                                            container
                                            spacing={1}
                                            alignItems="center"
                                            margin={res?.alignItems}
                                            sx={{
                                                margin: "13px -13px 0",
                                                lineHeight: "1.2",
                                                fontSize: {
                                                    xs: "14px",
                                                    lg: "16px",
                                                },
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: {
                                                        xs: "left",
                                                        lg: "right",
                                                    },
                                                    padding:
                                                        "0px 12px !important",
                                                    maxWidth: {
                                                        xs: "100%",
                                                        sm: "220px",
                                                        lg: "180px",
                                                    },
                                                    minWidth: {
                                                        xs: "100%",
                                                        sm: "220px",
                                                        lg: "180px",
                                                    },
                                                    mb: { xs: "5px", sm: 0 },
                                                }}
                                            >
                                                {res.title}
                                            </Grid>
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: "left",
                                                    fontWeight: "700",
                                                    padding:
                                                        "0px 12px !important",
                                                    width: {
                                                        xs: "100%",
                                                        sm: "calc(100% - 220px)",
                                                        lg: "calc(100% - 180px)",
                                                    },
                                                }}
                                            >
                                                {res.button ? (
                                                    <Box component="div">
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                maxWidth:
                                                                    "78px",
                                                                background:
                                                                    "#1ED800",
                                                                borderRadius:
                                                                    "40px",
                                                                boxShadow:
                                                                    "none",
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "none",
                                                                color: "#fff",
                                                                padding:
                                                                    "1px 11px",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            {res.iconPath}{" "}
                                                            {res.button}
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        component="div"
                                                        sx={{
                                                            maxWidth: {
                                                                xs: "100%",
                                                                xl: "70%",
                                                            },
                                                        }}
                                                    >
                                                        {isLoading ? (
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        "1rem",
                                                                }}
                                                            />
                                                        ) : (
                                                            res.info
                                                        )}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default UserDetail;
