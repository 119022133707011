import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAccessPage } from "customHooks/useAccess";
import CancelButton from "common/CancelButton";
import FileInputNoPreview from "common/FileInputNoPreview";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { addCertifications } from "redux/certificationsSlice";
import MultiSelectYearDropdown from "./CertificateYears";
import permissionSlug from "assets/data/permissionSlug.json";

export default function CertificationFormContainer() {
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { isLoading } = useSelector((state) => state.certifications);

    const validationSchema = yup.object().shape({
        certificationCompanyName: yup
            .string()
            .required("Validator is required"),
        certificationCertificateNumber: yup
            .string()
            .required("Certificate reference is required"),
        certificationCertificateFile: yup.mixed().required("File is required"),
        certificationFirstCertificationDate: yup
            .date()
            .nullable()
            .required("Date is required"),
        certificationYears: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Year is required"),
        certificationStartDate: yup
            .date()
            .nullable()
            .required("Date is required"),
        certificationEndDate: yup
            .date()
            .nullable()
            .required("Date is required  "),
    });

    const initialValues = {
        certificationCompanyName: "",
        certificationCertificateNumber: "",
        certificationFirstCertificationDate: null,
        certificationYears: "",
        certificationStartDate: null,
        certificationEndDate: null,
        certificationCertificateFile: "",
        certificationIdProgram: id,
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            for (const key in initialValues) {
                formData.append(key, values[key]);
            }
            dispatch(addCertifications(formData)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    navigate(`/program/${id}/certifications`);
                }
            });
        },
    });

    const { cannot } = useAccessPage();
    const certificationCompanyName = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" required fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                        Validator
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={formik.values.certificationCompanyName}
                        onChange={formik.handleChange}
                        name="certificationCompanyName"
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.certificationCompanyName &&
                            !!formik.errors.certificationCompanyName
                        }
                    >
                        <MenuItem value="certis">Certis</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.certificationCompanyName &&
                    formik.errors.certificationCompanyName && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.certificationCompanyName}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.certificationCompanyName,
        formik.errors.certificationCompanyName,
        formik.touched.certificationCompanyName,
    ]);

    const certificationCertificateNumber = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    id="certificationCertificateNumber"
                    label="Certificate reference"
                    variant="filled"
                    required
                    fullWidth
                    name="certificationCertificateNumber"
                    value={formik.values.certificationCertificateNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.certificationCertificateNumber &&
                        !!formik.errors.certificationCertificateNumber
                    }
                    helperText={
                        formik.touched.certificationCertificateNumber &&
                        formik.errors.certificationCertificateNumber
                    }
                />
            </Grid>
        );
    }, [
        formik.values.certificationCertificateNumber,
        formik.touched.certificationCertificateNumber,
        formik.errors.certificationCertificateNumber,
    ]);

    const certificationFirstCertificationDate = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        sx={{
                            width: "100%",
                        }}
                        label="1st certification"
                        required
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "certificationFirstCertificationDate"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError(
                                            "certificationFirstCertificationDate"
                                        )}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        id="certificationFirstCertificationDate"
                        name="certificationFirstCertificationDate"
                        value={
                            formik.values.certificationFirstCertificationDate
                                ? dayjs(
                                      formik.values
                                          .certificationFirstCertificationDate
                                  )
                                : null
                        }
                        onChange={(value) =>
                            formik.setFieldValue(
                                "certificationFirstCertificationDate",
                                value.format("YYYY-MM-DD")
                            )
                        }
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.certificationFirstCertificationDate,
        formik.errors.certificationFirstCertificationDate,
        formik.touched.certificationFirstCertificationDate,
    ]);

    const certificationStartDate = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        sx={{
                            width: "100%",
                        }}
                        label="Crediting period start date"
                        required
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "certificationStartDate"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError("certificationStartDate")}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        id="certificationStartDate"
                        name="certificationStartDate"
                        value={
                            formik.values.certificationStartDate
                                ? dayjs(formik.values.certificationStartDate)
                                : null
                        }
                        onChange={(value) =>
                            formik.setFieldValue(
                                "certificationStartDate",
                                value.format("YYYY-MM-DD")
                            )
                        }
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.certificationStartDate,
        formik.errors.certificationStartDate,
        formik.touched.certificationStartDate,
    ]);

    const certificationEndDate = useMemo(() => {
        return (
            <Grid item xs={12} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        sx={{
                            width: "100%",
                        }}
                        label="Crediting period end date"
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "certificationEndDate"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError("certificationEndDate")}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        id="certificationEndDate"
                        name="certificationEndDate"
                        value={
                            formik.values.certificationEndDate
                                ? dayjs(formik.values.certificationEndDate)
                                : null
                        }
                        onChange={(value) =>
                            formik.setFieldValue(
                                "certificationEndDate",
                                value.format("YYYY-MM-DD")
                            )
                        }
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.certificationEndDate,
        formik.errors.certificationEndDate,
        formik.touched.certificationEndDate,
    ]);
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                maxWidth: 810,
            }}
        >
            <Grid container spacing={4}>
                {certificationCompanyName}
                {certificationCertificateNumber}
                <MultiSelectYearDropdown formik={formik} />
            </Grid>

            <Grid container spacing={4}>
                {certificationFirstCertificationDate}
                {certificationStartDate}
                {certificationEndDate}
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                    <FileInputNoPreview
                        label="Certificate file"
                        name="certificationCertificateFile"
                        formik={formik}
                    />
                </Grid>
            </Grid>

            <Stack direction="row" spacing={1.5} mt={{ md: "19px" }}>
                <CancelButton address={`/program/${id}/certifications`} />
                <Button
                    type="submit"
                    variant="contained"
                    disabled={
                        isLoading ||
                        cannot(
                            permissionSlug[0].certification_slug
                                .create_certificate
                        )
                    }
                >
                    {isLoading ? "Processing..." : "Add"}
                </Button>
            </Stack>
        </Box>
    );
}
