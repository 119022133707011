import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { useSelector } from "react-redux";
export default function UcoTitle() {
    const { can } = useAccessPage();

    const { UCOSCounts } = useSelector((state) => state.registery);
    const tabsAccess = can(permissionSlug[0].uco_slug.list_UCO);
    return (
        tabsAccess && (
            <>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: { xs: "none", sm: "block" },
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "left", sm: "center", md: "left" },
                        }}
                    >
                        {`${UCOSCounts}  UCO Token`}
                    </Typography>
                </Grid>
            </>
        )
    );
}
