import { styled } from "@mui/material/styles";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "common/Breadcrumb";
// import Map from "assets/images/registry_detail.png";
import TableCell from "@mui/material/TableCell";
import CustomTable from "common/CustomTable";
import Chip from "@mui/material/Chip";
import OpenPreviousPage from "common/OpenPreviousPage";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
import { Button, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUCOSCCSDetail, getSCCActivity } from "redux/registrySlices";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import CollectionsStatusColor from "util/CollectionsStatusColor";
import sanitizedDigits from "util/sanitizedDigits";
import { useState } from "react";
import { useDateFormatter } from "customHooks/useDateFormatter";
import CancelsSCC from "components/registry/scc/CancelsSCC";
import FreezesSCC from "components/registry/scc/FreezesSCC";

const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "2px 0px",
    fontWeight: "400",
    width: "110px",
    marginRight: "28px",
}));

const headCells = [
    // {
    //     id: "event",
    //     numeric: false,
    //     label: "Event",
    // },
    {
        id: "txn_hash",
        numeric: true,
        label: "Txn Hash",
    },
    {
        id: "date",
        numeric: true,
        label: "Date",
    },
    {
        id: "owner",
        numeric: true,
        label: "Owner",
    },
    // {
    //     id: "to",
    //     numeric: true,
    //     label: "To",
    // },
    {
        id: "sccsStatuss",
        numeric: true,
        label: "New Status",
    },
    {
        id: "quantity",
        numeric: true,
        label: "Quantity",
    },
    {
        id: "",
        label: "",
    },
];

const RegistrySccDetails = () => {
    const [query, setQuery] = useState({ page: 1, limit: 10 });
    const [refetchings, setRefetchings] = useState(true);
    const [refetch, setRefetch] = useState(true);
    const [openCancelsModal, setOpenCancelsModal] = useState(false);
    const [openFreezesModal, setOpenFreezesModal] = useState(false);
    const { can, cannot } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].scc_slug.SCC_details),
        unAuthUserRedirectTo: "/401",
    };

    const { formatActivityDate } = useDateFormatter();

    const { address, field_id, program_id } = useParams();
    const dispatch = useDispatch();

    const {
        SCCSDetails,
        cancellingSCCs,
        isLoading,
        freezingSCCs,
        sccsActivity,
        count,
        isActivityLoading,
    } = useSelector((state) => state.registery);
    const {
        blockchain_name,
        contract_address,
        token_id,
        vintage,
        // quantity_per_value,
        program_unit,
        imageUrl,
        // program_uco_address,
    } = SCCSDetails;

    const balance = SCCSDetails?.owners?.[0]?.balances || [];
    // console.log(balance);
    const totalsQuantity = balance.reduce((accumulator, item) => {
        return accumulator + Number(item.value);
    }, 0);

    useEffect(() => {
        dispatch(
            fetchUCOSCCSDetail({
                field_id,
                program_id,
                address,
                type: "SCC",
            })
        );
    }, [refetch]);

    const nftDetails = [
        {
            title: "Blockchain",
            info: blockchain_name,
        },
        {
            title: "Contract",
            info: contract_address,
        },
        {
            title: "Token ID",
            info: token_id,
        },
        // {
        //     title: "Creator",
        //     info: "0xbe95c78a8b...aa68cdd0b4cd3",
        // },
        {
            title: "Vintage",
            info: vintage,
        },
        {
            title: "Quantity",
            info: `${sanitizedDigits(totalsQuantity)} ${program_unit}`,
        },
        {
            title: "SCC",
            info: address,
        },
        {
            title: "Status",
            info:
                balance.length > 0 ? (
                    <>
                        {balance.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <StyledChip
                                    label={item?.status}
                                    sx={{
                                        backgroundColor:
                                            CollectionsStatusColor[
                                                item?.status
                                            ],
                                        color: "primary.white",

                                        ...(balance.length > 1 && {
                                            marginBottom: "5px",
                                        }),
                                    }}
                                />
                                {sanitizedDigits(item.value)}
                            </div>
                        ))}
                    </>
                ) : (
                    "-"
                ),
        },
    ];

    useEffect(() => {
        dispatch(
            getSCCActivity({
                page: query.page,
                limit: query.limit,
                field_id,
                program_id,
                // HH
            })
        );
    }, [query, refetchings]);

    const sanitazidTabledData =
        sccsActivity &&
        sccsActivity?.map((item) => ({
            // event: item.event,
            txn_hash: item.account ? (
                <TableCell sx={{ minWidth: "150px" }}>
                    {item?.account}
                    {/* {"-"} */}
                </TableCell>
            ) : (
                "-"
            ),
            date: (
                <TableCell sx={{ minWidth: "189px" }}>
                    {formatActivityDate(item?.status_date) || "-"}
                </TableCell>
            ),
            owner: item.owner_address,
            // to: "-",
            sccsStatuss: item?.newStatus,
            // old_status: "-",
            // new_status: item?.newStatus || "-",
            // quantity: item.values
            //     ? item.values
            //           .reduce((accumulator, currentValue) => {
            //               return Number(accumulator) + Number(currentValue);
            //           }, 0)
            //           .toFixed(3)
            //     : 0,
            quantity: sanitizedDigits(item.values),
            vall: <TableCell sx={{ width: "40%" }}></TableCell>,
        }));

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", mb: "10px" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Breadcrumbs
                            sources={[
                                {
                                    label: "registry",
                                    link: "/registry/uco",
                                    canNavigate: true,
                                },
                                {
                                    label: "SCC detail",
                                    link: "",
                                },
                            ]}
                        />
                    </Grid>
                </Box>
                {/* <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ mt: 0 }}
                    >
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">
                                <OpenPreviousPage />
                                SCC #0X45/.../933B/234
                            </Typography>
                        </Grid>
                    </Grid>
                </Box> */}
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ mt: 0 }}
                    >
                        {/* <Grid item xs={12} md={6}> */}
                        <Grid xs={12} md={8} xl={9}>
                            {isLoading ? (
                                <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "3rem", width: "20rem" }}
                                />
                            ) : (
                                <Typography
                                    variant="h2"
                                    sx={{
                                        wordWrap: "break-word",
                                        fontSize: {
                                            md: "1.6rem",
                                            lg: "1.8rem",
                                            xl: "2.125rem",
                                        },
                                    }}
                                >
                                    <OpenPreviousPage /> SCC #
                                    {`${address}/${token_id}`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            xl={3}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "start", md: "flex-end" },
                            }}
                        >
                            <Button
                                variant="contained"
                                disabled={isLoading}
                                sx={{
                                    mr: {
                                        xs: "10px",
                                        lg: "20px",
                                    },
                                    padding: {
                                        xs: "5px 10px !important",
                                        lg: "5px 20px !important",
                                    },
                                }}
                                onClick={() => setOpenFreezesModal(true)}
                            >
                                Freeze SCC
                            </Button>
                            <Button
                                variant="contained"
                                disabled={
                                    cannot(
                                        permissionSlug[0].scc_slug.cancel_SCC
                                    ) || isLoading
                                }
                                onClick={() => setOpenCancelsModal(true)}
                            >
                                Cancel SCC
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        sx={{ mt: 0 }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={9}
                            sx={{
                                pt: "2px !important",
                                mt: "20px",
                                gap: "50px",
                            }}
                            display="flex"
                            flexDirection="column"
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    NFT Details
                                </Typography>
                                {nftDetails.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: {
                                                    xs: "100%",
                                                    sm: "150px",
                                                    md: "180px",
                                                },
                                                minWidth: {
                                                    xs: "100%",
                                                    sm: "150px",
                                                    md: "180px",
                                                },
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: {
                                                    xs: "100%",
                                                    sm: "calc(100% - 150px)",
                                                    md: "calc(100% - 180px)",
                                                },
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                        },
                                                    }}
                                                >
                                                    {/* {res.info} */}
                                                    {isLoading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize:
                                                                    "1rem",
                                                            }}
                                                        />
                                                    ) : (
                                                        res.info
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{ pt: "2px !important", gap: "23px" }}
                            display="flex"
                            flexDirection="column"
                        >
                            {/* <Box>
                                <Box
                                    component="img"
                                    alt="Map"
                                    src={Map}
                                    sx={{
                                        maxWidth: 1,
                                        mt: "20px",
                                    }}
                                />
                            </Box> */}

                            {isLoading ? (
                                <Skeleton
                                    sx={{
                                        marginLeft: { md: "auto" },
                                        mt: "20px",
                                    }}
                                    variant="rounded"
                                    animation="wave"
                                    width="100%"
                                    height="100%"
                                    // variant="text"
                                    // sx={{
                                    //     fontSize: "1rem",
                                    // }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: { md: "flex-end" },
                                    }}
                                >
                                    <Box
                                        component="img"
                                        alt="Map"
                                        src={imageUrl}
                                        sx={{
                                            width: "100%",
                                            maxWidth: 400,
                                            // maxHeight: 400,
                                            mt: "20px",
                                        }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Box mt={8}>
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Activity
                        </Typography>

                        <CustomTable
                            headCells={headCells}
                            rows={
                                sanitazidTabledData ? sanitazidTabledData : []
                            }
                            noDataFound="No Data Found"
                            query={query}
                            setQuery={setQuery}
                            totalRowCount={count}
                            loadingList={isActivityLoading}
                        />
                    </Box>
                </Box>
            </CheckpageAuth>
            <CancelsSCC
                balance={balance}
                setRefetchings={setRefetchings}
                openCancelsModal={openCancelsModal}
                setOpenCancelsModal={setOpenCancelsModal}
                setRefetch={setRefetch}
                cancellingSCCs={cancellingSCCs}
            />
            <FreezesSCC
                balance={balance}
                setRefetchings={setRefetchings}
                openFreezesModal={openFreezesModal}
                setOpenFreezesModal={setOpenFreezesModal}
                setRefetch={setRefetch}
                freezingSCCs={freezingSCCs}
            />
        </Layout>
    );
};

export default RegistrySccDetails;
