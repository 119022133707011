import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CheckpageAuth = ({ children, auth }) => {
    const navigate = useNavigate();
    const [isUserLoaded, setIsuserLoaded] = useState(false);
    const { accountDetails: users } = useSelector((state) => state.account);

    const userRole = (users?.role?.name || "").toLowerCase();

    // Check if the user's role has access to the route
    const checkUserRole = useMemo(() => {
        if (!auth.routeAccess && isUserLoaded) {
            navigate(auth.unAuthUserRedirectTo);
            return false;
        }
        if (isUserLoaded) {
            return true;
        }
    }, [auth, userRole, isUserLoaded, navigate]);

    useEffect(() => {
        if (userRole) {
            setIsuserLoaded(true);
        }
    }, [userRole]);

    return <>{checkUserRole ? children : null}</>;
};

export default CheckpageAuth;
