import { useMemo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toaastify({ title, close, variant }) {
    useEffect(() => {
        if (title) {
            if (variant === "standard") {
                toast.success(title, {
                    onClose: () => {
                        close();
                    },
                    toastId: "successId",
                });
            } else {
                toast.error(title, {
                    onClose: () => {
                        close();
                    },
                    toastId: "erroId",
                });
            }
        }
    }, [title]);

    const memoIsedToast = useMemo(() => {
        return <ToastContainer autoClose={7000} />;
    }, [title]);

    return memoIsedToast;
}

export default Toaastify;
