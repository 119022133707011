import { TextField } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";

const AutocompleteAddress = ({
    formik,
    name,
    onAddressSelect,
    sameAddress,
}) => {
    let autocomplete;
    let geocoder;

    useEffect(() => {
        initAutocomplete();
    }, []);

    function initAutocomplete() {
        const input = document.getElementById("address-input");
        autocomplete = new window.google.maps.places.Autocomplete(input);
        geocoder = new window.google.maps.Geocoder();
        autocomplete.addListener("place_changed", onPlaceChanged);
    }
    async function onPlaceChanged() {
        const place = autocomplete.getPlace();
        if (place.geometry) {
            const filteredResult = {
                zip_code: "",
                city: "",
                country: "",
                street: "",
                street_number: "",
                street_two: "",
            };
            const addresss = document.getElementById("address-input").value;
            const validateAddress = await axios.post(
                `${process.env.REACT_APP_GOOGLE_VALIDATION_API_KEY}`,
                {
                    address: {
                        addressLines: addresss,
                    },
                }
            );
            validateAddress?.data?.result.address.addressComponents.forEach(
                (item) => {
                    //result using from google validation api
                    if (item.componentType.includes("postal_code")) {
                        formik.setFieldValue(
                            "zip_code",
                            item.componentName.text
                        );
                        filteredResult.zip_code = item.componentName.text;
                    }
                    if (item.componentType.includes("locality")) {
                        filteredResult.city = item.componentName.text;
                    }
                    if (item.componentType.includes("country")) {
                        filteredResult.country = item.componentName.text;
                    }
                    if (
                        item.componentType.includes("route") ||
                        item.componentType.includes("neighborhood") ||
                        item.componentType.includes("colloquial_area")
                    ) {
                        filteredResult.street = item.componentName.text;
                    }
                    if (item.componentType.includes("street_number")) {
                        filteredResult.street_number = item.componentName.text;
                    }
                    if (item.componentType.includes("point_of_interest")) {
                        filteredResult.street_two = item.componentName.text;
                    }
                }
            );

            filteredResult.street =
                filteredResult.street_number + " " + filteredResult.street;

            //function to fill the region field below from google map api
            geocoder.geocode({ placeId: place.place_id }, (results, status) => {
                if (status === "OK") {
                    results[0].address_components?.forEach((item) => {
                        if (
                            item.types.includes("administrative_area_level_1")
                        ) {
                            filteredResult.region = item.long_name;
                        }
                    });
                    onAddressSelect(filteredResult);
                }
            });
            //

            onAddressSelect(filteredResult);
        }
    }

    return (
        <>
            <TextField
                fullWidth
                id="address-input"
                type="text"
                label="Autocompletion address"
                variant="filled"
                sx={{ mb: "5px" }}
                name={name}
                disabled={sameAddress}
                onChange={formik.handleChange}
            />
        </>
    );
};

export default AutocompleteAddress;
