import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import errorImg from "assets/images/authentication-failed.png";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <Box
                height={"100vh"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Box textAlign={"center"}>
                    <Box
                        component="img"
                        src={errorImg}
                        alt="404 img"
                        width={"100%"}
                        sx={{
                            maxWidth: { xs: "150px", md: "200px" },
                            margin: "auto",
                        }}
                    />
                    <Typography
                        variant="h2"
                        textAlign={"center"}
                        sx={{ padding: "10px 0px" }}
                    >
                        <Box
                            component="span"
                            sx={{
                                fontSize: {
                                    xs: "80px",
                                    md: "100px",
                                    lg: "120px",
                                },
                                color: "#2eaf7d",
                            }}
                        >
                            401
                        </Box>
                        <br />
                        Authorization Required
                    </Typography>
                    <Button
                        onClick={() => navigate(`/`)}
                        variant="contained"
                        sx={{
                            color: "white",
                            padding: {
                                xs: "5px 30px !important",
                                md: "9px 40px !important",
                            },
                            backgroundColor: "#02353C",
                            fontWeight: "500",
                            fontSize: {
                                xs: "16px !important",
                                md: "18px !important",
                                lg: "20px !important",
                            },
                            mt: "20px",
                            "&:hover": {
                                background:
                                    "var(--degrade-bouton-selected, linear-gradient(0deg, #02353C 0%, #2EAF7D   100%)) !important",
                                color: "#fff",
                            },
                        }}
                    >
                        Return To Farm Registry
                    </Button>
                </Box>
            </Box>
        </>
    );
};
export default NotFound;
