import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage, showSuccessMessage } from "./toastSlice";
import Services from "services";
import { resetStateHander } from "helpers/sanitizedFunction";

export const fetchProjectsByFarmers = createAsyncThunk(
    "farmersProject/fetchProjects",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getProjectsByFarmers(paramss);
            if (response.data.data.projects) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const uploadProjectPdf = createAsyncThunk(
    "farmersProject/uploadProjectFile",
    async (payload, thunkAPI) => {
        try {
            const response = await Services.uploadProjectFile(payload);
            if (response.data.data.projectPdfFile) {
                thunkAPI.dispatch(
                    showSuccessMessage("Project contract Added Successfully!")
                );
                return {
                    id: response.data.data.id,
                    file: response.data.data.projectPdfFile,
                };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage(
                    error?.response?.data?.message ||
                        error.message ||
                        error.name ||
                        error.response.data.message
                )
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    projects: [],
    count: 0,
    isUploading: false,
    error: null,
    isLoading: false,
    isLoaded: false,
};
export const farmersProjectSlice = createSlice({
    name: "farmersProject",
    initialState,
    reducers: {
        refetchFarmersProject: (state) => {
            state.isLoaded = false;
        },
        resetFarmersProject: (state) => {
            resetStateHander(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsByFarmers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProjectsByFarmers.fulfilled, (state, action) => {
                // Add user to the state array
                state.projects = action.payload.projects;
                state.count = action.payload.count;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(fetchProjectsByFarmers.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(uploadProjectPdf.pending, (state) => {
                state.isUploading = true;
            })
            .addCase(uploadProjectPdf.fulfilled, (state, action) => {
                state.error = null;
                state.isUploading = false;
                const projectToUpdate = state.projects.find(
                    (project) => project.id === action.payload.id
                );
                if (projectToUpdate) {
                    projectToUpdate["projectPdfFile"] = action.payload.file;
                }
            })
            .addCase(uploadProjectPdf.rejected, (state, { payload }) => {
                state.isUploading = false;
                state.error = payload;
                state.isLoaded = false;
            });
    },
});
export const { refetchFarmersProject, resetFarmersProject } =
    farmersProjectSlice.actions;
export default farmersProjectSlice.reducer;
