import protectedRequest from "helpers/protectedRequest";
import useAxios from "helpers/useAxios.js";
const getPermissions = async (params) => {
    return await protectedRequest(() =>
        useAxios.get(`role/permissions/all/${params}`)
    );
};
const updatePermissions = async (payload) => {
    return await protectedRequest(() =>
        useAxios.patch("permission/update", payload)
    );
};
export { getPermissions, updatePermissions };
