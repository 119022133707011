import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
// import TimelineImage2 from "assets/images/timelineimg2.png";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { fetchAllHistory } from "redux/fieldsSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "common/Modal";

import IconButton from "@mui/material/IconButton";

const StyledTimeline = styled(Timeline)(({ theme }) => ({
    "& .MuiTimelineDot-root": {
        padding: "11px",
        margin: "0px",
        border: "5px solid #fff",
        boxShadow: "none",
        position: "relative",
        zIndex: "999",
        marginTop: "-1px",
        cursor: "pointer",
    },
    "& .MuiTimelineConnector-root": {
        marginTop: "-1px",
        width: "13px",
        backgroundColor: "#02353C",
    },
    "& .MuiTimelineItem-root": {
        "& .MuiTimelineOppositeContent-root": {
            fontSize: "28px",
            color: "#02353C",
            lineHeight: 1,
            paddingTop: "3px",
            maxWidth: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            [theme.breakpoints.down("xl")]: {
                paddingLeft: "15px",
                fontSize: "26px",
                maxWidth: "120px",
            },
            [theme.breakpoints.down("md")]: {
                paddingLeft: "0px",
                fontSize: "20px",
                maxWidth: "100px",
            },
        },
        "& .MuiTimelineContent-root": {
            fontSize: "20px",
            fontWeight: "700",
            color: "#02353C",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            "@media(max-width:1280px)": {
                paddingRight: "0px",
            },
            "& .MuiTypography-root": {
                fontSize: "16px",
                fontWeight: "600",
                color: "#000",
            },
            "& .MuiBox-root.timelinetitle": {
                width: "30%",
                paddingLeft: "25px",
                [theme.breakpoints.down("xl")]: {
                    paddingLeft: "15px",
                },
                "@media(min-width:900px) and (max-width:1280px)": {
                    width: "100%",
                    paddingLeft: "0px",
                    textAlign: "right",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    paddingLeft: "0px",
                    textAlign: "right",
                },
            },
        },
        "& .timelineimg": {
            flex: 1,
            marginTop: "0",
            height: "100px",
            clipPath: "polygon(11% 0, 100% 0%, 100% 100%, 0% 100%)",
            transition: "0.5s ease",
            width: "70%",
            [theme.breakpoints.down("lg")]: {
                height: "80px",
            },
            [theme.breakpoints.down("sm")]: {
                height: "60px",
            },
        },
        "&.activewidget": {
            "& .MuiTimelineDot-root": {
                backgroundColor: "#3FD0C9",
            },
            "& .MuiTimelineOppositeContent-root": {
                color: "#3FD0C9",
                fontWeight: "700",
            },
            "& .timelineimg": {
                height: "300px",
                clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0% 100%)",
                [theme.breakpoints.down("lg")]: {
                    height: "200px",
                },
                [theme.breakpoints.down("sm")]: {
                    height: "120px",
                },
                cursor: "pointer !important",
            },
        },
    },
    "& .MuiTimelineItem-root:last-child": {
        "& .MuiTimelineSeparator-root": {
            "& .MuiTimelineConnector-root": {
                borderRadius: "0px 0px 80px 80px",
            },
        },
    },
    "& .MuiTimelineItem-root:first-child": {
        "& .topconnector": {
            borderRadius: "80px 80px 0px 0px",
        },
    },
}));

export default function OppositeContentTimeline({ harvestYear }) {
    // const [activeIndex, setActiveIndex] = useState(null);
    const [year, setYear] = useState();
    // const dispatch = useDispatch();
    const { history, historyLoading } = useSelector((state) => state.field);
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (harvestYear) {
            setYear(String(harvestYear));
        }
    }, [harvestYear]);
    // const { id: fieldID } = useParams();

    const handleDotClick = (year) => {
        setYear((prevIndex) => (prevIndex === year ? prevIndex : year));
    };

    const handleTimelineClick = (img) => {
        if (img) {
            setSelectedItem(img);
        }
        setOpenModal(true);
    };

    // const timelineData = [

    //     {
    //         year: "2023",
    //         title: "Axereal",
    //         desc: "Malt Neutre",
    //         imgSrc: TimelineImage2,
    //     },

    //     {
    //         year: "2022",
    //         title: "Axereal",
    //         desc: "Colza Bas GES",
    //         imgSrc: TimelineImage2,
    //     },

    //     {
    //         year: "2021",
    //         title: "Locked",
    //         desc: "Other program",
    //         imgSrc: TimelineImage2,
    //     },

    //     {
    //         year: "2020",
    //         title: "Locked",
    //         desc: "Other program",
    //         imgSrc: TimelineImage2,
    //     },

    //     {
    //         year: "2019",
    //         title: "Axereal",
    //         desc: "Colza Bas GES",
    //         imgSrc: TimelineImage2,
    //     },

    // ];

    return historyLoading ? (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
            }}
        >
            <CircularProgress
                sx={{
                    width: { md: "60px !important", lg: "80px !important" },
                    height: { md: "60px !important", lg: "80px !important" },
                }}
            />
        </Box>
    ) : (
        <>
            <StyledTimeline>
                {history.map((item) => (
                    <TimelineItem
                        key={item.year}
                        className={item.year === year ? "activewidget" : ""}
                    >
                        <TimelineOppositeContent
                            color="text.secondary"
                            onClick={() => handleDotClick(item.year)}
                        >
                            <Typography sx={{ cursor: "pointer" }}>
                                {item.year}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector className="topconnector" />
                            <TimelineDot
                                sx={{
                                    backgroundColor: "primary.dark",
                                }}
                                onClick={() => handleDotClick(item.year)}
                            />

                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ mb: "10px", pt: "0" }}>
                            <Box
                                className="timelineimg"
                                onClick={() => {
                                    if (item.year === year) {
                                        handleTimelineClick(item.imageUrl);
                                    }
                                }}
                                component="img"
                                alt="TimelineImage2"
                                src={item.imageUrl}
                                sx={{
                                    width: "100%",
                                    mt: 2,
                                }}
                            />
                            <Box className="timelinetitle">
                                {/* {item.title} */}
                                {item.year !== "Reference" && (
                                    <Typography>{`${item.fieldCount} ${
                                        item.fieldCount < 2 ? "field" : "fields"
                                    }`}</Typography>
                                )}
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </StyledTimeline>

            <Modal
                sx={{
                    "& .MuiBox-root": {
                        padding: 0,
                        overflow: "visible",
                        outline: "none",
                    },
                }}
                body={
                    <>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenModal(false)}
                            sx={{
                                position: "absolute",
                                right: -40,
                                top: -60,
                                color: "#fff",
                                zIndex: 1,
                                "@media(max-width:768px)": {
                                    right: -20,
                                },
                            }}
                        >
                            <HighlightOffIcon
                                sx={{
                                    fontSize: 40,
                                }}
                            />
                        </IconButton>
                        <Box sx={{ overflow: "hidden !important" }}>
                            <Box
                                // sx={{
                                //     position: "relative",
                                //     paddingTop: "75% !important",
                                //     "& iframe": {
                                //         position: "absolute",
                                //         width: "100%",
                                //         height: "100%",
                                //         left: 0,
                                //         top: 0,
                                //     },
                                // }}
                                component="img"
                                alt="TimelineImage2"
                                src={selectedItem}
                                sx={{
                                    width: "100%",
                                    marginBottom: "-8px",
                                    maxHeight: "calc(100vh - 200px)",
                                    objectFit: "cover",
                                }}
                            >
                                {/* {selectedItem && (
                                <iframe
                                    width="100%"
                                    src={selectedItem}
                                    title="description"
                                />
                            )} */}
                            </Box>
                        </Box>
                    </>
                }
                open={openModal}
                close={() => setOpenModal(false)}
            />
        </>
    );
}
