import { useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllUserRoles } from "redux/users";
import { getCompanyDetaill } from "redux/companiesSlice";
import { useParams } from "react-router-dom";

import { useAccessPage } from "customHooks/useAccess";
const jobTitles = [
    { id: 1, name: "Carbon Manager" },
    { id: 2, name: "Quality Team" },
];

const UserProfile = ({ formik, disable = false, edit }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { isRoles } = useAccessPage();
    const { userRole } = useSelector((state) => state.usersList);
    const { companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
        }),
        shallowEqual
    );
    const role = useSelector((state) => state.account.accountDetails.role);
    useEffect(() => {
        dispatch(getAllUserRoles());
    }, []);
    useEffect(() => {
        if (id) {
            if (!edit) {
                dispatch(getCompanyDetaill(id));
            }
        }
    }, [id]);
    const shouldProfileRole = (companyProfile, userRole) => {
        if (edit || (!edit && companyProfile)) {
            if (
                companyProfile == "developer" &&
                isRoles("admin") &&
                ["Principal", "Carbon Manager", "Other User"].includes(userRole)
            ) {
                return true;
            } else if (
                companyProfile == "developer" &&
                !isRoles("admin") &&
                ["Carbon Manager", "Other User"].includes(userRole)
            ) {
                return true;
            } else if (
                companyProfile == "trader" &&
                ["Trader User", "Other User"].includes(userRole)
            ) {
                return true;
            } else if (
                companyProfile === "corporation" &&
                ["Corporate User", "Other User"].includes(userRole)
            ) {
                return true;
            }
        }

        return false;
    };
    const user_job = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`job_title-label`}>Job Title</InputLabel>
                    <Select
                        labelId={`job_title-label`}
                        disabled={disable}
                        id="user_job"
                        name="user_job"
                        value={formik.values.user_job}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_job && !!formik.errors.user_job
                        }
                    >
                        {jobTitles.map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>

                    {formik.touched.user_job && formik.errors.user_job && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.user_job}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_job,
        formik.errors.user_job,
        formik.touched.user_job,
    ]);

    const user_account_type = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`profile_type-label`}>
                        Profile Type
                    </InputLabel>
                    <Select
                        labelId={`profile_type-label`}
                        disabled={disable}
                        id="user_account_type)"
                        name="user_account_type"
                        value={formik.values.user_account_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_account_type &&
                            !!formik.errors.user_account_type
                        }
                    >
                        {!id && (
                            <MenuItem key={role.id} value={role.id}>
                                {role.name}
                            </MenuItem>
                        )}

                        {userRole &&
                            id &&
                            userRole?.map(
                                (option) =>
                                    shouldProfileRole(
                                        companylist?.companyProfile,
                                        option.name
                                    ) && (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    )
                            )}
                    </Select>
                    {formik.touched.user_account_type &&
                        formik.errors.user_account_type && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_account_type}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_account_type,
        formik.errors.user_account_type,
        formik.touched.user_account_type,
        userRole,
        companylist?.companyProfile,
    ]);

    const user_status = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`status-label`}>Status</InputLabel>
                    <Select
                        labelId={`status-label`}
                        disabled={disable}
                        id="user_status"
                        name="user_status"
                        value={formik.values.user_status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_status &&
                            !!formik.errors.user_status
                        }
                    >
                        <MenuItem key={1} value="active">
                            Active
                        </MenuItem>
                        <MenuItem key={2} value="inactive">
                            Inactive
                        </MenuItem>
                    </Select>
                    {formik.touched.user_status &&
                        formik.errors.user_status && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_status}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_status,
        formik.errors.user_status,
        formik.touched.user_status,
    ]);

    const user_wallet_manager = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`wallet_manager-label`}>
                        Wallet Manager
                    </InputLabel>
                    <Select
                        labelId={`wallet_manager-label`}
                        disabled={disable}
                        id="user_wallet_manager"
                        name="user_wallet_manager"
                        value={formik.values.user_wallet_manager}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_wallet_manager &&
                            !!formik.errors.user_wallet_manager
                        }
                    >
                        <MenuItem key={1} value={1}>
                            Yes
                        </MenuItem>
                        <MenuItem key={2} value={0}>
                            No
                        </MenuItem>
                    </Select>
                    {formik.touched.user_wallet_manager &&
                        formik.errors.user_wallet_manager && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_wallet_manager}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_wallet_manager,
        formik.errors.user_wallet_manager,
        formik.touched.user_wallet_manager,
    ]);

    return (
        <>
            <Typography variant="h4" mt={1}>
                Profile
            </Typography>
            <Box
                display="grid"
                gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {user_job}
                {user_account_type}
                {user_status}
                {user_wallet_manager}
            </Box>
        </>
    );
};

export default UserProfile;
