import Grid from "@mui/material/Grid";
import { Box, Skeleton, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import OpenPreviousPage from "common/OpenPreviousPage";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getCompanyDetaill } from "redux/companiesSlice";
export default function ListTitle({ companyId }) {
    const { count, isLoading } = useSelector((state) => state.field);
    // const { id } = useParams();
    const dispatch = useDispatch();
    const { companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
        }),
        shallowEqual
    );
    useEffect(() => {
        if (companyId) {
            dispatch(getCompanyDetaill(companyId));
        }
    }, [companyId]);
    return (
        <>
            <Grid item xs={12} md="auto">
                {isLoading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "20rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        {" "}
                        {companyId ? <OpenPreviousPage /> : ""}
                        {!companyId && ""}
                        {count}
                        {count > 1 ? " Fields " : " Field "}
                        {companyId ? "on" : ""}{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Link
                                to={`/companies/detail/${companyId}`}
                                sx={{
                                    display: "inline-block",
                                }}
                            >
                                {/* {id ? `${count}  Users on ${companylist?.companyName}` : `${count} Users`} */}{" "}
                                {companyId ? `${companylist?.companyName}` : ""}
                            </Link>
                        </Box>
                    </Typography>
                )}
            </Grid>
        </>
    );
}
