import CircularProgress from "@mui/material/CircularProgress";
const LoaderOverlay = ({ message }) => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: "100000000",
                maxWidth: "100%",
                margin: "0px auto",
                padding: "20px",
                textAlign: "center",
                right: "0",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                background: "rgba(0,0,0,0.5)",
            }}
        >
            <span
                style={{
                    display: "block",
                    margin: "0 auto",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </span>
            <h4
                style={{
                    marginBottom: "6px",
                    fontsize: "25px",
                    color: "white",
                    marginTop: "7px",
                    fontWeight: "600",
                    width: "100%",
                }}
            >
                {" "}
                {message}
            </h4>
        </div>
    );
};

export default LoaderOverlay;
