import CustomTable from "common/CustomTable";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Searchbar from "common/Searchbar";
import UnderlineText from "common/UnderlineText";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
// import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { companiesList, refetchCompany } from "redux/companiesSlice";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import ConvertJsonToCsv from "common/ExportJson";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import TruncateWalletAddress from "util/TruncateWalletAddress";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    minWidth: "91px",
    fontSize: "14px",
}));

export default function Detail() {
    const theme = useTheme();
    const initialQuery = useRef({ page: 1, limit: 10, search: "" });
    const [query, setQuery] = useState(initialQuery.current);
    const { can } = useAccessPage();
    const canUserList = can(permissionSlug[0].users_slug.list_users);
    const canProgramList = can(permissionSlug[0].programSlug.list_programs);
    const canFarmersList = can(permissionSlug[0].farmer_slug.list_farmers);
    const canFieldsList = can(permissionSlug[0].fields_slug.list_fields);

    const tabsAccess = can(permissionSlug[0].company_slug.list_companies);
    const { response } = useSelector(
        (state) => ({
            response: state?.companylist?.companyList?.data?.companies,
        }),
        shallowEqual
    );
    const { companylist } = useSelector((state) => state);
    const loadingList = companylist?.loadingCompanyList;
    const totalCompanies = companylist?.companyList?.data?.count;
    const dispatch = useDispatch();

    useEffect(() => {
        if (tabsAccess) {
            if (companylist.companyLoaded === false) {
                dispatch(companiesList(query)).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        initialQuery.current = query;
                    }
                });
            }
        }
    }, [companylist.companyLoaded]);
    useEffect(() => {
        dispatch(refetchCompany());
    }, [query]);

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };
    const headCells = [
        {
            id: "companyid",
            label: "Company ID",
        },
        {
            id: "name",
            label: "Name",
        },
        {
            id: "profile",
            label: "Profile",
        },
        {
            id: "country",
            label: "Country",
        },
        {
            id: "walletaddress",
            label: "Wallet address",
        },
        {
            id: "status",
            label: "Status",
        },
        ...(canUserList
            ? [
                  {
                      id: "users",
                      label: "Users",
                  },
              ]
            : []),
        ...(canProgramList
            ? [
                  {
                      id: "programs",
                      label: "Programs",
                  },
              ]
            : []),
        ...(canFarmersList
            ? [
                  {
                      id: "farmers",
                      label: "Farmers",
                  },
              ]
            : []),
        ...(canFieldsList
            ? [
                  {
                      id: "fields",
                      label: "Fields",
                  },
              ]
            : []),
    ];

    const sanitazedTabledData =
        response &&
        response?.map((item) => ({
            companyid: item.id,
            name: can(permissionSlug[0].company_slug.company_details) ? (
                <TableCell>
                    <Link to={`/companies/detail/${item.id}`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(item.companyName)}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ) : (
                capitalizeFirstLetter(item.companyName)
            ),
            profile: capitalizeFirstLetter(item.companyProfile),
            country: capitalizeFirstLetter(item.companyCountry),
            walletaddress: can(
                permissionSlug[0].company_slug.company_details
            ) ? (
                <TableCell>
                    {(item?.wallet?.wallet_public_key && (
                        <Link to={`/companies/detail/${item.id}`}>
                            <UnderlineText>
                                {TruncateWalletAddress(
                                    item?.wallet?.wallet_public_key
                                )}
                            </UnderlineText>
                        </Link>
                    )) ||
                        "-"}
                </TableCell>
            ) : (
                item?.wallet?.wallet_public_key
            ),
            status: (
                <TableCell>
                    <StyledChip
                        label={capitalizeFirstLetter(item.companyStatus)}
                        icon={
                            <DoneIcon
                                color="primary.white"
                                sx={{ fontSize: "16px" }}
                            />
                        }
                        sx={{
                            backgroundColor:
                                theme.palette.statusColors[item.companyStatus],
                            color: "primary.white",
                        }}
                    />
                </TableCell>
            ),
            // empty: <TableCell sx={{ width: "20%" }}></TableCell>,
            users: canUserList ? (
                <TableCell>
                    <Link to={`/companies/${item.id}/users`}>
                        {/* <RemoveRedEyeRoundedIcon
                            color="primary.white"
                            sx={{ fontSize: "22px", color: "#3FD0C9" }}

                        /> */}
                        <UnderlineText>{item.userCount}</UnderlineText>
                    </Link>
                </TableCell>
            ) : null,
            programs: canProgramList ? (
                <TableCell>
                    <Link to={`/companies/${item.id}/programs`}>
                        {/* <RemoveRedEyeRoundedIcon
                            color="primary.white"
                            sx={{ fontSize: "22px", color: "#3FD0C9" }}
                        /> */}
                        <UnderlineText>{item?.programCount}</UnderlineText>
                    </Link>
                </TableCell>
            ) : null,
            farmers: canFarmersList ? (
                <TableCell>
                    <Link to={`/companies/${item.id}/farmers`}>
                        {/* <RemoveRedEyeRoundedIcon
                            color="primary.white"
                            sx={{ fontSize: "22px", color: "#3FD0C9" }}
                        /> */}
                        <UnderlineText>{item?.farmerCount}</UnderlineText>
                    </Link>
                </TableCell>
            ) : null,
            fields: canFieldsList ? (
                <TableCell>
                    <Link to={`/companies/${item.id}/fields`}>
                        {/* <RemoveRedEyeRoundedIcon
                            color="primary.white"
                            sx={{ fontSize: "22px", color: "#3FD0C9" }}
                        /> */}
                        <UnderlineText>{item?.fieldCount}</UnderlineText>
                    </Link>
                </TableCell>
            ) : null,
        }));

    return tabsAccess ? (
        <>
            <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                    flexWrap: { xs: "wrap", sm: "inherit" },
                    justifyContent: { xs: "center", sm: "space-between" },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                    sx={{
                        width: "100%",
                        md: "auto",
                        justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                            lg: "space-between",
                            xl: "space-between",
                        },
                    }}
                >
                    <Searchbar
                        placeholdertext="Search a company"
                        handleSearch={(search) => {
                            handleSearch(search);
                        }}
                    />
                </Stack>
            </Stack>
            <Box sx={{ mt: "30px" }}>
                <CustomTable
                    headCells={headCells}
                    exportbutton={
                        can(permissionSlug[0].company_slug.export_company) && (
                            <ConvertJsonToCsv
                                jsonData={response?.map((item) => {
                                    return {
                                        "Company ID": item.id,
                                        Name: item.companyName,
                                        Profile: item.companyProfile,
                                        Country: item.companyCountry,
                                        "Wallet address":
                                            item?.wallet?.wallet_public_key ||
                                            "-",
                                        Status: item.companyStatus,
                                    };
                                })}
                            />
                        )
                    }
                    loadingList={loadingList}
                    rows={sanitazedTabledData ? sanitazedTabledData : []}
                    setQuery={setQuery}
                    query={query}
                    totalRowCount={totalCompanies}
                    noDataFound="No companies found"
                />
            </Box>
        </>
    ) : (
        <Box
            sx={{
                mt: "30px",
            }}
        >
            You are not Authorized
        </Box>
    );
}
