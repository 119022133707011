import { useMemo, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
    Checkbox,
    ListItemText,
    FormControl,
    Chip,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    FormHelperText,
} from "@mui/material";

const certificateyear = {
    gap: "2px",
    height: "23px",
    overflow: "auto",
    "& .MuiChip-root": {
        height: "auto;",
    },
    "& .MuiChip-label": {
        paddingLeft: "9px",
        paddingRight: "9px",
        fontSize: "14px",
    },
};
const MultiSelectYearDropdown = ({ formik }) => {
    const years = [
        { value: "2020", label: "2020" },
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" },
        { value: "2023", label: "2023" },
        { value: "2024", label: "2024" },
        { value: "2025", label: "2025" },
        { value: "2026", label: "2026" },
        { value: "2027", label: "2027" },
        { value: "2028", label: "2028" },
        { value: "2029", label: "2029" },
        { value: "2030", label: "2030" },
        // Add more years here
    ];

    const [selectedYears, setSelectedYears] = useState([]);

    const handleCertificationYear = (value) => {
        setSelectedYears(value);
        formik.setFieldValue("certificationYears", value);
    };
    const handleDeleteYear = (yearToDelete) => () => {
        setSelectedYears(selectedYears.filter((year) => year !== yearToDelete));
        formik.setFieldValue(
            "certificationYears",
            formik.values.certificationYears.filter(
                (year) => year !== yearToDelete
            )
        );
    };

    const certificationYear = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl
                    required
                    variant="filled"
                    multiple
                    fullWidth
                    // sx={{ mb: "20px", overflow: "hidden" }}
                    sx={{ overflow: "hidden" }}
                    label="Certification years"
                >
                    <InputLabel id={`${"certificationYears"}-label`}>
                        Certification years
                    </InputLabel>
                    <Select
                        fullWidth
                        multiple
                        value={selectedYears}
                        onChange={(e) =>
                            handleCertificationYear(e.target.value)
                        }
                        renderValue={(selected) => (
                            <Stack
                                direction="row"
                                flexWrap="wrap"
                                sx={certificateyear}
                            >
                                {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        onDelete={handleDeleteYear(value)}
                                        deleteIcon={
                                            <CancelIcon
                                                sx={{
                                                    fontSize: "16px !important",
                                                }}
                                                onMouseDown={(event) =>
                                                    event.stopPropagation()
                                                }
                                            />
                                        }
                                    />
                                ))}
                            </Stack>
                        )}
                    >
                        {years.map((year) => (
                            <MenuItem key={year.value} value={year.value}>
                                <Checkbox
                                    checked={
                                        selectedYears.indexOf(year.value) > -1
                                    }
                                />
                                <ListItemText primary={year.label} />
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.errors.certificationYears &&
                    formik.touched.certificationYears ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {formik.errors.certificationYears}
                        </FormHelperText>
                    ) : null}
                </FormControl>
            </Grid>
        );
    }, [
        formik.values.certificationYears,
        formik.touched.certificationYears,
        formik.errors.certificationYears,
        years,
    ]);

    return certificationYear;
};

export default MultiSelectYearDropdown;
