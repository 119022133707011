import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ProgramFormContainer from "components/program/form/ProgramFormContainer";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const NewProgram = () => {
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].programSlug.create_program),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box
                    sx={{
                        maxWidth: "810px",
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <Grid
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 1 }}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Breadcrumbs
                                sm="auto"
                                sources={[
                                    {
                                        label: "programs",
                                        link: "/programs",
                                        canNavigate: true,
                                    },
                                    {
                                        label: "New program",
                                        link: "",
                                    },
                                ]}
                            />
                        </Grid>
                    </Box>
                    <Box sx={{ mt: "10px" }}>
                        <Typography variant="h2">Add a new program</Typography>
                        <Typography variant="body1" mt={1} mb={3}>
                            Please fill the following form to add a new program.
                        </Typography>
                        <Typography variant="h4" mb={3}>
                            Program information
                        </Typography>

                        <ProgramFormContainer />
                    </Box>
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default NewProgram;
