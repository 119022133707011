import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: { xs: "90%", sm: "680px" },
    maxHeight: "90%",
    overflowY: "auto",
    width: { xs: "90%", md: "680px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    margin: "0px 0px",
    p: { xs: 2, md: 5 },
};

export default function CustomModal({
    open,
    close,
    title,
    body,
    footer,
    customStyle,
    sx,
}) {
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ ...sx }}
        >
            <Box sx={{ ...style, ...customStyle }}>
                {title}
                {body}
                {footer}
            </Box>
        </Modal>
    );
}
