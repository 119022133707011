import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";
// import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
// import { ReactComponent as CheckBoxes } from "assets/icons/checkboxes.svg";
// import { ReactComponent as CheckboxeChecked } from "assets/icons/checkboxeChecked.svg";
// import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBackendcsvErrors } from "redux/farmersSlice";
import { resetBackendErrors } from "redux/projectSlice";
import { leadCompaniesList } from "redux/programSlice";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
// import { useAccessPage } from "customHooks/useAccess";
const Company = ({ formik, fieldErrors, type }) => {
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };
    const dispatch = useDispatch();
    const { leadCompanies } = useSelector((state) => state.program);
    useEffect(() => {
        dispatch(leadCompaniesList());
    }, []);

    // const { isRoles } = useAccessPage()
    // const isAdmin = isRoles("admin");
    const handleResetErrors = (key) => {
        if (fieldErrors?.[key]) {
            type == "farmer"
                ? dispatch(resetBackendcsvErrors(key))
                : dispatch(resetBackendErrors(key));
        }
    };

    const farmerExternalId = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    type="text"
                    id="farmerExternalId"
                    label="Farmer Ref"
                    name="farmerExternalId"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerExternalId}
                    error={showError("farmerExternalId")}
                    helperText={showError("farmerExternalId")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerExternalId,
        formik.touched.farmerExternalId,
        formik.errors.farmerExternalId,
    ]);

    const farmerCompanyName = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerCompanyName"
                    label="Farmer Company name"
                    name="farmerCompanyName"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerCompanyName}
                    error={showError("farmerCompanyName")}
                    helperText={showError("farmerCompanyName")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerCompanyName,
        formik.touched.farmerCompanyName,
        formik.errors.farmerCompanyName,
    ]);
    const farmerOfficialId = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerOfficialId"
                    label="Official ID"
                    name="farmerOfficialId"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerOfficialId}
                    error={showError("farmerOfficialId")}
                    helperText={showError("farmerOfficialId")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerOfficialId,
        formik.touched.farmerOfficialId,
        formik.errors.farmerOfficialId,
    ]);
    const farmerEuVatId = useMemo(() => {
        if (fieldErrors?.farmer_eu_vat_id) {
            formik.setFieldError(
                "farmerEuVatId",
                fieldErrors?.farmer_eu_vat_id
            );
        }
        {
            /* {fieldErrors?.farmer_eu_vat_id
                    ? formik.setFieldError(
                        "farmerEuVatId",
                        fieldErrors?.farmer_eu_vat_id
                    )
                    : ""} */
        }
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerEuVatId"
                    label="VAT number"
                    name="farmerEuVatId"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={(e) => {
                        formik.handleChange(e);
                        handleResetErrors("farmer_eu_vat_id");
                    }}
                    value={formik.values.farmerEuVatId}
                    error={showError("farmerEuVatId")}
                    helperText={showError("farmerEuVatId")}
                />
                {/* {fieldErrors?.farmer_eu_vat_id
                    ? formik.setFieldError(
                        "farmerEuVatId",
                        fieldErrors?.farmer_eu_vat_id
                    )
                    : ""} */}
            </Grid>
        );
    }, [
        formik.values.farmerEuVatId,
        formik.touched.farmerEuVatId,
        formik.errors.farmerEuVatId,
        fieldErrors?.farmer_eu_vat_id,
    ]);
    const farmerEuCapId = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerEuCapId"
                    label="European CAP ID"
                    name="farmerEuCapId"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerEuCapId}
                    error={showError("farmerEuCapId")}
                    helperText={showError("farmerEuCapId")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerEuCapId,
        formik.touched.farmerEuCapId,
        formik.errors.farmerEuCapId,
    ]);
    const farmerId = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    type="number"
                    id="projectIdFarmer"
                    label="Farmer ID (if already existing)"
                    name="projectIdFarmer"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.projectIdFarmer}
                    error={showError("projectIdFarmer")}
                    helperText={showError("projectIdFarmer")}
                />
            </Grid>
        );
    }, [
        formik.values.projectIdFarmer,
        formik.touched.projectIdFarmer,
        formik.errors.projectIdFarmer,
    ]);
    const company_listing = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    variant="filled"
                    fullWidth
                    // sx={{ mb: "20px" }}
                    label="Companies"
                >
                    <InputLabel id={`${"companyId"}-label`}>
                        Companies
                    </InputLabel>
                    <Select
                        labelId={`companyId`}
                        id="companyId"
                        name="companyId"
                        value={formik.values.companyId}
                        onChange={formik.handleChange}
                        variant="filled"
                    >
                        {leadCompanies?.map((item) => (
                            <MenuItem key={item.id} value={item?.id}>
                                {item?.companyName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {formik.errors.companyId && (
                    <FormHelperText
                        sx={{
                            color: "#F00",
                            fontSize: "14px",
                        }}
                    >
                        {formik.errors.companyId}
                    </FormHelperText>
                )}
            </Grid>
        );
    }, [
        formik.values.companyId,
        formik.touched.companyId,
        formik.errors.companyId,
        leadCompanies,
    ]);
    return (
        <>
            <Grid container rowSpacing="10px" columnSpacing={"30px"}>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={{ xs: "15px", sm: "30px" }}
                    >
                        {formik.values.checkFarmerIds ? <>{farmerId}</> : null}
                    </Stack>
                </Grid>
                {formik.values.checkFarmerIds ? null : (
                    <>
                        {farmerExternalId}
                        {farmerCompanyName}
                        {farmerOfficialId}
                        {farmerEuVatId}
                        {farmerEuCapId}
                        {type === "project" ? "" : company_listing}
                    </>
                )}
            </Grid>
        </>
    );
};

export default Company;
