import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { red } from "@mui/material/colors";
import { ReactComponent as IconFileupload } from "../../src/assets/icons/fileupload.svg";
import { FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import { showErrorMessage } from "redux/toastSlice";

const redColor = red[500];
const maxSizeInBytes = 10 * 1024 * 1024;

const FileInputNoPreview = ({
    label,
    name,
    formik,
    required,
    programDetails,
}) => {
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!(file.size > maxSizeInBytes)) {
                if (file && file.type === "application/pdf") {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    setFileName(file.name);
                    formik.setFieldValue(name, file);
                } else if (file && file.type !== "application/pdf") {
                    setFileName(null);
                    formik.setFieldError(name, "only pdf allowed");
                }
            } else {
                setFileName(null);
                formik.setFieldValue(name, "");
                formik.setFieldError(name, "Max 10 MB");
                dispatch(showErrorMessage("Max of 10 MB"));
            }
        } else {
            setFileName(null);
            formik.setFieldValue(name, "");
        }
    };

    return (
        <>
            <Box
                component="label"
                htmlFor={name}
                sx={{
                    height: 56,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #666",
                    backgroundColor: "#F5F5F5",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    "& .MuiButtonBase-root": {
                        color: "rgba(0, 0, 0, 0.6)",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "1.4375em",
                    },
                    "& .MuiButton-root": {
                        px: 0,
                    },
                }}
            >
                <Button
                    variant="text"
                    component="span"
                    sx={{
                        p: 0,
                        textTransform: "none",
                    }}
                >
                    {fileName ? (
                        <span
                            style={{
                                fontWeight: "bold",
                                maxWidth: "200px",
                                fontSize: "15px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {fileName}
                        </span>
                    ) : (
                        label
                    )}

                    {!required ? (
                        <Box sx={{ color: redColor, ml: "2px" }}>*</Box>
                    ) : null}
                </Button>
                <IconFileupload />
                <input
                    id={name}
                    hidden
                    accept=".pdf"
                    type="file"
                    onChange={handleFileUpload}
                    disabled={
                        required &&
                        programDetails?.program_status === "registered"
                    }
                />
                {formik?.errors[name] && formik?.touched[name] && (
                    <FormHelperText sx={{ color: "red", display: "inline" }}>
                        {formik?.errors[name]}
                    </FormHelperText>
                )}
            </Box>
            <Box
                component="div"
                sx={{
                    fontSize: "0.875rem",
                    color: "rgba(0, 0, 0, 0.6)",
                    mt: "3px",
                    ml: 2,
                }}
            >
                File format .pdf max 10 MB
            </Box>
        </>
    );
};

export default FileInputNoPreview;
