import { styled } from "@mui/material/styles";
import CustomTable from "common/CustomTable";
import UnderlineText from "common/UnderlineText";
import { Box, Checkbox } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Searchbar from "common/Searchbar";
import { Stack, Grid } from "@mui/material";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUCOListings } from "redux/registrySlices";
import TruncateWalletAddress from "util/TruncateWalletAddress";
// import CollectionsStatusColor from "util/CollectionsStatusColor";
import UCOsStatusColors from "util/UCOsStatusColor";
import { useTheme } from "@mui/material/styles";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "2px 0px",
    fontWeight: "400",
    width: "80px",
}));

const headCells = [
    {
        id: "project_dev",
        numeric: false,
        label: "Project dev.",
    },
    {
        id: "program_code",
        numeric: true,
        label: "Program code",
    },
    {
        id: "methodology",
        numeric: true,
        label: "Methodology",
    },
    {
        id: "field_id",
        numeric: true,
        label: "Field ID",
    },
    {
        id: "vintage",
        numeric: true,
        label: "Vintage",
    },
    {
        id: "country",
        numeric: true,
        label: "Country",
    },
    {
        id: "uco",
        numeric: true,
        label: "UCO",
    },
    {
        id: "token",
        numeric: true,
        label: "Token id",
    },
    {
        id: "status",
        numeric: true,
        label: "UCO Status",
    },

    {
        id: "scc",
        numeric: true,
        label: "SCC",
    },
];

const UniqueCertificateOrigin = ({ selects, refetch, setSelects }) => {
    const { can } = useAccessPage();
    const [query, setQuery] = useState({ page: 1, limit: 10, search: "" });

    const { UCOS, isLoading, UCOSCounts } = useSelector(
        (state) => state.registery
    );
    const dispatch = useDispatch();
    const tabsAccess = can(permissionSlug[0].uco_slug.list_UCO);
    const theme = useTheme();
    useEffect(() => {
        dispatch(fetchUCOListings(query));
    }, [query, refetch]);

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };

    const UCO_checkbox = can(permissionSlug[0].uco_slug.UCO_checkbox);

    if (
        can(permissionSlug[0].uco_slug.UCO_checkbox) &&
        !headCells.some((cell) => cell.id === "select")
    ) {
        headCells.push({
            id: "select",
            numeric: true,
            label: "UCO to cancel",
        });
    }

    const handleUCOSelection = (field_id, program_id) => {
        setSelects((selectionss) => {
            const isFieldSelected = selectionss.some(
                (obj) => obj.field_id === field_id
            );

            if (isFieldSelected) {
                // If the field is already selected, remove it from the selection
                return selectionss.filter(
                    (checkbox) => checkbox.field_id !== field_id
                );
            } else {
                // If the field is not selected, add it to the selection
                return [...selectionss, { program_id, field_id }];
            }
        });
    };
    const tableCellDefaultMinWidth =
        theme.components.MuiTableCell.styleOverrides.root.minWidth;
    theme.components.MuiTableCell.styleOverrides.root.minWidth = "77px";
    useEffect(() => {
        return () => {
            theme.components.MuiTableCell.styleOverrides.root.minWidth =
                tableCellDefaultMinWidth;
        };
    }, []);
    const sanitazidTabledData =
        UCOS &&
        UCOS?.map((item) => ({
            project_dev: item.program_developer,
            program_code: item.program_code,
            methodology: capitalizeFirstLetter(item.program_methodology),
            field_id: (
                <TableCell>
                    <Link to={`/field/${item.field_id}/details`}>
                        <UnderlineText>{item.field_id}</UnderlineText>
                    </Link>
                </TableCell>
            ),
            vintage: <TableCell>{item.field_field_harvest_year}</TableCell>,
            country: <TableCell>{item.program_country}</TableCell>,
            uco: (
                <TableCell sx={{ minWidth: "130px" }}>
                    {TruncateWalletAddress(item.program_uco_address)}
                </TableCell>
            ),
            token: item?.uco_tokenId ? (
                can(permissionSlug[0].uco_slug.UCO_details) ? (
                    <TableCell>
                        {" "}
                        <Link
                            to={`/registry/uco/${item.program_uco_address}/${item.field_id}/${item.programId}/details`}
                        >
                            <UnderlineText>{item.uco_tokenId}</UnderlineText>
                        </Link>
                    </TableCell>
                ) : (
                    item.uco_tokenId
                )
            ) : (
                "-"
            ),
            // quantity: item.volume,
            status: (
                <TableCell>
                    <StyledChip
                        label={item.uco_status}
                        sx={{
                            backgroundColor: UCOsStatusColors[item.uco_status],
                            color: "primary.white",
                        }}
                    />
                </TableCell>
            ),
            // retirement_year: item.field_retirement_year || "-",
            scc: item?.scc_tokenId ? (
                can(permissionSlug[0].scc_slug.SCC_details) ? (
                    <TableCell>
                        <Link
                            to={`/registry/scc/${item.program_scc_address}/${item.field_id}/${item.programId}/details`}
                        >
                            <UnderlineText>{item.scc_tokenId}</UnderlineText>
                        </Link>
                    </TableCell>
                ) : (
                    item.scc_tokenId
                )
            ) : (
                "-"
            ),
            select: UCO_checkbox && (
                <TableCell>
                    <Checkbox
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fontSize: "20px",
                            },
                        }}
                        checked={selects.some(
                            (obj) => obj.field_id === item.field_id
                        )}
                        onChange={() =>
                            handleUCOSelection(item.field_id, item.programId)
                        }
                        disabled={item.uco_status === "Cancelled"}
                    />
                </TableCell>
            ),
        }));

    return tabsAccess ? (
        <>
            <Grid
                item
                xs={6}
                sm={12}
                sx={{
                    width: { xs: "100%" },
                    mt: { xs: "0px" },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="10px"
                        sx={{
                            justifyContent: { xs: "center", lg: "flex-start" },
                            width: { xs: "100%", md: "auto" },
                        }}
                    >
                        <Searchbar
                            placeholdertext="Search a UCO"
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
            </Grid>
            <Box
                sx={{
                    mt: "30px",
                }}
            >
                <CustomTable
                    headCells={headCells}
                    rows={sanitazidTabledData ? sanitazidTabledData : []}
                    loadingList={isLoading}
                    totalRowCount={UCOSCounts}
                    noDataFound="No UCO found"
                    query={query}
                    setQuery={setQuery}
                />
            </Box>
        </>
    ) : (
        <Box
            sx={{
                mt: "30px",
            }}
        >
            You are not Authorized to view UCC
        </Box>
    );
};

export default UniqueCertificateOrigin;
