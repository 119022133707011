import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage } from "./toastSlice";
import Services from "services";
import { resetStateHander } from "helpers/sanitizedFunction";

export const fetchMapsProgram = createAsyncThunk(
    "program/fetchMapsProgram",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getMapsProgram();
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchCompanyMapData = createAsyncThunk(
    "program/fetchCompanyMapData",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getCompanyMapData(paramss);
            if (response) {
                const data = await response.json();
                return { data, id: paramss };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchFarmerMapsDropdowns = createAsyncThunk(
    "program/fetchMapsDropdowns",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getMapsDropdowns(paramss);
            if (response.data) {
                return { data: response.data, paramss };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchAllsMapsDropdowns = createAsyncThunk(
    "program/fetchAllsMapsDropdowns",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getMapsDropdowns(paramss);
            if (response.data) {
                return { data: response.data, paramss };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchCompanyYearMapData = createAsyncThunk(
    "program/fetchCompanyYearMapData",
    async (paramss, thunkAPI) => {
        try {
            let response;
            if (paramss.role === "Admin" || paramss.role === "admin") {
                response = await Services.getAdminYearMapData(paramss);
            } else {
                response = await Services.getCompanyYearMapData(paramss);
            }
            if (response.status === 200) {
                const data = await response.json();
                return data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    mapsPrograms: [],
    companygeojson: null,
    isLoaded: false,
    currentCompanyID: null,
    farmersOptions: [],
    yearOptions: [],
    programOptions: [],
    filtersFieldsCounts: 0,
};
export const mapsSlice = createSlice({
    name: "maps",
    initialState,
    reducers: {
        resetMaps: (state) => {
            resetStateHander(state, initialState);
        },
        setFarmersManually: (state, { payload }) => {
            state.farmersOptions = payload;
        },
        setFiltersFieldsCounts: (state, { payload }) => {
            state.filtersFieldsCounts = payload;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchMapsProgram.fulfilled, (state, action) => {
                // Add user to the state array
                state.mapsPrograms = action.payload;
            })
            .addCase(fetchCompanyMapData.fulfilled, (state, action) => {
                // Add user to the state array
                state.companygeojson = action.payload.data;
                state.currentCompanyID = action.payload.id;
                state.isLoaded = true;
            })
            .addCase(fetchCompanyYearMapData.fulfilled, (state, action) => {
                // Add user to the state array
                state.companygeojson = action.payload;
            })
            .addCase(fetchCompanyYearMapData.rejected, (state) => {
                state.companygeojson = null;
            })
            .addCase(fetchFarmerMapsDropdowns.fulfilled, (state, action) => {
                // Add user to the state array
                // state.farmersOptions = action.payload.farmers;
                state.yearOptions = action.payload.data.harvestYears;
                state.programOptions = action.payload.paramss.year
                    ? action.payload.data.programNames
                    : [];
            })
            .addCase(fetchAllsMapsDropdowns.fulfilled, (state, action) => {
                if (!action.payload.paramss?.program_id) {
                    // Add user to the state array
                    // state.farmersOptions = action.payload.farmers;
                    state.yearOptions = action.payload.data.harvestYears;
                    state.programOptions = action.payload.paramss?.year
                        ? action.payload.data.programNames
                        : [];
                    state.farmersOptions = [];
                } else {
                    state.farmersOptions = action.payload.data.farmers;
                }
            });
    },
});
export const { resetMaps, setFiltersFieldsCounts, setFarmersManually } =
    mapsSlice.actions;
export default mapsSlice.reducer;
