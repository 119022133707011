import { styled } from "@mui/material/styles";
import { Stack, Grid } from "@mui/material";
import CustomTable from "common/CustomTable";
import UnderlineText from "common/UnderlineText";
import { Box, Checkbox } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Searchbar from "common/Searchbar";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSCCListings } from "redux/registrySlices";
import TruncateWalletAddress from "util/TruncateWalletAddress";
import CollectionsStatusColor from "util/CollectionsStatusColor";
import sanitizedDigits from "util/sanitizedDigits";
import { useTheme } from "@mui/material/styles";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "2px 0px",
    fontWeight: "400",
    width: "80px",
}));

const headCells = [
    {
        id: "project_dev",
        numeric: false,
        label: "Project dev.",
    },
    {
        id: "program_code",
        numeric: true,
        label: "Program code",
    },
    {
        id: "methodology",
        numeric: true,
        label: "Methodology",
    },
    {
        id: "field_id",
        numeric: true,
        label: "Field ID",
    },
    {
        id: "vintage",
        numeric: true,
        label: "Vintage",
    },
    {
        id: "country",
        numeric: true,
        label: "Country",
    },
    {
        id: "scc",
        numeric: true,
        label: "SCC",
    },
    {
        id: "token",
        numeric: true,
        label: "Token id",
    },
    {
        id: "quantity",
        numeric: true,
        label: "Quantity",
    },
    {
        id: "status",
        numeric: true,
        label: "SCC Status",
    },
    {
        id: "retirement_year",
        numeric: true,
        label: "Retirement year",
    },
    {
        id: "uco",
        numeric: true,
        label: "UCO",
    },
];
const SmartCarbonCredit = ({ selects, refetch, setSelects }) => {
    const { can } = useAccessPage();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [query, setQuery] = useState({ page: 1, limit: 10, search: "" });

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };

    const SCC_checkbox = can(permissionSlug[0].scc_slug.SCC_checkbox);
    const { SCCS, isLoading, SCCSCounts } = useSelector(
        (state) => state.registery
    );
    const tabsAccess = can(permissionSlug[0].scc_slug.list_SCC);

    if (SCC_checkbox && !headCells.some((cell) => cell.id === "select")) {
        headCells.push({
            id: "select",
            numeric: true,
            label: "SSC to cancel",
        });
    }
    const tableCellDefaultMinWidth =
        theme.components.MuiTableCell.styleOverrides.root.minWidth;
    theme.components.MuiTableCell.styleOverrides.root.minWidth = "70px";
    useEffect(() => {
        return () => {
            theme.components.MuiTableCell.styleOverrides.root.minWidth =
                tableCellDefaultMinWidth;
        };
    }, []);
    useEffect(() => {
        dispatch(fetchSCCListings(query));
    }, [query, refetch]);

    const handleUCOSelection = (
        program_id,
        field_value,
        old_status,
        field_id
    ) => {
        setSelects((selectionss) => {
            const isFieldSelected = selectionss.some(
                (obj) =>
                    obj.field_id === field_id && obj.old_status === old_status
            );

            if (isFieldSelected) {
                // If the field is already selected, remove it from the selection
                return selectionss.filter((checkbox) => {
                    return (
                        checkbox.field_id !== field_id ||
                        checkbox.old_status !== old_status
                    );
                });
            } else {
                // If the field is not selected, add it to the selection
                return [
                    ...selectionss,
                    { program_id, field_value, old_status, field_id },
                ];
            }
        });
    };

    const sanitazidTabledData =
        SCCS &&
        SCCS?.map((item) => ({
            project_dev: item.program_developer,
            program_code: item.program_code,
            methodology: capitalizeFirstLetter(item.program_methodology),
            field_id: (
                <TableCell>
                    <Link to={`/field/${item.field_id}/details`}>
                        <UnderlineText>{item.field_id}</UnderlineText>
                    </Link>
                </TableCell>
            ),
            vintage: <TableCell>{item.field_field_harvest_year}</TableCell>,
            country: <TableCell>{item.program_country}</TableCell>,
            scc: (
                <TableCell sx={{ minWidth: "130px" }}>
                    {TruncateWalletAddress(item.program_scc_address)}
                </TableCell>
            ),
            token: item?.scc_tokenId ? (
                can(permissionSlug[0].scc_slug.SCC_details) ? (
                    <TableCell>
                        <Link
                            to={`/registry/scc/${item.program_scc_address}/${item.field_id}/${item.programId}/details`}
                        >
                            <UnderlineText>{item.scc_tokenId}</UnderlineText>
                        </Link>
                    </TableCell>
                ) : (
                    <TableCell>{item.scc_tokenId} </TableCell>
                )
            ) : (
                "-"
            ),
            quantity: sanitizedDigits(item.volume),
            status: (
                <TableCell>
                    <StyledChip
                        label={item.field_field_status}
                        sx={{
                            backgroundColor:
                                CollectionsStatusColor[item.field_field_status],
                            color: "primary.white",
                        }}
                    />
                </TableCell>
            ),
            retirement_year: item.field_retirement_year || "-",
            uco: item?.uco_tokenId ? (
                can(permissionSlug[0].uco_slug.UCO_details) ? (
                    <TableCell sx={{ minWidth: "70px" }}>
                        {" "}
                        <Link
                            to={`/registry/uco/${item.program_uco_address}/${item.field_id}/${item.programId}/details`}
                        >
                            <UnderlineText>{item.uco_tokenId}</UnderlineText>
                        </Link>
                    </TableCell>
                ) : (
                    item.uco_tokenId
                )
            ) : (
                "-"
            ),
            select: SCC_checkbox && (
                <TableCell>
                    <Checkbox
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fontSize: "20px",
                            },
                        }}
                        checked={selects.some(
                            (obj) =>
                                obj.field_id === item.field_id &&
                                obj.old_status === item.field_field_status
                        )}
                        onChange={() =>
                            handleUCOSelection(
                                item.programId,
                                item.volume,
                                item.field_field_status,
                                item.field_id
                            )
                        }
                        // disabled={item.field_field_status === "Cancelled"}
                    />
                </TableCell>
            ),
        }));
    return tabsAccess ? (
        <>
            <Grid
                item
                xs={6}
                sm={12}
                sx={{
                    width: { xs: "100%" },
                    mt: { xs: "0px" },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="10px"
                        sx={{
                            justifyContent: { xs: "center", md: "flex-start" },
                            width: { xs: "100%", md: "auto" },
                        }}
                    >
                        <Searchbar
                            placeholdertext="Search a SCC"
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
            </Grid>
            <Box
                sx={{
                    mt: "30px",
                }}
            >
                <CustomTable
                    headCells={headCells}
                    loadingList={isLoading}
                    query={query}
                    rows={sanitazidTabledData ? sanitazidTabledData : []}
                    totalRowCount={SCCSCounts}
                    setQuery={setQuery}
                    noDataFound="No SCC found"
                />
            </Box>
        </>
    ) : (
        <Box
            sx={{
                mt: "30px",
            }}
        >
            You are not Authorized to view SCC
        </Box>
    );
};

export default SmartCarbonCredit;
