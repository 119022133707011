import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { shallowEqual, useSelector } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutocompleteFarmers({ farmers, handleFarmers }) {
    const farmersOptions = useSelector(
        (state) => state.maps.farmersOptions,
        shallowEqual
    );

    const handleChangedFarmerss = (value) => {
        handleFarmers("farmers", value);
    };
    return (
        <Autocomplete
            multiple
            onChange={(_, value) => handleChangedFarmerss(value)}
            id="checkboxes-tags-demo"
            limitTags={1}
            value={farmers}
            options={farmersOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} label="Farmers" placeholder="Farmers" />
            )}
        />
    );
}
