import { createSlice } from "@reduxjs/toolkit";
import { resetStateHander } from "helpers/sanitizedFunction";

const initialState = {
    error: "",
    success: "",
    showToast: false,
};

const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        showErrorMessage: (state, { payload }) => {
            state.error = payload;
            state.showToast = true;
        },
        showSuccessMessage: (state, { payload }) => {
            state.success = payload;
            state.showToast = true;
        },
        hideToastMessage: (state) => {
            state.error = "";
            state.success = "";
            state.showToast = false;
        },
        resetToast: (state) => {
            resetStateHander(state, initialState);
        },
    },
});

export const {
    showErrorMessage,
    hideToastMessage,
    showSuccessMessage,
    resetToast,
} = errorSlice.actions;
export default errorSlice.reducer;
