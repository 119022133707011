import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./authentication";
import errorSlice from "./toastSlice";
import usersSlice from "./users";
import companySlice from "./companiesSlice";
import accountSlice from "./accountSlice";
import programSlice from "./programSlice";
import projectSlice from "./projectSlice";
import certificationsSlice from "./certificationsSlice";
import farmersSlice from "./farmersSlice";
import farmersProjectSlice from "./farmersProjectSlice";
import fieldsSlice from "./fieldsSlice";
import globalSearchSlice from "./globalSearchSlice";
import mapsSlice from "./mapsSlice";
import rolesSlice from "./rolesSlice";
import permissionsSlicer from "./permissionsSlicer";
import walletSlice from "./walletSlice";
import registrySlices from "./registrySlices";
export const store = configureStore({
    reducer: {
        login: loginSlice,
        toastMessage: errorSlice,
        companylist: companySlice,
        usersList: usersSlice,
        account: accountSlice,
        program: programSlice,
        certifications: certificationsSlice,
        project: projectSlice,
        farmers: farmersSlice,
        farmersProject: farmersProjectSlice,
        field: fieldsSlice,
        globalSearch: globalSearchSlice,
        maps: mapsSlice,
        roles: rolesSlice,
        permissions: permissionsSlicer,
        wallet: walletSlice,
        registery: registrySlices,
    },
});
