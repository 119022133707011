import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Tabs from "../common/Tabs";
import Box from "@mui/material/Box";
import Companies from "components/admin/company/Companies";
import CompanyHeader from "components/admin/company/CompanyHeader";
import CompanyTitle from "components/admin/company/CompanyTitle";
import Users from "components/admin/user/Users";
import UserHeader from "components/admin/user/UserHeader";
import UserTitle from "components/admin/user/UserTitle";
// import permissionSlug from "assets/data/permissionSlug.json";
import { useNavigate, useParams } from "react-router-dom";
import CheckpageAuth from "auth/CheckPageAccess";
// import { useEffect, useState } from "react";
import { useAccessPage } from "customHooks/useAccess";
// import { useSelector } from "react-redux";
import permissionSlug from "assets/data/permissionSlug.json";
const Admin = ({ activeTab }) => {
    const { can, cannot } = useAccessPage();
    const { id } = useParams();
    const navigate = useNavigate();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].menu_slugs.company_menu),
        unAuthUserRedirectTo: "/401",
    };
    // const { accountDetails: users } = useSelector((state) => state.account);

    // const userRole = (users?.role?.name || "").toLowerCase();
    const ShowActiveExpand = () => {
        switch (activeTab) {
            case "companies":
                return {
                    header: <CompanyHeader />,
                    body: <Companies />,
                    pagetitle: <CompanyTitle />,
                };

            case "users":
                return {
                    header: <UserHeader companyId={id} />,
                    body: <Users />,
                    pagetitle: <UserTitle companyId={id} />,
                };

            default:
                navigate("/404");
                return {
                    header: "",
                    body: "",
                    pagetitle: "",
                };
        }
    };
    // useEffect(() => {
    //     // if (userRole === "user") {
    //     setRouteAccess((prev) => ({
    //         ...prev,
    //         routeAccess: can("company_menu"),
    //     }));
    //     // }
    // }, [can]);
    const { header, body, pagetitle } = ShowActiveExpand();

    const handleTabChange = (routeName) => {
        navigate(routeName);
    };

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", mb: "11px" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        {header}
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ mt: 0 }}
                    >
                        {pagetitle}
                        <Grid item xs={12} md={6}>
                            <Tabs
                                tabItem={[
                                    {
                                        name: "Companies",
                                        route: "/companies",
                                        disableTabs: cannot(
                                            permissionSlug[0].company_slug
                                                .list_companies
                                        ),
                                    },

                                    {
                                        name: "Users",
                                        route: id
                                            ? `/companies/${id}/users`
                                            : "/users",
                                        disableTabs: cannot(
                                            permissionSlug[0].users_slug
                                                .list_users
                                        ),
                                    },
                                ]}
                                onTabChange={handleTabChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {body}
            </CheckpageAuth>
        </Layout>
    );
};

export default Admin;
