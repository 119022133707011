import { useMemo } from "react";
import Typography from "@mui/material/Typography";
import FileInput from "common/FileInput";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const CompanyInformation = ({ formik }) => {
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };

    const companyName = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="company_name"
                    label="Company Name"
                    name="company_name"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.company_name}
                    error={showError("company_name")}
                    helperText={showError("company_name")}
                />
            </Box>
        );
    }, [
        formik.values.company_name,
        formik.touched.company_name,
        formik.errors.company_name,
    ]);
    const officialId = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="official_id"
                    label="Official ID"
                    name="official_id"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.official_id}
                    error={showError("official_id")}
                    helperText={showError("official_id")}
                />
            </Box>
        );
    }, [
        formik.values.official_id,
        formik.touched.official_id,
        formik.errors.official_id,
    ]);

    const intraco = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="intraco"
                    label="EU VAT intraco"
                    name="intraco"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.intraco}
                    error={showError("intraco")}
                    helperText={showError("intraco")}
                />
            </Box>
        );
    }, [formik.values.intraco, formik.touched.intraco, formik.errors.intraco]);

    const phone = useMemo(() => {
        return (
            <Box>
                <PhoneInput
                    fullWidth
                    variant="filled"
                    name="phone"
                    id="phone"
                    country={"fr"}
                    value={formik.values.phone}
                    enableSearch={true}
                    onChange={(phone, dialCode, e, value) => {
                        formik.setFieldValue("phone", value);
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(showError("phone"))}
                    helperText={showError("phone")}
                    inputProps={{
                        style: { width: "100%", height: "57px" },
                        name: "phone",
                        required: true,
                        placeholder: "Phone number",
                    }}
                    countryCodeEditable={false}
                />
                {formik.errors.phone && formik.touched.phone && (
                    <div style={{ color: "red" }}>{formik.errors.phone}</div>
                )}
            </Box>
        );
    }, [
        formik.values.phone,
        formik.values.dialCode,
        formik.touched.phone,
        formik.errors.phone,
    ]);
    const email = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={showError("email")}
                    helperText={showError("email")}
                />
            </Box>
        );
    }, [formik.values.email, formik.touched.email, formik.errors.email]);
    const website = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="website"
                    label="Website"
                    name="website"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.website}
                    error={showError("website")}
                    helperText={showError("website")}
                />
            </Box>
        );
    }, [formik.values.website, formik.touched.website, formik.errors.website]);
    const companyDescription = useMemo(() => {
        return (
            <TextField
                sx={{
                    "& .MuiFormHelperText-root": {
                        display: "flex",
                        justifyContent: "space-between",
                    },
                }}
                required
                id="company-description"
                label="Company description"
                name="company_description"
                multiline
                fullWidth
                rows={2}
                variant="filled"
                onChange={formik.handleChange}
                value={formik.values.company_description}
                error={showError("company_description")}
                helperText={showError("company_description")}
            />
        );
    }, [
        formik.values.company_description,
        formik.touched.company_description,
        formik.errors.company_description,
    ]);
    return (
        <>
            <Typography variant="h4" sx={{ mb: "8px" }}>
                Company Information
            </Typography>

            <Box
                display="grid"
                gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {companyName}
                {officialId}
                {intraco}
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="repeat(2 , minmax(250px, 0))"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {phone}
                {email}
                {website}
                <Box
                    item
                    sx={{
                        "@media (min-width: 768px)": {
                            minWidth: "330px",
                        },
                    }}
                >
                    <FileInput
                        label="Logo.jpg"
                        name="companyLogo"
                        imagePreview={true}
                        fileName="File format .jpg, .png, .gif, .svg"
                        formik={formik}
                    />
                </Box>
                <Box
                    sx={{
                        "@media (min-width: 768px)": {
                            gridColumn: "span 2",
                        },
                    }}
                >
                    {companyDescription}
                </Box>
            </Box>
        </>
    );
};

export default CompanyInformation;
