const CollectionsStatusColor = {
    Issued: "#1ED800",
    Listed: "#3FD0C9",
    Retired: "#02353C",
    Cancelled: "#B6B6B6",
    Escrowed: "#FF8A00",
    Active: "green",
    Locked: "red",
    Frozen: "#FF8A00",
    FrozenForSale: "#FF8A00",
    Accepted: "#FF8A00",
    OfferIn: "#FF8A00",
    Allocated: "#02353C",
};
export default CollectionsStatusColor;
