import { useEffect } from "react";
import {
    FormHelperText,
    // Stack,
    // OutlinedInput,
    // InputLabel,
    // MenuItem,
    // Chip,
    // Select,
    // FormControl,
    // Checkbox,
    TextField,
} from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
// import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
// import Chip from '@mui/material/Chip';
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function MultipleSelectChip({
    formik,
    selectedNames,
    handleSelectedCompaniesChange,
    setSelectedNames,
    company,
    programDetails,
    edit,
}) {
    const { leadCompanies } = useSelector((state) => state.program);

    const filterdData = () => {
        if (selectedNames?.length > 0) {
            const filtered = leadCompanies?.filter((val) => {
                return selectedNames.every((item) => item.id !== val.id);
            });
            return filtered;
        } else {
            return leadCompanies;
        }
    };
    useEffect(() => {
        if (
            leadCompanies?.length > 0 &&
            company?.slaveCompaniesName?.length > 0
        ) {
            const companiesName = company?.slaveCompaniesName?.map(
                (obj) => obj.companyName
            );
            const sanitizedCompanies = leadCompanies.filter((item) =>
                companiesName.includes(item.companyName)
            );
            setSelectedNames(sanitizedCompanies);
        }
    }, [company, leadCompanies]);

    const uniqueData = filterdData()?.filter((item) => {
        if (formik.values.program_developer) {
            if (item.companyName?.includes(formik.values.program_developer)) {
                return;
            }
        }
        return item;
    });

    return (
        <>
            <Autocomplete
                sx={{
                    ".MuiFormControl-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.06)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                    },
                    ".MuiInputBase-root": {
                        minHeight: "62px",
                        maxHeight: "100px",
                        overflowY: "auto",
                        backgroundColor: "transparent !important",
                        marginTop: "22px",
                        paddingTop: 0,
                        "&::before, ::after": {
                            display: "none",
                        },
                        "& .MuiChip-label": {
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            fontSize: "13px",
                        },
                        "& .MuiButtonBase-root": {
                            height: "24px",
                        },
                        "& .MuiSvgIcon-root": {
                            width: "14px",
                            height: "14px",
                        },
                    },
                }}
                multiple
                onChange={(_, value) => handleSelectedCompaniesChange(value)}
                id="checkboxes-tags-demo"
                value={selectedNames}
                options={uniqueData}
                disableCloseOnSelect
                disabled={
                    edit && programDetails?.program_status === "registered"
                }
                getOptionLabel={(option) => option.companyName}
                renderOption={(props, option) => (
                    <li {...props}>{option.companyName}</li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label="Program developers"
                        // disabled={programDetails.program_status === "registered"}
                    />
                )}
            />

            {formik.touched.slaveCompanyName &&
                formik.errors.slaveCompanyName && (
                    <FormHelperText
                        sx={{
                            color: "#F00",
                            fontSize: "14px",
                        }}
                    >
                        {formik.errors.slaveCompanyName}
                    </FormHelperText>
                )}
        </>
    );
}
