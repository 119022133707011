import CustomTable from "common/CustomTable";
import Box from "@mui/material/Box";
import PdfIcon from "common/PdfIcon";
import TableCell from "@mui/material/TableCell";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    fetchCertifications,
    refetchCertifications,
} from "redux/certificationsSlice";
import { useParams } from "react-router-dom";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
// import dayjs from "dayjs";
import { useDateFormatter } from "customHooks/useDateFormatter";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const headCells = [
    {
        id: "certificateref",
        label: "Certificate ref.",
    },
    {
        id: "validator",
        label: "Validator",
    },
    {
        id: "certification",
        label: "1st certification",
    },
    {
        id: "certificateyear",
        label: "Certification year",
    },
    {
        id: "periodstart",
        label: "Crediting period start",
    },
    {
        id: "periodend",
        label: "Crediting period end",
    },
    {
        id: "certificate",
        label: "Certificate",
    },
];

export default function Detail() {
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(
            permissionSlug[0].certification_slug.list_certificates
        ),
        unAuthUserRedirectTo: "/401",
    };

    const { formatDates } = useDateFormatter();
    const { id } = useParams();
    const initialQuery = useRef({ programId: id, page: 1, limit: 10 });
    const [query, setQuery] = useState(initialQuery.current);
    const dispatch = useDispatch();

    const { certifications, isLoading, isLoaded, count, previousProgramId } =
        useSelector((state) => state.certifications, shallowEqual);

    useEffect(() => {
        if (!isLoaded) {
            dispatch(fetchCertifications(query)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    initialQuery.current = query;
                }
            });
        }
    }, [isLoaded]);
    useEffect(() => {
        if (
            initialQuery.current.page !== query.page ||
            initialQuery.current.limit !== query.limit ||
            Number(id) !== Number(previousProgramId)
        ) {
            dispatch(refetchCertifications());
        }
    }, [query]);

    const sanitazidTabledData =
        certifications &&
        certifications?.map((item) => ({
            certificateref: item.certificationCertificateNumber,
            validator: capitalizeFirstLetter(item.certificationCompanyName),
            certification: formatDates(
                item.certificationFirstCertificationDate
            ),
            certificateyear: item?.certificationYears ? (
                <TableCell sx={{ width: "20%" }}>
                    {item?.certificationYears}
                </TableCell>
            ) : (
                "-"
            ),
            periodstart: formatDates(item.certificationStartDate),
            periodend: formatDates(item.certificationEndDate),
            certificate: (
                <TableCell>
                    <PdfIcon address={item.certificationCertificateFile} />
                </TableCell>
            ),
        }));
    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <Box component="div" sx={{ mt: 7 }}>
                    <CustomTable
                        headCells={headCells}
                        loadingList={isLoading}
                        rows={sanitazidTabledData ? sanitazidTabledData : []}
                        setQuery={setQuery}
                        query={query}
                        totalRowCount={count}
                        noDataFound="No certifications found"
                    />
                </Box>
            </CheckpageAuth>
        </>
    );
}
