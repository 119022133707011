import { useEffect, useState } from "react";
import Layout from "Layout";
import Breadcrumbs from "common/Breadcrumb";
import { Typography } from "@mui/material";
import CancelButton from "common/CancelButton";
import { Box, Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchPermissions } from "redux/permissionsSlicer";
import PermissionsForm from "components/permissions/PermissionsForm";
import { useNavigate, useParams } from "react-router-dom";
import { fetchRoles } from "redux/rolesSlice";
import { updatePermissions } from "redux/permissionsSlicer";
import OpenPreviousPage from "common/OpenPreviousPage";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const Permissions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess:
            can(permissionSlug[0].roles_slugs.list_permissions) &&
            can(permissionSlug[0].roles_slugs.edit_permission),
        unAuthUserRedirectTo: "/401",
    };
    const { id } = useParams();
    const [roleName, setRoleName] = useState("");
    const [updatedPermissions, setUpdatedPermissions] = useState({});

    const addToMainSection = (item, mainSection) => {
        if (
            item.slug.startsWith("edit_") ||
            item.slug.startsWith("create_") ||
            item.slug.startsWith("upload_") ||
            item.slug.startsWith("cancel_")
        ) {
            mainSection.Functions.push({
                id: item.id,
                slug: item.slug,
                label: item.name,
                permission: item.permission,
            });
        } else {
            mainSection.Views.push({
                id: item.id,
                slug: item.slug,
                label: item.name,
                permission: item.permission,
            });
        }
    };

    useEffect(() => {
        dispatch(fetchRoles());
    }, []);

    const { roles } = useSelector((state) => state.roles);

    useEffect(() => {
        if (roles && roles?.data?.length > 0) {
            const role = roles?.data?.find((role) => role.id === parseInt(id));

            if (role) {
                setRoleName(role.name);
            }
        }
    }, [id, roles]);

    useEffect(() => {
        if (can(permissionSlug[0].roles_slugs.list_permissions)) {
            dispatch(fetchPermissions(id));
        }
    }, [can(permissionSlug[0].roles_slugs.list_permissions)]);

    const { permissions, isLoading } = useSelector(
        (state) => state.permissions
    );

    const handleUpdatePermissions = (updatedItem, checked) => {
        setUpdatedPermissions((prevPermissions) => ({
            ...prevPermissions,
            [updatedItem.id]: {
                slug: updatedItem.slug,
                checked: checked,
            },
        }));
    };

    const handleSavePermissions = () => {
        const payload = {
            role_id: parseInt(id),
            permissions: Object.keys(updatedPermissions).map((key) => ({
                id: parseInt(key),
                slug: updatedPermissions[key].slug,
                permission: updatedPermissions[key].checked,
            })),
        };
        if (can(permissionSlug[0].roles_slugs.edit_permission)) {
            dispatch(updatePermissions(payload)).then((res) => {
                if (res.payload.success === true) {
                    navigate("/roles");
                }
            });
        }
    };

    const mainSections = {
        Account: {
            Views: [],
            Functions: [],
        },
        User: {
            Views: [],
            Functions: [],
        },
        Company: {
            Views: [],
            Functions: [],
        },
        Program: {
            Views: [],
            Functions: [],
        },
        Project: {
            Views: [],
            Functions: [],
        },
        Certificate: {
            Views: [],
            Functions: [],
        },
        Field: {
            Views: [],
            Functions: [],
        },
        Farmer: {
            Views: [],
            Functions: [],
        },
        UCO: {
            Views: [],
            Functions: [],
        },
        SCC: {
            Views: [],
            Functions: [],
        },
        Menu: {
            Views: [],
            Functions: [],
        },
        Role: {
            Views: [],
            Functions: [],
        },
        Permission: {
            Views: [],
            Functions: [],
        },
    };

    permissions?.data?.forEach((item) => {
        if (item.slug === "my_account" || item.slug === "edit_account") {
            addToMainSection(item, mainSections.Account);
        } else if (
            item.slug === "list_users" ||
            item.slug === "create_user" ||
            item.slug === "user_details" ||
            item.slug === "edit_user" ||
            item.slug === "export_users"
        ) {
            addToMainSection(item, mainSections.User);
        } else if (
            item.slug === "company_menu" ||
            item.slug === "list_companies" ||
            item.slug === "create_company" ||
            item.slug === "company_details" ||
            item.slug === "edit_company" ||
            item.slug === "export_company"
        ) {
            addToMainSection(item, mainSections.Company);
        } else if (
            item.slug === "program_menu" ||
            item.slug === "list_programs" ||
            item.slug === "create_program" ||
            item.slug === "program_details" ||
            item.slug === "edit_program"
        ) {
            addToMainSection(item, mainSections.Program);
        } else if (
            item.slug === "list_projects" ||
            item.slug === "export_projects" ||
            item.slug === "upload_projects" ||
            item.slug === "create_project" ||
            item.slug === "upload_project_contract" ||
            item.slug === "view_project_contract" ||
            item.slug === "edit_project"
        ) {
            addToMainSection(item, mainSections.Project);
        } else if (
            item.slug === "field_menu" ||
            item.slug === "list_fields" ||
            item.slug === "export_fields" ||
            item.slug === "upload_fields" ||
            item.slug === "export_results" ||
            item.slug === "cancel_import" ||
            item.slug === "fields_map" ||
            item.slug === "field_details" ||
            item.slug === "fields_history"
        ) {
            addToMainSection(item, mainSections.Field);
        } else if (
            item.slug === "list_certificates" ||
            item.slug === "create_certificate" ||
            item.slug === "edit_certificate" ||
            item.slug === "certificate_details"
        ) {
            addToMainSection(item, mainSections.Certificate);
        } else if (
            item.slug === "farmer_menu" ||
            item.slug === "list_farmers" ||
            item.slug === "create_farmer" ||
            item.slug === "upload_farmer" ||
            item.slug === "farmer_details" ||
            item.slug === "edit_farmer" ||
            item.slug === "export_farmer"
        ) {
            addToMainSection(item, mainSections.Farmer);
        } else if (
            item.slug === "create_UCO" ||
            item.slug === "upload_values" ||
            item.slug === "list_UCO" ||
            item.slug === "UCO_details" ||
            item.slug === "cancel_UCO" ||
            item.slug === "UCO_checkbox"
        ) {
            addToMainSection(item, mainSections.UCO);
        } else if (
            item.slug === "list_SCC" ||
            item.slug === "SCC_details" ||
            item.slug === "cancel_SCC" ||
            item.slug === "SCC_checkbox"
        ) {
            addToMainSection(item, mainSections.SCC);
        } else if (
            item.slug === "marketplace_menu" ||
            item.slug === "statistic_menu" ||
            item.slug === "account_menu" ||
            item.slug === "support_menu" ||
            item.slug === "admin_menu" ||
            item.slug === "role_menu" ||
            item.slug === "registry_menu"
        ) {
            addToMainSection(item, mainSections.Menu);
        } else if (item.slug === "list_roles") {
            addToMainSection(item, mainSections.Role);
        } else if (
            item.slug === "list_permissions" ||
            item.slug === "edit_permission"
        ) {
            addToMainSection(item, mainSections.Permission);
        }
    });

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box
                    sx={{
                        maxWidth: "810px",
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <Breadcrumbs
                            sm="auto"
                            sources={[
                                {
                                    label: "Roles",
                                    link: "/roles",
                                    canNavigate: true,
                                },
                                {
                                    label: "Manage Permissions",
                                    link: "",
                                },
                            ]}
                        />
                    </Box>

                    {isLoading ? (
                        <p
                            style={{
                                textAlign: "center",
                                color: "#02353C",
                                fontWeight: "bold",
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            Processing...
                        </p>
                    ) : (
                        <>
                            <Box sx={{ mt: "10px" }}>
                                <Typography variant="h2">
                                    <OpenPreviousPage /> Manage Permissions for{" "}
                                    <Box
                                        component="span"
                                        sx={{
                                            fontWeight: "normal",
                                            borderBottom: "1px solid #000",
                                            display: "inline-block",
                                            "& a": {
                                                color: "inherit",
                                            },
                                        }}
                                    >
                                        {roleName}
                                    </Box>
                                </Typography>

                                {Object.keys(mainSections).map(
                                    (sectionName) => (
                                        <Box
                                            component="div"
                                            key={sectionName}
                                            sx={{ mt: 2 }}
                                        >
                                            <Typography
                                                variant="h4"
                                                sx={{ fontSize: "20px" }}
                                            >
                                                {sectionName}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    paddingLeft: "30px",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                {mainSections[sectionName]
                                                    .Views &&
                                                    mainSections[sectionName]
                                                        .Views.length > 0 && (
                                                        <>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    fontSize:
                                                                        "17px",
                                                                }}
                                                                mb={1}
                                                            >
                                                                Views
                                                            </Typography>
                                                            <PermissionsForm
                                                                items={
                                                                    mainSections[
                                                                        sectionName
                                                                    ].Views
                                                                }
                                                                onUpdatePermissions={
                                                                    handleUpdatePermissions
                                                                }
                                                            />
                                                        </>
                                                    )}

                                                {mainSections[sectionName]
                                                    .Functions &&
                                                    mainSections[sectionName]
                                                        .Functions.length >
                                                        0 && (
                                                        <>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    fontSize:
                                                                        "17px",
                                                                }}
                                                                mb={1}
                                                            >
                                                                Functions
                                                            </Typography>
                                                            <PermissionsForm
                                                                items={
                                                                    mainSections[
                                                                        sectionName
                                                                    ].Functions
                                                                }
                                                                onUpdatePermissions={
                                                                    handleUpdatePermissions
                                                                }
                                                            />
                                                        </>
                                                    )}
                                            </Box>
                                        </Box>
                                    )
                                )}

                                <Stack direction="row" spacing={2} mt="49px">
                                    <CancelButton address={"/roles"} />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={handleSavePermissions}
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            </Box>
                        </>
                    )}
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};
export default Permissions;
