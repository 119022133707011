import Grid from "@mui/material/Grid";
import Breadcrumbs from "common/Breadcrumb";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Button, Box, Stack } from "@mui/material";
import Modal from "common/Modal";
// import { Link } from "react-router-dom";
import CustomDropzone from "common/CustomDropzone";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { useDispatch, useSelector } from "react-redux";
import csvFieldsFile from "assets/data/cancel_scc_file77.csv";
import csvFreezesFile from "assets/data/freeze_scc_files.csv";

import CloseIcon from "@mui/icons-material/Close";
import { getBlockChainToken } from "util/token";
import {
    cancelSelectedSCCs,
    cancelsSCCsWithCsv,
    freezeSelectedSCCs,
    freezesSCCsWithCsv,
} from "redux/registrySlices";
import { showErrorMessage } from "redux/toastSlice";
import BlockchainProcessingModalsMessages from "common/BlockchainProcessingModalsMessages";
export default function SccHeader({ selects, setSelects, setRefetch }) {
    const [error, setError] = useState(null);
    const isMobile = useMediaQuery("(max-width:500px)");
    const { cannot } = useAccessPage();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [OpenSelectedSccModal, setOpenSelectedSccModal] = useState();
    const [OpenMissCancellationModal, setOpenMissCancellationModal] =
        useState();
    const [OpenSelectedFreezingSccModal, setOpenSelectedFreezingSccModal] =
        useState(false);
    const [massActions, setMassActions] = useState(null);
    // const [OpenMassFreezingModals, setOpenMassFreezingModals] = useState(null);
    const dispatch = useDispatch();
    const { SCCSCounts } = useSelector((state) => state.registery);
    const { cancellingSCCs, freezingSCCs } = useSelector(
        (state) => state.registery
    );
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [elmt, setelmt] = React.useState(null);
    const open2 = Boolean(elmt);
    const handleClick2 = (event) => {
        setelmt(event.currentTarget);
    };
    const handleClose2 = () => {
        setelmt(null);
    };
    const [file, setFile] = useState([]);

    const handleCloseValueFile = (event) => {
        event.stopPropagation();
        setFile("");
        // dispatch(resetSccValueUploadErrors());
    };

    const handleCancels = () => {
        setMassActions(null);
        setOpenMissCancellationModal(false);
        setFile("");
        setError(null);
        // dispatch(resetSccValueUploadErrors());
    };

    const handleMassCancellationClicked = () => {
        setMassActions("Cancel");
        setOpenMissCancellationModal(true);
        setAnchorEl(false);
    };

    const handleMassFreezingClicked = () => {
        setMassActions("Freeze");
        setOpenMissCancellationModal(true);
        // setAnchorEl(false);
        setelmt(false);
    };

    const cancelsMass = () => {
        const formData = new FormData();
        if (!file[0]) {
            setError("Please select file");
        } else {
            const blockchainToken = getBlockChainToken();
            formData.append("file", file[0]);
            // formData.append("programId", id);
            formData.append("blockchain_token", blockchainToken);
            dispatch(cancelsSCCsWithCsv(formData)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    // dispatch(refetchProgramFields());
                    setMassActions(null);
                    setOpenMissCancellationModal(false);
                    setFile("");
                    setError("");
                    setRefetch((vl) => {
                        return !vl;
                    });
                    // navigate(`/import-result?programId=${id}`);
                }
            });
        }
    };

    const freezesMass = () => {
        const formData = new FormData();
        if (!file[0]) {
            setError("Please select file");
        } else {
            const blockchainToken = getBlockChainToken();
            formData.append("file", file[0]);
            // formData.append("programId", id);
            formData.append("blockchain_token", blockchainToken);
            dispatch(freezesSCCsWithCsv(formData)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    // dispatch(refetchProgramFields());
                    setMassActions(null);
                    setOpenMissCancellationModal(false);
                    setFile("");
                    setError("");
                    setRefetch((valuess) => {
                        return !valuess;
                    });
                    // navigate(`/import-result?programId=${id}`);
                }
            });
        }
    };

    const handleSelectedCancelsClicked = () => {
        const filtersSCCS = selects.filter(
            (select) => select.old_status !== "Cancelled"
        );
        setSelects(filtersSCCS);
        setAnchorEl(false);
        if (filtersSCCS.length > 0) {
            setOpenSelectedSccModal(true);
        } else {
            dispatch(
                showErrorMessage("Please select atleast one SCC to cancel")
            );
        }
    };

    const selectedCancel = () => {
        // if (selects.length < 1) {
        //     setSelectError("Please select any UCO checkbox first");
        // } else {
        dispatch(cancelSelectedSCCs(selects)).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                // dispatch(refetchProgramFields());
                setOpenSelectedSccModal(false);
                setSelects([]);
                // setSelectError(null);
                setRefetch((vl) => {
                    return !vl;
                });
            }
        });
        // }
    };

    const handleSelectedFreezesClicked = () => {
        const filtersSCCS = selects.filter(
            (select) => select.old_status !== "Frozen"
        );
        setSelects(filtersSCCS);
        setelmt(false);
        if (filtersSCCS.length > 0) {
            setOpenSelectedFreezingSccModal(true);
        } else {
            dispatch(
                showErrorMessage("Please select atleast one SCC to freeze")
            );
        }
    };

    const selectedFreeze = () => {
        // if (selects.length < 1) {
        //     setSelectError("Please select any UCO checkbox first");
        // } else {
        dispatch(freezeSelectedSCCs(selects)).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                // dispatch(refetchProgramFields());
                // setOpenSelectedSccModal(false);
                setOpenSelectedFreezingSccModal(false);
                setSelects([]);
                // setSelectError(null);
                setRefetch((vl) => {
                    return !vl;
                });
            }
        });
        // }
    };
    return (
        <>
            <Breadcrumbs
                sm="auto"
                sources={[
                    {
                        label: "registry",
                        link: "",
                    },
                    {
                        label: isMobile ? "SCC" : "smart carbon credit",
                        link: "",
                    },
                ]}
                otherContent={
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "left", sm: "center", md: "left" },
                            marginTop: "1px !important",
                            paddingLeft: "10px",
                            display: { sm: "none" },
                            fontSize: "16px",
                        }}
                    >
                        {SCCSCounts} SCC Token
                    </Typography>
                }
            />

            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick2}
                    endIcon={<KeyboardArrowDownIcon />}
                    disabled={cannot(permissionSlug[0].uco_slug.cancel_UCO)}
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                        display: {
                            xs: "none",
                            sm: "flex",
                        },
                        justifyContent: { sm: "center", md: "flex-end" },
                    }}
                >
                    Freeze SCC
                </Button>
                <Menu
                    sx={{
                        "& .MuiPaper-root": {
                            minWidth: 180,
                        },
                    }}
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={elmt}
                    open={open2}
                    onClose={handleClose2}
                >
                    <MenuItem onClick={() => handleSelectedFreezesClicked()}>
                        Selected SCC
                    </MenuItem>
                    <MenuItem onClick={() => handleMassFreezingClicked()}>
                        Mass freeze
                    </MenuItem>
                </Menu>

                {/*  */}
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    disabled={cannot(permissionSlug[0].scc_slug.cancel_SCC)}
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                        ml: "12px",
                        display: {
                            xs: "none",
                            sm: "flex",
                        },
                        justifyContent: { sm: "center", md: "flex-end" },
                    }}
                >
                    Cancel SCC
                </Button>
                <Menu
                    sx={{
                        "& .MuiPaper-root": {
                            minWidth: 180,
                        },
                    }}
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => handleSelectedCancelsClicked()}
                        disableRipple
                    >
                        Selected SCC
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleMassCancellationClicked()}
                        disableRipple
                    >
                        Mass cancellation
                    </MenuItem>
                </Menu>
            </Grid>
            {/* <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    justifyContent: { sm: "center", md: "flex-end" },
                    pt: "2px !important",
                    gap: "28px",
                }}
            >
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    disabled={cannot(permissionSlug[0].scc_slug.cancel_SCC)}
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                        display: {
                            xs: "none",
                            sm: "flex",
                        },
                        justifyContent: { sm: "center", md: "flex-end" },
                    }}
                >
                    Cancel SCC
                </Button>
                <Menu
                    sx={{
                        "& .MuiPaper-root": {
                            minWidth: 180,
                        },
                    }}
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => setOpenSelectedSccModal(true)}
                        disableRipple
                    >
                        Selected SCC
                    </MenuItem>
                    <MenuItem
                        onClick={() => setOpenMissCancellationModal(true)}
                        disableRipple
                    >
                        Mass cancellation
                    </MenuItem>
                </Menu>
            </Grid> */}
            <Modal
                title={
                    <Typography variant="h2">
                        Cancellation of selected SCC
                    </Typography>
                }
                body={
                    <>
                        <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                            <Typography sx={{ mb: "25px" }}>
                                Are you sure to cancel those selected SCC ?
                            </Typography>
                        </Typography>
                    </>
                }
                footer={
                    <>
                        {cancellingSCCs && (
                            <BlockchainProcessingModalsMessages />
                        )}
                        <Stack
                            direction="row"
                            spacing={1}
                            mt="84px"
                            justifyContent="center"
                        >
                            <Button
                                variant="outlined"
                                disabled={cancellingSCCs}
                                onClick={() => setOpenSelectedSccModal(false)}
                                sx={{ fontWeight: "500" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disabled={cancellingSCCs}
                                onClick={() => selectedCancel()}
                                color="buttonPrimary"
                            >
                                {!cancellingSCCs ? "Confirm" : "Processing"}
                            </Button>
                        </Stack>
                    </>
                }
                open={OpenSelectedSccModal}
                // close={() => setOpenSelectedSccModal(false)}
            />
            <Modal
                title={
                    <Typography variant="h2">
                        SCC{" "}
                        {massActions === "Cancel" ? "cancellation" : "freezing"}{" "}
                        batch import
                    </Typography>
                }
                body={
                    <>
                        <Typography id="modal-modal-description" sx={{ mt: 5 }}>
                            <Typography sx={{ lineHeight: "18px" }}>
                                {massActions === "Cancel" ? "Cancel" : "Freeze"}{" "}
                                SCC by using .csv or .xls file.
                            </Typography>
                            <Typography
                                sx={{
                                    lineHeight: "18px",
                                    "& a": {
                                        color: "#5282B6",
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                Template File:{" "}
                                {/* <Link href="#" color="#5282B6">
                                    cancel_scc_file.csv
                                </Link> */}
                                <a
                                    href={
                                        massActions === "Cancel"
                                            ? csvFieldsFile
                                            : csvFreezesFile
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    download="csv file"
                                    style={{ color: "#5282B6" }}
                                >
                                    {massActions === "Cancel"
                                        ? "cancel_scc_file.csv"
                                        : "freeze_scc_file.csv"}
                                </a>
                            </Typography>
                        </Typography>
                        <Box sx={{ margin: "35px auto" }}>
                            <CustomDropzone
                                onChange={(res) => setFile(res)}
                                files={file}
                                content={
                                    <>
                                        {file && file[0]?.name ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",

                                                    color: "rgb(63, 208, 201)",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        maxWidth: "200px",
                                                        fontSize: "15px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                >
                                                    {file[0].name}
                                                </span>
                                                <CloseIcon
                                                    style={{
                                                        fontSize: "20px",
                                                        marginLeft: "5px",
                                                        color: "grey",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={
                                                        handleCloseValueFile
                                                    }
                                                />
                                            </Box>
                                        ) : (
                                            <>
                                                <Box
                                                    component="p"
                                                    sx={{ mt: 0 }}
                                                >
                                                    Drop your .csv file here{" "}
                                                    <br />
                                                    or upload it from your
                                                    computer
                                                </Box>
                                                <Button
                                                    sx={{
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                        border: "1px solid #000",
                                                        borderRadius: "20px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    <IconFileupload />
                                                    <Box
                                                        component="span"
                                                        sx={{ ml: "10px" }}
                                                    >
                                                        {" "}
                                                        Upload
                                                    </Box>
                                                </Button>
                                                <Box
                                                    component="p"
                                                    sx={{ color: "red" }}
                                                >
                                                    {error}
                                                </Box>
                                            </>
                                        )}
                                    </>
                                }
                            />
                        </Box>
                    </>
                }
                footer={
                    <Stack
                        direction="row"
                        spacing={1}
                        mt="44px"
                        justifyContent="flex-end"
                    >
                        <Button
                            variant="outlined"
                            disabled={cancellingSCCs || freezingSCCs}
                            onClick={() => handleCancels()}
                            sx={{ fontWeight: "500" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            disabled={cancellingSCCs || freezingSCCs}
                            onClick={() =>
                                massActions === "Cancel"
                                    ? cancelsMass()
                                    : freezesMass()
                            }
                            color="buttonPrimary"
                        >
                            {!cancellingSCCs && !freezingSCCs
                                ? "Confirm"
                                : "Processing"}
                        </Button>
                    </Stack>
                }
                open={OpenMissCancellationModal}
                close={() => handleCancels()}
            />

            <Modal
                title={
                    <Typography variant="h2">
                        Freezing of selected SCC
                    </Typography>
                }
                body={
                    <>
                        <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                            <Typography sx={{ mb: "25px" }}>
                                Are you sure to freeze those selected SCC ?
                            </Typography>
                        </Typography>
                    </>
                }
                footer={
                    <>
                        {freezingSCCs && <BlockchainProcessingModalsMessages />}
                        <Stack
                            direction="row"
                            spacing={1}
                            mt="84px"
                            justifyContent="center"
                        >
                            <Button
                                variant="outlined"
                                disabled={freezingSCCs}
                                onClick={() =>
                                    setOpenSelectedFreezingSccModal(false)
                                }
                                sx={{ fontWeight: "500" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disabled={freezingSCCs}
                                onClick={() => selectedFreeze()}
                                color="buttonPrimary"
                            >
                                {!freezingSCCs ? "Confirm" : "Processing"}
                            </Button>
                        </Stack>
                    </>
                }
                open={OpenSelectedFreezingSccModal}
                // close={() => setOpenSelectedSccModal(false)}
            />
        </>
    );
}
