import Dropzone from "react-dropzone";
import { Box } from "@mui/material";

export default function CustomDropzone({ content, onChange }) {
    return (
        <Box component="section">
            <Dropzone
                onDrop={(acceptedFiles) => onChange(acceptedFiles)}
                accept=".csv"
                type="file"
            >
                {({ getRootProps, getInputProps }) => (
                    <Box
                        component="div"
                        sx={{
                            background: "#F5F5F5",
                            borderRadius: "10px",
                            padding: { xs: "20px", md: "40px" },
                            border: "1px dashed #666",
                            textAlign: "center",
                            lineHeight: "22px",
                            fontWeight: "600",
                            maxWidth: "380px",
                            minHeight: "182px",
                            margin: "15px auto",
                            color: "#000",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div {...getRootProps()}>
                            <input
                                {...getInputProps()}
                                accept=".csv"
                                type="file"
                            />
                            {content}
                        </div>
                    </Box>
                )}
            </Dropzone>
        </Box>
    );
}
