import Layout from "Layout";
import { useEffect } from "react";
import CustomTable from "common/CustomTable";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "common/Breadcrumb";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { fetchRoles } from "redux/rolesSlice";
import { useDispatch, useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    minWidth: "91px",
    fontSize: "14px",
}));
const headCells = [
    {
        id: "id",
        label: "ID",
    },
    {
        id: "role",
        label: "Role",
    },
    {
        id: "action",
        label: "Action",
    },
];

const Roles = () => {
    const dispatch = useDispatch();
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].roles_slugs.list_roles),
        unAuthUserRedirectTo: "/401",
    };
    useEffect(() => {
        if (can(permissionSlug[0].roles_slugs.list_roles)) {
            dispatch(fetchRoles());
        }
    }, [can(permissionSlug[0].roles_slugs.list_roles)]);

    const { roles, isLoading } = useSelector((state) => state.roles);
    const renderActionButtons = (item) => (
        <TableCell>
            <Link to={`/roles/manage-permissions/${item.id}`}>
                <StyledChip
                    label={"Edit"}
                    sx={{
                        backgroundColor: "#52B686",
                        color: "primary.white",
                        "&:hover": {
                            backgroundColor: "#3D7C5B",
                        },
                    }}
                />
            </Link>
        </TableCell>
    );

    const sanitizedTableData =
        roles?.data?.map((item) => ({
            id: item.id,
            name: getSanitizedRoleName(item.name),
            action: renderActionButtons(item),
        })) || [];

    function getSanitizedRoleName(roleName) {
        const finalRoles = {
            ["Admin"]: "Admin",
            ["Principal"]: "Developer / Prinipal",
            ["Carbon Manager"]: "Developer / Carbon Manager",
            ["Other User"]: "Developer / Other User",
            ["Trader User"]: "Trader / Trader User",
            ["Corporate User"]: "Corporation / Corporate User",
        };
        return finalRoles[roleName];
        // switch (roleName) {
        //     case "Admin":
        //         return roleName;
        //     case "Principal":
        //     case "Carbon Manager":
        //     case "Other User":
        //         return "Developer / " + roleName;
        //     case "Trader User":
        //         return "Trader / " + roleName;
        //     case "Corporate User":
        //         return "Corporation / " + roleName;
        //     default:
        //         return roleName;
        // }
    }

    return (
        <Box sx={{ mt: "30px" }}>
            <Layout>
                <CheckpageAuth auth={routeAccess}>
                    <Grid
                        container
                        rowSpacing={{ md: 0, xs: "15px" }}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb="10px"
                    >
                        <Breadcrumbs
                            sources={[
                                {
                                    label: "roles",
                                    link: "",
                                },
                                {
                                    label: "all roles",
                                    link: "",
                                },
                            ]}
                        />

                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    md: "flex-end",
                                },
                                gap: { xs: "18px", lg: "28px" },
                            }}
                        >
                            {/* <Button
                            //         onClick={() => navigate("")}
                            variant="contained"
                            color="buttonPrimary"
                            sx={{
                                my: {
                                    xs: "5px",
                                    md: "0",
                                    textTransform: "none",
                                },
                            }}
                        >
                            Create a Role
                        </Button> */}
                        </Grid>
                    </Grid>
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        {"Roles"}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        ></Box>{" "}
                    </Typography>
                    <CustomTable
                        headCells={headCells}
                        rows={sanitizedTableData}
                        loadingList={isLoading}
                        showPaginationControls={false}
                        noDataFound="Roles Not found"
                    />
                </CheckpageAuth>
            </Layout>
        </Box>
    );
};

export default Roles;
