import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

const AntTabs = styled(Tabs)({
    minHeight: "38px",
    margin: "0",
    border: "1px solid #02353C",
    borderRadius: "30px",
    "& .MuiTabs-indicator": {
        backgroundColor: "red",
        display: "none",
    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        minHeight: "38px",
        padding: "9px 25px",
        borderRight: "1px solid #02353C",
        maxWidth: "initial",
        fontSize: "16px",
        fontWeight: "600",
        "@media (max-width:1300px)": {
            "&.MuiButtonBase-root": {
                padding: "9px 15px",
            },
        },
        "@media (max-width:1100px)": {
            "&.MuiButtonBase-root": {
                padding: "9px 20px",
            },
        },

        "@media (max-width:767px)": {
            "&.MuiButtonBase-root": {
                padding: "9px 13px",
            },
        },

        "@media (max-width:600px)": {
            "&.MuiButtonBase-root": {
                padding: "9px 10px",
            },
        },

        "&:last-child": {
            borderRight: 0,
        },
        [theme.breakpoints.down("lg")]: {
            padding: "9px 10px",
            borderRight: "1px solid #02353C",
            maxWidth: "initial",
            fontSize: "14px",
        },
        color: "#02353C",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#02353C",
        },
        "&.Mui-selected": {
            color: "#fff",
            backgroundColor: "#02353C",
        },
        "&.Mui-focusVisible": {
            color: "#fff",
            backgroundColor: "#02353C",
        },
    })
);

export default function CustomTabs({ tabItem, onTabChange }) {
    let { pathname } = useLocation();
    const activeTabIndex = tabItem.findIndex((res) => res.route === pathname);
    const [value, setValue] = React.useState(activeTabIndex);

    const handleChange = (e, newV) => {
        setValue(newV);
    };
    React.useEffect(() => {
        setValue(activeTabIndex);
    }, [pathname]);
    return (
        <Box sx={{ width: "100%", mt: { xs: "15px", md: "0" } }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "end" },
                }}
            >
                <AntTabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                >
                    {tabItem.map((res, index) => {
                        return (
                            <AntTab
                                onClick={() => onTabChange(res.route)}
                                key={index}
                                label={res.name}
                                disabled={res.disableTabs}
                            />
                        );
                    })}
                </AntTabs>
            </Box>
        </Box>
    );
}
