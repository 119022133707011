// import { useState } from "react";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "common/Breadcrumb";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import { Input, Skeleton } from "@mui/material";
// import TableRow from "@mui/material/TableRow";
// import Map from "assets/images/registry_detail.png";
// import TableCell from "@mui/material/TableCell";
// import CustomTable from "common/CustomTable";

// import OpenPreviousPage from "common/OpenPreviousPage";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
// import CancelButton from "common/CancelButton";
// import { Button } from "@mui/material";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchRetirementDetails } from "redux/registrySlices";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import RetirementQuantity from "components/registry/collection/RetirementQuantity";
import OpenPreviousPage from "common/OpenPreviousPage";

// const headCells = [
//     {
//         id: "vintage",
//         numeric: true,
//         label: "Vintage",
//     },
//     {
//         id: "quantity_available",
//         numeric: true,
//         label: "Quantity available",
//     },
//     {
//         id: "quantity_to_retire",
//         numeric: true,
//         label: "Quantity to retire",
//     },
// ];

// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     // createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//     createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//     // createData("Eclair", 262, 16.0, 24, 6.0),
//     // createData("Cupcake", 305, 3.7, 67, 4.3),
//     // createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

const CollectionSccDetails = () => {
    const { can } = useAccessPage();
    const dispatch = useDispatch();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].scc_slug.SCC_details),
        unAuthUserRedirectTo: "/401",
    };
    const { id } = useParams();
    const { retirementDetails, isLoading } = useSelector(
        (state) => state.registery,
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchRetirementDetails(id));
    }, [id]);

    const {
        program_id,
        program_name,
        program_code,
        program_type,
        program_developer,
        program_external_id,
        field_quantity = [],
        program_unit,
        program_region,
        program_country,
    } = retirementDetails;

    const programDetails = [
        {
            title: "ID program",
            info: program_id,
        },
        {
            title: "Program name",
            info: program_name,
        },
        {
            title: "Program code",
            info: program_code,
        },
        {
            title: "Type",
            info: program_type,
        },
        {
            title: "Lead developer",
            info: program_developer,
        },
        {
            title: "Program external ID",
            info: program_external_id,
        },
        {
            title: "Region",
            info: program_region,
        },
        {
            title: "Country",
            info: program_country,
        },
    ];

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", mb: "10px" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Breadcrumbs
                            sources={[
                                {
                                    label: "registry",
                                    link: "/registry/collections",
                                    canNavigate: true,
                                },
                                {
                                    label: "retirement",
                                    link: "",
                                },
                            ]}
                        />
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ mt: 0 }}
                    >
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">
                                <OpenPreviousPage />
                                Retirement items{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: "normal",
                                        // borderBottom: "1px solid #000",
                                        display: "inline-block",
                                        // "& a": {
                                        //     color: "inherit",
                                        // },
                                        // "&:hover": {
                                        //     color: "grey",
                                        //     cursor: "pointer",
                                        // },
                                    }}
                                >
                                    {` on program `}
                                </Box>
                                {"  "}
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: "normal",
                                        borderBottom: "1px solid #000",
                                        display: "inline-block",
                                        "& a": {
                                            color: "inherit",
                                        },
                                        "&:hover": {
                                            color: "grey",
                                            cursor: "pointer",
                                        },
                                    }}
                                >
                                    <Link
                                        to={`/program/${id}/detail`}
                                        sx={{
                                            display: "inline-block",
                                        }}
                                    >
                                        {`  ${program_code}`}
                                    </Link>
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    {" "}
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ mt: 0, paddingLeft: "20px" }}
                    >
                        <Grid item xs={12} md={6}>
                            Please fill the quantity per year you want to
                            retire.
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        sx={{ mt: 0 }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                pt: "2px !important",
                                mt: "20px",
                                gap: "50px",
                            }}
                            display="flex"
                            flexDirection="column"
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Program details
                                </Typography>
                                {programDetails.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: {
                                                    xs: "100%",
                                                    sm: "150px",
                                                    md: "180px",
                                                },
                                                minWidth: {
                                                    xs: "100%",
                                                    sm: "150px",
                                                    md: "180px",
                                                },
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: {
                                                    xs: "100%",
                                                    sm: "calc(100% - 150px)",
                                                    md: "calc(100% - 180px)",
                                                },
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize:
                                                                    "1rem",
                                                            }}
                                                        />
                                                    ) : (
                                                        res.info
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                        {/* <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ pt: "2px !important", gap: "23px" }}
                            display="flex"
                            flexDirection="column"
                        >
                            <Box>
                                <Box
                                    component="img"
                                    alt="Map"
                                    src={Map}
                                    sx={{
                                        maxWidth: 1,
                                        mt: "20px",
                                    }}
                                />
                            </Box>
                        </Grid> */}
                    </Grid>
                    <RetirementQuantity
                        field_quantity={field_quantity}
                        program_unit={program_unit}
                    />
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default CollectionSccDetails;
