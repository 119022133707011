import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
        <TableHead>
            <TableRow>
                {props.headCells.map((headCell, index) => (
                    <TableCell
                        sx={{
                            fontWeight: "700",
                            fontFamily: "'Istok Web', sans-serif !important",
                            fontSize: "14px",
                            borderBottom: "2px solid #ccc",
                            padding: "8px 12px",
                            color: "#000",
                            whiteSpace: "nowrap",
                            background: headCell?.background,
                            maxWidth: headCell?.maxWidth,
                            minWidth: headCell?.minWidth,
                            ...(headCell.id === "harvest" ||
                            headCell.id === "area"
                                ? {
                                      paddingLeft: "20px",
                                  }
                                : {}),
                        }}
                        key={index}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
    headCells,
    rows,
    lastcellstyle,
    exportbutton,
    loadingList,
    setQuery,
    query,
    totalRowCount,
    noDataFound,
    importResult = false,
    showPaginationControls = true,
    type,
}) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const totalPage = Math.ceil(totalRowCount / query?.limit);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };
    // Avoid a layout jump when reaching the last page with empty rows.

    const visibleRows = React.useMemo(
        () => stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, query, rows]
    );

    const handleLimitChange = (event) => {
        const newLimit = event.target.value;
        if (newLimit === totalRowCount) {
            setQuery((prev) => ({
                ...prev,
                page: 1,
                limit: newLimit,
            }));
        } else {
            const newTotalPage = Math.ceil(totalRowCount / newLimit);
            const newPage = Math.min(query.page, newTotalPage);

            setQuery((prev) => ({
                ...prev,
                page: newPage,
                limit: newLimit,
            }));
        }
    };
    const handlePreviousPageChange = () => {
        if (query?.page > 1) {
            setQuery((prev) => ({ ...prev, page: prev.page - 1 }));
        }
    };
    const handleNextPageChange = () => {
        if (query?.page < totalPage) {
            setQuery((prev) => ({
                ...prev,
                page: prev.page + 1,
            }));
        }
    };
    const pageLimits = [10, 25, 50, 100, 250];
    return (
        <Box sx={{ width: "100%", position: "relative" }}>
            <Paper sx={{ width: "100%", mb: 2, boxShadow: 0 }}>
                <TableContainer sx={{ mt: 1, minHeight: "50px" }}>
                    <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            headCells={headCells}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row.name)
                                        }
                                        tabIndex={-1}
                                        key={index}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {Object.keys(row).map((res, index) =>
                                            typeof row[res] === "object" ? (
                                                row[res]
                                            ) : (
                                                <TableCell
                                                    lastcellstyle={
                                                        lastcellstyle
                                                    }
                                                    key={index}
                                                    sx={{
                                                        ...((res ===
                                                            "harvest" && {
                                                            paddingLeft: "30px",
                                                        }) ||
                                                            (res === "area" && {
                                                                paddingLeft:
                                                                    "25px",
                                                            })),
                                                    }}
                                                >
                                                    {row[res]}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {loadingList && (
                    <Box
                        style={{
                            textAlign: "center",
                            color: "#02353C",
                            fontSize: "18px",
                            fontWeight: "bold",
                            position: "absolute",
                            top: "45%",
                            left: "45%",
                            transform: "translate(-50%, -50%)",
                            // backgroundImage: "linear-gradient(40deg, #02353C -50%, #2EAF7D 40%)",
                            padding: "7px 20px",
                            borderRadius: "8px",
                        }}
                    >
                        Processing...
                    </Box>
                )}
                {visibleRows?.length === 0 && !loadingList && (
                    <Box
                        style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "18px",
                            fontWeight: "bold",
                        }}
                    >
                        {noDataFound ? noDataFound : "No Data Found ."}
                    </Box>
                )}
                {visibleRows?.length === 0 && !loadingList
                    ? null
                    : showPaginationControls && (
                          <Box
                              sx={{
                                  display: "flex",
                                  flexFlow: {
                                      xs: "column-reverse",
                                      sm: "row-reverse",
                                  },
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  justifyContent: { xs: "center", sm: "end" },
                                  mt: { xs: "25px", sm: "15px" },
                              }}
                          >
                              {importResult || type === "collection" ? (
                                  ""
                              ) : (
                                  <React.Fragment>
                                      <Box
                                          sx={{
                                              display: "flex",
                                          }}
                                      >
                                          <ArrowBackIosRoundedIcon
                                              onClick={handlePreviousPageChange}
                                              sx={{
                                                  color:
                                                      query?.page > 1
                                                          ? "black"
                                                          : "grey",
                                                  width: "15px",
                                                  marginRight: "18px",
                                                  cursor:
                                                      query?.page > 1
                                                          ? "pointer"
                                                          : "not-allowed",
                                              }}
                                          />
                                          <ArrowForwardIosRoundedIcon
                                              onClick={handleNextPageChange}
                                              sx={{
                                                  color:
                                                      query?.page < totalPage
                                                          ? "black"
                                                          : "grey",
                                                  width: "15px",

                                                  cursor:
                                                      query?.page < totalPage
                                                          ? "pointer"
                                                          : "not-allowed",
                                              }}
                                          />
                                      </Box>
                                      <Box
                                          sx={{
                                              display: "flex",
                                              flexDirection: {
                                                  xl: "row-reverse",
                                                  xs: "row-reverse",
                                                  sm: "row-reverse",
                                              },
                                              alignItems: "center",
                                          }}
                                      >
                                          <Box
                                              sx={{
                                                  marginLeft: "12px",
                                                  marginRight: "12px",
                                                  flexDirection: { xs: "row" },
                                              }}
                                          >
                                              {" "}
                                              {`Page ${query?.page} of  ${totalPage} `}
                                          </Box>

                                          <FormControl
                                              sx={{ m: 1, minWidth: 70 }}
                                          >
                                              <Select
                                                  value={
                                                      totalRowCount <
                                                      query?.limit
                                                          ? totalRowCount
                                                          : query?.limit
                                                  }
                                                  onChange={handleLimitChange}
                                                  displayEmpty
                                                  inputProps={{
                                                      "aria-label":
                                                          "Without label",
                                                  }}
                                                  sx={{ height: "40px" }}
                                              >
                                                  {pageLimits.map(
                                                      (limit) =>
                                                          limit <
                                                              totalRowCount && (
                                                              <MenuItem
                                                                  value={limit}
                                                                  key={limit}
                                                              >
                                                                  {limit}
                                                              </MenuItem>
                                                          )
                                                  )}
                                                  <MenuItem
                                                      value={totalRowCount}
                                                  >
                                                      All
                                                  </MenuItem>
                                              </Select>
                                          </FormControl>

                                          <p style={{ color: "black" }}>
                                              Items per page :
                                          </p>
                                      </Box>
                                  </React.Fragment>
                              )}

                              {exportbutton}
                          </Box>
                      )}
            </Paper>
        </Box>
    );
}
