import CustomTable from "common/CustomTable";
import permissionSlug from "assets/data/permissionSlug.json";
import UnderlineText from "common/UnderlineText";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Searchbar from "common/Searchbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ConvertJsonToCsv from "common/ExportJson";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import {
    fetchAllProgramFields,
    refetchProgramFields,
} from "redux/programSlice";
import sanitizedString from "util/sanitizedString";
import sanitizedDigits from "util/sanitizedDigits";
import { useAccessPage } from "customHooks/useAccess";
import CheckpageAuth from "auth/CheckPageAccess";
const headCells = [
    {
        id: "farmer",
        numeric: false,
        label: "Farmer",
    },
    {
        id: "field",
        numeric: true,
        label: "Field",
    },
    {
        id: "location",
        numeric: true,
        label: "Location",
    },
    {
        id: "harvest",
        numeric: true,
        label: "Harvest",
    },
    {
        id: "landuse",
        numeric: true,
        label: "Land-use",
    },
    {
        id: "area",
        numeric: true,
        label: "Area (ha)",
    },
    {
        id: "ucd",
        numeric: true,
        label: "UCO",
    },
    {
        id: "scc",
        numeric: true,
        label: "SCC",
    },
    {
        id: "value",
        numeric: true,
        label: "Value",
    },
];

export default function Detail() {
    const { id } = useParams();
    const initialQuery = useRef({ page: 1, limit: 10, search: "" });
    const [query, setQuery] = useState(initialQuery.current);
    const dispatch = useDispatch();
    const { fields, isLoading, isLoaded, fieldCount, previousProgramId } =
        useSelector((state) => state.program);
    useEffect(() => {
        if (isLoaded === false) {
            dispatch(fetchAllProgramFields({ query, id })).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    initialQuery.current = query;
                }
            });
        }
    }, [isLoaded]);
    useEffect(() => {
        if (
            initialQuery.current.page !== query.page ||
            initialQuery.current.limit !== query.limit ||
            initialQuery.current.search !== query.search ||
            Number(id) !== Number(previousProgramId)
        ) {
            dispatch(refetchProgramFields());
        }
    }, [query]);

    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].fields_slug.list_fields),
        unAuthUserRedirectTo: "/401",
    };

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };

    const sanitizedfieldData =
        fields &&
        fields?.map((item) => ({
            farmer: (
                <TableCell>
                    <Link to={`/farmer/${item?.project?.farmer?.id}/details`}>
                        <UnderlineText>
                            {item.project &&
                                item.project.farmer &&
                                capitalizeFirstLetter(
                                    item.project.farmer.farmerCompanyName
                                )}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            field: (
                <TableCell>
                    <Link to={`/field/${item?.id}/details`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(item?.fieldName)}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            location: `${sanitizedString(
                `${item.fieldCitycodeInsee} ${item?.fieldCityInsee}`
            )}`,
            harvest: item?.fieldHarvestYear,
            landuse: item?.fieldLandUse,
            area: sanitizedDigits(item?.fieldshapefileArea),
            uco: (
                <TableCell>
                    {item?.fieldUcoTokenId ? (
                        can(permissionSlug[0].uco_slug.UCO_details) ? (
                            <Link
                                to={`/registry/uco/${item.project?.program?.program_uco_address}/${item.id}/${item.project?.program?.id}/details`}
                            >
                                {/* truncate uco  values pending */}
                                <UnderlineText>
                                    {item?.fieldUcoTokenId}
                                </UnderlineText>
                            </Link>
                        ) : (
                            item?.fieldUcoTokenId
                        )
                    ) : (
                        "In Progress"
                    )}
                </TableCell>
            ),
            scc: (
                <TableCell>
                    {item?.fieldSccTokenId ? (
                        can(permissionSlug[0].scc_slug.SCC_details) ? (
                            <Link
                                to={`/registry/scc/${item.project?.program?.program_scc_address}/${item.id}/${item.project?.program?.id}/details`}
                            >
                                {/* truncate scc  values pending */}
                                <UnderlineText>
                                    {item?.fieldSccTokenId}
                                </UnderlineText>
                            </Link>
                        ) : (
                            item?.fieldSccTokenId
                        )
                    ) : (
                        "-"
                    )}
                </TableCell>
            ),
            value: item?.fieldValue
                ? `${sanitizedDigits(item?.fieldValue)} ${
                      item.project.program.program_unit
                  }`
                : "-",
        }));
    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        flexWrap: { xs: "wrap", sm: "inherit" },
                        justifyContent: { xs: "center", sm: "space-between" },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="18px"
                        sx={{ width: "100%", md: "auto" }}
                    >
                        <Searchbar
                            placeholdertext="Search Fields, Land-use, Location, ..."
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
                <CustomTable
                    headCells={headCells}
                    rows={sanitizedfieldData ? sanitizedfieldData : []}
                    loadingList={isLoading}
                    query={query}
                    setQuery={setQuery}
                    totalRowCount={fieldCount}
                    exportbutton={
                        can(permissionSlug[0].fields_slug.export_fields) && (
                            <ConvertJsonToCsv
                                jsonData={fields?.map((item) => {
                                    return {
                                        Farmer:
                                            (item.project.farmer &&
                                                item.project.farmer
                                                    .farmerFirstname +
                                                    "" +
                                                    item.project.farmer
                                                        .farmerLastname) ||
                                            "",
                                        Field: item.fieldName,
                                        Location: item.fieldCityInsee,
                                        Harvest: item.fieldHarvestYear,
                                        "Land-use": item.fieldLandUse,
                                        area: item.fieldArea,
                                        Value: item.fieldValue,
                                    };
                                })}
                            />
                        )
                    }
                    noDataFound="No fields found"
                />
            </CheckpageAuth>
        </>
    );
}
