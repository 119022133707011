import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

export default function DetailsTitle() {
    const { id } = useParams();
    return (
        <>
            <Grid item xs={12} md={7}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    Program #{id}
                </Typography>
            </Grid>
        </>
    );
}
