import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import UserAddFormContainer from "components/admin/user/UserAddForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getCompanyDetaill } from "redux/companiesSlice";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const ModifyUser = () => {
    const { companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
        }),
        shallowEqual
    );
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].users_slug.create_user),
        unAuthUserRedirectTo: "/401",
    };
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        if (can(permissionSlug[0].users_slug.create_user)) {
            if (!companylist) {
                dispatch(getCompanyDetaill(id));
            }
        }
    }, [companylist]);

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb="10px"
                >
                    <Breadcrumbs
                        sources={[
                            {
                                label: "admin",
                                link: "/companies",
                                canNavigate: true,
                            },
                            {
                                label: "company",
                                link: "/companies",
                                canNavigate: true,
                            },
                            {
                                label: companylist?.companyName,
                                link: `/companies/detail/${id}`,
                                canNavigate: true,
                            },
                            {
                                label: "add a user",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "center",
                                sm: "flex-end",
                            },
                            pt: "2px !important",
                        }}
                    ></Grid>
                </Grid>

                <Typography variant="h2">
                    Add a user on Company #{companylist?.companyName}
                </Typography>
                <Typography variant="body1" mt={1} mb={3}>
                    Please fill the following form to add a new user and attach
                    it to a company.
                </Typography>

                <UserAddFormContainer companyData={companylist} />
            </CheckpageAuth>
        </Layout>
    );
};

export default ModifyUser;
