import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography, Button, Box } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import Modal from "common/Modal";
import CustomDropzone from "common/CustomDropzone";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { refetchProject, uploadNewProjects } from "redux/projectSlice";
import CloseIcon from "@mui/icons-material/Close";
import cscProjectFile from "assets/data/upload_projects .csv";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { getProgram } from "redux/programSlice";
export default function ProjectHeader() {
    const { id } = useParams();
    const programNumber = id;
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState();
    const [file, setFile] = useState([]);
    const [error, setError] = useState();
    const dispatch = useDispatch();
    const { programDetails } = useSelector((state) => state.program);
    // const { uploadError } = useSelector((state) => state.project);
    const { isUploadings } = useSelector((state) => state.project);
    // const errors = uploadError?.errors || [];
    // const success = uploadError?.successfulInserts || [];
    // console.log(errors, success);
    const { can, cannot } = useAccessPage();
    useEffect(() => {
        dispatch(getProgram(id));
    }, []);

    const handleUploadFile = () => {
        if (can(permissionSlug[0].project_slug.upload_projects)) {
            const formData = new FormData();
            if (!file[0]) {
                setError("Please select file");
            } else {
                formData.append("file", file[0]);
                dispatch(uploadNewProjects(formData)).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        dispatch(refetchProject());
                        setOpenModal(false);
                        setFile("");
                        setError("");
                    }
                });
            }
        }
    };
    const handleCancel = (event) => {
        event.stopPropagation();
        setFile("");
    };
    const handleCancelUpload = () => {
        setOpenModal(false);
        setFile("");
        setError("");
    };
    if (!programDetails?.program_status) {
        return null;
    }
    return (
        <>
            <Breadcrumbs
                sources={[
                    {
                        label: "programs",
                        link: "/programs",
                        canNavigate: true,
                    },
                    {
                        label: `program #${id}`,
                        link: `/program/${id}/detail`,
                        canNavigate: true,
                    },
                    {
                        label: "all projects",
                        link: "",
                    },
                ]}
            />
            <Grid
                item
                xs={12}
                md="auto"
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                <Button
                    onClick={() => setOpenModal(true)}
                    disabled={
                        cannot(
                            permissionSlug[0].project_slug.upload_projects
                        ) || programDetails?.program_status !== "registered"
                    }
                    variant="contained"
                    color="buttonPrimary"
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                        mr: "10px",
                    }}
                >
                    Upload new projects
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        cannot(permissionSlug[0].project_slug.create_project) ||
                        programDetails?.program_status !== "registered"
                    }
                    onClick={() => navigate(`/add-project/${id}`)}
                    color="buttonPrimary"
                    sx={{ my: { xs: "5px", md: "0", textTransform: "none" } }}
                >
                    Add a new project
                </Button>
            </Grid>
            <Modal
                title={
                    <Typography variant="h2">
                        Add projects to{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                            }}
                        >
                            Program #{programNumber}
                        </Box>
                    </Typography>
                }
                body={
                    <>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Typography sx={{ mb: "25px" }}>
                                Upload new projects (farmers’ contracts) by
                                using the .csv import, including farmers
                                références (if not already exist in the
                                database, they will be create) Please use our
                                template file:{" "}
                                <a
                                    href={cscProjectFile}
                                    target="_blank"
                                    rel="noreferrer"
                                    download="csv file"
                                    style={{ color: "#5282B6" }}
                                >
                                    upload_projects.csv
                                </a>
                            </Typography>

                            <Typography sx={{ mt: "15px" }}>
                                <Box component="b">Note:</Box> If farmers had
                                been already created, farmers’ information are
                                not mandatory.
                            </Typography>
                            <Typography sx={{ mt: "5px" }}>
                                <Box component="b">Note:</Box> Use
                                projectIdFarmer displayed by FarmRegistry for
                                farmers already created
                            </Typography>
                        </Typography>
                        <CustomDropzone
                            onChange={(res) => setFile(res)}
                            files={file}
                            content={
                                <>
                                    {file && file[0]?.name ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",

                                                color: "rgb(63, 208, 201)",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    maxWidth: "200px",
                                                    fontSize: "15px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {file[0].name}
                                            </span>
                                            <CloseIcon
                                                style={{
                                                    fontSize: "20px",
                                                    marginLeft: "5px",
                                                    color: "grey",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleCancel}
                                            />
                                        </Box>
                                    ) : (
                                        <>
                                            <Box component="p" sx={{ mt: 0 }}>
                                                Drop your .csv file here <br />
                                                or upload it from your computer
                                            </Box>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#f5f5f5",
                                                    border: "1px solid #000",
                                                    borderRadius: "20px",
                                                    color: "#000000",
                                                }}
                                                disabled={cannot(
                                                    permissionSlug[0]
                                                        .project_slug
                                                        .upload_projects
                                                )}
                                            >
                                                <IconFileupload />
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        ml: "10px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    {" "}
                                                    Upload
                                                </Box>
                                            </Button>
                                            <Box
                                                component="p"
                                                sx={{ color: "red" }}
                                            >
                                                {error}
                                            </Box>
                                        </>
                                    )}
                                </>
                            }
                        />
                    </>
                }
                footer={
                    <Stack
                        direction="row"
                        spacing={1}
                        mt="44px"
                        justifyContent="flex-end"
                    >
                        <Button
                            variant="outlined"
                            onClick={handleCancelUpload}
                            sx={{ fontWeight: "500" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="buttonPrimary"
                            disabled={isUploadings}
                            onClick={handleUploadFile}
                        >
                            {isUploadings ? "Processing..." : "Save"}
                        </Button>
                    </Stack>
                }
                open={openModal}
                close={() => setOpenModal(false)}
            />
        </>
    );
}
