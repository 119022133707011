import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage, showSuccessMessage } from "./toastSlice";
import Services from "services";
import { resetStateHander } from "helpers/sanitizedFunction";
import errorHandler from "helpers/errorhandler";

export const fetchAllPrograms = createAsyncThunk(
    "program/fetchAllPrograms",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getAllPrograms(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchMore = createAsyncThunk(
    "program/fetchMore",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getAllPrograms(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createProgram = createAsyncThunk(
    "program/createProgram",
    async (payload, thunkAPI) => {
        try {
            const response = await Services.createProgram(payload);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("Program Created Successfully!")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            // const backendErrorss = Object.values(
            //     error.response.data?.errors || null
            // );
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getProgram = createAsyncThunk(
    "program/getProgram",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getProgramDetails(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const editProgram = createAsyncThunk(
    "program/editProgram",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.editProgram(params);
            thunkAPI.dispatch(
                showSuccessMessage("Program updated successfully!")
            );
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const slaveCompanies = createAsyncThunk(
    "program/slaveCompanies",
    async (params, thunkAPI) => {
        try {
            const response = await Services.slaveCompaniesList(params);
            return response.data.data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(error.message));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const addCompaniesToProgram = createAsyncThunk(
    "program/addCompaniesToProgram",
    async (params, thunkAPI) => {
        try {
            const response = await Services.addProgramsCompanies(params);
            if (response) {
                thunkAPI.dispatch(
                    showSuccessMessage("Company added successfully!")
                );
            }

            return response.data.data;
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage(error.response.data.message || error.message)
            );
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const fetchAllProgramFields = createAsyncThunk(
    "program/fetchAllProgramFields",
    async (params, thunkAPI) => {
        try {
            const response = await Services.getAllfields(params);
            if (response.data.data) {
                return { id: params.id, response: response.data.data };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const leadCompaniesList = createAsyncThunk(
    "program/leadCompaniesList",
    async (params, thunkAPI) => {
        try {
            const response = await Services.leadCompaniesAll();
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
const initialState = {
    programs: [],
    error: null,
    programDetails: {},
    isLoading: false,
    isLoaded: false,
    count: null,
    fetchingMore: false,
    companies: [],
    isCompanyLoading: false,
    fields: [],
    previousProgramId: null,
    fieldCount: null,
    leadCompanies: [],
    isLoadingList: false,
};
export const programSlice = createSlice({
    name: "program",
    initialState,
    reducers: {
        resetProgram: (state) => {
            resetStateHander(state, initialState);
        },
        refetchProgram: (state) => {
            state.isLoaded = false;
        },
        refetchProgramFields: (state) => {
            state.isLoaded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPrograms.pending, (state) => {
                state.isLoading = true;
                state.count = null;
                state.programs = [];
            })
            .addCase(fetchAllPrograms.fulfilled, (state, action) => {
                // Add user to the state array
                state.programs = action.payload.programs;
                state.count = action.payload.count;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(fetchAllPrograms.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createProgram.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createProgram.fulfilled, (state) => {
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(createProgram.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
                state.isLoaded = false;
            })
            .addCase(getProgram.pending, (state) => {
                state.isLoading = true;
                state.programDetails = {};
            })
            .addCase(getProgram.fulfilled, (state, action) => {
                // Add user to the state array
                state.programDetails = action.payload;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(getProgram.rejected, (state) => {
                state.isLoading = false;
                state.isLoaded = false;
            })
            .addCase(editProgram.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editProgram.fulfilled, (state) => {
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(editProgram.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
                state.isLoaded = false;
            })
            .addCase(fetchMore.pending, (state) => {
                state.fetchingMore = true;
            })
            .addCase(fetchMore.fulfilled, (state, action) => {
                // Add user to the state array
                state.programs = [
                    ...state.programs,
                    ...action.payload.programs.filter(
                        (newObj) =>
                            !state.programs.some(
                                (item) => item.id === newObj.id
                            )
                    ),
                ];
                state.count = action.payload.count;
                state.error = null;
                state.fetchingMore = false;
                state.isLoaded = true;
            })
            .addCase(fetchMore.rejected, (state) => {
                state.fetchingMore = false;
            })
            .addCase(slaveCompanies.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.companies = [];
            })
            .addCase(slaveCompanies.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.companies = payload;
                state.error = null;
            })
            .addCase(slaveCompanies.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            })
            .addCase(addCompaniesToProgram.pending, (state) => {
                state.isCompanyLoading = true;
                state.error = null;
            })
            .addCase(addCompaniesToProgram.fulfilled, (state) => {
                state.isCompanyLoading = false;
                // state.companies = payload;
                state.error = null;
            })
            .addCase(addCompaniesToProgram.rejected, (state, { payload }) => {
                state.isCompanyLoading = false;
                showErrorMessage(payload);
            })
            .addCase(fetchAllProgramFields.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.isLoaded = false;
            })
            .addCase(fetchAllProgramFields.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.fieldCount = payload.response.count;
                state.previousProgramId = payload.id;
                state.fields = payload.response.fields;
                state.isLoaded = true;
                state.error = null;
            })
            .addCase(fetchAllProgramFields.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isLoaded = false;
                showErrorMessage(payload);
            })
            .addCase(leadCompaniesList.pending, (state) => {
                state.isLoadingList = true;
                state.error = null;
            })
            .addCase(leadCompaniesList.fulfilled, (state, { payload }) => {
                state.isLoadingList = false;
                state.leadCompanies = payload;
                state.error = null;
            })
            .addCase(leadCompaniesList.rejected, (state, { payload }) => {
                state.isLoadingList = false;
                showErrorMessage(payload);
            });
    },
});
export const { resetProgram, refetchProgram, refetchProgramFields } =
    programSlice.actions;
export default programSlice.reducer;
