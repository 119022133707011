import Breadcrumbs from "common/Breadcrumb";

export default function UserHeader({ companyId }) {
    if (companyId) {
        return (
            <>
                <Breadcrumbs
                    xs="auto"
                    sources={[
                        {
                            label: "admin",
                            link: `/companies`,
                            canNavigate: true,
                        },
                        {
                            label: "company users",
                            link: "",
                        },
                    ]}
                />
            </>
        );
    }
    return (
        <>
            <Breadcrumbs
                xs="auto"
                sources={[
                    {
                        label: "admin",
                        link: "",
                    },
                    {
                        label: "all users",
                        link: "",
                    },
                ]}
            />
        </>
    );
}
