import { Box, Typography } from "@mui/material";
import dotsImagess from "assets/images/dotsLoaders.gif";
function BlockchainProcessingModalsMessages() {
    return (
        <Box sx={{ textAlign: "center" }}>
            <Box
                component="img"
                alt="Map"
                src={dotsImagess}
                sx={{
                    width: "5%",
                    maxWidth: 400,
                    display: "block",
                    margin: "auto",
                }}
            />
            <Typography variant="body1" fontWeight="bold" color="#02353C">
                We are processing your request, it may take a few minutes. Your
                patience is greatly appreciated!
            </Typography>
        </Box>
    );
}

export default BlockchainProcessingModalsMessages;
