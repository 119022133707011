import useAxios from "helpers/useAxios";
import protectedRequest from "helpers/protectedRequest.js";
import { getBlockChainToken } from "util/token";
//func to get programs field
const getAllfields = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(
            `fields/program/all?programId=${paramss.id}&page=${paramss.query.page}&limit=${paramss.query.limit}&search=${paramss.query.search}`
        )
    );
};
//func to get all fields
const getFields = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/all`, {
            params: {
                page: paramss.page,
                limit: paramss.limit,
                search: paramss.search,
                ...(paramss.id && { companyId: paramss.id }),
            },
        })
    );
};
//func to get farmers fields
const getFarmerFields = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(
            `fields/farmer/all?farmerId=${paramss?.id}&page=${paramss.query.page}&limit=${paramss.query.limit}&search=${paramss.query.search}`
        )
    );
};
const uploadCsvFile = async (params) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/upload`, params.formData)
    );
};
const uploadValuesFiles = async (params) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/upload/scc/value`, params)
    );
};
const uploadCsvImportResult = async (params) => {
    const blockchainToken = getBlockChainToken();
    const importsData = {
        ...{ data: params.importResultData?.data },
        ...{ blockchain_token: blockchainToken },
    };
    return await protectedRequest(() =>
        useAxios.post(
            `fields/import/${params.programId}/${params.companyId}`,
            importsData
        )
    );
};
const getFieldDetails = async (id) => {
    return await protectedRequest(() => useAxios.get(`fields/${id}`));
};

const getFieldHistory = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(
            `fields/get/history?fieldCapId=${paramss.id}&fieldHarvestYear=${paramss.year}`
        )
    );
};

const getAllHistory = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/get/all/history?fieldExternalId=${paramss}`)
    );
};

const getHistoryTimelines = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/get/history`, paramss)
    );
};
const deleteImportResultData = async () => {
    return await protectedRequest(() => useAxios.delete("fields/delete/maps"));
};
export {
    getAllfields,
    uploadValuesFiles,
    uploadCsvFile,
    getFields,
    getFarmerFields,
    getFieldDetails,
    getFieldHistory,
    getHistoryTimelines,
    getAllHistory,
    uploadCsvImportResult,
    deleteImportResultData,
};
