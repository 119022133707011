import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UnderlineText from "common/UnderlineText";
import Timeline from "components/field/history/Timeline";
// import { ReactComponent as Blackuploadicon } from "assets/icons/blackuploadicon.svg";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getFieldDetails,
    fetchHistoryTimelines,
    // getFieldHistory,
} from "redux/fieldsSlice";
import { Link, useParams } from "react-router-dom";
import sanitizedDigits from "util/sanitizedDigits";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const History = () => {
    const dispatch = useDispatch();
    const { id: fieldID } = useParams();
    const { fieldsDetails, isLoading } = useSelector(
        (state) => state.field,
        shallowEqual
    );
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].fields_slug.fields_history),
        unAuthUserRedirectTo: "/401",
    };
    useEffect(() => {
        if (
            !fieldsDetails?.id &&
            !isLoading &&
            fieldsDetails?.id !== Number(fieldID)
        ) {
            dispatch(getFieldDetails(fieldID)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    dispatch(
                        fetchHistoryTimelines({
                            companyId: res.payload.program?.company?.id,
                            fieldId: fieldID,
                        })
                    );
                    // setFetched(true);
                }
            });
        }
    }, [fieldID]);

    // const handleYearChanged = (year) => {
    //     dispatch(
    //         getFieldHistory({
    //             id: fieldExternalId,
    //             year: year,
    //         })
    //     );
    // };

    const farmerDetails = fieldsDetails?.project?.farmer || {};
    const programDetails = fieldsDetails?.project?.program || {};

    const {
        project: { projectReferenceExternalId } = {},
        id,
        fieldExternalId,
        fieldCitycodeInsee,
        fieldCityInsee,
        fieldshapefileArea,
        fieldLandUse,
        fieldValue,
        fieldHarvestYear,
        fieldSccTokenId,
        fieldUcoTokenId,
    } = fieldsDetails;

    const {
        id: farmerID,
        farmerCompanyName,
        farmerOfficialId,
        farmerEuCapId,
        farmerCountry,
    } = farmerDetails;

    const {
        program_developer,
        program_code,
        program_name,
        id: program_id,
    } = programDetails;

    const farmer = [
        {
            title: "ID",
            info: farmerID,
        },
        {
            title: "Company name",
            info: farmerCompanyName,
        },
        {
            title: "Official ID",
            info: farmerOfficialId,
        },
        {
            title: "European CAP ID",
            info: farmerEuCapId,
        },
    ];

    const location = [
        {
            title: "Field ID",
            info: id,
        },
        {
            title: "Reference",
            info: fieldExternalId,
        },
        {
            title: "Zip code",
            info: fieldCitycodeInsee,
        },
        {
            title: "City",
            info: fieldCityInsee,
        },
        {
            title: "Country",
            info: farmerCountry,
        },
        {
            title: "Area (ha)",
            info: `${sanitizedDigits(fieldshapefileArea)} ha`,
        },
        // {
        //     title: "Coordinates",
        //     info: <Blackuploadicon />,
        // },
    ];

    const program = [
        {
            title: "Project dev.",
            info: program_developer,
        },
        {
            title: "Program code",
            info: (
                <Link to={`/program/${program_id}/detail`}>
                    <UnderlineText>{program_code}</UnderlineText>
                </Link>
            ),
        },
        {
            title: "Program name",
            info: program_name,
        },
        {
            title: "Project réf.",
            info: `#${projectReferenceExternalId}`,
        },
        {
            title: "Vintage",
            info: fieldHarvestYear,
        },
        {
            title: "Land-use",
            info: fieldLandUse,
        },
        {
            title: "UCO",
            info: fieldUcoTokenId ? (
                <Link
                    to={`/registry/uco/${programDetails?.program_uco_address}/${id}/${program_id}/details`}
                >
                    <UnderlineText>{fieldUcoTokenId}</UnderlineText>
                </Link>
            ) : (
                "-"
            ),
        },
        {
            title: "SCC",
            info: fieldSccTokenId ? (
                <Link
                    to={`/registry/scc/${programDetails?.program_scc_address}/${id}/${program_id}/details`}
                >
                    <UnderlineText>{fieldSccTokenId}</UnderlineText>
                </Link>
            ) : (
                "-"
            ),
        },
        {
            title: "Value",
            info: fieldValue
                ? `${sanitizedDigits(fieldValue)}  ${
                      fieldsDetails?.program?.program_unit
                  }`
                : "-",
        },
    ];

    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        sx={{ mt: 0 }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                            xl={6}
                            sx={{ mt: "20px", gap: "30px" }}
                            display="flex"
                            flexDirection="column"
                        >
                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Farmer
                                </Typography>
                                {farmer.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: "180px",
                                                minWidth: "180px",
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: "calc(100% - 180px)",
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {res.info}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Location
                                </Typography>
                                {location.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: "180px",
                                                minWidth: "180px",
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: "calc(100% - 180px)",
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {res.info}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Program
                                </Typography>
                                {program.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: "180px",
                                                minWidth: "180px",
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: "calc(100% - 180px)",
                                            }}
                                        >
                                            {res.button ? (
                                                <Box component="div">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: "78px",
                                                            background:
                                                                "#1ED800",
                                                            borderRadius:
                                                                "40px",
                                                            boxShadow: "none",
                                                            fontSize: "14px",
                                                            textTransform:
                                                                "none",
                                                            color: "#fff",
                                                            padding: "1px 11px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        {res.iconPath}{" "}
                                                        {res.button}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {res.info}
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            xl={6}
                            sx={{
                                pt: "2px !important",
                                gap: "23px",
                            }}
                            display="flex"
                            flexDirection="column"
                        >
                            <Box mt={2} sx={{ width: "100%" }}>
                                <Timeline
                                    harvestYear={fieldHarvestYear}
                                    // fieldExternalId={fieldExternalId}
                                    // onYearChange={(years) => {
                                    //     handleYearChanged(years);
                                    // }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CheckpageAuth>
        </>
    );
};

export default History;
