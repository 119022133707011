import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
import permissionSlug from "assets/data/permissionSlug.json";
import { useAccessPage } from "customHooks/useAccess";
import Grid from "@mui/material/Grid";
import FormContainerProject from "components/project/FormContainerProject";
import { useParams } from "react-router-dom";
import CheckpageAuth from "auth/CheckPageAccess";
import { useProtectProgram } from "customHooks/useProgramStatus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProgram } from "redux/programSlice";
// import { useSelector } from "react-redux";

const AddProject = () => {
    const { id } = useParams();
    const { can } = useAccessPage();
    const dispatch = useDispatch();
    const { canModifyProgram } = useProtectProgram();
    const { programDetails } = useSelector((state) => state.program);

    useEffect(() => {
        dispatch(getProgram(id));
    }, []);

    const routeAccess = {
        routeAccess:
            (can(permissionSlug[0].project_slug.create_project) &&
                !programDetails?.program_status) ||
            canModifyProgram(programDetails?.program_status),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Breadcrumbs
                        sources={[
                            {
                                label: "program",
                                link: "/programs",
                                canNavigate: true,
                            },
                            {
                                label: "projects",
                                link: `/program/${id}/projects`,
                                canNavigate: true,
                            },
                            {
                                label: "New project",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "center",
                                sm: "flex-end",
                            },
                            pt: "2px !important",
                        }}
                    ></Grid>
                </Grid>

                <Typography variant="h2">
                    Add a project on a new farmer
                </Typography>
                <Typography variant="body1" mt={0.5} mb={3}>
                    Please fill the following form to add a new farmer to your
                    famers list.
                </Typography>
                <FormContainerProject />
            </CheckpageAuth>
        </Layout>
    );
};

export default AddProject;
