import Grid from "@mui/material/Grid";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProgram, refetchProgram } from "redux/programSlice";
import DetailsPageImage from "common/DetailsPageImage";
import PdfIcon from "common/PdfIcon";
import sanitizedDigits from "util/sanitizedDigits";
import permissionSlug from "assets/data/permissionSlug.json";
import programStatusColor from "util/ProgramStatusColor";
import { useDateFormatter } from "customHooks/useDateFormatter";
import { useAccessPage } from "customHooks/useAccess";
import CheckpageAuth from "auth/CheckPageAccess";
import SustainableGoals from "./SustainableGoals";
export default function Detail({ program_id }) {
    const { formatDates } = useDateFormatter();
    const dispatch = useDispatch();

    const { can, isRoles } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].programSlug.program_details),
        unAuthUserRedirectTo: "/401",
    };
    const { programDetails, isLoading, isLoaded } = useSelector(
        (state) => state.program
    );
    // const format =
    //     useSelector(
    //         (state) => state.account.accountDetails.user_dateformat,
    //         shallowEqual
    //     )?.toUpperCase() || "DD-MM-YYYY";
    useEffect(() => {
        if (!isLoaded) {
            dispatch(getProgram(program_id));
        }
    }, [isLoaded]);

    useEffect(() => {
        if (Number(program_id) !== Number(programDetails.id)) {
            dispatch(refetchProgram());
        }
    }, [program_id]);
    const {
        id,
        program_name,
        program_code,
        program_color,
        program_type,
        program_developer,
        program_external_id,
        program_region,
        program_country,
        program_location,
        program_status,
        program_image,
        program_description,
        program_registry,
        program_standard,
        program_methodology,
        program_methodology_contract,
        program_exclusive_farm,
        program_exclusive_field,
        program_registration_date,
        program_crediting_period_start,
        program_crediting_period_end,
        program_credit_type,
        program_unit,
        program_farmer_fees,
        program_developer_fees,
        program_registry_fees,
        program_uco_address,
        program_scc_address,
        slaveCompaniesName,
    } = programDetails;
    const programInfo = [
        {
            title: "Status",
            button: capitalizeFirstLetter(program_status),
            alignItems: "center",
            iconPath: (
                <CheckIcon
                    sx={{
                        width: "16px",
                        marginRight: "2px",
                        height: "12px",
                        position: "relative",
                        top: "2px",
                    }}
                />
            ),
        },
        {
            title: "ID program",
            info: id,
        },
        {
            title: "Program name",
            info: program_name,
        },
        {
            title: "Program code",
            info: program_code,
        },
        {
            title: "Type",
            info: capitalizeFirstLetter(program_type),
        },
        {
            title: "Company lead developer",
            info: program_developer,
        },
        {
            title: "Program external ID",
            info: program_external_id || "-",
        },
        {
            title: "Region",
            info: program_region,
        },
        {
            title: "Country",
            info: program_country,
        },
        {
            title: "Location",
            info: program_location,
        },
        {
            title: "Field color",
            info: (
                <Box
                    sx={{
                        backgroundColor: program_color,
                        height: "15px",
                        width: "60px",
                    }}
                >
                    <span style={{ display: "none" }}>{program_color}</span>
                </Box>
            ),
        },

        {
            title: "Program image",
            info: <DetailsPageImage url={program_image} />,
        },
        {
            title: "Description",
            info: program_description,
        },
    ];

    const methodologyInfo = [
        {
            title: "Registry",
            info: program_registry,
        },
        {
            title: "Standard",
            info: program_standard,
        },
        {
            title: "Methodology",
            info: program_methodology,
        },
        {
            title: "Methodology contract",
            info: program_methodology_contract ? (
                <PdfIcon address={program_methodology_contract} />
            ) : (
                "-"
            ),
        },
        {
            title: "Exclusive farm",
            info: program_exclusive_farm ? "Yes" : "No",
        },
        {
            title: "Exclusive field",
            info: program_exclusive_field ? "Yes" : "No",
        },
        {
            title: "Registration date",
            info: formatDates(program_registration_date),
        },
        {
            title: "Crediting period start date",
            info: formatDates(program_crediting_period_start),
        },
        {
            title: "Crediting period end date",
            info: formatDates(program_crediting_period_end),
        },
        {
            title: "Credit type",
            info: program_credit_type || "-",
        },
        {
            title: "Unit",
            info: program_unit,
        },
    ];
    const feesInfo = [
        {
            title: "Farmer",
            info: `${sanitizedDigits(program_farmer_fees)}%`,
        },
        {
            title: "Program developer",
            info: `${sanitizedDigits(program_developer_fees)}%`,
        },
        {
            title: "Registry",
            info: `${sanitizedDigits(program_registry_fees)}%`,
        },
        {
            title: "UCO contract",
            info: program_uco_address ? program_uco_address : "-",
        },
        {
            title: "SCC contract",
            info: program_scc_address ? program_scc_address : "-",
        },
    ];
    // const contractAddress = [
    //     {
    //         title: "UCO contract",
    //         info: program_uco_address ? program_uco_address : "-",
    //     },
    //     {
    //         title: "SCC contract",
    //         info: program_scc_address ? program_scc_address : "-",
    //     },
    // ];
    const slaveCompanies = [
        {
            title: "Program developer",
            info: slaveCompaniesName,
        },
    ];
    return (
        <Box sx={{ width: "100%" }}>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    sx={{ mt: 0 }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            pt: "2px !important",
                            mt: { xs: "30px", md: "60px" },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Program information
                        </Typography>
                        {programInfo.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                alignItems={res?.alignItems}
                                margin={res?.alignItems}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "15px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: { xs: "left", lg: "right" },
                                        padding: "0px 12px !important",
                                        maxWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                        minWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                        mb: { xs: "5px", sm: 0 },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                            lg: "calc(100% - 180px)",
                                        },
                                    }}
                                >
                                    {res.button ? (
                                        <Box component="div">
                                            <Box
                                                component="span"
                                                sx={{
                                                    background:
                                                        programStatusColor[
                                                            program_status
                                                        ],
                                                    borderRadius: "40px",
                                                    boxShadow: "none",
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    color: "#fff",
                                                    padding: "7px 11px",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {res.iconPath} {res.button}
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box
                                            component="div"
                                            sx={{
                                                maxWidth: {
                                                    xs: "100%",
                                                    xl: "70%",
                                                },
                                            }}
                                        >
                                            {isLoading ? (
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem" }}
                                                />
                                            ) : (
                                                res.info
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            pt: "2px !important",
                            mt: { xs: "30px", md: "60px" },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Methodology
                        </Typography>
                        {methodologyInfo.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "15px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: { xs: "left", lg: "right" },
                                        padding: "0px 12px !important",
                                        maxWidth: { xs: "100%", sm: "220px" },
                                        minWidth: { xs: "100%", sm: "220px" },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                        },
                                        maxWidth: { xs: "100%", xl: "70%" },
                                    }}
                                >
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: "1rem" }}
                                        />
                                    ) : (
                                        res.info
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    {(isRoles("admin") || isRoles("principal")) && (
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                pt: "2px !important",
                                mt: { xs: "30px", md: "60px" },
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Fees
                            </Typography>
                            {feesInfo.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "15px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            minWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: {
                                                xs: "100%",
                                                sm: "calc(100% - 220px)",
                                                lg: "calc(100% - 180px)",
                                            },
                                            maxWidth: { xs: "100%", lg: "70%" },
                                        }}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem" }}
                                            />
                                        ) : (
                                            res.info
                                        )}
                                    </Grid>
                                </Grid>
                            ))}

                            {slaveCompaniesName?.length > 0 ? (
                                <>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: "left",
                                            color: "#02353C",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "16px",
                                                md: "18px",
                                                lg: "20px",
                                            },
                                            mt: { xs: "30px", md: "60px" },
                                        }}
                                    >
                                        Program developers
                                    </Typography>

                                    {slaveCompanies[0].info?.map(
                                        (res, index) => (
                                            <Grid
                                                key={index}
                                                container
                                                spacing={1}
                                                sx={{
                                                    margin: "13px -13px 0",
                                                    lineHeight: "1.2",
                                                    fontSize: {
                                                        xs: "14px",
                                                        lg: "15px",
                                                    },
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    sx={{
                                                        textAlign: {
                                                            xs: "left",
                                                            lg: "right",
                                                        },
                                                        padding:
                                                            "0px 12px !important",
                                                        maxWidth: {
                                                            xs: "100%",
                                                            sm: "220px",
                                                        },
                                                        minWidth: {
                                                            xs: "100%",
                                                            sm: "220px",
                                                        },
                                                    }}
                                                >
                                                    {res.title}
                                                </Grid>
                                                <Grid
                                                    item
                                                    sx={{
                                                        textAlign: "left",
                                                        fontWeight: "700",
                                                        padding:
                                                            "0px 12px !important",
                                                        width: {
                                                            xs: "100%",
                                                            sm: "calc(100% - 220px)",
                                                        },
                                                        maxWidth: {
                                                            xs: "100%",
                                                            xl: "70%",
                                                        },
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize:
                                                                    "1rem",
                                                            }}
                                                        />
                                                    ) : (
                                                        capitalizeFirstLetter(
                                                            res?.companyName
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </>
                            ) : (
                                " "
                            )}
                        </Grid>
                    )}

                    {/*  <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            pt: "2px !important",
                            mt: { xs: "30px", md: "60px" },
                        }}
                    >
                        {contractAddress.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "15px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: {
                                            xs: "left",
                                            lg: "right",
                                        },
                                        padding: "0px 12px !important",
                                        maxWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                        minWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                            lg: "180px",
                                        },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                            lg: "calc(100% - 180px)",
                                        },
                                        maxWidth: { xs: "100%", lg: "70%" },
                                    }}
                                >
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: "1rem" }}
                                        />
                                    ) : (
                                        res.info
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid> */}

                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            pt: "2px !important",
                            mt: { xs: "30px", md: "10px" },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Sustainable development goals
                        </Typography>
                        <SustainableGoals
                            disable={true}
                            program={
                                programDetails?.program_un_goals &&
                                programDetails?.program_un_goals
                            }
                            detail={true}
                        />
                    </Grid>

                    {/*  */}
                    {/* {slaveCompaniesName?.length > 0 ? (
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                pt: "2px !important",
                        mt: {xs: "5px", md: "5px" },
                            }}
                        >
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: "left",
                                color: "#02353C",
                                fontWeight: "bold",
                                fontSize: {
                                    xs: "16px",
                                    md: "18px",
                                    lg: "20px",
                                },
                            }}
                        >
                            Program developers
                        </Typography>

                        {slaveCompanies[0].info?.map((res, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={1}
                                sx={{
                                    margin: "13px -13px 0",
                                    lineHeight: "1.2",
                                    fontSize: { xs: "14px", lg: "15px" },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        textAlign: {
                                            xs: "left",
                                            lg: "right",
                                        },
                                        padding: "0px 12px !important",
                                        maxWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                        },
                                        minWidth: {
                                            xs: "100%",
                                            sm: "220px",
                                        },
                                    }}
                                >
                                    {res.title}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        padding: "0px 12px !important",
                                        width: {
                                            xs: "100%",
                                            sm: "calc(100% - 220px)",
                                        },
                                        maxWidth: { xs: "100%", xl: "70%" },
                                    }}
                                >
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: "1rem" }}
                                        />
                                    ) : (
                                        capitalizeFirstLetter(
                                            res?.companyName
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    ) : (
                    " "
                    )} */}
                </Grid>
            </CheckpageAuth>
        </Box>
    );
}
