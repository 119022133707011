import protectedRequest from "helpers/protectedRequest";
import useAxios from "helpers/useAxios.js";

const getProjectsList = async (params) => {
    return await protectedRequest(() =>
        useAxios.get(
            `projects/all?page=${params?.query?.page}&limit=${params?.query?.limit}&programId=${params?.id}&search=${params?.query?.search}`
        )
    );
};
const projectsCreate = async (params) => {
    return await protectedRequest(() =>
        useAxios.post(`projects/create/${params?.id}`, params.formData)
    );
};
const projectDetail = async (params) => {
    return await protectedRequest(() => useAxios.get(`projects/${params}`));
};
const editProjects = async (params) => {
    return await protectedRequest(() =>
        useAxios.patch(
            `projects/update/${params.id}/${params?.programId}`,
            params.formData
        )
    );
};
const uploadProjectsCsvFile = async (formData) => {
    return await protectedRequest(() =>
        useAxios.post(`projects/upload/new/projects`, formData)
    );
};
export {
    getProjectsList,
    projectsCreate,
    projectDetail,
    editProjects,
    uploadProjectsCsvFile,
};
