import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
    shallowEqual,
    // useDispatch,
    useSelector,
} from "react-redux";

// const programNames = [
//     {
//         id: "30",
//         name: "rwer",
//         program_code: "RWERWER",
//     },
//     {
//         id: "31",
//         name: "Axereal bas GES",
//         program_code: "AXL001",
//     },
//     {
//         id: "32",
//         name: "new tes",
//         program_code: "354345",
//     },
//     {
//         id: "37",
//         name: "dfd",
//         program_code: "DSF",
//     },
//     {
//         id: "39",
//         name: "test program",
//         program_code: "12345",
//     },
//     {
//         id: "40",
//         name: "SOME NAME",
//         program_code: "12412352",
//     },
//     {
//         id: "49",
//         name: "Harshdeep Singh Toor",
//         program_code: "12412352",
//     },
// ];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutocompletePrograms({ programs, handleProgram }) {
    // const dispatch = useDispatch();
    const programOptions = useSelector(
        (state) => state.maps.programOptions,
        shallowEqual
    );

    const handleChangedProgram = (value) => {
        // const programIDS = value.map((val) => Number(val.id));
        handleProgram("program", value);
    };
    return (
        <Autocomplete
            multiple
            limitTags={1}
            id="multiple-limit-tags"
            // id="checkboxes-tags-demo"
            options={programOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => handleChangedProgram(value)}
            value={programs}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} label="Program" placeholder="Program" />
            )}
            filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                return options.filter((option) => {
                    const name = option.name.toLowerCase();
                    const programCode = option.program_code.toLowerCase();
                    const companyName = option?.company_name.map((company) =>
                        company.toLowerCase()
                    );

                    return (
                        name.includes(inputValue) ||
                        programCode.includes(inputValue) ||
                        companyName.some((company) =>
                            company.includes(inputValue)
                        )
                    );
                });
            }}
        />
    );
}
