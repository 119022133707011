import { styled, useTheme } from "@mui/material/styles";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#F8FAFB",
    margin: 0,
    padding: theme.spacing(3),
    "& h2": {
        color: "#333333",
        fontSize: 20,
        fontWeight: "600",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    backgroundColor: "#F8FAFB",
    boxShadow: "0px -4px 4px rgba(6, 53, 75, 0.07)",
    height: 112,
    margin: 0,
    padding: theme.spacing(3),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    // position: "absolute",
    // right: theme.spacing(1),
    // top: 3,
    padding: "0px",
    color: theme.palette.grey[500],
}));

const StyledBox = styled(Box)(() => ({
    height: "85vh",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
}));

const StyledDialogContent = styled(DialogContent)({
    padding: 0,
    margin: 0,
    "&::-webkit-scrollbar": {
        width: 0,
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
});

export default function ReaderViewModal({
    open = true,
    title,
    pdfFile,
    onClose,
    actions,
    modalType = "large",
}) {
    const theme = useTheme();

    const dialogPaper = {
        margin: "16px",
        [theme.breakpoints.up("md")]: {
            maxWidth: "450px",
            minWidth: "850px",
            borderRadius: "8px",
            // minWidth: "95%",
            // maxHeight: "calc(100% - 20px)",
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "450px",
            width: "100%",
        },
    };

    const dialogPaperSmall = {
        [theme.breakpoints.up("md")]: {
            minWidth: "450px",
            maxWidth: "450px",
            borderRadius: "8px",
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "95%",
            maxHeight: "calc(100% - 50px)",
            borderRadius: "8px",
        },
    };

    const dialogPaperLg = {
        margin: "16px",
        [theme.breakpoints.up("sm")]: {
            maxWidth: "850px",
            minWidth: "450px",
            borderRadius: "8px",
            // minWidth: "95%",
            // maxHeight: "calc(100% - 20px)",
        },
    };

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        ...(modalType === "large"
                            ? dialogPaper
                            : modalType === "lg"
                            ? dialogPaperLg
                            : dialogPaperSmall),
                    },
                },
            }}
        >
            <StyledDialogTitle>
                <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {title}
                </Typography>
                <StyledIconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </StyledIconButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                <StyledBox>
                    <Box
                        sx={{
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <iframe
                            width="100%"
                            height="100%"
                            src={`${pdfFile}#navpanes=0&scrollbar=0`}
                            title="description"
                        />
                    </Box>
                </StyledBox>
                {actions && (
                    <StyledDialogActions>{actions}</StyledDialogActions>
                )}
            </StyledDialogContent>
        </Dialog>
    );
}
