import Stack from "@mui/material/Stack";
import Company from "components/program/form/Company";
import Contact from "components/program/form/Contact";
import Address from "components/program/form/Address";
import Contract from "components/program/form/Contract";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as CheckBoxes } from "assets/icons/checkboxes.svg";
import { ReactComponent as CheckboxeChecked } from "assets/icons/checkboxeChecked.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
    createProgramProjects,
    editProgramProject,
    refetchProject,
} from "redux/projectSlice";
import CancelButton from "common/CancelButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";

// import { useMemo } from "react";
const FormContainerProject = ({ edit = false, projectDetail }) => {
    const navigate = useNavigate();
    const { can, cannot } = useAccessPage();
    const { id } = useParams();
    const projectId = id; //store project id in a variable
    const programId = id; //store program id in a variable
    const programIdForEdit = projectDetail?.projectIdProgram;

    const dispatch = useDispatch();
    const { isloading, farmerAddErrors } = useSelector(
        (state) => state.project
    );
    const { programDetails } = useSelector((state) => state.program);

    const initialValues = {
        //if true
        projectIdFarmer: "",
        projectReferenceExternalId: "",
        projectStartDate: "",
        projectEndDate: "",
        projectSignatureDate: "",
        projectPdfFile: "",
        projectReferenceYear: "",
        projectActiveYears: [],
        //if false
        companyId: "",
        farmerExternalId: "",
        farmerCompanyName: "",
        farmerOfficialId: "",
        farmerEuVatId: "",
        farmerEuCapId: "",
        farmerFirstname: "",
        farmerLastname: "",
        farmerPhone: "",
        farmerEmail: "",
        farmerFullAddress: "",
        farmerAddressXY: "POINT(1 1)",
        farmerStreet: "",
        farmerStreet2: "",
        farmerZipcode: "",
        farmerZipcode2: "",
        farmerCity: "",
        farmerRegion: "",
        farmerCountry: "",
        checkFarmerIds: edit ? true : false,
    };

    if (cannot(permissionSlug[0].project_slug.upload_project_contract)) {
        delete initialValues.projectPdfFile;
    }

    const validateFarmerExist = (checkFarmerIds, schema, errroMessage) => {
        const isFarmerChecked = checkFarmerIds.toString().includes("true");
        if (isFarmerChecked) {
            schema;
        } else {
            return schema.required(errroMessage);
        }
    };

    const makeRequiredForExistFarmer = (
        checkFarmerIds,
        schema,
        errroMessage
    ) => {
        const isFarmerChecked = checkFarmerIds.toString().includes("true");
        if (isFarmerChecked) {
            return schema.required(errroMessage);
        } else {
            schema;
        }
    };

    //1st field
    const farmerIdValidation = {
        projectIdFarmer: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                makeRequiredForExistFarmer(
                    checkFarmerIds,
                    schema,
                    "Farmer id required"
                )
        ),
    };
    //2nd field
    const farmerDetailsValidation = {
        companyId: Yup.string(),
        farmerExternalId: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Farmer Ref is Required"
                )
        ),
        farmerCompanyName: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Company name is Required"
                )
        ),
        farmerOfficialId: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Official Id is Required"
                )
        ),
        farmerEuVatId: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Vat number is Required"
                )
        ),
        farmerEuCapId: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Europeancap Id is Required"
                )
        ),
    };
    //3rd field
    const FarmercontractValidation = {
        projectReferenceExternalId: Yup.string().required(
            "Reference id is Required"
        ),
        projectStartDate: Yup.string().required("Start date is Required"),
        projectEndDate: Yup.string().required("End date is Required"),
        projectSignatureDate: Yup.string().required(
            "Signature date is Required"
        ),
        projectPdfFile:
            edit ||
            cannot(permissionSlug[0].project_slug.upload_project_contract)
                ? Yup.string()
                : Yup.string().required("Pdf is Required"),
        projectReferenceYear: Yup.number()
            .typeError("Year must be a number")
            .required("Reference Year is Required")
            .integer("Year must be an integer")
            .min(1900, "Please enter a valid year")
            .max(2099, "Please enter a valid year")
            .when("year", {
                is: (val) => val !== undefined && val !== null && val !== "", // Apply validation only if the year field is not empty
                then: Yup.number().test(
                    "len",
                    "Year must be exactly 4 digits",
                    (val) => val.toString().length === 4
                ),
            }),
        projectActiveYears: Yup.number()
            .required("Active Year is Required")
            .transform((value) => (Number.isNaN(value) ? null : value)),
    };
    //4th field
    const FarmercontactValidation = {
        farmerFirstname: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Firstname is Required"
                )
        ),
        farmerLastname: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Lastname is required"
                )
        ),
        farmerPhone: Yup.string(),
        farmerEmail: Yup.string().email("Invalid email address"),
    };
    //5th field
    const FarmerAddressValidation = {
        farmerStreet: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Street is required"
                )
        ),
        farmerZipcode: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Zip code is required"
                )
        ),
        farmerRegion: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Region is required"
                )
        ),
        farmerCity: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(checkFarmerIds, schema, "City is required")
        ),
        farmerCountry: Yup.string().when(
            "checkFarmerIds",
            (checkFarmerIds, schema) =>
                validateFarmerExist(
                    checkFarmerIds,
                    schema,
                    "Country is required"
                )
        ),
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            ...farmerIdValidation,
            ...farmerDetailsValidation,
            ...FarmercontractValidation,
            ...FarmercontactValidation,
            ...FarmerAddressValidation,
        }),

        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("checkFarmerIds", values.checkFarmerIds);
            formData.append(
                "projectReferenceExternalId",
                values.projectReferenceExternalId
            );
            formData.append("projectStartDate", values.projectStartDate);
            formData.append("projectEndDate", values.projectEndDate);
            formData.append(
                "projectSignatureDate",
                values.projectSignatureDate
            );
            if (can(permissionSlug[0].project_slug.upload_project_contract)) {
                formData.append("projectPdfFile", values.projectPdfFile);
            }
            formData.append(
                "projectReferenceYear",
                values.projectReferenceYear
            );
            formData.append("projectActiveYears", values.projectActiveYears);

            if (values.checkFarmerIds || edit) {
                formData.append("projectIdFarmer", values.projectIdFarmer);
                // formData.append("projectIdProgram", programIdForEdit);
            } else {
                formData.append("farmerExternalId", values.farmerExternalId);
                formData.append("farmerCompanyName", values.farmerCompanyName);
                formData.append("farmerOfficialId", values.farmerOfficialId);
                formData.append("farmerEuVatId", values.farmerEuVatId);
                formData.append("farmerEuCapId", values.farmerEuCapId);
                formData.append("farmerFirstname", values.farmerFirstname);
                formData.append("farmerLastname", values.farmerLastname);
                formData.append("farmerPhone", values.farmerPhone);
                formData.append("farmerEmail", values.farmerEmail);
                formData.append("farmerFullAddress", values.farmerFullAddress);
                formData.append("farmerAddressXY", values.farmerAddressXY);
                formData.append("farmerStreet", values.farmerStreet);
                formData.append("farmerStreet2", values.farmerStreet2);
                formData.append("farmerZipcode", values.farmerZipcode);
                formData.append("farmerZipcode2", values.farmerZipcode2);
                formData.append("farmerCity", values.farmerCity);
                formData.append("farmerRegion", values.farmerRegion);
                formData.append("farmerCountry", values.farmerCountry);
            }
            formData.append("companyId", programDetails?.program_id_company_KO);
            if (edit) {
                dispatch(
                    editProgramProject({
                        id: projectId,
                        programId: programIdForEdit,
                        formData,
                    })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate(`/projectdetail/${projectId}`);
                        dispatch(refetchProject());
                    }
                });
            } else {
                dispatch(
                    createProgramProjects({ id: programId, formData })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate(`/program/${programId}/projects`);
                        dispatch(refetchProject());
                        formik.resetForm();
                    }
                });
            }
        },
    });
    useEffect(() => {
        if (projectDetail) {
            if (edit) {
                const currentValues = {
                    ...formik.values,
                    projectIdFarmer: projectDetail?.projectIdFarmer || "",
                    projectReferenceExternalId:
                        projectDetail?.projectReferenceExternalId || "",
                    projectStartDate: projectDetail?.projectStartDate || "",
                    projectEndDate: projectDetail?.projectEndDate || "",
                    projectSignatureDate:
                        projectDetail?.projectSignatureDate || "",
                    projectReferenceYear:
                        projectDetail?.projectReferenceYear || "",
                    projectActiveYears: projectDetail?.projectActiveYears
                        ? projectDetail?.projectActiveYears
                              .split(",")
                              .map((year) => year.trim())
                        : [],
                };

                if (
                    can(permissionSlug[0].project_slug.upload_project_contract)
                ) {
                    currentValues.projectPdfFile =
                        projectDetail?.projectPdfFile || "";
                }
                formik.setValues(currentValues);
            }
        }
    }, [projectDetail, edit]);

    const isFarmerExist = useMemo(() => {
        return (
            <FormControlLabel
                sx={{
                    mr: 0,
                    ml: "auto",
                    fontSize: { xs: "14px", lg: "16px" },
                    "& span": {
                        fontWeight: "600",
                    },
                    "&:MuiCheckbox-root": {
                        padding: "0 7px",
                    },
                }}
                id="checkFarmerIds"
                name="checkFarmerIds"
                onChange={formik.handleChange}
                value={formik.values.checkFarmerIds}
                control={
                    <Checkbox
                        icon={<CheckBoxes />}
                        checkedIcon={<CheckboxeChecked />}
                    />
                }
                label="Check If Farmer already exist"
            />
        );
    }, [formik.values.checkFarmerIds]);

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                maxWidth: 530,
            }}
        >
            <Typography variant="h4" sx={{ display: "flex", flexWrap: "wrap" }}>
                Farmer
                {edit ? null : isFarmerExist}
            </Typography>
            <Company
                formik={formik}
                fieldErrors={farmerAddErrors}
                type="project"
            />
            <Contact formik={formik} />
            <Address
                formik={formik}
                fieldErrors={farmerAddErrors}
                type="project"
            />
            <Contract
                formik={formik}
                edit={edit}
                showPDF={can(
                    permissionSlug[0].project_slug.upload_project_contract
                )}
            />
            <Stack direction="row" spacing={2} mt={{ xs: "19px", md: "29px" }}>
                <CancelButton
                    address={
                        edit
                            ? `/projectdetail/${projectId}`
                            : `/program/${programId}/projects`
                    }
                />
                {/* <CancelButton address={`/program/${programId}/projects`} /> */}
                <Button type="submit" variant="contained" disabled={isloading}>
                    {isloading ? "Processing..." : "Add"}
                </Button>
            </Stack>
        </Box>
    );
};

export default FormContainerProject;
