import { Box, Typography } from "@mui/material";
import errorImg from "assets/images/404.svg";

const NotFound = () => {
    return (
        <>
            <Box
                height={"100vh"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Box>
                    <Box
                        component="img"
                        src={errorImg}
                        alt="404 img"
                        width={"100%"}
                    />
                    <Typography variant="h2" textAlign={"center"}>
                        404 Not Found
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
export default NotFound;
