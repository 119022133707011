import { useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import sanitizedDigits from "util/sanitizedDigits";
import { setFiltersFieldsCounts } from "redux/mapsSlice";

export default function AllMaps({ selectedprograms, selectedfarmers }) {
    const dispatch = useDispatch();
    const mapRef = useRef(null);
    const { companygeojson: geoJsonData } = useSelector(
        (state) => state.maps,
        shallowEqual
    );

    useEffect(() => {
        if (mapRef.current) {
            updateMapLayers(); // Update layers without reinitializing the map
        } else {
            initializeMap(); // Initialize the map if it hasn't been initialized yet
        }
    }, [geoJsonData, selectedprograms, selectedfarmers]);

    function initializeMap() {
        const map = L.map("maCarte").setView([47.85475, 1.91216], 8);
        mapRef.current = map;

        L.tileLayer(
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            {
                attribution:
                    '&copy; <a href="https://www.esri.com">Esri</a> contributors, ...',
            }
        ).addTo(map);

        const drawControl = new L.Control.Draw({
            draw: {
                polyline: false,
                polygon: false,
                circle: false,
                marker: false,
                circlemarker: false,
                rectangle: true, // Enable rectangle drawing
            },
            edit: false,
        });

        map.addControl(drawControl);

        updateMapLayers();

        mapRef.current.on("draw:created", function (e) {
            const type = e.layerType;
            const layer = e.layer;

            if (type === "rectangle") {
                const bounds = layer.getBounds();
                mapRef.current.fitBounds(bounds);
            }
        });
    }

    function updateMapLayers() {
        const map = mapRef.current;
        let bounds = null;

        if (geoJsonData) {
            // Clear existing layers if any
            map.eachLayer((layer) => {
                if (!(layer instanceof L.TileLayer)) {
                    map.removeLayer(layer);
                }
            });
            let count = 0;
            geoJsonData.features.forEach((feature) => {
                if (
                    feature &&
                    feature.properties?.program_code &&
                    selectedprograms.includes(
                        feature.properties.program_code
                    ) &&
                    feature.properties?.farmerId &&
                    selectedfarmers.includes(feature.properties.farmerId)
                ) {
                    const properties = feature.properties;
                    const layer = L.geoJSON(feature, {
                        style: {
                            color: properties.stroke || "#109a0c",
                            weight: properties["stroke-width"],
                            opacity: properties["stroke-opacity"],
                            fillColor: properties.fill || "#0EDA08",
                            fillOpacity: 0.8,
                        },
                    })
                        .bindPopup(function () {
                            return `ref: ${
                                properties.external_id || ""
                            }<br>farmer: ${properties.farmer_company_name || ""}<br>id: ${properties.id_field || ""}<br>${properties.company_name || ""}<br>${properties.program_code}<br>${properties.field_name || ""}<br>${sanitizedDigits(properties.field_area) || ""} hectare<br>${properties.field_land_use || ""}`;
                        })
                        .addTo(map);

                    // if (
                    //     properties?.program_code &&
                    //     selectedprograms.includes(properties.program_code) &&
                    //     properties?.farmerId &&
                    //     selectedfarmers.includes(properties.farmerId)
                    // ) {
                    count++;
                    layer.setStyle({ color: "#ff0000", weight: "4" });

                    if (bounds === null) {
                        bounds = layer.getBounds(); // Initialize bounds with the first layer's bounds
                    } else {
                        bounds.extend(layer.getBounds()); // Extend bounds to include the current layer's bounds
                    }
                    // }
                }
            });
            dispatch(setFiltersFieldsCounts(count));

            // Your code for flying to the bounds of the selected feature

            if (bounds !== null) {
                map.flyToBounds(bounds, { maxZoom: 14 });
            } else {
                // map.flyTo([47.85475, 1.91216], 8);
            }

            // const firstMatchingFeature = geoJsonData.features.find(
            //     (feature) => {
            //         const properties = feature?.properties;
            //         const farmerIdPropertyValue = feature?.properties?.farmerId;
            //         return (
            //             properties?.program_code &&
            //             // feature.properties.programId === fieldProgram
            //             selectedprograms.includes(properties.program_code) &&
            //             (!selectedfarmers[0] ||
            //                 (farmerIdPropertyValue &&
            //                     selectedfarmers.includes(
            //                         farmerIdPropertyValue
            //                     )))
            //         );
            //     }
            // );
            // if (firstMatchingFeature) {
            //     map.flyToBounds(L.geoJSON(firstMatchingFeature).getBounds(), {
            //         maxZoom: 14,
            //     });
            // } else {
            //     map.flyTo([47.85475, 1.91216], 8);
            // }
        }
    }

    return <div id="maCarte" style={{ height: "700px" }} />;
}
