import Box from "@mui/material/Box";
import { Checkbox } from "@mui/material";
import { goals } from "assets/data/goals.js";
import { useEffect, useState } from "react";
function SustainableGoals({
    disable,
    formik = {},
    program,
    edit,
    detail = false,
}) {
    const [sustainableGoals, setsustainableGoals] = useState(goals);
    const handleCheckBox = (event, selectedId) => {
        const temp = [...sustainableGoals];
        temp?.forEach((item, index) => {
            if (item.goal_id === selectedId) {
                temp[index].selected = !temp[index].selected;
            }
        });
        setsustainableGoals(temp);
        const finalValue = temp
            ?.map((items) => {
                if (items.selected) {
                    return {
                        selected: items?.selected,
                        goal_id: items?.goal_id,
                    };
                }
            })
            ?.filter((filteredValue) => filteredValue);
        if (finalValue) {
            formik.setFieldValue("program_un_goals", finalValue);
        } else {
            formik.setFieldValue("program_un_goals", []);
        }
    };
    useEffect(() => {
        if ((program && program !== undefined) || detail || edit) {
            const goals = sustainableGoals?.map((values) => {
                const sanitizedGoals =
                    program &&
                    program?.some(
                        (item) =>
                            values?.goal_id === item?.goal_id && item?.selected
                    );
                return { ...values, selected: sanitizedGoals };
            });
            setsustainableGoals(goals);
        }
    }, [program]);
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                mr: { xl: "280px" },
            }}
        >
            {sustainableGoals?.map((item, index) => (
                <Box key={index} sx={{ m: 2 }}>
                    <Box
                        component="img"
                        // alt="TimelineImage2"
                        src={item?.goalImage}
                        sx={{
                            width: "60px",
                        }}
                    />
                    <Box textAlign={"center"}>
                        <Checkbox
                            disabled={disable}
                            checked={item.selected}
                            name="program_un_goals"
                            value={formik.values?.program_un_goals}
                            onChange={(e) => {
                                handleCheckBox(e, item.goal_id);
                            }}
                            sx={{ p: 0 }}
                        />
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

export default SustainableGoals;
