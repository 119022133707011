import CustomTable from "common/CustomTable";
import Box from "@mui/material/Box";
import UnderlineText from "common/UnderlineText";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import Searchbar from "common/Searchbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import { fetchAllFarmerFields, refetchFarmersFields } from "redux/farmersSlice";
import sanitizedDigits from "util/sanitizedDigits";
import sanitizedString from "util/sanitizedString";
// import DoneIcon from "@mui/icons-material/Done";
// import Chip from "@mui/material/Chip";
// import { styled } from "@mui/material/styles";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
// const StyledChip = styled(Chip)(() => ({
//     height: "28px",
//     padding: "1px 0px",
//     minWidth: "91px",
//     fontSize: "14px",
// }));
const headCells = [
    {
        id: "programid",
        numeric: false,
        label: "Program ID",
    },
    {
        id: "fieldid",
        numeric: false,
        label: "Field ID",
    },
    {
        id: "field",
        numeric: true,
        label: "Field",
    },
    {
        id: "location",
        numeric: true,
        label: "Location",
    },
    {
        id: "harvest",
        numeric: true,
        label: "Harvest",
    },
    {
        id: "landuse",
        numeric: true,
        label: "Land-use",
    },
    {
        id: "area",
        numeric: true,
        label: "Area (ha)",
    },
    {
        id: "uco",
        numeric: true,
        label: "UCO",
    },
    {
        id: "scc",
        numeric: true,
        label: "SCC",
    },
    {
        id: "value",
        numeric: true,
        label: "Value",
    },
    // {
    //     id: "status",
    //     numeric: true,
    //     label: "SCC status",
    // },
];

export default function Detail() {
    const { id: farmer_id } = useParams();
    const initialQuery = useRef({ page: 1, limit: 10, search: "" });
    const dispatch = useDispatch();
    const [query, setQuery] = useState(initialQuery.current);
    const { fields, isLoading, isLoaded, farmerFieldCount, previousFarmerId } =
        useSelector((state) => state.farmers);
    useEffect(() => {
        if (isLoaded === false) {
            dispatch(fetchAllFarmerFields({ query, id: farmer_id })).then(
                (res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        initialQuery.current = query;
                    }
                }
            );
        }
    }, [isLoaded]);
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].fields_slug.list_fields),
        unAuthUserRedirectTo: "/401",
    };
    useEffect(() => {
        if (
            initialQuery.current.page !== query.page ||
            initialQuery.current.limit !== query.limit ||
            initialQuery.current.search !== query.search ||
            Number(farmer_id) !== Number(previousFarmerId)
        ) {
            dispatch(refetchFarmersFields());
        }
    }, [query]);

    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 10, search: search });
    };
    const sanitizedfieldData =
        fields &&
        fields?.map((item) => ({
            programid: (
                <TableCell>
                    <Link
                        to={`/program/${item.project?.projectIdProgram}/detail`}
                    >
                        <UnderlineText>
                            {item?.project?.projectIdProgram}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            fieldid: item.id,
            field: (
                <TableCell>
                    <Link to={`/field/${item.id}/details`}>
                        <UnderlineText>
                            {capitalizeFirstLetter(item?.fieldName)}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            location: `${sanitizedString(
                `${item.fieldCitycodeInsee} ${item?.fieldCityInsee}`
            )}`,
            harvest: item?.fieldHarvestYear,
            landuse: item?.fieldLandUse,
            area: sanitizedDigits(item?.fieldshapefileArea),
            uco: (
                <TableCell>
                    {item?.fieldUcoTokenId ? (
                        can(permissionSlug[0].uco_slug.UCO_details) ? (
                            <Link
                                to={`/registry/uco/${item.project?.program?.program_uco_address}/${item.id}/${item.project?.program?.id}/details`}
                            >
                                {/* truncate uco  values pending */}
                                <UnderlineText>
                                    {item?.fieldUcoTokenId}
                                </UnderlineText>
                            </Link>
                        ) : (
                            item?.fieldUcoTokenId
                        )
                    ) : (
                        "-"
                    )}
                </TableCell>
            ),
            scc: (
                <TableCell>
                    {item?.fieldSccTokenId ? (
                        can(permissionSlug[0].scc_slug.SCC_details) ? (
                            <Link
                                to={`/registry/scc/${item.project?.program?.program_scc_address}/${item.id}/${item.project?.program?.id}/details`}
                            >
                                {/* truncate scc  values pending */}
                                <UnderlineText>
                                    {item?.fieldSccTokenId}
                                </UnderlineText>
                            </Link>
                        ) : (
                            item?.fieldSccTokenId
                        )
                    ) : (
                        "-"
                    )}
                </TableCell>
            ),
            value: item?.fieldValue
                ? `${sanitizedDigits(item?.fieldValue)} ${
                      item?.project?.program?.program_unit
                  }`
                : "-",
            // status: (
            //     <TableCell>
            //         <StyledChip
            //             label="Active"
            //             icon={
            //                 <DoneIcon
            //                     color="primary.white"
            //                     sx={{ fontSize: "16px" }}
            //                 />
            //             }
            //             sx={{
            //                 backgroundColor: "primary.light",
            //                 color: "primary.white",
            //             }}
            //         />
            //     </TableCell>
            // ),
        }));
    return (
        <CheckpageAuth auth={routeAccess}>
            <>
                <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        flexWrap: { xs: "wrap", sm: "inherit" },
                        justifyContent: { xs: "center", sm: "space-between" },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="18px"
                        sx={{ width: "100%", md: "auto" }}
                    >
                        <Searchbar
                            placeholdertext="Search a  harvest, field, city"
                            handleSearch={(search) => {
                                handleSearch(search);
                            }}
                        />
                    </Stack>
                </Stack>
                <Box component="div" sx={{ mt: 3 }}>
                    <CustomTable
                        headCells={headCells}
                        rows={sanitizedfieldData ? sanitizedfieldData : []}
                        loadingList={isLoading}
                        query={query}
                        setQuery={setQuery}
                        totalRowCount={farmerFieldCount}
                        noDataFound="No fields found"
                    />
                </Box>
            </>
        </CheckpageAuth>
    );
}
