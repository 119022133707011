import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function MapTitle() {
    const { filtersFieldsCounts } = useSelector((state) => state.maps);
    return (
        <>
            <Grid item xs={12} md={6}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    {filtersFieldsCounts}
                    {filtersFieldsCounts > 1 ? " Fields " : " Field "}
                </Typography>
            </Grid>
        </>
    );
}
