import useAxios from "helpers/useAxios";
import protectedRequest from "helpers/protectedRequest.js";
const getMapsProgram = async () => {
    return await protectedRequest(() => useAxios.get(`programs/all/dropdown`));
};

const getCompanyMapData = async (id) => {
    const reacts_url = process.env.REACT_APP_API_URL;
    return await protectedRequest(() =>
        fetch(`${reacts_url}uploads/map_files/company_${id}/geoJson.json`)
    );
};

const getMapsDropdowns = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(
            `fields/get/mapDropdown?farmerId=${
                paramss?.farmer_id || ""
            }&programId=${paramss?.program_id || ""}&harvest=${
                paramss?.year || ""
            }`
        )
    );
};

const getCompanyYearMapData = async (paramss) => {
    const url = process.env.REACT_APP_API_URL;
    return await protectedRequest(() =>
        fetch(
            `${url}uploads/map_files/company_${paramss.companyID}/${paramss.year}/geoJson.json`
        )
    );
};

const getAdminYearMapData = async (paramss) => {
    const url = process.env.REACT_APP_API_URL;
    return await protectedRequest(() =>
        fetch(`${url}uploads/map_files/admin/${paramss.year}/geoJson.json`)
    );
};
export {
    getMapsProgram,
    getCompanyMapData,
    getCompanyYearMapData,
    getMapsDropdowns,
    getAdminYearMapData,
};
