import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FormHelperText, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import FileInputNoPreview from "../../../common/FileInputNoPreview";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMemo } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const Methodology = ({ formik, edit }) => {
    const { programDetails } = useSelector((state) => state.program);
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };

    const program_registry = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`program_registry-label`}>
                        Registry
                    </InputLabel>
                    <Select
                        labelId={`program_registry-label`}
                        id="program_registry"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        name="program_registry"
                        value={formik.values.program_registry}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.program_registry &&
                            !!formik.errors.program_registry
                        }
                    >
                        <MenuItem value="FarmRegistry">Farm Registry</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.program_registry &&
                    formik.errors.program_registry && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_registry}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_registry,
        formik.errors.program_registry,
        formik.touched.program_registry,
    ]);

    const program_standard = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`program_standard-label`}>
                        Standard
                    </InputLabel>
                    <Select
                        labelId={`program_standard-label`}
                        id="program_standard"
                        name="program_standard"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        value={formik.values.program_standard}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.program_standard &&
                            !!formik.errors.program_standard
                        }
                    >
                        <MenuItem value="2bsvs">2 bsvs</MenuItem>
                        <MenuItem value="label-bas-carbone">
                            Label Bas Carbone
                        </MenuItem>
                        <MenuItem value="cargill-regen-connect">
                            Cargill Regen Connect
                        </MenuItem>
                        <MenuItem value="soil-capital">Soil Capital</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.program_standard &&
                    formik.errors.program_standard && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_standard}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_standard,
        formik.errors.program_standard,
        formik.touched.program_standard,
    ]);

    const program_methodology = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    required
                    fullWidth
                    id="program_methodology"
                    name="program_methodology"
                    label="Methodology"
                    variant="filled"
                    disabled={
                        edit && programDetails?.program_status === "registered"
                    }
                    value={formik.values.program_methodology}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.program_methodology &&
                        !!formik.errors.program_methodology
                    }
                    helperText={
                        formik.touched.program_methodology &&
                        formik.errors.program_methodology
                    }
                />
            </Grid>
        );
    }, [
        formik.values.program_methodology,
        formik.touched.program_methodology,
        formik.errors.program_methodology,
    ]);

    const program_methodology_contract = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FileInputNoPreview
                    formik={formik}
                    label="Methodology contract"
                    id="program_methodology_contract"
                    name="program_methodology_contract"
                    programDetails={programDetails}
                    value={formik.values.program_methodology_contract}
                    onChange={formik.handleChange}
                    required={edit}
                />
            </Grid>
        );
    }, [
        formik.values.program_methodology_contract,
        formik.touched.program_methodology_contract,
        formik.errors.program_methodology_contract,
    ]);

    const program_exclusive_farm = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`program_exclusive_farm-label`}>
                        Exclusive farm
                    </InputLabel>
                    <Select
                        labelId={`program_exclusive_farm-label`}
                        id="program_exclusive_farm"
                        name="program_exclusive_farm"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        value={formik.values.program_exclusive_farm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.program_exclusive_farm &&
                            !!formik.errors.program_exclusive_farm
                        }
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.program_exclusive_farm &&
                    formik.errors.program_exclusive_farm && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_exclusive_farm}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_exclusive_farm,
        formik.errors.program_exclusive_farm,
        formik.touched.program_exclusive_farm,
    ]);

    const program_exclusive_field = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`program_exclusive_field-label`}>
                        Exclusive field
                    </InputLabel>
                    <Select
                        labelId={`program_exclusive_field-label`}
                        id="program_exclusive_field"
                        name="program_exclusive_field"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        value={formik.values.program_exclusive_field}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.program_exclusive_field &&
                            !!formik.errors.program_exclusive_field
                        }
                    >
                        <MenuItem value="per-contract">Per contract</MenuItem>
                        <MenuItem value="per-year">Per Year</MenuItem>
                        <MenuItem value="non-exclusive">Non exclusive</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.program_exclusive_field &&
                    formik.errors.program_exclusive_field && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_exclusive_field}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_exclusive_field,
        formik.errors.program_exclusive_field,
        formik.touched.program_exclusive_field,
    ]);

    const program_registration_date = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        label="Registration Date"
                        sx={{
                            width: "100%",
                        }}
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "program_registration_date"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError("program_registration_date")}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        id="program_registration_date"
                        name="program_registration_date"
                        // value={formik.values.program_registration_date}
                        value={
                            formik.values.program_registration_date
                                ? dayjs(formik.values.program_registration_date)
                                : null
                        }
                        onChange={(value) =>
                            formik.setFieldValue(
                                "program_registration_date",
                                value.format("YYYY-MM-DD")
                            )
                        }
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.program_registration_date,
        formik.errors.program_registration_date,
        formik.touched.program_registration_date,
    ]);

    const program_crediting_period_start = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        label="Crediting period start date"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        sx={{
                            width: "100%",
                        }}
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "program_crediting_period_start"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError(
                                            "program_crediting_period_start"
                                        )}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        id="program_crediting_period_start"
                        name="program_crediting_period_start"
                        // value={formik.values.program_crediting_period_start}
                        value={
                            formik.values.program_crediting_period_start
                                ? dayjs(
                                      formik.values
                                          .program_crediting_period_start
                                  )
                                : null
                        }
                        onChange={(value) =>
                            formik.setFieldValue(
                                "program_crediting_period_start",
                                value.format("YYYY-MM-DD")
                            )
                        }
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.program_crediting_period_start,
        formik.errors.program_crediting_period_start,
        formik.touched.program_crediting_period_start,
    ]);

    const program_crediting_period_end = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        label="Crediting period end date"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        sx={{
                            width: "100%",
                        }}
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "program_crediting_period_end"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError(
                                            "program_crediting_period_end"
                                        )}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        id="program_crediting_period_end"
                        name="program_crediting_period_end"
                        // value={formik.values.program_crediting_period_end}
                        value={
                            formik.values.program_crediting_period_end
                                ? dayjs(
                                      formik.values.program_crediting_period_end
                                  )
                                : null
                        }
                        onChange={(value) =>
                            formik.setFieldValue(
                                "program_crediting_period_end",
                                value.format("YYYY-MM-DD")
                            )
                        }
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.program_crediting_period_end,
        formik.errors.program_crediting_period_end,
        formik.touched.program_crediting_period_end,
    ]);

    const program_credit_type = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" fullWidth required={false}>
                    <InputLabel id={`program_credit_type-label`}>
                        Credit type
                    </InputLabel>
                    <Select
                        labelId={`program_credit_type-label`}
                        id="program_credit_type"
                        name="program_credit_type"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        value={formik.values.program_credit_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.program_credit_type &&
                            !!formik.errors.program_credit_type
                        }
                    >
                        <MenuItem value="VCM Carbon credit">
                            VCM Carbon credit
                        </MenuItem>
                        <MenuItem value="Biomass Biofuel certificats">
                            Biomass Biofuel certificats
                        </MenuItem>
                        <MenuItem value="Water credit">Water credit</MenuItem>
                        <MenuItem value="Biodiversity credit">
                            Biodiversity credit
                        </MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.program_credit_type &&
                    formik.errors.program_credit_type && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.program_credit_type}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.program_credit_type,
        formik.errors.program_credit_type,
        formik.touched.program_credit_type,
    ]);

    const program_unit = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`program_unit-label`}>Unit</InputLabel>
                    <Select
                        labelId={`program_unit-label`}
                        id="program_unit"
                        name="program_unit"
                        disabled={
                            edit &&
                            programDetails?.program_status === "registered"
                        }
                        value={formik.values.program_unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.program_unit &&
                            !!formik.errors.program_unit
                        }
                    >
                        <MenuItem value="T CO2eq">T CO2eq</MenuItem>
                        <MenuItem value="g CO2 / Kg MS">g CO2 / Kg MS</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.program_unit && formik.errors.program_unit && (
                    <FormHelperText
                        sx={{
                            color: "#F00",
                            fontSize: "14px",
                        }}
                    >
                        {formik.errors.program_unit}
                    </FormHelperText>
                )}
            </Grid>
        );
    }, [
        formik.values.program_unit,
        formik.errors.program_unit,
        formik.touched.program_unit,
    ]);

    return (
        <>
            <Box
                sx={{
                    my: "49px",
                    "& .MuiInputLabel-asterisk": {
                        color: "red",
                    },
                }}
            >
                <Typography variant="h4" mb={3}>
                    Methodology
                </Typography>

                <Grid container spacing="30px">
                    {program_registry}
                    {program_standard}
                    {program_methodology}
                    {program_methodology_contract}
                    {program_exclusive_farm}
                    {program_exclusive_field}
                    {program_registration_date}
                    {program_crediting_period_start}
                    {program_crediting_period_end}
                    {program_credit_type}
                    {program_unit}
                </Grid>
            </Box>
        </>
    );
};

export default Methodology;
