import useAxios from "helpers/useAxios";
import protectedRequest from "helpers/protectedRequest.js";
import { getBlockChainToken } from "util/token";

const getRegistryCollections = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/all/registry_collection`, {
            params: {
                page: paramss.page,
                limit: paramss.limit,
                search: paramss.search,
                // ...(paramss.id && { companyId: paramss.id }),
            },
        })
    );
};

const getRetirementDetails = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/all/registry/${paramss}`)
    );
};

const getSCCListings = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/all/scc_collection`, {
            params: {
                page: paramss.page,
                limit: paramss.limit,
                search: paramss.search,
                // ...(paramss.id && { companyId: paramss.id }),
            },
        })
    );
};

const getUCOListings = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/all/uco_collection`, {
            params: {
                page: paramss.page,
                limit: paramss.limit,
                search: paramss.search,
                // ...(paramss.id && { companyId: paramss.id }),
            },
        })
    );
};

const retireProgramSCC = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/retired/scc`, {
            program_id: paramss.program_id,
            data: paramss.data,
            blockchain_token: getBlockChainToken(),
        })
    );
};

const getUCODetails = async (paramss) => {
    const bcToken = getBlockChainToken();
    return await protectedRequest(() =>
        useAxios.get(`fields/scc_uco/detail`, {
            params: {
                field_id: paramss.field_id,
                programId: paramss.program_id,
                address_key: paramss.address,
                blockchain_token: bcToken,
                type: paramss.type,
            },
        })
    );
};
const fetchUcoCertiPdf = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`fields/uco/get_certificate_PDF`, {
            params: {
                programId: paramss.program_id,
                fieldId: paramss.field_id,
                imageUrl: paramss.imageUrl,
            },
        })
    );
};

const fetchsSCCsActivity = async (paramss) => {
    const bcToken = getBlockChainToken();
    return await protectedRequest(() =>
        useAxios.get(
            `fields/scc/status_activity?page=${paramss?.page}&limit=${paramss?.limit}`,
            {
                params: {
                    program_id: paramss.program_id,
                    field_id: paramss.field_id,
                    blockchain_token: bcToken,
                },
            }
        )
    );
};

const fetchUcoStatusActivity = async (paramss) => {
    const bcToken = getBlockChainToken();
    return await protectedRequest(() =>
        useAxios.get(
            `fields/uco/status_activity?page=${paramss?.page}&limit=${paramss?.limit}`,
            {
                params: {
                    program_id: paramss.program_id,
                    field_id: paramss.field_id,
                    blockchain_token: bcToken,
                },
            }
        )
    );
};

const cancelsUCOs = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/cancel/uco`, {
            new_status: "Cancelled",
            fieldId: paramss.fieldId,
            programId: paramss.programId,
            blockchain_token: getBlockChainToken(),
        })
    );
};

const cancelSelectedUCOs = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/cancel/selected_uco`, {
            data: paramss,
            blockchain_token: getBlockChainToken(),
        })
    );
};

const cancelSelectedSCCs = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/cancel/selected_scc`, {
            data: paramss,
            blockchain_token: getBlockChainToken(),
        })
    );
};

const freezeSelectedSCCs = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/freeze/selected_scc`, {
            data: paramss,
            blockchain_token: getBlockChainToken(),
        })
    );
};

const cancelsUCOsWithCsv = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/cancel/uco/withcsv`, paramss)
    );
};
const cancelsSCCsWithCsv = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/cancel/scc/withcsv`, paramss)
    );
};

const cancelsSCCs = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/cancel/scc`, {
            new_status: "Cancelled",
            old_status: paramss.old_status,
            fieldId: paramss.fieldId,
            value: paramss.value,
            programId: paramss.programId,
            blockchain_token: getBlockChainToken(),
        })
    );
};

const freezesSCCsWithCsv = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/freeze/scc/withcsv`, paramss)
    );
};

const freezesSCCs = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.post(`fields/freeze/scc`, {
            new_status: "Frozen",
            old_status: paramss.old_status,
            fieldId: paramss.fieldId,
            value: paramss.value,
            programId: paramss.programId,
            blockchain_token: getBlockChainToken(),
        })
    );
};

export {
    getRegistryCollections,
    getSCCListings,
    getRetirementDetails,
    getUCOListings,
    retireProgramSCC,
    getUCODetails,
    fetchUcoStatusActivity,
    cancelsUCOs,
    cancelsSCCs,
    freezesSCCs,
    cancelsUCOsWithCsv,
    freezesSCCsWithCsv,
    cancelsSCCsWithCsv,
    fetchUcoCertiPdf,
    cancelSelectedUCOs,
    cancelSelectedSCCs,
    freezeSelectedSCCs,
    fetchsSCCsActivity,
};
