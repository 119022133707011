import {
    Box,
    Button,
    Grid,
    Input,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import CancelButton from "common/CancelButton";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { retireSCCs } from "redux/registrySlices";
import { sanitziedThreeDigitsAfterRgex } from "util/regex";
import sanitizedDigits from "util/sanitizedDigits";

export default function RetirementQuantity({ field_quantity, program_unit }) {
    const [quantity, setQuantity] = useState({});
    const dispatch = useDispatch();
    const sum = Object.values(quantity).reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue);
    }, 0);
    const navigates = useNavigate();

    const { retiringLoadings } = useSelector((state) => state.registery);
    // const [editedRows, setEditedRows] = useState(rows);
    const { id } = useParams();
    // const handleInputChange = (value, year, volumes) => {
    //     const valuess = Number(value) > Number(volumes) ? volumes : value;
    //     setQuantity((prevState) => ({
    //         ...prevState,
    //         [year]: valuess ?? 0,
    //     }));
    // };

    const handleInputChange = (value, year, volumes) => {
        if (sanitziedThreeDigitsAfterRgex.test(value) || value === "") {
            const numericValue = Number(value);
            if (numericValue > volumes) {
                setQuantity((prevState) => ({
                    ...prevState,
                    [year]: value ?? 0,
                    [year + "-error"]:
                        "Please add value below total quantity available",
                }));
            } else {
                setQuantity((prevState) => ({
                    ...prevState,
                    [year]: value ?? 0,
                    [year + "-error"]: "",
                }));
            }
        }
    };
    const handleSubmitsRetirement = () => {
        const data = [];
        Object.keys(quantity).forEach((key) => {
            const program = {
                ...field_quantity.find(
                    (element) =>
                        element.field_field_harvest_year === Number(key)
                ),
                ...{ retired_volume: Number(quantity[key]) },
            };
            if (!key.includes("error")) {
                data.push(program);
            }
        });
        dispatch(retireSCCs({ program_id: id, data: data })).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                navigates("/registry/collections");
            }
        });
    };

    const shouldDisable = () => {
        let disabled = false;
        for (let key in quantity) {
            if (key.includes("error") && quantity[key]) {
                disabled = true;
                return disabled;
            } else disabled = false;
        }

        return disabled;
    };

    const totalToRetires = useMemo(() => {
        return (
            <Grid container spacing="30px" mt={4}>
                <Grid item xs={12} sm={12} md={12} lg={6} spacing={"30px"}>
                    <Typography textAlign={"end"} fontWeight={"700"} mt={1}>
                        Total to Retire
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} spacing={"30px"}>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography
                            sx={{
                                padding: "8px 18px",
                                border: "1px solid #02353C",
                                borderRadius: 20,
                                color: "#02353C",
                                fontWeight: 500,
                                width: "100%",
                                ms: 1,
                            }}
                        >
                            {sum ? `${sum}` : 0} {`${program_unit}`}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }, [sum, program_unit]);

    const quantityAvailables = useMemo(() => {
        return (
            <Grid
                item
                sm={12}
                md={12}
                lg={6}
                spacing={"30px"}
                sx={{
                    "@media (max-width: 480px)": {
                        overflowX: "scroll",
                        width: "100%",
                    },
                }}
            >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontWeight: "600",
                                    fontFamily:
                                        "'Istok Web', sans-serif !important",
                                    fontSize: "14px",
                                    borderBottom: "2px solid #ccc",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                Vintage
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "600",
                                    fontFamily:
                                        "'Istok Web', sans-serif !important",
                                    fontSize: "14px",
                                    borderBottom: "2px solid #ccc",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                Quantity available
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            borderBottom: "1px solid #ccc",
                        }}
                    >
                        {field_quantity.map((row) => (
                            <TableRow
                                key={row.field_field_harvest_year}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.field_field_harvest_year}
                                </TableCell>
                                <TableCell>
                                    {`${
                                        sanitizedDigits(row.total_volume) || 0
                                    } ${row.program_unit}`}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        );
    }, [field_quantity]);

    return (
        <Box mt={8}>
            <Typography
                variant="h4"
                sx={{
                    textAlign: "left",
                    color: "#02353C",
                    fontWeight: "bold",
                    fontSize: {
                        xs: "16px",
                        md: "18px",
                        lg: "20px",
                    },
                }}
            >
                Add quantity to retire
            </Typography>

            <Box sx={{ maxWidth: "814px" }}>
                <Grid container spacing="30px">
                    {quantityAvailables}

                    <Grid item sm={12} md={12} lg={3} spacing={"30px"}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontWeight: "600",
                                            fontFamily:
                                                "'Istok Web', sans-serif !important",
                                            fontSize: "14px",
                                            borderBottom: "2px solid #ccc",
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                    >
                                        Quantity to retire
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {field_quantity.map((row) => (
                                    <>
                                        <TableRow
                                            key={row.field_field_harvest_year}
                                        >
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "#F5F5F5",
                                                    borderBottom: "0",
                                                    borderLeft:
                                                        "4px solid #fff",
                                                    padding: 0,
                                                }}
                                            >
                                                <Input
                                                    sx={{
                                                        outline: "none",
                                                        padding: "6.2px 12px",
                                                    }}
                                                    placeholder="Quantity"
                                                    name="name"
                                                    type="number"
                                                    value={
                                                        quantity[
                                                            row
                                                                .field_field_harvest_year
                                                        ]
                                                    }
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e.target.value,
                                                            row.field_field_harvest_year,
                                                            row.total_volume
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <span style={{ color: "red" }}>
                                            {
                                                quantity[
                                                    row.field_field_harvest_year +
                                                        "-error"
                                                ]
                                            }
                                        </span>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                {totalToRetires}
                <Box
                    sx={{ maxWidth: "602px" }}
                    mt={5}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                >
                    <Box mr={2}>
                        <CancelButton address={`/registry/collections`} />
                    </Box>
                    <Button
                        type="submit"
                        disabled={
                            retiringLoadings ||
                            shouldDisable() ||
                            !Object.values(quantity).some((res) => res)
                        }
                        onClick={handleSubmitsRetirement}
                        variant="contained"
                    >
                        {!retiringLoadings ? "Retire" : "Loading..."}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
