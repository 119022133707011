import { Button } from "@mui/material";
function JsonToCSV(data) {
    let JsonFields = Object.keys(data[0]);
    let csvStr = JsonFields.join(",") + "\n";
    data.forEach((element) => {
        let rowData = JsonFields.map((header) => element[header]);
        let rowString = rowData.join(",");
        csvStr += rowString + "\n";
    });
    downloadCSV(csvStr);
}

function downloadCSV(csvStr) {
    let hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = "list";
    hiddenElement.click();
}
const ConvertJsonToCsv = ({ jsonData }) => {
    return (
        <>
            <Button
                variant="contained"
                sx={{
                    padding: { xs: "4px 18px", md: "4px 23px" },
                    backgroundColor: "buttonSecondary.main",
                    border: "1px solid #000",
                    fontWeight: "500",
                    height: "38px",
                    mr: { sm: "20px" },
                    my: { xs: "5px", md: "0" },
                    "&:hover": {
                        color: "#fff",
                    },
                }}
                onClick={() => JsonToCSV(jsonData)}
            >
                Export .XLS
            </Button>
        </>
    );
};
export default ConvertJsonToCsv;
