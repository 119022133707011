import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import MapImg from "assets/images/big_map.png";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchAllsMapsDropdowns,
    fetchCompanyYearMapData,
    setFarmersManually,
} from "redux/mapsSlice";
import AutocompletePrograms from "./AutocompletePrograms";
import AutocompleteFarmers from "./AutocompleteFarmers";
import AllMaps from "./AllMaps";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";

const Map = () => {
    const dispatch = useDispatch();

    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].fields_slug.fields_map),
        unAuthUserRedirectTo: "/401",
    };

    const [year, setYear] = useState("");
    const [program, setProgram] = useState([]);
    const [farmers, setFarmers] = useState([]);
    const companyID = useSelector(
        (state) => state.account.accountDetails?.company?.id,
        shallowEqual
    );
    const role = useSelector(
        (state) => state.account.accountDetails?.role?.name,
        shallowEqual
    );
    useEffect(() => {
        dispatch(fetchAllsMapsDropdowns()).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                if (res.payload.data.harvestYears[0]) {
                    setYear(res.payload.data.harvestYears[0] || "");
                } else {
                    setProgram([]);
                    setFarmers([]);
                }
                // The above setProgram And The SetsFarmers is called because if the user will returns from the other map like the maps in the farmer details ,
                // then if there is not any year the chances is that there will be the old programs data which was fetched there ,
                // Since we are using the same variable names for storing these values in the Redux Toolkit Store
            }
        });
    }, []);

    useEffect(() => {
        if (year) {
            dispatch(fetchAllsMapsDropdowns({ year })).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    if (res.payload.data.programNames[0]) {
                        setProgram(res.payload.data.programNames);
                        dispatch(setFarmersManually(res.payload.data.farmers));
                        setFarmers(res.payload.data.farmers);
                    }
                }
            });
            if (role) {
                dispatch(fetchCompanyYearMapData({ companyID, year, role }));
            }
        }
    }, [year, companyID, role]);

    const years = useSelector((state) => state.maps.yearOptions, shallowEqual);

    const handleChange = (type, value) => {
        if (type === "years") {
            setYear(value);
            setProgram([]);
            setFarmers([]);
        } else if (type === "program") {
            setProgram(value);
            setFarmers([]);
            const idString = value.map((item) => item.id).join();
            // if (idString) {
            dispatch(
                fetchAllsMapsDropdowns({ year, program_id: idString })
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    if (idString) {
                        setFarmers(res.payload.data.farmers);
                    } else {
                        setFarmers([]);
                    }
                }
            });
        } else {
            setFarmers(value);
        }
    };

    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <Box
                    sx={{
                        maxWidth: 810,
                        mt: 4,
                    }}
                >
                    <Box component="form" noValidate autoComplete="off">
                        <Grid container spacing="30px">
                            {/* {fields.map((field) => ( */}
                            <Grid item xs={12} sm={6} lg={4}>
                                {/* {renderField(field)} */}
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id={`harvest-label`}>
                                        Harvest
                                    </InputLabel>
                                    <Select
                                        labelId={`harvest-label`}
                                        id="havest"
                                        name="harvest"
                                        value={year}
                                        onChange={(event) =>
                                            handleChange(
                                                "years",
                                                event.target.value
                                            )
                                        }
                                    >
                                        {/* <MenuItem
                                        // key={option.value}
                                        value={2023}
                                    >
                                        {2023}
                                    </MenuItem> */}

                                        {years.map((year) => {
                                            return (
                                                <MenuItem
                                                    key={year}
                                                    value={Number(year)}
                                                >
                                                    {year}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <AutocompletePrograms
                                    programs={program}
                                    handleProgram={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <AutocompleteFarmers
                                    farmers={farmers}
                                    handleFarmers={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* <Box
                component="img"
                alt="Map"
                src={MapImg}
                sx={{
                    width: "100%",
                    mt: 2,
                }}
            /> */}
                <Grid
                    sx={{
                        width: "100%",
                        mt: 2,
                    }}
                >
                    <AllMaps
                        selectedprograms={program.map((pr) => pr.program_code)}
                        selectedfarmers={farmers.map((fr) => Number(fr.id))}
                    />
                </Grid>
            </CheckpageAuth>
        </>
    );
};

export default Map;
