import { Typography, Box, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import logo from "../assets/images/logo.jpg";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import googleicon from "../assets/images/icons8-google.svg";
import { useDispatch, useSelector } from "react-redux";
import { googleLogin, passwordlessLoginHandler } from "redux/authentication";
import * as Yup from "yup";
import { useFormik } from "formik";
import Layout from "Layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "util/token";
import { showSuccessMessage } from "redux/toastSlice";
const CssTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "&:hover input": {
            borderColor: "red",
        },
        "& fieldset": {
            borderRadius: "10px",
            padding: "0px 22px",
        },
        "& .MuiInputBase-input": {
            padding: "19px 22px",
            fontSize: "20px",
            fontWeight: "200",
            color: "#252525",
            [theme.breakpoints.down("md")]: {
                padding: "19px 18px",
            },
            "&::placeholder": {
                fontSize: "20px",
                fontWeight: "200",
                color: "#000000",
                [theme.breakpoints.down("lg")]: {
                    fontSize: "16px",
                },
                [theme.breakpoints.down("xs")]: {
                    fontSize: "12px",
                },
            },
        },
    },
}));

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { login } = useSelector((state) => state);
    const isLoading = login?.loading;
    const isSignInWithEmail = login?.isSignInWithEmail;
    let cookieToken = getToken();
    useEffect(() => {
        if (login.emailSent) {
            formik.resetForm();
        }
    }, [login]);

    const handleAlreadyLogin = () => {
        dispatch(showSuccessMessage("You are already logged in"));
        navigate("/programs");
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email is Required"),
        }),
        onSubmit: (values) => {
            isLoading;
            if (cookieToken) {
                //for already login
                handleAlreadyLogin();
                return;
            }
            dispatch(passwordlessLoginHandler(values));
        },
    });

    const handleGoogleLogin = () => {
        if (cookieToken) {
            //for already login
            handleAlreadyLogin();
            return;
        }
        dispatch(googleLogin());
    };
    return (
        <Layout>
            <Box
                sx={{
                    maxWidth: "474px",
                    position: "relative",
                    margin: "0 auto",
                    padding: { xs: "0px 20px", md: "0px" },
                    zIndex: "11",
                }}
            >
                <Typography
                    variant="h1"
                    textAlign="center"
                    component="h2"
                    sx={{
                        color: "#fff",
                        fontSize: {
                            md: "34px",
                            sm: "30px",
                            xs: "20px",
                        },
                        fontWeight: "bold",
                        mb: "35px",
                        fontFamily: "'Anek Bangla', sans-serif !important",
                    }}
                >
                    Welcome on FarmRegistry App
                </Typography>
                <Box
                    sx={{
                        background: "#fff",
                        borderRadius: "10px",
                        padding: {
                            lg: "15px 36px 35px",
                            md: "15px 26px 35px",
                            xs: "15px 16px 35px",
                        },
                    }}
                >
                    {" "}
                    <Box component="div" textAlign="center">
                        <Box
                            component="img"
                            src={logo}
                            sx={{
                                width: "299px",
                                maxWidth: "100%",
                            }}
                        ></Box>
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        sx={{ mt: "15px" }}
                        onSubmit={formik.handleSubmit}
                    >
                        <Box>
                            <CssTextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="julien@farmregistry.eu"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                sx={{ width: "100%" }}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div style={{ color: "red", marginTop: "5px" }}>
                                    {formik.errors.email}
                                </div>
                            ) : null}
                        </Box>
                        <Box
                            component="button"
                            type="submit"
                            sx={{
                                fontFamily: "'Anek Bangla'",
                                background: "#02353C",
                                color: "#fff",
                                borderRadius: "10px",
                                width: "100%",
                                mt: "12px",
                                padding: "2px 22px",
                                fontSize: {
                                    lg: "20px",
                                    md: "16px",
                                    xs: "14px",
                                },
                                fontWeight: "bold",
                                textAlign: "center",
                                cursor: "pointer",
                                border: "0px",
                                height: "40px",
                                "&:hover": {
                                    background:
                                        "var(--degrade-bouton-selected, linear-gradient(0deg, #02353C 0%, #2EAF7D   100%))",
                                },
                            }}
                        >
                            {/* <CircularProgress size={30} color="inherit" /> */}
                            {/* Log in by email{""} */}
                            {isLoading && isSignInWithEmail
                                ? "Processing..."
                                : "Log in by email"}
                        </Box>
                        <Divider
                            sx={{
                                borderColor: "rgba(0,0,0,0.5)",
                                my: 4,
                            }}
                        />
                    </Box>
                    <Button
                        variant=""
                        type="submit"
                        onClick={handleGoogleLogin}
                        sx={{
                            border: "2px solid #e3e3e3!important",
                            width: "100%",
                            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.1)",
                            textTransform: "none",
                            color: "#646464 !important",
                            fontWeight: "600",
                            borderRadius: "8px",
                            fontSize: { md: "16px", xs: "14px" },
                            "&:hover": {
                                background: "#f5faf0 !important",
                                borderColor: "#ADD085 !important",
                            },
                        }}
                    >
                        <Box
                            component="img"
                            src={googleicon}
                            sx={{ height: "22px", mr: { lg: 2, xs: 1 } }}
                        ></Box>
                        Log with Google
                    </Button>
                </Box>
            </Box>
        </Layout>
    );
};

export default Login;
