import dayjs from "dayjs";
import { useSelector, shallowEqual } from "react-redux";

export function useDateFormatter() {
    const dateFormat =
        useSelector(
            (state) => state.account.accountDetails.user_dateformat,
            shallowEqual
        )?.toUpperCase() || "DD-MM-YYYY";

    const formatDates = (date) => {
        return dayjs(date).format(dateFormat);
    };
    const formatDate = (date) => {
        return dayjs(date).format("DD/MM/YYYY");
    };
    const formatActivityDate = (date) => {
        return dayjs(date).format("DD/MM/YYYY, hh:mm:ss A");
    };
    return { formatDates, formatActivityDate, formatDate };
}
