import axios from "axios";
import Cookies from "js-cookie";
import { getBlockChainToken } from "util/token";

const useBlockChainAxios = axios.create({
    baseURL: process.env.REACT_APP_BLOCKCHAIN_APP_URL,
});

useBlockChainAxios.interceptors.request.use(
    (config) => {
        const authToken = getBlockChainToken();
        config.headers["Authorization"] = `Bearer ${authToken}`;
        config.headers["Referrer-Policy"] = "strict-origin-when-cross-origin";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

useBlockChainAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status) {
            if ([401].includes(error.response.status)) {
                Cookies.remove("token");
                window.location.href = "/login";
                window.reload();
            }
        }

        if (error.message === "Network Error") {
            throw new Error("Server error");
            // return Promise.reject("Server error");
        }

        return Promise.reject(error);
    }
);

export default useBlockChainAxios;
