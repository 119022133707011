import protectedRequest from "helpers/protectedRequest";
import useAxios from "helpers/useAxios.js";

const getCertifications = async (params) => {
    return await protectedRequest(() =>
        useAxios.get(
            `certifications/all?programId=${params?.programId}&page=${params?.page}&limit=${params?.limit}`
        )
    );
};

const createCertifications = async (payload) => {
    return await protectedRequest(() =>
        useAxios.post(`certifications/create`, payload)
    );
};

export { getCertifications, createCertifications };
