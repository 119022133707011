import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage } from "redux/toastSlice";
import errorHandler from "helpers/errorhandler";

export const fetchRoles = createAsyncThunk(
    "roles/fetchRoles",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.getRoles(params);
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialState = {
    roles: [],
    isLoading: false,
    error: null,
};

const rolesSlice = createSlice({
    name: "roles",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchRoles.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.roles = payload;
                state.error = null;
            })
            .addCase(fetchRoles.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            });
    },
});

export default rolesSlice.reducer;
