import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function CancelButton({ address }) {
    return (
        <Button component={Link} to={address} variant="outlined">
            Cancel
        </Button>
    );
}
