import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AutocompleteAddress from "common/AutocompleteAddress";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { resetBackendcsvErrors } from "redux/farmersSlice";
import { resetBackendErrors } from "redux/projectSlice";

// const fields = [
//     {
//         id: "farmerStreet",
//         label: "Street",
//         variant: "filled",
//     },
//     { id: "farmerStreet-2", label: "Street 2", variant: "filled" },
//     { id: "zip-code", label: "Zip code", variant: "filled" },
//     { id: "farmerCity", label: "City", variant: "filled" },
//     { id: "farmerRegion", label: "Region", variant: "filled" },
//     { id: "farmerCountry", label: "Country", variant: "filled" },
// ];

const Address = ({ formik, fieldErrors, type }) => {
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };

    const dispatch = useDispatch();
    const onAddressSelect = (address) => {
        const farmerFullAddress = {
            farmerZipcode: address.zip_code,
            farmerCity: address.city,
            farmerCountry: address.country,
            farmerStreet: address.street,
            farmerRegion: address.region,
            farmerStreet2: address.street_two,
        };
        formik.setValues((prev) => ({ ...prev, ...farmerFullAddress }));
    };

    const handleResetErrors = (key) => {
        if (fieldErrors?.[key]) {
            type == "farmer"
                ? dispatch(resetBackendcsvErrors(key))
                : dispatch(resetBackendErrors(key));
        }
    };

    const autocompletionAddress = useMemo(() => {
        return (
            <Grid item xs={12} sm={12}>
                <AutocompleteAddress
                    formik={formik}
                    name="farmerFullAddress"
                    onAddressSelect={onAddressSelect}
                />
            </Grid>
        );
    }, [formik.values.farmerFullAddress]);
    const farmerStreet = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerStreet"
                    label="Street"
                    name="farmerStreet"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerStreet}
                    error={showError("farmerStreet")}
                    helperText={showError("farmerStreet")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerStreet,
        formik.touched.farmerStreet,
        formik.errors.farmerStreet,
    ]);
    const farmerStreet2 = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    id="farmerStreet2"
                    label="Address complement"
                    name="farmerStreet2"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerStreet2}
                />
            </Grid>
        );
    }, [formik.values.farmerStreet2]);
    const farmerZipcode = useMemo(() => {
        if (fieldErrors?.farmer_zipcode) {
            formik.setFieldError("farmerZipcode", fieldErrors?.farmer_zipcode);
        }
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    type="number"
                    id="farmerZipcode"
                    label="Zip code"
                    name="farmerZipcode"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={(e) => {
                        formik.handleChange(e);
                        handleResetErrors("farmer_zipcode");
                    }}
                    value={formik.values.farmerZipcode}
                    error={showError("farmerZipcode")}
                    helperText={showError("farmerZipcode")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerZipcode,
        formik.touched.farmerZipcode,
        formik.errors.farmerZipcode,
        fieldErrors?.farmer_zipcode,
    ]);
    const farmerCity = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerCity"
                    label="City"
                    name="farmerCity"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerCity}
                    error={showError("farmerCity")}
                    helperText={showError("farmerCity")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerCity,
        formik.touched.farmerCity,
        formik.errors.farmerCity,
    ]);
    const farmerRegion = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerRegion"
                    label="Region"
                    name="farmerRegion"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.farmerRegion}
                    error={showError("farmerRegion")}
                    helperText={showError("farmerRegion")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerRegion,
        formik.touched.farmerRegion,
        formik.errors.farmerRegion,
    ]);
    const farmerCountry = useMemo(() => {
        if (fieldErrors?.farmer_country) {
            formik.setFieldError("farmerCountry", fieldErrors?.farmer_country);
        }
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="farmerCountry"
                    label="Country"
                    name="farmerCountry"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={(e) => {
                        formik.handleChange(e);
                        handleResetErrors("farmer_country");
                    }}
                    // onChange={formik.handleChange}
                    value={formik.values.farmerCountry}
                    error={showError("farmerCountry")}
                    helperText={showError("farmerCountry")}
                />
            </Grid>
        );
    }, [
        formik.values.farmerCountry,
        formik.touched.farmerCountry,
        formik.errors.farmerCountry,
        fieldErrors?.farmer_country,
    ]);

    return (
        <>
            {formik.values.checkFarmerIds ? null : (
                <>
                    <Typography variant="h4">Address</Typography>
                    <Grid container rowSpacing="10px" columnSpacing="30px">
                        {autocompletionAddress}
                        {farmerStreet}
                        {farmerStreet2}
                        {farmerZipcode}
                        {farmerCity}
                        {farmerRegion}
                        {farmerCountry}
                    </Grid>
                </>
            )}
        </>
    );
};

export default Address;
