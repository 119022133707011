import { useMemo } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";

const UserPreferences = ({ formik }) => {
    const timezones = [
        {
            value: "UTC−10:00 SocietyIslands,Tuamotus,AustralIslands",
            label: "UTC−10:00 Society Islands, Tuamotus, Austral Islands",
        },
        {
            value: "UTC−09:30 Marquesas Islands",
            label: "UTC−09:30 Marquesas Islands",
        },
        {
            value: "UTC−09:00 Gambier Islands",
            label: "UTC−09:00 Gambier Islands",
        },
        { value: "Pacific/Clipperton", label: "UTC−08:00 Clipperton Island" },
        {
            value: "UTC−08:00 Clipperton Island",
            label: "UTC−04:00 (AST) Guadeloupe, Martinique, Saint Barthélemy, Saint Martin",
        },
        {
            value: "UTC−03:00 (PMST) French Guiana, Saint Pierre and Miquelon",
            label: "UTC−03:00 (PMST) French Guiana, Saint Pierre and Miquelon",
        },
        {
            value: "UTC+01:00 (CET) metropolitan France",
            label: "UTC+01:00 (CET) metropolitan France",
        },
        {
            value: "UTC+03:00 Mayotte, Scattered Islands in the Indian Ocean",
            label: "UTC+03:00 Mayotte, Scattered Islands in the Indian Ocean",
        },
        {
            value: "UTC+04:00 Réunion, Crozet Islands",
            label: "UTC+04:00 Réunion, Crozet Islands",
        },
        {
            value: "UTC+05:00 Kerguelen Islands, Saint Paul and Amsterdam Islands",
            label: "UTC+05:00 Kerguelen Islands, Saint Paul and Amsterdam Islands",
        },
        {
            value: "UTC+11:00 New Caledonia",
            label: "UTC+11:00 New Caledonia",
        },
        {
            value: "UTC+12:00 Wallis and Futuna",
            label: "UTC+12:00 Wallis and Futuna",
        },
    ];
    const user_dateformat = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`date_format-label`}>
                        Date Format
                    </InputLabel>
                    <Select
                        labelId={`date_format-label`}
                        id="user_dateformat"
                        name="user_dateformat"
                        value={formik.values.user_dateformat}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_dateformat &&
                            !!formik.errors.user_dateformat
                        }
                    >
                        <MenuItem value="DD-MM-YYYY">dd-mm-yyyy</MenuItem>
                        <MenuItem value="MM-DD-YYYY">mm-dd-yyyy</MenuItem>
                        <MenuItem value="DD-MM-YY">dd-mm-yy</MenuItem>
                        <MenuItem value="MM-DD-YY">mm-dd-yy</MenuItem>
                    </Select>
                    {formik.touched.user_dateformat &&
                        formik.errors.user_dateformat && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_dateformat}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_dateformat,
        formik.errors.user_dateformat,
        formik.touched.user_dateformat,
    ]);

    const user_measurement = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`measurement_units-label`}>
                        Measurement Units
                    </InputLabel>
                    <Select
                        labelId={`measurement_units-label`}
                        id="user_measurement"
                        name="user_measurement"
                        value={formik.values.user_measurement}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_measurement &&
                            !!formik.errors.user_measurement
                        }
                    >
                        <MenuItem key={1} value="Metric">
                            Metric
                        </MenuItem>
                    </Select>
                    {formik.touched.user_measurement &&
                        formik.errors.user_measurement && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_measurement}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_measurement,
        formik.errors.user_measurement,
        formik.touched.user_measurement,
    ]);

    const user_language = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`language-label`}>Language</InputLabel>
                    <Select
                        labelId={`language-label`}
                        id="user_language"
                        name="user_language"
                        value={formik.values.user_language}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_language &&
                            !!formik.errors.user_language
                        }
                    >
                        <MenuItem key={1} value="English">
                            English
                        </MenuItem>
                    </Select>
                    {formik.touched.user_language &&
                        formik.errors.user_language && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_language}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_language,
        formik.errors.user_language,
        formik.touched.user_language,
    ]);

    const user_timezone = useMemo(() => {
        return (
            <Box>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id={`timezone-label`}>Timezone</InputLabel>
                    <Select
                        labelId={`timezone-label`}
                        sx={{ maxWidth: "250px" }}
                        id="user_timezone"
                        name="user_timezone"
                        value={formik.values.user_timezone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.user_timezone &&
                            !!formik.errors.user_timezone
                        }
                    >
                        {timezones.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                        {/* <MenuItem key={1} value="UTC">
                            UTC
                        </MenuItem> */}
                    </Select>
                    {formik.touched.user_timezone &&
                        formik.errors.user_timezone && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.user_timezone}
                            </FormHelperText>
                        )}
                </FormControl>
            </Box>
        );
    }, [
        formik.values.user_timezone,
        formik.errors.user_timezone,
        formik.touched.user_timezone,
    ]);

    return (
        <>
            <Typography variant="h4" mt={1}>
                Preferences
            </Typography>
            <Box
                display="grid"
                gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {user_timezone}
                {user_dateformat}
                {user_measurement}
                {user_language}
            </Box>
        </>
    );
};

export default UserPreferences;
