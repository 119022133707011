import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMessage, showSuccessMessage } from "./toastSlice";
import Services from "services";
import errorHandler from "helpers/errorhandler";
import { resetStateHander } from "helpers/sanitizedFunction";

export const uploadCsvFieldFiles = createAsyncThunk(
    "fields/uploadCsvFieldFiles",
    async (params, thunkAPI) => {
        try {
            const response = await Services.uploadCsvFile(params);
            if (response.data.data) {
                if (params.type === "program-page") {
                    thunkAPI.dispatch(
                        showSuccessMessage(
                            "Fields Created Successfully!" || response.message
                        )
                    );
                }
                return {
                    response: response.data.data,
                };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));

            if (error.response.status === 406) {
                return thunkAPI.rejectWithValue(error.response.data.data);
            } else if (error.response.status === 422) {
                return thunkAPI.rejectWithValue(error.response.data.message);
            } else {
                return thunkAPI.rejectWithValue(error.response.data.data);
            }
        }
    }
);

export const uploadValuesFieldFiles = createAsyncThunk(
    "fields/uploadValuesFieldFiles",
    async (params, thunkAPI) => {
        try {
            const response = await Services.uploadValuesFiles(params);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Values added successfully!" || response.message
                    )
                );
                return {
                    response: response.data,
                };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));

            if (error.response.status === 406) {
                return thunkAPI.rejectWithValue(error.response.data.data);
            } else if (error.response.status === 422) {
                return thunkAPI.rejectWithValue(error.response.data.message);
            } else {
                return thunkAPI.rejectWithValue(error.response.data.data);
            }
        }
    }
);
//import result of fields csv
export const uploadCsvFieldsImportResult = createAsyncThunk(
    "fields/uploadCsvFieldsImportResult",
    async (params, thunkAPI) => {
        try {
            const response = await Services.uploadCsvImportResult(params);
            if (response.data.data) {
                // if (params.type === "program-page") {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Fields Created Successfully!" || response.message
                    )
                );
                // }

                return {
                    response: response.data.data,
                };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const fetchAllFields = createAsyncThunk(
    "fields/fetchAllFields",
    async (params, thunkAPI) => {
        try {
            const response = await Services.getFields(params);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getFieldDetails = createAsyncThunk(
    "fields/getFieldDetails",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getFieldDetails(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getFieldHistory = createAsyncThunk(
    "fields/getFieldHistory",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getFieldHistory(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchAllHistory = createAsyncThunk(
    "fields/fetchAllHistory",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getAllHistory(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchHistoryTimelines = createAsyncThunk(
    "fields/fetchHistoryTimelines",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getHistoryTimelines(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
//func to delete import fields data
export const deleteFieldImportData = createAsyncThunk(
    "fields/deleteFieldImportData",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.deleteImportResultData();
            if (response.data) {
                return response.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
const initialState = {
    fields: [],
    fieldsDetails: {},
    csvFieldsUploadData: [],
    fieldCsvErrors: {},
    isLoaded: false,
    error: null,
    uploadingsValues: false,
    fieldsValuesErrors: [],
    isLoading: false,
    fieldLoaded: false,
    count: null,
    importResultData: [],
    history: [],
    totalFields: null,
    totalCountTocehck: null,
    ucoCreatingLoading: null,
};
export const fieldSlice = createSlice({
    name: "fields",
    initialState,
    reducers: {
        resetFields: (state) => {
            resetStateHander(state, initialState);
        },
        resetsValuesErrors: (state) => {
            state.fieldsValuesErrors = [];
        },
        refetchFields: (state) => {
            state.fieldLoaded = false;
        },
        resetCsvFieldErrors: (state) => {
            state.fieldCsvErrors = {};
        },
        resetSccValueUploadErrors: (state) => {
            state.fieldsValuesErrors = [];
        },
        refetchCsvUploadFields: (state) => {
            state.fieldLoaded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadCsvFieldFiles.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.fieldCsvErrors = [];
            })
            .addCase(uploadCsvFieldFiles.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.fieldLoaded = true;
                state.csvFieldsUploadData = payload.response.data;
                state.importResultData = payload.response;
                state.fieldCsvErrors = [];
                state.totalFields = payload.response.total_fields;
                state.totalCountTocehck = payload.response.total_count_tocehck;
            })
            .addCase(uploadCsvFieldFiles.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.fieldCsvErrors = payload;
                state.fieldLoaded = false;
                showErrorMessage(payload);
            })
            .addCase(uploadValuesFieldFiles.pending, (state) => {
                state.uploadingsValues = true;
                state.error = null;
                state.fieldsValuesErrors = [];
            })
            .addCase(uploadValuesFieldFiles.fulfilled, (state) => {
                state.uploadingsValues = false;
                state.error = null;
                // state.fieldLoaded = true;
                // state.csvFieldsUploadData = payload.response.data;
                // state.importResultData = payload.response;
                state.fieldsValuesErrors = [];
            })
            .addCase(uploadValuesFieldFiles.rejected, (state, { payload }) => {
                state.uploadingsValues = false;
                state.fieldsValuesErrors = payload;
                // state.fieldLoaded = false;
                showErrorMessage(payload);
            })
            //import result of fields
            .addCase(uploadCsvFieldsImportResult.pending, (state) => {
                state.ucoCreatingLoading = true;
                state.error = null;
                state.fieldCsvErrors = [];
            })
            .addCase(uploadCsvFieldsImportResult.fulfilled, (state) => {
                state.ucoCreatingLoading = false;
                state.error = null;
                state.fieldLoaded = true;
                state.fieldCsvErrors = [];
            })
            .addCase(
                uploadCsvFieldsImportResult.rejected,
                (state, { payload }) => {
                    state.ucoCreatingLoading = false;
                    // state.fieldCsvErrors = payload;
                    state.fieldLoaded = false;
                    showErrorMessage(payload);
                }
            )
            .addCase(fetchAllFields.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllFields.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.count = payload.count;
                state.fields = payload.fields;
                state.fieldLoaded = true;
                state.error = null;
            })
            .addCase(fetchAllFields.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
                state.fieldLoaded = false;
            })
            .addCase(getFieldDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFieldDetails.fulfilled, (state, action) => {
                // Add user to the state array
                state.fieldsDetails = action.payload;
                state.error = null;
                state.isLoading = false;
            })
            .addCase(getFieldDetails.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchHistoryTimelines.pending, (state) => {
                state.historyLoading = true;
            })
            .addCase(fetchHistoryTimelines.fulfilled, (state, action) => {
                // Add user to the state array
                state.history = action.payload;
                // state.error = null;
                state.historyLoading = false;
            })
            .addCase(fetchHistoryTimelines.rejected, (state) => {
                state.historyLoading = false;
            })
            .addCase(deleteFieldImportData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteFieldImportData.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(deleteFieldImportData.rejected, (state) => {
                state.isLoading = false;
            });
    },
});
export const {
    refetchFields,

    resetFields,
    resetCsvFieldErrors,
    resetsValuesErrors,
    refetchCsvUploadFields,
    resetSccValueUploadErrors,
} = fieldSlice.actions;
export default fieldSlice.reducer;
