import Layout from "Layout";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
// import { Link } from "react-router-dom";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCompanyDetaill } from "redux/companiesSlice";
// import DetailsPageImage from "common/DetailsPageImage";
// import { getCompanyDetaill } from "redux/companiesSlice";
import { Skeleton } from "@mui/material";
import Modal from "common/Modal";
// import dayjs from "dayjs";
import UnderlineText from "common/UnderlineText";
import { useDateFormatter } from "customHooks/useDateFormatter";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
import { hideWalletModal } from "redux/authentication";
import { Magic } from "magic-sdk";
import { ethers } from "ethers";
import { storeWalletAddress } from "redux/walletSlice";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "1px 0px",
    minWidth: "91px",
    fontSize: "14px",
}));

const Program = () => {
    const [OpenWalletModal, setOpenWalletModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [isMagicLoading, setIsMagicLoading] = useState(false);
    const [walletAddress, setwalletAddress] = useState();
    const theme = useTheme();
    const { id } = useParams();
    const { formatDates } = useDateFormatter();
    const navigate = useNavigate();
    const { companylist, loadingCompanyList } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
            loadingCompanyList: state?.companylist?.loadingCompanyList,
        }),
        shallowEqual
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCompanyDetaill(id));
    }, [id]);

    const { can, isRoles, cannot } = useAccessPage();

    const isPrincipal = isRoles("principal");
    // const isAdmin = isRoles("admin");
    const routeAccess = {
        routeAccess: can(permissionSlug[0].company_slug.company_details),
        unAuthUserRedirectTo: "/401",
    };
    const information = [
        {
            title: "ID",
            info: companylist?.id,
        },
        {
            title: "Company name",
            info: capitalizeFirstLetter(companylist?.companyName),
        },
        {
            title: "Official ID",
            info: companylist?.companyOfficialId,
        },
        {
            title: "EU Intraco VAT",
            info: companylist?.companyVatNumber,
        },
        {
            title: "Phone",
            info: companylist?.companyPhone,
        },
        {
            title: "Email",
            info: companylist?.companyEmail,
        },
        {
            title: "Web site",
            info: companylist?.companyWebsite,
        },
        {
            title: "Logo",
            info: (
                <Box
                    component="img"
                    src={companylist?.companyLogo}
                    alt="Uploaded Image"
                />
            ),
        },
        {
            title: "Description",
            info: capitalizeFirstLetter(companylist?.companyDescription),
        },
    ];
    const profile = [
        {
            title: "Profile",
            info: capitalizeFirstLetter(companylist?.companyProfile),
        },
        {
            title: "Wallet management",
            info: capitalizeFirstLetter(companylist?.wallet?.wallet_management),
        },
        {
            title: "Wallet email",
            info:
                (companylist?.wallet?.wallet_email && (
                    <UnderlineText UnderlineText>
                        {companylist?.wallet?.wallet_email}
                    </UnderlineText>
                )) ||
                "-",
        },
        {
            title: "Wallet public address",
            info: companylist?.wallet?.wallet_public_key || "-",
        },
        {
            title: "Contract sign date",
            info: formatDates(companylist?.companySignDate),
        },
        {
            title: "Renewal date",
            info: formatDates(companylist?.companyRenewalDate),
        },
    ];
    const address = [
        {
            title: "Street",
            info: capitalizeFirstLetter(companylist?.companyStreet),
        },
        {
            title: "Address complement",
            info: capitalizeFirstLetter(companylist?.companyStreet2) || "-",
        },
        {
            title: "Zip Code",
            info: companylist?.companyZipcode,
        },
        {
            title: "City",
            info: capitalizeFirstLetter(companylist?.companyCity),
        },
        {
            title: "Region",
            info: capitalizeFirstLetter(companylist?.companyRegion),
        },
        {
            title: "Country",
            info: capitalizeFirstLetter(companylist?.companyCountry),
        },
    ];
    const handleWalletModal = async () => {
        setIsMagicLoading(true);
        try {
            await launchMagicLink();
        } catch (err) {
            // console.log("error connecting with Magic Link", err);
        }
        setIsMagicLoading(false);
        setOpenWalletModal(false);
        setSuccessModal(true);
    };
    const handleHideModal = () => {
        setOpenWalletModal(false);
        dispatch(hideWalletModal());
    };
    async function launchMagicLink() {
        const magic = new Magic(process.env.REACT_APP_MAGIC_LINK_API_KEY, {
            // this API key is not sensitive information
            network: {
                rpcUrl: process.env.REACT_APP_MAGIC_RPC_URL,
                chainId: process.env.REACT_APP_MAGIC_CHAIN_ID, // or 80001 for polygon testnet
            },
        });
        const email = companylist?.wallet?.wallet_email;
        // const email = "flo_t@carboncreditregistry.eu" // you can put your own to test

        await magic.auth.loginWithEmailOTP({ email });

        const magicProvider = await magic.wallet.getProvider();
        const provider = new ethers.BrowserProvider(magicProvider);

        // const signer = await provider.getSigner();
        // console.log("signer", signer); // this is the object we will use later to send transactions

        const account = (await provider.listAccounts())[0];
        setwalletAddress(account.address);

        // [TODO]: dispatch to add the account.address as the new company wallet_public_key

        if (account.address && email && id) {
            dispatch(
                storeWalletAddress({
                    companyId: id,
                    address: account.address,
                    email: email,
                })
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    dispatch(getCompanyDetaill(id));
                }
            });
        }
    }

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", backgroundColor: "primary" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb="10px"
                    >
                        <Breadcrumbs
                            sm="auto"
                            sources={[
                                {
                                    label: "admin",
                                    link: "/companies",
                                    canNavigate: true,
                                },
                                {
                                    label: "company",
                                    link: "/companies",
                                    canNavigate: true,
                                },
                                {
                                    label: companylist?.companyName,
                                    link: "",
                                },
                            ]}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-end",
                                },
                                pt: "2px !important",
                                mb: { xs: "10px", sm: 0 },
                            }}
                        >
                            <Button
                                onClick={() =>
                                    navigate(
                                        `/companies/edit/${companylist?.id}`
                                    )
                                }
                                variant="contained"
                                sx={{
                                    padding: { xs: "4px 18px", md: "4px 23px" },
                                    backgroundColor: "buttonSecondary.main",
                                    border: can(
                                        permissionSlug[0].company_slug
                                            .edit_company
                                    )
                                        ? "1px solid #000"
                                        : "  ",
                                    fontWeight: "500",
                                    my: { xs: "5px", md: "0" },
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                    mr: "10px",
                                }}
                                disabled={cannot(
                                    permissionSlug[0].company_slug.edit_company
                                )}
                            >
                                Modify
                            </Button>
                            {/*  button for wallet creation below*/}
                            {/* Object.entries(companylist)?.length !== 0 && */}
                            <Button
                                onClick={() => setOpenWalletModal(true)}
                                variant="contained"
                                sx={{
                                    padding: { xs: "4px 18px", md: "4px 23px" },
                                    backgroundColor: "buttonSecondary.main",
                                    border:
                                        companylist?.wallet
                                            ?.wallet_public_key ||
                                        !companylist?.wallet?.wallet_email ||
                                        !isPrincipal ||
                                        companylist?.companyProfile !==
                                            "developer"
                                            ? ""
                                            : "1px solid #000",
                                    fontWeight: "500",
                                    my: { xs: "5px", md: "0" },
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                    mr: "10px",
                                }}
                                disabled={
                                    companylist?.wallet?.wallet_public_key ||
                                    !companylist?.wallet?.wallet_email ||
                                    !isPrincipal ||
                                    companylist?.companyProfile !== "developer"
                                }
                            >
                                Create wallet
                            </Button>
                            <Modal
                                title={
                                    <Typography
                                        sx={{
                                            linHeight: "36px",
                                            fontSize: "28px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#02353C",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Welcome
                                        </span>{" "}
                                        <span
                                            style={{
                                                color: "#02353C",
                                                fontWeight: "normal",
                                            }}
                                        >
                                            on FarmRegistry !
                                        </span>
                                    </Typography>
                                }
                                body={
                                    <>
                                        <Typography
                                            id="modal-modal-description"
                                            sx={{ mt: 3 }}
                                        >
                                            {/**/}
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                <Box
                                                    component="span"
                                                    sx={{ color: "#02353C" }}
                                                >
                                                    {" "}
                                                    Your{" "}
                                                    <UnderlineText>
                                                        {" "}
                                                        first step{" "}
                                                    </UnderlineText>{" "}
                                                    with the blockchain :
                                                </Box>
                                                <span
                                                    style={{
                                                        color: "#02353C",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    {" "}
                                                    create company’s wallet
                                                </span>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    padding: "19px 0px",
                                                }}
                                            >
                                                Your company wallet has not been
                                                established yet. To unleash the
                                                full features of our platform,
                                                it is imperative that you create
                                                it promptly.
                                            </Typography>
                                            {""}
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    "& a": {
                                                        color: "#5282B6",
                                                    },
                                                }}
                                            >
                                                Creating a wallet will allow you
                                                to manage your Token
                                                efficiently, ensure secure
                                                transactions, and access various
                                                other exclusive features
                                                tailored to enhance your
                                                experience on our platform. It
                                                serves as a gateway to a suite
                                                of advanced functionalities
                                                designed to provide you with
                                                optimal control and management
                                                over your assets and
                                                transactions.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {" "}
                                                    Note :
                                                </span>{" "}
                                                To set up your company wallet,
                                                you will require access to the
                                                mailbox associated with{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {
                                                        companylist?.wallet
                                                            ?.wallet_email
                                                    }
                                                </span>
                                                {""}. This mailbox will receive
                                                verification links, and other
                                                crucial information necessary
                                                for the wallet creation process.
                                                Ensure that you have the proper
                                                permissions and access rights to
                                                this mailbox to avoid any
                                                disruptions in setting up your
                                                wallet.
                                            </Typography>
                                        </Typography>
                                    </>
                                }
                                footer={
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        mt="44px"
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={handleHideModal}
                                            sx={{ fontWeight: "500" }}
                                        >
                                            Not now
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="buttonPrimary"
                                            onClick={handleWalletModal}
                                        >
                                            {isMagicLoading ? (
                                                <CircularProgress color="inherit" />
                                            ) : (
                                                "Create wallet"
                                            )}
                                        </Button>
                                    </Stack>
                                }
                                open={OpenWalletModal}
                                close={() => setOpenWalletModal(false)}
                            />
                            {/* second modal for success  below*/}
                            <Modal
                                title={
                                    <Typography
                                        sx={{
                                            linHeight: "36px",
                                            fontSize: "28px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#02353C",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Congrats
                                        </span>{" "}
                                        <span
                                            style={{
                                                color: "#02353C",
                                                fontWeight: "normal",
                                            }}
                                        >
                                            and welcome on board !
                                        </span>
                                    </Typography>
                                }
                                body={
                                    <>
                                        <Typography
                                            id="modal-modal-description"
                                            sx={{ mt: 3 }}
                                        >
                                            {/**/}
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                <Box
                                                    component="span"
                                                    sx={{ color: "#02353C" }}
                                                >
                                                    <UnderlineText>
                                                        Your wallet
                                                    </UnderlineText>{" "}
                                                    has been created on
                                                    blockchain.
                                                </Box>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    padding: "19px 0px",
                                                }}
                                            >
                                                The official public address for
                                                your company&apos;s wallet is:
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {` ${walletAddress}`}
                                                </span>{" "}
                                                . <br />
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {" "}
                                                    Note :
                                                </span>{" "}
                                                This wallet is exclusively
                                                associated with the email :
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {" "}
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {
                                                            companylist?.wallet
                                                                ?.wallet_email
                                                        }
                                                    </span>
                                                    {""}
                                                </span>
                                                {""}.
                                            </Typography>
                                            {""}
                                            <Typography
                                                sx={{
                                                    lineHeight: "18px",
                                                    "& a": {
                                                        color: "#5282B6",
                                                    },
                                                }}
                                            >
                                                This is a significant milestone,
                                                and our entire team is excited
                                                to welcome your company to this
                                                new phase of our journey
                                                together.
                                                <br />
                                                Don’t be afraid. The public key
                                                is already saved on your
                                                account. On our platform, this
                                                wallet is more than an NFT
                                                wallet. It’s your gateway to a
                                                plethora of features and
                                                opportunities. With this wallet,
                                                you are now equipped to engage
                                                in seamless transactions and
                                                experience the full breadth of
                                                what our platform has to offer.
                                                Whether you’re looking to
                                                transfer assets on this new
                                                blockchain-based services, we
                                                have designed everything with
                                                your convenience and security in
                                                mind.
                                                <br />
                                                Our primary goal has always been
                                                to provide our users with a
                                                comprehensive, user-friendly,
                                                and secure environment. With
                                                your new wallet, you are now at
                                                the heart of this sustainable
                                                and digital ecosystem we have
                                                crafted, and we’re confident
                                                you’ll find it both intuitive
                                                and empowering. <br /> We want
                                                to take this opportunity to
                                                thank you for your trust and for
                                                choosing our platform. Our
                                                dedicated team is continuously
                                                working to enhance the user
                                                experience and introduce even
                                                more features. If you have any
                                                questions or need assistance,
                                                our support team is always here
                                                to help.
                                            </Typography>
                                        </Typography>
                                    </>
                                }
                                footer={
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        mt="44px"
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            variant="contained"
                                            color="buttonPrimary"
                                            onClick={() =>
                                                setSuccessModal(false)
                                            }
                                        >
                                            {"Let's go"}
                                        </Button>
                                    </Stack>
                                }
                                open={successModal}
                                close={() => setSuccessModal(false)}
                            />
                            {/* <Link
                            to={`/users/add/${id}`}
                        // state={{ companyData: companylist }}
                        > */}
                            <Button
                                variant="contained"
                                color="buttonPrimary"
                                onClick={() => navigate(`/users/add/${id}`)}
                                sx={{
                                    my: {
                                        xs: "5px",
                                        md: "0",
                                        textTransform: "none",
                                    },
                                }}
                                disabled={
                                    companylist?.companyStatus === "inactive" ||
                                    cannot(
                                        permissionSlug[0].users_slug.create_user
                                    )
                                }
                            >
                                Add a user
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {loadingCompanyList ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100px" }}
                    />
                ) : (
                    <Typography variant="h2">
                        {capitalizeFirstLetter(companylist?.companyName)}
                        <StyledChip
                            label={capitalizeFirstLetter(
                                companylist?.companyStatus
                            )}
                            icon={
                                <DoneIcon
                                    color="primary.white"
                                    sx={{ fontSize: "16px" }}
                                />
                            }
                            sx={{
                                backgroundColor:
                                    theme.palette.statusColors[
                                        companylist?.companyStatus
                                    ],
                                color: "primary.white",
                                ml: "20px",
                            }}
                        />
                    </Typography>
                )}

                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        sx={{ mt: 0 }}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Information
                            </Typography>
                            {information.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    alignItems={res?.alignItems}
                                    margin={res?.alignItems}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "16px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            minWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            mb: { xs: "5px", sm: 0 },
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: {
                                                xs: "100%",
                                                sm: "calc(100% - 220px)",
                                                lg: "calc(100% - 180px)",
                                            },
                                        }}
                                    >
                                        {res.button ? (
                                            <Box component="div">
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        maxWidth: "78px",
                                                        background: "#1ED800",
                                                        borderRadius: "40px",
                                                        boxShadow: "none",
                                                        fontSize: "14px",
                                                        textTransform: "none",
                                                        color: "#fff",
                                                        padding: "1px 11px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {res.iconPath} {res.button}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                component="div"
                                                sx={{
                                                    maxWidth: {
                                                        xs: "100%",
                                                        xl: "70%",
                                                    },
                                                }}
                                            >
                                                {loadingCompanyList ? (
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{
                                                            fontSize: "1rem",
                                                        }}
                                                    />
                                                ) : (
                                                    res.info
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        {(isRoles("admin") || isRoles("principal")) && (
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                sx={{
                                    pt: "2px !important",
                                    mt: { xs: "25px" },
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "left",
                                        color: "#02353C",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "16px",
                                            md: "18px",
                                            lg: "20px",
                                        },
                                    }}
                                >
                                    Profile
                                </Typography>
                                {profile.map((res, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        sx={{
                                            margin: "13px -13px 0",
                                            lineHeight: "1.2",
                                            fontSize: {
                                                xs: "14px",
                                                lg: "16px",
                                            },
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: {
                                                    xs: "left",
                                                    lg: "right",
                                                },
                                                padding: "0px 12px !important",
                                                maxWidth: {
                                                    xs: "100%",
                                                    sm: "220px",
                                                },
                                                minWidth: {
                                                    xs: "100%",
                                                    sm: "220px",
                                                },
                                            }}
                                        >
                                            {res.title}
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                padding: "0px 12px !important",
                                                width: {
                                                    xs: "100%",
                                                    sm: "calc(100% - 220px)",
                                                },
                                                maxWidth: {
                                                    xs: "100%",
                                                    xl: "70%",
                                                },
                                            }}
                                        >
                                            {loadingCompanyList ? (
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem" }}
                                                />
                                            ) : (
                                                res.info
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{ pt: "2px !important", mt: { xs: "25px" } }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Address
                            </Typography>
                            {address.map((res, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={1}
                                    sx={{
                                        margin: "13px -13px 0",
                                        lineHeight: "1.2",
                                        fontSize: { xs: "14px", lg: "16px" },
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: {
                                                xs: "left",
                                                lg: "right",
                                            },
                                            padding: "0px 12px !important",
                                            maxWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                            minWidth: {
                                                xs: "100%",
                                                sm: "220px",
                                                lg: "180px",
                                            },
                                        }}
                                    >
                                        {res.title}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "700",
                                            padding: "0px 12px !important",
                                            width: {
                                                xs: "100%",
                                                sm: "calc(100% - 220px)",
                                                lg: "calc(100% - 180px)",
                                            },
                                            maxWidth: { xs: "100%", lg: "70%" },
                                        }}
                                    >
                                        {loadingCompanyList ? (
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem" }}
                                            />
                                        ) : (
                                            res.info
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </CheckpageAuth>
        </Layout>
    );
};

export default Program;
