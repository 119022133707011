import protectedRequest from "helpers/protectedRequest";
import useAxios from "helpers/useAxios.js";
const getUsersList = async (payload) => {
    return await protectedRequest(() =>
        useAxios.get(`users/all`, {
            params: {
                page: payload.page,
                limit: payload.limit,
                search: payload.search,
                ...(payload.id && { companyId: payload.id }),
            },
        })
    );
};
const EditUser = async (payload) => {
    return await protectedRequest(() =>
        useAxios.patch(`users/${payload.id}`, payload)
    );
};
const AddUser = async (payload) => {
    return await protectedRequest(() => useAxios.post(`users/create`, payload));
};
const GetUser = async (id) => {
    return await protectedRequest(() => useAxios.get(`users/${id}`));
};
const usersRoles = async () => {
    return await protectedRequest(() => useAxios.get(`roles/all`));
};
export { getUsersList, EditUser, AddUser, GetUser, usersRoles };
