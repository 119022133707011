import Grid from "@mui/material/Grid";
import { Skeleton, Typography } from "@mui/material";
import OpenPreviousPage from "common/OpenPreviousPage";
import { useSelector } from "react-redux";

export default function FieldDetailsTitle() {
    const { fieldsDetails: { id, fieldName } = {}, isLoading } = useSelector(
        (state) => state.field
    );
    return (
        <>
            <Grid item xs={12} md={8}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    <OpenPreviousPage />{" "}
                    {!isLoading ? (
                        `Field ${fieldName} #${id}`
                    ) : (
                        <Skeleton
                            variant="text"
                            sx={{ fontSize: "3rem", width: "20rem" }}
                        />
                    )}
                </Typography>
            </Grid>
        </>
    );
}
