import { ReactComponent as ContractIcon } from "assets/icons/contracticon.svg";

export default function PdfIcon({ address }) {
    return (
        <a
            target="_blank"
            rel="noreferrer"
            href={address}
            download="certificate"
        >
            <ContractIcon />
        </a>
    );
}
