import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";
// import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import UserEditFormContainer from "components/admin/user/UserEditForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetUser } from "redux/users";
import { useEffect } from "react";
// import { useParams } from "react-router-dom";

// import Stack from "@mui/material/Stack";
// import CompanyInformation from "components/admin/form/CompanyInfomation";
// import CompanyProfile from "components/admin/form/CompanyProfile";
// import CompanyAddress from "components/admin/form/CompanyAddress";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const ModifyUser = () => {
    const { id } = useParams();

    const { usersList } = useSelector(
        (state) => ({
            usersList: state.usersList.GetUser.data,
            loading: state?.usersList?.loading,
        }),
        shallowEqual
    );
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].users_slug.edit_user),
        unAuthUserRedirectTo: "/401",
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(GetUser(id));
        }
    }, [id]);

    if (usersList) {
        return (
            <Layout>
                <CheckpageAuth auth={routeAccess}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb="10px"
                    >
                        <Breadcrumbs
                            sources={[
                                {
                                    label: "users",
                                    link: "/users",
                                    canNavigate: true,
                                },
                                {
                                    label: "user",
                                    link: `/users/detail/${id}`,
                                    canNavigate: true,
                                },
                                {
                                    label: "modify user",
                                    link: "",
                                },
                            ]}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-end",
                                },
                                pt: "2px !important",
                            }}
                        ></Grid>
                    </Grid>

                    <Typography variant="h2">Modify user detail</Typography>
                    <Typography variant="body1" mt={1} mb={3}>
                        Please fill the following form to edit details.
                    </Typography>

                    <UserEditFormContainer userDetail={usersList} edit={true} />
                </CheckpageAuth>
            </Layout>
        );
    }

    return "";
};

export default ModifyUser;
