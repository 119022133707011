import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage, showSuccessMessage } from "redux/toastSlice";
import { resetStateHander } from "helpers/sanitizedFunction";
import errorHandler from "helpers/errorhandler";

export const usersList = createAsyncThunk(
    "users/usersList",
    async (payload, thunkAPI) => {
        try {
            const response = await Services.getUsersList(payload);
            return response.data.data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(error.message));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const editUser = createAsyncThunk(
    "users/editUser",
    async (payload, thunkAPI) => {
        try {
            const { data } = await Services.EditUser(payload);
            thunkAPI.dispatch(showSuccessMessage("User updated successfully!"));
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(error.message));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const AddUser = createAsyncThunk(
    "users/AddUser",
    async (payload, thunkAPI) => {
        try {
            const { data } = await Services.AddUser(payload);
            thunkAPI.dispatch(showSuccessMessage("User added successfully!"));
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const GetUser = createAsyncThunk(
    "users/GetUser",
    async (id, thunkAPI) => {
        try {
            const { data } = await Services.GetUser(id);
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(error.message));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const getAllUserRoles = createAsyncThunk(
    "users/getAllUserRoles",
    async (id, thunkAPI) => {
        try {
            const response = await Services.usersRoles();
            return response.data.data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(error.message));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialState = {
    loading: false,
    users: [],
    count: null,
    editUser: [],
    AddUser: [],
    GetUser: [],
    error: null,
    userLoaded: false,
    userRole: [],
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        resetUsers: (state) => {
            resetStateHander(state, initialState);
        },
        refetchUsers: (state) => {
            state.userLoaded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(usersList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.users = [];
            })
            .addCase(usersList.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.users = payload.users;
                state.count = payload.count;
                state.error = null;
            })
            .addCase(usersList.rejected, (state, { payload }) => {
                state.loading = false;
                showErrorMessage(payload);
            })
            .addCase(editUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.editUser = payload;
                state.error = null;
            })
            .addCase(editUser.rejected, (state, { payload }) => {
                state.loading = false;
                showErrorMessage(payload);
            })
            .addCase(AddUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.AddUser = payload;
                state.error = null;
            })
            .addCase(AddUser.rejected, (state, { payload }) => {
                state.loading = false;
                showErrorMessage(payload);
            })
            .addCase(GetUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.GetUser = payload;
                state.error = null;
            })
            .addCase(GetUser.rejected, (state, { payload }) => {
                state.loading = false;
                showErrorMessage(payload);
            })
            .addCase(getAllUserRoles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllUserRoles.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userRole = payload;
                state.error = null;
            })
            .addCase(getAllUserRoles.rejected, (state, { payload }) => {
                state.loading = false;
                showErrorMessage(payload);
            });
    },
});
export const { resetUsers, refetchUsers } = usersSlice.actions;
export default usersSlice.reducer;
