import * as React from "react";
import { useState } from "react";
import Breadcrumbs from "common/Breadcrumb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Button, Box, Grid, Stack } from "@mui/material";
import Modal from "common/Modal";
import { Link } from "react-router-dom";
import CustomDropzone from "common/CustomDropzone";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import { getBlockChainToken } from "util/token";
import { useDispatch, useSelector } from "react-redux";
import { cancelSelectedUCOs, cancelsUCOsWithCsv } from "redux/registrySlices";
import CloseIcon from "@mui/icons-material/Close";
import { showErrorMessage } from "redux/toastSlice";
import csvFieldsFile from "assets/data/cancel_uco_file77.csv";

export default function UcoHeader({ selects, setSelects, setRefetch }) {
    const isMobile = useMediaQuery("(max-width:500px)");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [OpenSelectedUcoModal, setOpenSelectedUcoModal] = useState();
    const [OpenMissCancellationModal, setOpenMissCancellationModal] =
        useState();
    const [error, setError] = useState(null);
    const open = Boolean(anchorEl);
    const { cannot } = useAccessPage();
    const { UCOSCounts } = useSelector((state) => state.registery);
    const [selectError, setSelectError] = useState(null);
    const { cancellingUCOs } = useSelector((state) => state.registery);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    // const [elmt, setelmt] = React.useState(null);
    // const open2 = Boolean(elmt);
    // const handleClick2 = (event) => {
    //     setelmt(event.currentTarget);
    // };
    // const handleClose2 = () => {
    //     setelmt(null);
    // };
    const [file, setFile] = useState([]);
    const handleCancels = () => {
        setOpenMissCancellationModal(false);
        setFile("");
        setError(null);
        // dispatch(resetSccValueUploadErrors());
    };
    const handleCloseValueFile = (event) => {
        event.stopPropagation();
        setFile("");
        // dispatch(resetSccValueUploadErrors());
    };
    const handleMassCancel = () => {
        setAnchorEl(false);
        setOpenMissCancellationModal(true);
    };
    const cancelsMass = () => {
        const formData = new FormData();
        if (!file[0]) {
            setError("Please select file");
        } else {
            const blockchainToken = getBlockChainToken();
            formData.append("file", file[0]);
            // formData.append("programId", id);
            formData.append("blockchain_token", blockchainToken);
            dispatch(cancelsUCOsWithCsv(formData)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    // dispatch(refetchProgramFields());
                    setOpenMissCancellationModal(false);
                    setFile("");
                    setError("");
                    // navigate(`/import-result?programId=${id}`);
                }
            });
        }
    };
    const handleSelectCancellations = () => {
        setAnchorEl(false);
        if (selects.length > 0) {
            setOpenSelectedUcoModal(true);
        } else {
            dispatch(
                showErrorMessage("Please select atleast one UCO to cancel")
            );
        }
    };
    const handleSelectCancel = () => {
        setSelectError(null);
        setOpenSelectedUcoModal(false);
    };
    const selectedCancel = () => {
        if (selects.length < 1) {
            setSelectError("Please select any UCO checkbox first");
        } else {
            dispatch(cancelSelectedUCOs(selects)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    // dispatch(refetchProgramFields());
                    setOpenSelectedUcoModal(false);
                    setSelects([]);
                    setSelectError(null);
                    setRefetch((vl) => {
                        return !vl;
                    });
                }
            });
        }
    };

    return (
        <>
            <Breadcrumbs
                sm="auto"
                sources={[
                    {
                        label: "registry",
                        link: "",
                    },
                    {
                        label: isMobile
                            ? "UCO"
                            : "unique certificate of origin",
                        link: "",
                    },
                ]}
                otherContent={
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "left", sm: "center", md: "left" },
                            marginTop: "1px !important",
                            paddingLeft: "10px",
                            display: { sm: "none" },
                            fontSize: "16px",
                        }}
                    >
                        {UCOSCounts} UCO Token
                    </Typography>
                }
            />
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                {/* <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick2}
                    endIcon={<KeyboardArrowDownIcon />}
                    disabled={cannot(permissionSlug[0].uco_slug.cancel_UCO)}
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                        display: {
                            xs: "none",
                            sm: "flex",
                        },
                        justifyContent: { sm: "center", md: "flex-end" },
                    }}
                >
                    Freeze UCO
                </Button>
                <Menu
                    sx={{
                        "& .MuiPaper-root": {
                            minWidth: 180,
                        },
                    }}
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={elmt}
                    open={open2}
                    onClose={handleClose2}
                    
                >
                    <MenuItem onClick={handleClose}>Selected UCO</MenuItem>
                    <MenuItem onClick={handleClose}>Mass freeze</MenuItem>
                </Menu> */}
                {/* <Button
                    variant="contained"

                    sx={{
                        mr: "20px",
                    }}
                >
                    Freeze UCO
                </Button> */}
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    disabled={cannot(permissionSlug[0].uco_slug.cancel_UCO)}
                    sx={{
                        my: {
                            xs: "5px",
                            md: "0",
                            textTransform: "none",
                        },
                        ml: "12px",
                        display: {
                            xs: "none",
                            sm: "flex",
                        },
                        justifyContent: { sm: "center", md: "flex-end" },
                    }}
                >
                    Cancel UCO
                </Button>
                <Menu
                    sx={{
                        "& .MuiPaper-root": {
                            minWidth: 180,
                        },
                    }}
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => handleSelectCancellations()}
                        disableRipple
                    >
                        Selected UCO
                    </MenuItem>
                    <MenuItem onClick={() => handleMassCancel()} disableRipple>
                        Mass cancellation
                    </MenuItem>
                </Menu>
            </Grid>

            <Modal
                title={
                    <Typography variant="h2">
                        Cancellation of selected UCO
                    </Typography>
                }
                body={
                    <>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 4, textAlign: "left" }}
                        >
                            <Typography sx={{ mb: "25px" }}>
                                Are you sure to cancel those selected UCO ?
                            </Typography>
                        </Typography>
                        <Box
                            component="p"
                            sx={{ color: "red", textAlign: "center" }}
                        >
                            {selectError}
                        </Box>
                    </>
                }
                footer={
                    <Stack
                        direction="row"
                        spacing={1}
                        mt="84px"
                        justifyContent="center"
                    >
                        <Button
                            variant="outlined"
                            disabled={cancellingUCOs}
                            onClick={() => handleSelectCancel()}
                            sx={{ fontWeight: "500" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            disabled={cancellingUCOs}
                            onClick={() => selectedCancel()}
                            color="buttonPrimary"
                        >
                            {!cancellingUCOs ? "Confirm" : "Processing"}
                        </Button>
                    </Stack>
                }
                open={OpenSelectedUcoModal}
                close={() => handleSelectCancel()}
            />
            <Modal
                title={
                    <Typography variant="h2">
                        UCO cancellation batch import
                    </Typography>
                }
                body={
                    <>
                        <Typography id="modal-modal-description" sx={{ mt: 5 }}>
                            <Typography sx={{ lineHeight: "18px" }}>
                                Cancel UCO by using .csv or .xls file.
                            </Typography>
                            <Typography
                                sx={{
                                    lineHeight: "18px",
                                    "& a": {
                                        color: "#5282B6",
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                Template File:{" "}
                                <Link href="#" color="#5282B6"></Link>
                                <a
                                    href={csvFieldsFile}
                                    target="_blank"
                                    rel="noreferrer"
                                    download="csv file"
                                    style={{ color: "#5282B6" }}
                                >
                                    cancel_uco_file.csv
                                </a>
                            </Typography>
                        </Typography>
                        <Box sx={{ margin: "35px auto" }}>
                            <CustomDropzone
                                onChange={(res) => setFile(res)}
                                files={file}
                                content={
                                    <>
                                        {file && file[0]?.name ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",

                                                    color: "rgb(63, 208, 201)",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        maxWidth: "200px",
                                                        fontSize: "15px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                >
                                                    {file[0].name}
                                                </span>
                                                <CloseIcon
                                                    style={{
                                                        fontSize: "20px",
                                                        marginLeft: "5px",
                                                        color: "grey",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={
                                                        handleCloseValueFile
                                                    }
                                                />
                                            </Box>
                                        ) : (
                                            <>
                                                <Box
                                                    component="p"
                                                    sx={{ mt: 0 }}
                                                >
                                                    Drop your .csv file here{" "}
                                                    <br />
                                                    or upload it from your
                                                    computer
                                                </Box>
                                                <Button
                                                    sx={{
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                        border: "1px solid #000",
                                                        borderRadius: "20px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    <IconFileupload />
                                                    <Box
                                                        component="span"
                                                        sx={{ ml: "10px" }}
                                                    >
                                                        {" "}
                                                        Upload
                                                    </Box>
                                                </Button>
                                                <Box
                                                    component="p"
                                                    sx={{ color: "red" }}
                                                >
                                                    {error}
                                                </Box>
                                            </>
                                        )}
                                    </>
                                }
                            />
                        </Box>
                    </>
                }
                footer={
                    <Stack
                        direction="row"
                        spacing={1}
                        mt="44px"
                        justifyContent="flex-end"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => handleCancels()}
                            sx={{ fontWeight: "500" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => cancelsMass()}
                            disabled={cancellingUCOs}
                            variant="contained"
                            color="buttonPrimary"
                        >
                            {!cancellingUCOs ? "Confirm" : "Processing"}
                        </Button>
                    </Stack>
                }
                open={OpenMissCancellationModal}
                close={() => handleCancels()}
            />
        </>
    );
}
