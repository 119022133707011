import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage, showSuccessMessage } from "redux/toastSlice";
import errorHandler from "helpers/errorhandler";
import { resetStateHander } from "helpers/sanitizedFunction";

export const companiesList = createAsyncThunk(
    "companies/companiesList",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.getCompanyList(params);
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const createCompany = createAsyncThunk(
    "companies/createCompany",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.createdCompany(params);
            if (data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        "Company created successfully!" || data.message
                    )
                );
            }

            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const getCompanyDetaill = createAsyncThunk(
    "companies/getCompanyDetail",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.getCompanyDetail(params);
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const getCompanyUpdate = createAsyncThunk(
    "companies/getCompanyUpdate",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.companyUpdate(params);
            if (data) {
                thunkAPI.dispatch(
                    showSuccessMessage("Company updated successfully!")
                );
            }
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialState = {
    loadingCompanyList: false,
    companyList: [],
    createComapnyData: [],
    companyDetail: [],
    companyUpdate: [],
    error: null,
    companyLoaded: false,
};

const companySlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
        refetchCompany: (state) => {
            state.companyLoaded = false;
        },
        resetCompanies: (state) => {
            resetStateHander(state, initialState);
        },
        resetCompaniesDetail: (state) => {
            state.companyDetail = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(companiesList.pending, (state) => {
                state.loadingCompanyList = true;
                state.error = null;
                state.companyList = [];
            })
            .addCase(companiesList.fulfilled, (state, { payload }) => {
                state.loadingCompanyList = false;
                state.companyList = payload;
                state.companyLoaded = true;
                state.error = null;
            })
            .addCase(companiesList.rejected, (state, { payload }) => {
                state.loadingCompanyList = false;
                showErrorMessage(payload);
            })
            .addCase(createCompany.pending, (state) => {
                state.loadingCompanyList = true;
                state.error = null;
                state.createComapnyData = [];
            })
            .addCase(createCompany.fulfilled, (state, { payload }) => {
                state.loadingCompanyList = false;
                state.createComapnyData = payload;
                state.error = null;
            })
            .addCase(createCompany.rejected, (state, { payload }) => {
                state.loadingCompanyList = false;
                showErrorMessage(payload);
            })
            .addCase(getCompanyDetaill.pending, (state) => {
                state.loadingCompanyList = true;
                state.companyDetail = [];
                state.error = null;
            })
            .addCase(getCompanyDetaill.fulfilled, (state, { payload }) => {
                state.loadingCompanyList = false;
                state.companyDetail = payload;
                state.error = null;
            })
            .addCase(getCompanyDetaill.rejected, (state, { payload }) => {
                state.loadingCompanyList = false;
                showErrorMessage(payload);
            })
            .addCase(getCompanyUpdate.pending, (state) => {
                state.loadingCompanyList = true;
                state.error = null;
                state.companyUpdate = [];
            })
            .addCase(getCompanyUpdate.fulfilled, (state, { payload }) => {
                state.loadingCompanyList = false;
                state.companyUpdate = payload;
                state.error = null;
            })
            .addCase(getCompanyUpdate.rejected, (state, { payload }) => {
                state.loadingCompanyList = false;
                showErrorMessage(payload);
            });
    },
});
export const { refetchCompany, resetCompanies, resetCompaniesDetail } =
    companySlice.actions;
export default companySlice.reducer;
