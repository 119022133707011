import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Services from "services";
import {
    resetToast,
    showErrorMessage,
    showSuccessMessage,
} from "redux/toastSlice";
import {
    setToken,
    getToken,
    removeToken,
    setBlockChainToken,
    removeBlockChainToken,
} from "util/token";
import { resetAccount } from "redux/accountSlice";
import { resetStateHander } from "helpers/sanitizedFunction";
import { resetCertification } from "./certificationsSlice";
import { resetCompanies } from "./companiesSlice";
import { resetFarmersProject } from "./farmersProjectSlice";
import { resetFarmers } from "./farmersSlice";
import { resetFields } from "./fieldsSlice";
import { resetProgram } from "./programSlice";
import { resetProjects } from "./projectSlice";
import { resetUsers } from "./users";
import { resetMaps } from "./mapsSlice";
import { resetRegistry } from "./registrySlices";

export const passwordlessLoginHandler = createAsyncThunk(
    "authentication/passwordlessLoginHandler",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.initiateEmailSignIn(params);
            if (data.data == true) {
                const sendEmailLinkResponse = await Services.sendEmailLink(
                    params.email
                );
                thunkAPI.dispatch(
                    showSuccessMessage("Email sent! Please check your email.")
                );
                return sendEmailLinkResponse;
            } else {
                thunkAPI.dispatch(showErrorMessage("User is not registered"));
                return thunkAPI.rejectWithValue("User is not registered");
            }
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage(
                    error?.response?.data?.message ||
                        error.message ||
                        error.name
                )
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//for email verification after user click on email link
export const verifyEmailLinkDetail = createAsyncThunk(
    "authentication/verifyEmailLinkDetail",
    async (params, thunkAPI) => {
        try {
            //step 1
            await Services.verifySignInLink();
            const storedEmail = localStorage.getItem("emailForSignIn");

            if (storedEmail) {
                //step 2
                const response = await Services.verifyAuthEmailLink(
                    storedEmail
                );
                if (response?.user?.accessToken) {
                    //step 3
                    thunkAPI.dispatch(login(response?.user?.accessToken));
                    return response;
                }
            } else {
                thunkAPI.dispatch(showErrorMessage("Failed to verify"));
                return thunkAPI.rejectWithValue("Failed to verify");
            }
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage("Email verification link is expired.")
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//for gogole login only
export const googleLogin = createAsyncThunk(
    "authentication/googleLogin",
    async (params, thunkAPI) => {
        try {
            const { user } = await Services.handleGoogleLogin();
            if (user.accessToken) {
                thunkAPI.dispatch(login(user?.accessToken));
                return user;
            }
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage(error.message || "google error")
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//for finall login after successfull email verification
export const login = createAsyncThunk(
    "authentication/login",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.loginUser(params);
            if (data.token) {
                setToken(data.token);
                thunkAPI.dispatch(BlockChainLogin());
            }
            thunkAPI.dispatch(showSuccessMessage("Login Successful!"));

            return data;
        } catch (error) {
            const errMsg = error?.response?.data?.message || error?.message;
            thunkAPI.dispatch(showErrorMessage(errMsg));
            return thunkAPI.rejectWithValue(errMsg);
        }
    }
);

export const BlockChainLogin = createAsyncThunk(
    "authentication/BlockChainLogin",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.BlockChainLogin();
            if (data) {
                setBlockChainToken(data);
                // thunkAPI.dispatch(login());
            }
            // thunkAPI.dispatch(showSuccessMessage("Login Successful!"));

            return data;
        } catch (error) {
            const errMsg = error?.response?.data?.message || error?.message;
            thunkAPI.dispatch(showErrorMessage(errMsg));
            return thunkAPI.rejectWithValue(errMsg);
        }
    }
);

const logoutPromise = new Promise((resolve) => resolve());
export const LogoutUser = createAsyncThunk(
    "authentication/logout",
    async (params, thunkAPI) => {
        const resetActions = [
            resetAccount,
            resetCertification,
            resetCompanies,
            resetFarmersProject,
            resetFarmers,
            resetFields,
            resetProgram,
            resetProjects,
            resetToast,
            resetUsers,
            resetMaps,
            resetRegistry,
        ];
        logoutPromise.then(() => {
            removeToken();
            removeBlockChainToken();
            resetActions.forEach((actions) => {
                thunkAPI.dispatch(actions());
            });
            thunkAPI.dispatch(showSuccessMessage("Logout Successful"));
        });
    }
);

const initialState = {
    loading: false,
    loginData: {},
    token: null,
    loginDetail: [],
    error: null,
    emailLinkVerified: false,
    emailSent: false,
    isSignInWithEmail: false,
    showCreateWalletModal: false,
};
const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setAuthToken: (state) => {
            const token = getToken();
            state.loginData.token = token;
            state.emailLinkVerified = true;
        },
        hideWalletModal: (state) => {
            state.showCreateWalletModal = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(passwordlessLoginHandler.pending, (state) => {
                state.isSignInWithEmail = true;
                state.loading = true;
                state.error = null;
                state.emailSent = false; // Reset emailSent when the request starts added code
            })
            .addCase(passwordlessLoginHandler.fulfilled, (state) => {
                state.isSignInWithEmail = true;
                state.loading = false;
                state.error = null;
                state.emailSent = true;
            })
            .addCase(passwordlessLoginHandler.rejected, (state) => {
                state.isSignInWithEmail = false;
                state.loading = false;
                state.emailSent = false;
            })
            /*  */
            .addCase(googleLogin.pending, (state) => {
                state.loading = true;
            })
            .addCase(googleLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.loginData = payload;
                state.error = null;
            })
            .addCase(googleLogin.rejected, (state) => {
                state.loading = false;
            })
            .addCase(verifyEmailLinkDetail.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.error = null;
                if (payload?.user?.accessToken) {
                    state.emailLinkVerified = true;
                }
            })
            .addCase(verifyEmailLinkDetail.rejected, (state, { payload }) => {
                state.loading = false;
                state.emailLinkVerified = false;
                state.error = payload;
            })
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.loginData = payload;
                state.error = null;
                state.showCreateWalletModal = true;
            })
            .addCase(login.rejected, (state) => {
                state.loading = false;
            })
            .addCase(LogoutUser.fulfilled, (state) => {
                resetStateHander(state, initialState);
            });
        //         .addCase(logoutUser.pending, (state) => {
        //             state.loading = true;
        //         })
        //         .addCase(logoutUser.fulfilled, (state, { payload }) => {
        //             state.loading = false;
        //             state.loginData = payload;
        //             state.error = null;
        //         })
        //         .addCase(logoutUser.rejected, (state) => {
        //             state.loading = false;
        //         });
    },
});
export const { setAuthToken, hideWalletModal } = loginSlice.actions;
export default loginSlice.reducer;
