import { createTheme } from "@mui/material/styles";

const palette = {
    buttonPrimary: {
        main: "#3FD0C9",
        contrastText: "#000",
    },
    buttonSecondary: {
        main: "#fff",
        contrastText: "#000",
    },
    primary: {
        light: "#1ED800",
        main: "#3FD0C9",
        dark: "#02353C",
        contrastText: "#000",
        white: "#fff",
        gradientDark: "#000000",
    },
    secondary: {
        light: "#ADD085",
        main: "#52B686",
        dark: "#ba000d",
        contrastText: "#757575",
    },
    grey: {
        main: "#B6B6B6",
    },
    error: {
        main: "#F00",
    },
    orange: {
        main: "#FF8A00",
    },
    statusColors: {
        active: "#1ED800",
        inactive: "#B6B6B6",
        pending: "#FF8A00",
    },
};

export const myTheme = createTheme({
    palette: palette,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "*, ::after, ::before": {
                    boxSizing: " border-box",
                },
                body: {
                    fontFamily: "Anek Bangla",
                    fontStyle: "normal",
                    backgroundColor: "rgba(2, 53, 60, 0.05)",
                },

                "& .MuiInputLabel-asterisk": {
                    color: "red",
                },
                "& .MuiFilledInput-root": {
                    borderRadius: "0 !important",
                },
                "& .MuiInputBase-inputMultiline": {
                    resize: "auto !important",
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    padding: "5px 12px",
                    boxShadow: "none",
                    borderRadius: "30px",
                    fontWeight: 600,
                    fontSize: "13px",
                    textTransform: "none",
                    "@media (min-width:992px)": {
                        padding: "5px 25px",
                        fontSize: "1rem",
                    },

                    "&.MuiButton-contained": {
                        "&:hover": {
                            background: "#02353C",
                            color: "#fff !important",
                        },
                    },
                    "&.MuiButton-containedbuttonSecondary": {
                        backgroundColor: "#3FD0C9",
                        "&:hover": {
                            background: "#02353C",
                            color: "#fff !important",
                        },
                    },
                    "&.MuiButton-outlined": {
                        color: "#000",
                        borderColor: "#000",
                        fontWeight: 500,
                        "&:hover": {
                            backgroundColor: "#000",
                            color: "#fff !important",
                        },
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderColor: "#666",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "1.7rem",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    background: "#fff",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    minWidth: "120px",
                    fontSize: 14,
                    fontFamily: "'Istok Web',sans-serif!important",
                    padding: "10px 12px",
                    borderBottom: "1px solid #ccc",
                    color: "#000",
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    border: 0,
                    minWidth: 180,
                    "& .MuiMenu-list": {
                        padding: "4px 0",
                    },
                    "& .MuiMenuItem-root": {
                        color: "#000",
                        fontWeight: "600",
                    },
                },
            },
        },
    },

    typography: {
        fontFamily: "Anek Bangla",
        fontSize: "1rem",
        h2: {
            fontSize: "1.4rem",
            fontWeight: "bold",
            color: "#02353C",
            "@media (min-width:1081px)": {
                fontSize: "2.125rem",
            },
        },
        h4: {
            color: "#02353C",
            fontSize: "1.25rem",
            fontWeight: "bold",
        },
        h6: {
            color: "#02353C",
            fontSize: "1.25rem",
            fontWeight: 600,
        },
        span: {
            color: "#02353C",
            fontSize: "20px",
        },
    },
});
