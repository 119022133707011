import Grid from "@mui/material/Grid";
import { useAccessPage } from "customHooks/useAccess";
import Button from "@mui/material/Button";
import permissionSlug from "assets/data/permissionSlug.json";
import Breadcrumbs from "common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
export default function DetailsHeader() {
    const { cannot } = useAccessPage();
    const navigate = useNavigate();
    const { role } = useSelector(
        (state) => state.account?.accessibility,
        shallowEqual
    );
    const { programDetails } = useSelector((state) => state.program);

    const statusOfProgram =
        programDetails?.program_status !== "under-validation";
    const adminRole = role !== "admin";
    const withdrawnStatus =
        programDetails?.program_status === "withdrawn" ||
        programDetails?.program_status === "rejected";
    if (!programDetails?.program_status) {
        return null;
    }
    let { id: program_id } = useParams();
    return (
        <>
            <Breadcrumbs
                xs="auto"
                sources={[
                    {
                        label: "programs",
                        link: "/programs",
                        canNavigate: true,
                    },
                    {
                        label: `program #${program_id}`,
                        link: "",
                    },
                ]}
            />
            <Grid
                item
                xs="auto"
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                <Button
                    onClick={() => navigate(`/program/edit/${program_id}`)}
                    disabled={
                        cannot(permissionSlug[0].programSlug.edit_program) ||
                        (statusOfProgram && adminRole) ||
                        withdrawnStatus
                    }
                    variant="contained"
                    sx={{
                        padding: { xs: "4px 18px", md: "4px 23px" },
                        backgroundColor: "buttonSecondary.main",
                        border:
                            (statusOfProgram && adminRole) || withdrawnStatus
                                ? ""
                                : "1px solid #000",
                        fontWeight: "500",
                        my: { xs: "5px", md: "0px" },
                        "&:hover": {
                            color: "#fff",
                        },
                    }}
                >
                    Modify
                </Button>
            </Grid>
        </>
    );
}
