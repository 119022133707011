import { Box, Button, Stack, Typography } from "@mui/material";
import Modal from "common/Modal";
import { Link } from "react-router-dom";
import CustomDropzone from "./CustomDropzone";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";

export default function ContractPDFUploader({
    file,
    setFile,
    openFarmerModal,
    setOpenFarmerModal,
    onClick,
    currentID,
    isLoading,
}) {
    const handleClose = () => {
        setFile(false);
        setOpenFarmerModal(false);
    };
    return (
        <Modal
            title={
                <Typography variant="h2">
                    Contract Upload For Project #{currentID}
                </Typography>
            }
            body={
                <>
                    <Typography id="modal-modal-description" sx={{ mt: 5 }}>
                        <Typography sx={{ lineHeight: "18px" }}>
                            Upload farmers by using .pdf file.
                        </Typography>
                        <Typography
                            sx={{
                                lineHeight: "18px",
                                "& a": {
                                    color: "#5282B6",
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            Template File:{" "}
                            <Link href="#" color="#5282B6">
                                upload_project_file.pdf
                            </Link>
                        </Typography>
                    </Typography>
                    <Box sx={{ margin: "35px auto" }}>
                        <CustomDropzone
                            onChange={(res) => setFile(res)}
                            files={file}
                            content={
                                <>
                                    {file && file[0]?.name ? (
                                        <>{file[0].name}</>
                                    ) : (
                                        <>
                                            <Box component="p" sx={{ mt: 0 }}>
                                                Drop your .pdf file here <br />
                                                or upload it from your computer
                                            </Box>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#f5f5f5",
                                                    border: "1px solid #000",
                                                    borderRadius: "20px",
                                                    color: "#000000",
                                                }}
                                            >
                                                <IconFileupload />
                                                <Box
                                                    component="span"
                                                    sx={{ ml: "10px" }}
                                                >
                                                    {" "}
                                                    Upload
                                                </Box>
                                            </Button>
                                        </>
                                    )}
                                </>
                            }
                        />
                    </Box>
                </>
            }
            footer={
                <Stack
                    direction="row"
                    spacing={1}
                    mt="44px"
                    justifyContent="flex-end"
                >
                    <Button
                        variant="outlined"
                        onClick={() => handleClose()}
                        sx={{ fontWeight: "500" }}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onClick(currentID)}
                        variant="contained"
                        color="buttonPrimary"
                        disabled={isLoading}
                    >
                        {isLoading ? "Uploading..." : "Creation"}
                    </Button>
                </Stack>
            }
            open={openFarmerModal}
            close={() => (!isLoading ? handleClose : setOpenFarmerModal(true))}
        />
    );
}
