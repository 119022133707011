import Breadcrumbs from "common/Breadcrumb";

export default function MapHeader() {
    return (
        <>
            <Breadcrumbs
                sources={[
                    {
                        label: "fields",
                        link: "",
                    },
                    {
                        label: "map",
                        link: "",
                    },
                ]}
            />
        </>
    );
}
