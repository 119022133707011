import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as SupportIcon } from "assets/icons/supporticon.svg";
import { ReactComponent as AdminIcon } from "assets/icons/adminicon.svg";
import { ReactComponent as AccountIcon } from "assets/icons/accounticon.svg";
// import { ReactComponent as StatisticsIcon } from "assets/icons/statisticsicon.svg";
import { ReactComponent as ProgramIcon } from "assets/icons/programicon.svg";
import { ReactComponent as FarmersIcon } from "assets/icons/farmersicon.svg";
import { ReactComponent as FieldsIcon } from "assets/icons/fieldsicon.svg";
import { ReactComponent as RegistryIcon } from "assets/icons/registryicon.svg";
// import { ReactComponent as Marketplaceicon } from "assets/icons/marketplaceicon.svg";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useParams } from "react-router-dom";
import HeaderSearchbar from "common/HeaderSearchbar";

import { globalSearch, resetAllSearch } from "redux/globalSearchSlice";
import { ClickAwayListener, Popper } from "@mui/material";
import capitalizeFirstLetter from "util/CapitalizeFirstLetter";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useAccessPage } from "customHooks/useAccess";

import permissionSlug from "assets/data/permissionSlug.json";

// const Search = styled("div")(({ theme }) => ({
//     position: "relative",
//     borderRadius: "30px",
//     backgroundColor: "#fff",
//     marginLeft: 0,
//     width: "421px",
//     [theme.breakpoints.down("md")]: {
//         width: "100%",
//     },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     right: 0,
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: "inherit",
//     "& .MuiInputBase-input": {
//         padding: theme.spacing(1, 1, 1, 0),
//         paddingLeft: `22px`,
//         transition: theme.transitions.create("width"),
//         width: "100%",
//         color: "#000",
//         fontFamily: "'Anek Bangla', sans-serif !important",
//     },
// }));

const drawerWidth = 260;
const mobdrawerWidth = "100%";

const openedMixin = (theme) => ({
    width: drawerWidth,
    paddingTop: "20px",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    marginTop: "95px",
    border: "0px",
    [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        width: mobdrawerWidth,
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `100px`,
    paddingTop: "20px",
    border: "0px",
    [theme.breakpoints.up("md")]: {
        marginTop: "95px",
    },
    [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        width: "0px",
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MiniDrawer() {
    const { can, isRoles } = useAccessPage();
    const [searchValue, setSearchValue] = useState("");
    const [openPopper, setOpenPopper] = useState(false);

    // const { accountDetails: user, isLoaded } = useSelector(
    //     (state) => state.account
    // );
    const { searchData } = useSelector((state) => state.globalSearch);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (!isLoaded) {
    //         dispatch(fetchAccountDetails());
    //     }
    // }, [isLoaded]);

    const { pathname } = useLocation();
    const { id } = useParams();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleSearch = (search) => {
        if (search !== "") {
            dispatch(globalSearch(search)).then((res) => {
                if (res.meta.requestStatus === "fulfilled" && !res.error) {
                    setOpenPopper(true);
                }
            });
        } else {
            dispatch(resetAllSearch());
            setOpenPopper(false);
        }
    };

    const handleClose = () => {
        setOpenPopper(false);
        dispatch(resetAllSearch());
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    // const { accountDetails: users } = useSelector((state) => state.account);
    const tabsRoute = {
        program: [
            "/programs",
            `/program/${id}/detail`,
            `/program/${id}/projects`,
            `/program/${id}/fields`,
            `/program/${id}/certifications`,
            "/new-program",
            "/program/edit",
            "/add-project",
            `/add-certification/${id}`,
            `/add-project/${id}`,
            `/projectdetail/${id}`,
            `/companies/${id}/programs`,
            `/program/edit/${id}`,
        ],

        fields: [
            "/field/list",
            `/companies/${id}/fields`,
            "/field/map",
            `/field/${id}/details`,
            `/field/${id}/history`,
            "/import-result",
        ],
        farmer: [
            "/farmers",
            `/farmer/${id}/details`,
            `/farmer/${id}/projects`,
            `/farmer/${id}/fields`,
            "/add-farmer",
            `/farmer/edit/${id}`,
            `/farmer/${id}/fieldsmap`,
            "/field/history",
            `/companies/${id}/farmers`,
        ],
        admin: [
            "/companies",
            "/create-company",
            "/users",
            `/users/detail/${id}`,
            `/users/edit/${id}`,
            `/companies/detail/${id}`,
            `/companies/edit/${id}`,
            `/users/add/${id}`,
            `/companies/${id}/users`,
        ],
    };

    const menuitems1 = [
        ...(can(permissionSlug[0].menu_slugs.program_menu)
            ? [
                  {
                      title: "Programs",
                      icon: <ProgramIcon />,
                      url: "/programs",
                      active: tabsRoute.program.includes(pathname),
                  },
              ]
            : []),
        ...(can(permissionSlug[0].menu_slugs.farmer_menu)
            ? [
                  {
                      title: "Farmers",
                      icon: <FarmersIcon />,
                      url: "/farmers",
                      active: tabsRoute.farmer.includes(pathname),
                  },
              ]
            : []),
        ...(can(permissionSlug[0].menu_slugs.field_menu)
            ? [
                  {
                      title: "Fields",
                      icon: <FieldsIcon />,
                      url: "/field/list",
                      active: tabsRoute.fields.includes(pathname),
                  },
              ]
            : []),
        ...(can(permissionSlug[0].menu_slugs.registry_menu)
            ? [
                  {
                      title: "Registry",
                      icon: <RegistryIcon />,
                      url: "/registry/collections",
                      active: pathname.includes("/registry"),
                  },
              ]
            : []),
        // {
        //     title: "Marketplace",
        //     icon: <Marketplaceicon />,
        //     url: "/marketplace",
        //     active: pathname === "/marketplace",
        // },
        // {
        //     title: "Statistics",
        //     icon: <StatisticsIcon />,
        //     url: "statistics",
        //     active: pathname === "/statistics",
        // },
    ];
    const menuitems2 = [
        ...(can(permissionSlug[0].menu_slugs.account_menu)
            ? [
                  {
                      title: "Account",
                      icon: <AccountIcon />,
                      url: "/my-account",
                      active: pathname.includes("/my-account"),
                  },
              ]
            : []),
        ...(can(permissionSlug[0].menu_slugs.support_menu)
            ? [
                  {
                      title: "Support",
                      icon: <SupportIcon />,
                      url: "/coming-soon",
                      active: pathname === "/support",
                  },
              ]
            : []),
        ...(can(permissionSlug[0].menu_slugs.company_menu) ||
        can(permissionSlug[0].menu_slugs.admin_menu)
            ? [
                  {
                      title: isRoles("admin") ? "Admin" : "Company",
                      icon: <AdminIcon />,
                      url: "/companies",
                      active: tabsRoute.admin.includes(pathname),
                  },
              ]
            : []),
        ...(can(permissionSlug[0].menu_slugs.role_menu)
            ? [
                  {
                      title: "Roles",
                      icon: <AccountIcon />,
                      url: "/roles",
                      active: pathname.includes("/roles"),
                  },
              ]
            : []),
    ];

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Box>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                    position: "fixed",
                    right: "10px",
                    top: "20px",
                    zIndex: "9999",
                    display: { md: "none" },
                    ...(open && { display: "none" }),
                }}
            >
                <MenuIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Drawer
                variant="permanent"
                open={open}
                sx={{ mt: "99px" }}
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
            >
                <IconButton
                    color="inherit"
                    onClick={handleDrawerClose}
                    edge="start"
                    sx={{
                        position: "absolute",
                        top: "4px",
                        right: "20px",
                        zIndex: "99",
                        display: { md: "none" },
                    }}
                >
                    <CloseIcon sx={{ color: { md: "#000", xs: "#fff" } }} />
                </IconButton>
                <Box
                    sx={{
                        width: { xs: "100%", md: "auto" },
                        display: { xs: "block", md: "none" },
                        background:
                            "var(--degrade-bouton-selected, linear-gradient(78deg, #02353C 65%, #2EAF7D 100%))",
                        p: 2,
                        pt: 7,
                        mt: "-20px",
                    }}
                >
                    <HeaderSearchbar
                        handleSearch={(search) => {
                            handleSearch(search);
                        }}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />

                    <Box sx={{ position: "absolute" }}>
                        <Popper
                            id={2}
                            open={openPopper && searchValue !== ""}
                            style={{
                                position: "fixed",
                                top: "68px",
                                width: "25em",
                                zIndex: 9999,
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                            sx={{
                                "@media (max-width:480px)": {
                                    top: "97px !important",
                                    left: "50% !important",
                                    width: "92% !important",
                                },
                            }}
                        >
                            {({ TransitionProps }) => (
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Box
                                        {...TransitionProps}
                                        sx={{
                                            p: 2,
                                            bgcolor: "#f7f7f7",
                                            boxShadow:
                                                "0px 0px 15px rgba(0,0,0,0.2)",
                                            borderRadius: 2,
                                            maxHeight: "266px",
                                            overflowY: "scroll",
                                            scrollbarWidth: "thin",
                                            "&::-webkit-scrollbar": {
                                                width: "8px",
                                            },
                                            "&::-webkit-scrollbar-track": {
                                                background: "#f4f4f4",
                                            },
                                            "&::-webkit-scrollbar-thumb": {
                                                background: "#e0e0e0",
                                                borderRadius: "8px",
                                            },
                                        }}
                                    >
                                        {Object.values(searchData).every(
                                            (array) => array.length === 0
                                        ) ? (
                                            "No Data Found"
                                        ) : (
                                            <>
                                                {searchData?.farmers?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                            }}
                                                        >
                                                            Farmers
                                                        </Typography>
                                                        {searchData.farmers.map(
                                                            (farmer) => {
                                                                return (
                                                                    <Link
                                                                        to={`/farmer/${farmer.id}/details`}
                                                                        key={
                                                                            farmer.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {`${capitalizeFirstLetter(
                                                                                farmer.farmerFirstname
                                                                            )} ${capitalizeFirstLetter(
                                                                                farmer.farmerLastname
                                                                            )}`}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                                {searchData?.fields?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                                paddingTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Fields
                                                        </Typography>
                                                        {searchData.fields.map(
                                                            (farmer) => {
                                                                return (
                                                                    <Link
                                                                        to={`/field/${farmer.id}/details`}
                                                                        key={
                                                                            farmer.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {capitalizeFirstLetter(
                                                                                farmer.fieldName
                                                                            )}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}

                                                {searchData?.programs?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                                paddingTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Programs
                                                        </Typography>
                                                        {searchData.programs.map(
                                                            (program) => {
                                                                return (
                                                                    <Link
                                                                        to={`/program/${program.id}/detail`}
                                                                        key={
                                                                            program.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {capitalizeFirstLetter(
                                                                                program.program_name
                                                                            )}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}

                                                {searchData?.projects?.length >
                                                    0 && (
                                                    <>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                                color: "#02353C",
                                                                fontWeight:
                                                                    "Medium",
                                                                fontSize: {
                                                                    xs: "14px",
                                                                    md: "16px",
                                                                    lg: "18px",
                                                                },
                                                                paddingTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Projects
                                                        </Typography>
                                                        {searchData.projects.map(
                                                            (project) => {
                                                                return (
                                                                    <Link
                                                                        to={`/projectdetail/${project.id}`}
                                                                        key={
                                                                            project.id
                                                                        }
                                                                        onClick={
                                                                            handleClose
                                                                        }
                                                                    >
                                                                        <li
                                                                            className="header_listing"
                                                                            style={{
                                                                                listStyleType:
                                                                                    "none",
                                                                                paddingLeft:
                                                                                    "16px",
                                                                            }}
                                                                        >
                                                                            {capitalizeFirstLetter(
                                                                                project.projectReferenceExternalId
                                                                            )}
                                                                        </li>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </Box>

                    {/* <Search
                        sx={{ width: { xs: "100%", sm: "70%" }, mx: "auto" }}
                    >
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: "#CCCCCC" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search program, project, field, ..."
                            inputProps={{ "aria-label": "search" }}
                            sx={{ width: "100%" }}
                        />
                    </Search> */}
                </Box>
                <List sx={{ mx: "25px" }}>
                    {menuitems1.map((res, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                display: "block",
                                color: res.active ? "#fff" : "#02353C",
                                mb: "14px",
                                px: "15px",
                                borderRadius: open ? "30px" : "5px",
                                background:
                                    res.active &&
                                    "var(--degrade-bouton-selected, linear-gradient(78deg, #02353C 65%, #2EAF7D 100%))",
                                "&:hover": {
                                    background:
                                        "var(--degrade-bouton-selected, linear-gradient(78deg, #02353C 65%, #2EAF7D 100%))",
                                    color: "#fff",
                                    fontWeight: "600",
                                    "& .MuiListItemText-root": {
                                        fontWeight: "600",
                                    },
                                },
                                "& a": {
                                    color: "inherit",
                                },
                            }}
                        >
                            <Link to={res.url}>
                                <ListItemButton
                                    sx={{
                                        height: 40,
                                        px: 0,
                                        justifyContent: open
                                            ? "initial"
                                            : "center",
                                        background: "transparent !important",
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? "11px" : "auto",
                                            justifyContent: "center",
                                            color: "currentcolor",
                                        }}
                                    >
                                        {res.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={res.title}
                                        disableTypography
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            fontWeight: res.active
                                                ? "600"
                                                : "400",
                                        }}
                                    />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    ))}
                </List>
                <Divider sx={{ border: "0", my: "5px" }} />

                <List sx={{ mx: "25px" }}>
                    {menuitems2.map((res, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                display: "block",
                                color: res.active ? "#fff" : "#02353C",
                                mb: "14px",
                                px: "15px",
                                borderRadius: open ? "30px" : "5px",
                                background:
                                    res.active &&
                                    "var(--degrade-bouton-selected, linear-gradient(78deg, #02353C 65%, #2EAF7D 100%))",
                                "&:hover": {
                                    background:
                                        "var(--degrade-bouton-selected, linear-gradient(78deg, #02353C 65%, #2EAF7D 100%))",
                                    color: "#fff",
                                    fontWeight: "600",
                                    "& .MuiListItemText-root": {
                                        fontWeight: "bold",
                                    },
                                },
                                "& a": {
                                    color: "inherit",
                                },
                            }}
                        >
                            <Link to={res.url}>
                                <ListItemButton
                                    sx={{
                                        height: 40,
                                        px: 0,
                                        justifyContent: open
                                            ? "initial"
                                            : "center",
                                        background: "transparent !important",
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? "11px" : "auto",
                                            justifyContent: "center",
                                            color: "currentcolor",
                                        }}
                                    >
                                        {res.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        disableTypography
                                        primary={res.title}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            fontWeight: res.active
                                                ? "600"
                                                : "400",
                                        }}
                                    />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}
