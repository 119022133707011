// import TablePagination from "@mui/material/TablePagination";
// import { useEffect } from "react";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import * as React from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
export default function PaginationForImportResult({
    totalRowCount,
    exportbutton,
    setQuery,
    query,
}) {
    const totalPage = Math.ceil(totalRowCount / query?.limit);
    const handleLimitChange = (event) => {
        const newLimit = event.target.value;
        if (newLimit === totalRowCount) {
            setQuery((prev) => ({
                ...prev,
                page: 1,
                limit: newLimit,
            }));
        } else {
            const newTotalPage = Math.ceil(totalRowCount / newLimit);
            const newPage = Math.min(query.page, newTotalPage);

            setQuery((prev) => ({
                ...prev,
                page: newPage,
                limit: newLimit,
            }));
        }
    };
    const handlePreviousPageChange = () => {
        if (query?.page > 1) {
            setQuery((prev) => ({ ...prev, page: prev.page - 1 }));
        }
    };
    const handleNextPageChange = () => {
        if (query?.page < totalPage) {
            setQuery((prev) => ({
                ...prev,
                page: prev.page + 1,
            }));
        }
    };
    const pageLimits = [10, 25, 50, 100, 250];
    return (
        <Box
            sx={{
                display: "flex",
                flexFlow: {
                    xs: "column-reverse",
                    sm: "row-reverse",
                },
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: { xs: "center", sm: "end" },
            }}
        >
            <React.Fragment>
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <ArrowBackIosRoundedIcon
                        onClick={handlePreviousPageChange}
                        sx={{
                            color: query?.page > 1 ? "black" : "grey",
                            width: "15px",
                            marginRight: "18px",
                            cursor: query?.page > 1 ? "pointer" : "not-allowed",
                        }}
                    />
                    <ArrowForwardIosRoundedIcon
                        onClick={handleNextPageChange}
                        sx={{
                            color: query?.page < totalPage ? "black" : "grey",
                            width: "15px",

                            cursor:
                                query?.page < totalPage
                                    ? "pointer"
                                    : "not-allowed",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {
                            xl: "row-reverse",
                            xs: "row-reverse",
                            sm: "row-reverse",
                        },
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            marginLeft: "12px",
                            marginRight: "12px",
                            flexDirection: { xs: "row" },
                        }}
                    >
                        {" "}
                        {`Page ${query?.page} of  ${totalPage} `}
                    </Box>

                    <FormControl sx={{ m: 1, minWidth: 70 }}>
                        <Select
                            value={
                                totalRowCount < query?.limit
                                    ? totalRowCount
                                    : query?.limit
                            }
                            onChange={handleLimitChange}
                            displayEmpty
                            inputProps={{
                                "aria-label": "Without label",
                            }}
                            sx={{ height: "40px" }}
                        >
                            {pageLimits.map(
                                (limit) =>
                                    limit < totalRowCount && (
                                        <MenuItem value={limit} key={limit}>
                                            {limit}
                                        </MenuItem>
                                    )
                            )}
                            <MenuItem value={totalRowCount}>All</MenuItem>
                        </Select>
                    </FormControl>

                    <p style={{ color: "black" }}>Items per page :</p>
                </Box>
            </React.Fragment>

            {exportbutton}
        </Box>
    );
}
