import CompanyInformation from "../form/CompanyInfomation";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { useFormik } from "formik";
import CompanyProfile from "components/admin/form/CompanyProfile";
import CompanyAddress from "components/admin/form/CompanyAddress";

import { Button } from "@mui/material";
import {
    // companiesList,
    createCompany,
    getCompanyDetaill,
    getCompanyUpdate,
    refetchCompany,
    resetCompaniesDetail,
} from "redux/companiesSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import roles from "constant/roles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CancelButton from "common/CancelButton";
import { useEffect } from "react";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
const FormContainer = ({ edit = false }) => {
    const { can } = useAccessPage();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { loadingCompanyList, companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
            loadingCompanyList: state?.companylist?.loadingCompanyList,
            createComapnyData: state,
        }),
        shallowEqual
    );
    useEffect(() => {
        if (can(permissionSlug[0].company_slug.edit_company)) {
            if (edit) {
                dispatch(getCompanyDetaill(id));
            }
        }
    }, [edit]);
    // dispatch(refetchCompany())
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            company_name: "",
            official_id: "",
            intraco: "",
            phone: "",
            email: "",
            website: "",
            companyLogo: null,
            autocompletionAddress: "",
            company_description: "",
            profile: "",
            wallet_management: "",
            wallet_email: "",
            wallet_public_key: "",
            contractSignDate: null,
            companyStatus: "",
            renewalDate: null,
            street: "",
            street_two: "",
            zip_code: "",
            // zip_code2: "",
            region: "",
            city: "",
            country: "",
        },
        validationSchema: Yup.object({
            company_name: Yup.string().required("Company name is Required"),
            official_id: Yup.string().required("Official id is Required"),
            intraco: Yup.string().required("Intraco is Required"),
            phone: Yup.mixed().required("Phone is Required"),
            email: Yup.string()
                .matches(
                    /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g,
                    "Invalid email address"
                )
                .required("Email is Required"),
            website: Yup.string()
                .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    "Enter correct url!"
                )
                .required("Website is Required"),
            company_description: Yup.string().required(
                "Description is Required"
            ),
            companyLogo: Yup.string().required("File is Required"),
            profile: Yup.string().required("Profile is Required"),
            wallet_management: Yup.string().required(
                "Wallet management is Required"
            ),
            wallet_email: Yup.string()
                .email("Invalid email address")
                .nullable(),
            wallet_public_key: Yup.string().nullable(),
            contractSignDate: Yup.string().required("Date is Required"),
            renewalDate: Yup.string().required("Date is Required"),
            // companyStatus: Yup.string(),
            street: Yup.string().required("Street is required"),
            // street_two: Yup.string(),
            zip_code: Yup.string().required("Zip code is required"),
            // zip_code2: Yup.string().required("Zip code2 is required"),
            // region: Yup.string(),
            city: Yup.string().required("City is required"),
            country: Yup.string().required("Country is required"),
        }),

        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("companyName", values.company_name);
            formData.append("companyStatus", values.companyStatus);
            formData.append("companySignDate", values.contractSignDate);
            formData.append("companyRenewalDate", values.renewalDate);
            formData.append("companyLogo", values.companyLogo);
            formData.append("companyOfficialId", values.official_id);
            formData.append("companyVatNumber", values.intraco);
            formData.append("companyStreet", values.street);
            formData.append("companyEmail", values.email);
            formData.append("companyStreet2", values.street_two);
            formData.append("companyFullAddress", values.autocompletionAddress);
            formData.append("companyZipcode", values.zip_code);
            // formData.append("companyZipcode2", values.zip_code2);
            formData.append("companyCity", values.city);
            formData.append("companyRegion", values.region);
            formData.append("companyCountry", values.country);
            formData.append("companyPhone", values.phone);
            formData.append("companyWebsite", values.website);
            formData.append("companyDescription", values.company_description);
            formData.append("companyProfile", values.profile);
            formData.append("wallet_public_key", values?.wallet_public_key);
            formData.append("wallet_email", values.wallet_email || "");
            formData.append("wallet_management", values.wallet_management);
            // formData.append("role", roles[2]);
            if (edit) {
                dispatch(
                    getCompanyUpdate({ id: companylist?.id, formData })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate(`/companies/detail/${companylist?.id}`);
                        dispatch(refetchCompany());
                    }
                });
            } else {
                dispatch(createCompany(formData)).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate("/companies");
                        dispatch(refetchCompany());
                        formik.resetForm();
                    }
                });
            }
        },
    });
    useEffect(() => {
        if (companylist) {
            formik.setFieldValue("company_name", companylist?.companyName);
            formik.setFieldValue("companyStatus", companylist?.companyStatus);
            formik.setFieldValue("companyLogo", companylist?.companyLogo);
            formik.setFieldValue(
                "contractSignDate",
                companylist?.companySignDate
            );
            formik.setFieldValue(
                "renewalDate",
                companylist?.companyRenewalDate
            );
            formik.setFieldValue("official_id", companylist?.companyOfficialId);
            formik.setFieldValue("intraco", companylist?.companyVatNumber);
            formik.setFieldValue(
                "autocompletionAddress",
                companylist?.autocompletionAddress
            );
            formik.setFieldValue("street", companylist?.companyStreet);
            formik.setFieldValue("email", companylist?.companyEmail);
            formik.setFieldValue("street_two", companylist?.companyStreet2);
            formik.setFieldValue("zip_code", companylist?.companyZipcode);
            // formik.setFieldValue("zip_code2", companylist?.companyZipcode2);
            formik.setFieldValue("city", companylist?.companyCity);
            formik.setFieldValue("region", companylist?.companyRegion);
            formik.setFieldValue("country", companylist?.companyCountry);
            formik.setFieldValue("phone", companylist?.companyPhone);
            formik.setFieldValue("website", companylist?.companyWebsite);
            formik.setFieldValue(
                "company_description",
                companylist?.companyDescription
            );
            formik.setFieldValue("profile", companylist?.companyProfile);
            formik.setFieldValue(
                "wallet_public_key",
                companylist?.wallet?.wallet_public_key
            );
            formik.setFieldValue(
                "wallet_email",
                companylist?.wallet?.wallet_email
            );
            formik.setFieldValue(
                "wallet_management",
                companylist?.wallet?.wallet_management
            );
            if (!pathname.includes("/companies/edit")) {
                formik.resetForm();
                dispatch(resetCompaniesDetail());
            }
        }
    }, [edit, companylist]);
    return (
        <div>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
                onSubmit={formik.handleSubmit}
            >
                <CompanyInformation formik={formik} />
                <CompanyProfile formik={formik} companylist={companylist} />
                <CompanyAddress formik={formik} />

                <Stack
                    direction="row"
                    spacing={2}
                    mt={{ xs: "29px", md: "29px" }}
                >
                    <CancelButton address="/companies" />
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={loadingCompanyList}
                    >
                        {!loadingCompanyList
                            ? !edit
                                ? "Save"
                                : "Save"
                            : "Processing..."}
                    </Button>
                </Stack>
            </Box>
        </div>
    );
};

export default FormContainer;
