import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
// import { useAccessPage } from "customHooks/useAccess";
// import permissionSlug from "assets/data/permissionSlug.json";
export default function CollectiosccTitle() {
    const { collectionsCount } = useSelector((state) => state.registery);
    // const { can } = useAccessPage();

    // const tabsAccess = can(permissionSlug[0].uco_slug.list_UCO);
    return (
        <>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: { xs: "left", sm: "center", md: "left" },
                    }}
                >
                    {collectionsCount} item{collectionsCount > 1 ? "s" : ""} on{" "}
                    <Box
                        component="span"
                        sx={{
                            fontWeight: "normal",
                            borderBottom: "1px solid #000",
                            display: "inline-block",
                            "& a": {
                                color: "inherit",
                            },
                            // "&:hover": {
                            //     color: "grey",
                            //     cursor: "pointer",
                            // },
                        }}
                    >
                        {/* <Link
                            to={`/program/${programNumber}/detail`}
                            sx={{
                                display: "inline-block",
                            }}
                        >
                            {" "}
                            Program #{programNumber}
                        </Link> */}
                        my Collection
                    </Box>
                </Typography>
            </Grid>
        </>
    );
}
