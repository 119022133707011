import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "30px",
    backgroundColor: "#fff",
    marginLeft: 0,
    width: "421px",
    height: "39px",
    minWidth: "340px",
    [theme.breakpoints.down("lg")]: {
        width: "auto",
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `22px`,
        transition: theme.transitions.create("width"),
        width: "100%",
        color: "#000",
        fontFamily: "'Anek Bangla', sans-serif !important",
    },
}));

export default function HeaderSearchbar(props) {
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const handleDebouncedSearch = (value) => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        const delay = 500;
        const timeout = setTimeout(() => {
            props.handleSearch(value);
        }, delay);

        setDebounceTimeout(timeout);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        props.setSearchValue(value);
        handleDebouncedSearch(value);
    };

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon sx={{ color: "#CCCCCC" }} />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search program, project, farmer, field, ..."
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "100%" }}
                value={props.searchValue}
                onChange={handleChange}
            />
        </Search>
    );
}
