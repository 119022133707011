import useAxios from "helpers/useAxios";
import protectedRequest from "helpers/protectedRequest.js";
const getAllPrograms = async (paramss) => {
    return await protectedRequest(() =>
        useAxios.get(`programs/all`, {
            params: {
                page: paramss.page,
                limit: paramss.limit,
                search: paramss.search,
                ...(paramss.id && { companyId: paramss.id }),
            },
        })
    );
};

const createProgram = async (payload) => {
    return await protectedRequest(() =>
        useAxios.post(`programs/create`, payload)
    );
};

const getProgramDetails = async (id) => {
    return await protectedRequest(() => useAxios.get(`programs/${id}`));
};
const editProgram = async (params) => {
    return await protectedRequest(() =>
        useAxios.patch(`programs/update/${params.id}`, params.formData)
    );
};
const slaveCompaniesList = async (params) => {
    return await protectedRequest(() =>
        useAxios.get(`company/all-except/${params.id}/${params.programId}`)
    );
};
const addProgramsCompanies = async (params) => {
    return await protectedRequest(() =>
        useAxios.post(`programs/addCompanyToProgram`, {
            company_program_id_company: params.companyId,
            company_program_id_program: params.programId,
            company_program_lead_company: "false",
        })
    );
};
const leadCompaniesAll = async () => {
    return await protectedRequest(() => useAxios.get(`company/all/dropdown`));
};
export {
    getAllPrograms,
    createProgram,
    getProgramDetails,
    editProgram,
    addProgramsCompanies,
    slaveCompaniesList,
    leadCompaniesAll,
};
