import { useMemo, useState } from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FileInputNoPreview from "common/FileInputNoPreview";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
    FormHelperText,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Checkbox,
    ListItemText,
    Chip,
    Stack,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const projectyear = {
    gap: "2px",
    height: "23px",
    overflow: "auto",
    "& .MuiChip-root": {
        height: "auto;",
    },
    "& .MuiChip-label": {
        paddingLeft: "9px",
        paddingRight: "9px",
        fontSize: "14px",
    },
};

const Contract = ({ formik, edit, showPDF = true }) => {
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };
    const [selectedYears, setSelectedYears] = useState([]);
    const handleActiveYears = (value) => {
        setSelectedYears(value);
        formik.setFieldValue("projectActiveYears", value);
    };
    const handleDeleteYear = (yearToDelete) => () => {
        setSelectedYears(selectedYears.filter((year) => year !== yearToDelete));
        formik.setFieldValue(
            "projectActiveYears",
            formik.values.projectActiveYears.filter(
                (year) => year !== yearToDelete
            )
        );
    };
    useEffect(() => {
        if (edit) {
            setSelectedYears(formik.values.projectActiveYears);
        }
    }, [edit, formik.values.projectActiveYears]);
    const ProjectreferenceId = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="projectReferenceExternalId"
                    label="Reference ID"
                    name="projectReferenceExternalId"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    onChange={formik.handleChange}
                    value={formik.values.projectReferenceExternalId}
                    error={showError("projectReferenceExternalId")}
                    helperText={showError("projectReferenceExternalId")}
                />
            </Grid>
        );
    }, [
        formik.values.projectReferenceExternalId,
        formik.touched.projectReferenceExternalId,
        formik.errors.projectReferenceExternalId,
    ]);
    const projectReferenceYear = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <FormControl required variant="filled" fullWidth>
                    <InputLabel id={`projectReferenceYear`}>
                        Reference year
                    </InputLabel>
                    <Select
                        labelId={`projectReferenceYear`}
                        id="projectReferenceYear"
                        name="projectReferenceYear"
                        value={formik.values.projectReferenceYear}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.projectReferenceYear &&
                            !!formik.errors.projectReferenceYear
                        }
                    >
                        <MenuItem value="2019">2019</MenuItem>
                        <MenuItem value="2020">2020</MenuItem>
                        <MenuItem value="2021">2021</MenuItem>
                        <MenuItem value="2022">2022</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                        <MenuItem value="2024">2024</MenuItem>
                        <MenuItem value="2025">2025</MenuItem>
                    </Select>
                </FormControl>
                {formik.touched.projectReferenceYear &&
                    formik.errors.projectReferenceYear && (
                        <FormHelperText
                            sx={{
                                color: "#F00",
                                fontSize: "14px",
                            }}
                        >
                            {formik.errors.projectReferenceYear}
                        </FormHelperText>
                    )}
            </Grid>
        );
    }, [
        formik.values.projectReferenceYear,
        formik.errors.projectReferenceYear,
        formik.touched.projectReferenceYear,
    ]);

    const projectActiveYears = useMemo(() => {
        const years = [
            { value: "2020", label: "2020" },
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" },
            { value: "2023", label: "2023" },
            { value: "2024", label: "2024" },
            { value: "2025", label: "2025" },
            { value: "2026", label: "2026" },
            { value: "2027", label: "2027" },
            { value: "2028", label: "2028" },
            { value: "2029", label: "2029" },
            { value: "2030", label: "2030" },
            // Add more years here
        ];

        return (
            <Grid item xs={12} sm={6}>
                <FormControl required variant="filled" fullWidth>
                    <InputLabel id={`projectActiveYears`}>
                        Active years
                    </InputLabel>
                    <Select
                        multiple
                        labelId={`projectActiveYears`}
                        id="projectActiveYears"
                        name="projectActiveYears"
                        // value={selectedYears}
                        value={formik.values.projectActiveYears}
                        onChange={(e) => {
                            handleActiveYears(e.target.value);
                        }}
                        error={
                            formik.touched.projectActiveYears &&
                            !!formik.errors.projectActiveYears
                        }
                        renderValue={(selected) => (
                            <Stack
                                direction="row"
                                flexWrap="wrap"
                                sx={projectyear}
                            >
                                {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        onDelete={handleDeleteYear(value)}
                                        deleteIcon={
                                            <CancelIcon
                                                sx={{
                                                    fontSize: "16px !important",
                                                }}
                                                onMouseDown={(event) =>
                                                    event.stopPropagation()
                                                }
                                            />
                                        }
                                    />
                                ))}
                            </Stack>
                        )}
                    >
                        {years.map((year) => (
                            <MenuItem key={year.value} value={year.value}>
                                <Checkbox
                                    checked={selectedYears.includes(year.value)}
                                />
                                <ListItemText primary={year.label} />
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.projectActiveYears &&
                        formik.errors.projectActiveYears && (
                            <FormHelperText
                                sx={{
                                    color: "#F00",
                                    fontSize: "14px",
                                }}
                            >
                                {formik.errors.projectActiveYears}
                            </FormHelperText>
                        )}
                </FormControl>
            </Grid>
        );
    }, [
        formik.values.projectReferenceYear,
        formik.errors.projectReferenceYear,
        formik.touched.projectReferenceYear,
        selectedYears,
    ]);

    const projectStartDate = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        required
                        label="Start date"
                        name="projectStartDate"
                        format="DD/MM/YYYY"
                        sx={{
                            width: "100%",
                            // borderBottom: formik.errors.projectStartDate && "1px solid red"
                        }}
                        slotProps={{
                            textField: {
                                helperText: showError("projectStartDate") && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                        }}
                                    >
                                        {showError("projectStartDate")}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        // value={formik.values.projectStartDate}
                        value={
                            formik.values.projectStartDate
                                ? dayjs(formik.values.projectStartDate)
                                : null
                        }
                        onChange={(value) => {
                            // formik.setFieldValue("projectStartDate", value);
                            formik.setFieldValue(
                                "projectStartDate",
                                value?.format("YYYY-MM-DD")
                            );
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.projectStartDate,
        formik.touched.projectStartDate,
        formik.errors.projectStartDate,
    ]);
    const projectEndDate = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="End date"
                        name="projectEndDate"
                        format="DD/MM/YYYY"
                        sx={{
                            width: "100%",
                        }}
                        slotProps={{
                            textField: {
                                helperText: showError("projectEndDate") && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                            mb: "5px",
                                        }}
                                    >
                                        {showError("projectEndDate")}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        // value={formik.values.projectEndDate}
                        value={
                            formik.values.projectEndDate
                                ? dayjs(formik.values.projectEndDate)
                                : null
                        }
                        onChange={(value) => {
                            // formik.setFieldValue("projectEndDate", value);
                            formik.setFieldValue(
                                "projectEndDate",
                                value?.format("YYYY-MM-DD")
                            );
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.projectEndDate,
        formik.touched.projectEndDate,
        formik.errors.projectEndDate,
    ]);
    const projectSignatureDate = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Signature date"
                        name="projectSignatureDate"
                        format="DD/MM/YYYY"
                        sx={{
                            width: "100%",
                        }}
                        slotProps={{
                            textField: {
                                helperText: showError(
                                    "projectSignatureDate"
                                ) && (
                                    <FormHelperText
                                        sx={{
                                            color: "red",
                                            display: "inline",
                                            ml: "0",
                                            mb: "5px",
                                        }}
                                    >
                                        {showError("projectSignatureDate")}
                                    </FormHelperText>
                                ),
                                variant: "filled",
                                required: true,
                            },
                        }}
                        // value={formik.values.projectSignatureDate}
                        value={
                            formik.values.projectSignatureDate
                                ? dayjs(formik.values.projectSignatureDate)
                                : null
                        }
                        onChange={(value) => {
                            // formik.setFieldValue("projectSignatureDate", value);
                            formik.setFieldValue(
                                "projectSignatureDate",
                                value?.format("YYYY-MM-DD")
                            );
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        );
    }, [
        formik.values.projectSignatureDate,
        formik.touched.projectSignatureDate,
        formik.errors.projectSignatureDate,
    ]);
    const projectPdfFile = useMemo(() => {
        return (
            <Grid item xs={12} sm={6}>
                <FileInputNoPreview
                    formik={formik}
                    label="Contract PDF"
                    id="projectPdfFile"
                    name="projectPdfFile"
                    value={formik.values.projectPdfFile}
                    onChange={formik.handleChange}
                    required={edit}
                />
            </Grid>
        );
    }, [
        formik.values.projectPdfFile,
        formik.touched.projectPdfFile,
        formik.errors.projectPdfFile,
    ]);
    return (
        <>
            {/* {formik.values. checkFarmerIds  ? */}
            <>
                <Typography variant="h4">Contract</Typography>
                <Grid container>
                    <Grid
                        container
                        sx={{ paddingBottom: "13px" }}
                        columnSpacing="30px"
                        spacing="30px"
                    >
                        {ProjectreferenceId}
                    </Grid>
                    <Grid
                        container
                        rowSpacing="16px"
                        columnSpacing="30px"
                        spacing="30px"
                    >
                        {projectReferenceYear}
                        {projectActiveYears}
                        {projectStartDate}
                        {projectEndDate}
                        {projectSignatureDate}
                        {showPDF && projectPdfFile}
                    </Grid>
                </Grid>
            </>
            {/* : null} */}
        </>
    );
};

export default Contract;
