import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import OpenPreviousPage from "common/OpenPreviousPage";

export default function DetailsTitle() {
    const { count, isLoading } = useSelector((state) => state.certifications);
    const { id } = useParams();
    return (
        <>
            <Grid item xs={12} md={7}>
                {isLoading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "20rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        <OpenPreviousPage />
                        {count}{" "}
                        {count > 1 ? "Certifications on" : "Certification on "}{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Link
                                to={`/program/${id}/detail`}
                                sx={{
                                    display: "inline-block",
                                }}
                            >
                                Program #{id}
                            </Link>
                        </Box>
                    </Typography>
                )}
            </Grid>
        </>
    );
}
