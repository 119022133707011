import { Box } from "@mui/material";

export default function DetailsPageImage({ url }) {
    return (
        <Box
            component="img"
            src={url}
            alt="Uploaded Image"
            sx={{
                maxHeight: "80px",
                width: "83px",
                objectFit: "contain",
            }}
        />
    );
}
