import {
    initiateEmailSignIn,
    sendEmailLink,
    handleGoogleLogin,
    verifyAuthEmailLink,
    loginUser,
    logoutUser,
    verifySignInLink,
    BlockChainLogin,
} from "services/auth";
import {
    getUsersList,
    EditUser,
    AddUser,
    GetUser,
    usersRoles,
} from "services/user";
import {
    getCompanyList,
    createdCompany,
    getCompanyDetail,
    companyUpdate,
} from "services/company";

import {
    getAccount,
    editAccount,
    editAdminAccount,
    toggleSharingOptions,
} from "./account";
import {
    getAllPrograms,
    createProgram,
    getProgramDetails,
    editProgram,
    addProgramsCompanies,
    slaveCompaniesList,
    leadCompaniesAll,
} from "services/program";
import {
    getProjectsList,
    projectsCreate,
    projectDetail,
    editProjects,
    uploadProjectsCsvFile,
} from "services/projects";
import {
    getCertifications,
    createCertifications,
} from "services/certifications";
import {
    getFarmers,
    createFarmer,
    getFarmersDetails,
    editFarmers,
    getProjectsByFarmers,
    uploadProjectFile,
    uploadFarmersCsvFile,
    uploadFinalFarmersCsvToDb,
} from "services/farmers";
import {
    getAllfields,
    uploadValuesFiles,
    uploadCsvFile,
    uploadCsvImportResult,
    getFields,
    getFarmerFields,
    getFieldDetails,
    getHistoryTimelines,
    getFieldHistory,
    getAllHistory,
    deleteImportResultData,
} from "services/fields";
import { searchAllData } from "services/searchAll";
import {
    getMapsProgram,
    getCompanyMapData,
    getCompanyYearMapData,
    getMapsDropdowns,
    getAdminYearMapData,
} from "services/maps";
import { getRoles } from "services/roles";
import { getPermissions, updatePermissions } from "services/permissions";
import { updateWalletAddress } from "services/walletCreation";
import {
    getRegistryCollections,
    getRetirementDetails,
    getSCCListings,
    getUCOListings,
    retireProgramSCC,
    getUCODetails,
    fetchUcoCertiPdf,
    fetchUcoStatusActivity,
    cancelsUCOs,
    cancelsSCCs,
    freezesSCCs,
    cancelsUCOsWithCsv,
    cancelsSCCsWithCsv,
    fetchsSCCsActivity,
    freezesSCCsWithCsv,
    cancelSelectedSCCs,
    freezeSelectedSCCs,
    cancelSelectedUCOs,
} from "services/registery";
const Services = {
    getAccount,
    editAccount,
    editAdminAccount,
    toggleSharingOptions,
    getAllPrograms,
    createProgram,
    getProgramDetails,
    editProgram,
    getCertifications,
    getRegistryCollections,
    getSCCListings,
    getUCOListings,
    getRetirementDetails,
    retireProgramSCC,
    getUCODetails,
    createCertifications,
    getFarmers,
    createFarmer,
    getFarmersDetails,
    editFarmers,
    getProjectsByFarmers,
    uploadProjectFile,
    uploadFarmersCsvFile,
    handleGoogleLogin,
    verifyAuthEmailLink,
    initiateEmailSignIn,
    verifySignInLink,
    sendEmailLink,
    loginUser,
    logoutUser,
    getCompanyList,
    getUsersList,
    EditUser,
    AddUser,
    BlockChainLogin,
    createdCompany,
    getCompanyDetail,
    companyUpdate,
    GetUser,
    getProjectsList,
    projectsCreate,
    projectDetail,
    editProjects,
    getAllfields,
    uploadValuesFiles,
    uploadCsvFile,
    uploadCsvImportResult,
    getFields,
    getFieldDetails,
    getHistoryTimelines,
    getFieldHistory,
    getAllHistory,
    getMapsProgram,
    getCompanyMapData,
    getCompanyYearMapData,
    getAdminYearMapData,
    getMapsDropdowns,
    getFarmerFields,
    uploadProjectsCsvFile,
    searchAllData,
    addProgramsCompanies,
    slaveCompaniesList,
    leadCompaniesAll,
    usersRoles,
    getRoles,
    getPermissions,
    updatePermissions,
    updateWalletAddress,
    deleteImportResultData,
    uploadFinalFarmersCsvToDb,
    cancelsUCOsWithCsv,
    cancelsSCCsWithCsv,
    freezesSCCsWithCsv,
    fetchsSCCsActivity,
    cancelSelectedSCCs,
    freezeSelectedSCCs,
    cancelSelectedUCOs,
    cancelsUCOs,
    cancelsSCCs,
    freezesSCCs,
    fetchUcoCertiPdf,
    fetchUcoStatusActivity,
};

export default Services;
