import { Box, Button, Stack, Typography } from "@mui/material";
import ProgramInformation from "./ProgramInformation";
import Methodology from "./Methodology";
import * as yup from "yup";
import Fees from "./Fees";
import CancelButton from "common/CancelButton";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createProgram, editProgram, refetchProgram } from "redux/programSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import sanitizedDigits from "util/sanitizedDigits";
import fieldColors from "assets/data/fieldColors.json";
// import permissionSlug from "assets/data/permissionSlug.json";
import { useAccessPage } from "customHooks/useAccess";
import SustainableGoals from "../detail/SustainableGoals";
import { getBlockChainToken } from "util/token";
export default function ProgramFormContainer({ edit = false, company }) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading } = useSelector((state) => state.program);
    const navigate = useNavigate();
    const { leadCompanies } = useSelector((state) => state.program);
    const { isRoles } = useAccessPage();
    const isAdmin = isRoles("admin");

    const initialValues = {
        program_name: "",
        program_code: "",
        program_type: "",
        program_developer: "",
        program_external_id: "",
        program_region: "",
        program_country: "",
        program_location: "",
        program_status: "under-validation",
        program_image: "",
        program_description: "",
        program_registry: "",
        program_standard: "",
        program_methodology: "",
        program_methodology_contract: "",
        program_exclusive_farm: "",
        program_exclusive_field: "",
        program_registration_date: null,
        program_crediting_period_start: null,
        program_crediting_period_end: null,
        program_credit_type: "",
        program_unit: "",
        program_farmer_fees: "",
        program_developer_fees: "",
        program_registry_fees: "",
        programFieldColor: "",
        slaveCompanyName: [],
        program_un_goals: [],
    };

    if (!isAdmin) {
        delete initialValues.program_farmer_fees;
        delete initialValues.program_developer_fees;
        delete initialValues.program_registry_fees;
    }

    const validationSchema = yup.object().shape({
        program_name: yup.string().required("Program name is required"),
        program_code: yup.string().required("Program code is required"),
        program_type: yup.string().required("Program type is required"),
        program_developer: yup
            .string()
            .required("Company lead developer is required"),
        slaveCompanyName: yup.array(),
        // .test(
        //     "has-at-least-one-value",
        //     "Slave company is required",
        //     function (value) {
        //         return value && value.length > 0;
        //     }
        // ),
        // program_external_id: yup
        //     .string()
        //     .required("Program external ID is required"),
        program_region: yup.string().required("Program region is required"),
        program_country: yup.string().required("Program country is required"),
        program_location: yup.string().required("Program location is required"),
        program_status: yup.string().required("Program status is required"),
        programFieldColor: yup.string().required("Color is required"),
        program_image: edit
            ? yup.string()
            : yup.string().required("File is required"),
        program_description: yup
            .string()
            .required("Program description is required")
            .max(200, "Program description must be less than 200 characters"),
        program_registry: yup.string().required("Registry is required"),
        program_standard: yup.string().required("Standard is required"),
        program_methodology: yup.string().required("Methodology is required"),
        program_methodology_contract: edit
            ? yup.mixed()
            : yup.mixed().required("File is required"),
        program_exclusive_farm: yup
            .string()
            .required("Exclusive farm is required"),
        program_exclusive_field: yup
            .string()
            .required("Exclusive field is required"),
        program_registration_date: yup
            .date()
            .nullable()
            .required("Date is required"),
        program_crediting_period_start: yup
            .date()
            .nullable()
            .required("Date is required"),
        program_crediting_period_end: yup
            .date()
            .nullable()
            .required("Date is required  "),
        // program_credit_type: yup.string().required("Credit types is required"),
        program_credit_type: yup.string(),
        program_unit: yup.string().required("Unit is required"),
        program_farmer_fees: isAdmin
            ? yup
                  .number()
                  .typeError("Farmer fees must be a numeric value")
                  .required("Farmer fees are required")
                  .min(0, "Farmer fees cannot be less than 0%")
                  .max(100, "Farmer fees cannot be greater than 100%")
                  .transform((value, originalValue) => {
                      return !isNaN(value) ? +value : originalValue;
                  })
            : yup.number(),
        program_developer_fees: isAdmin
            ? yup
                  .number()
                  .typeError("Developer fees must be a numeric value")
                  .required("Developer fees are required")
                  .min(0, "Farmer fees cannot be less than 0%")
                  .max(100, "Developer fees cannot be greater than 100%")
                  .transform((value, originalValue) => {
                      return !isNaN(value) ? +value : originalValue;
                  })
            : yup.number(),
        program_registry_fees: isAdmin
            ? yup
                  .number()
                  .typeError("Registry fees must be a numeric value")
                  .required("Registry fees are required")
                  .min(0, "Farmer fees cannot be less than 0%")
                  .max(100, "Registry fees cannot be greater than 100%")
                  .transform((value, originalValue) => {
                      return !isNaN(value) ? +value : originalValue;
                  })
            : yup.number(),
        program_un_goals: yup.array(),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: () => {
            const values = formik.values;
            let un_goals_val = [];
            if (values?.program_un_goals) {
                un_goals_val = JSON.stringify(values?.program_un_goals);
            }

            const selectedBorderColor = fieldColors?.find(
                (color) => color.light == values.programFieldColor
            );
            const selectedDevCompId = leadCompanies?.find(
                (item) => item.companyName == values.program_developer
            );
            const formData = new FormData();
            for (const key in initialValues) {
                if (key === "program_un_goals") {
                    formData.append(key, un_goals_val);
                } else {
                    formData.append(key, values[key]);
                }
            }
            formData.append("program_id_company_KO", selectedDevCompId?.id);
            formData.append("programBorderColor", selectedBorderColor?.dark);
            if (
                values.program_status === "registered" &&
                (!edit ||
                    !company?.program_uco_address ||
                    !company?.program_scc_address)
            ) {
                const blockchain_token = getBlockChainToken();
                formData.append("blockchain_token", blockchain_token);
            }

            if (!edit) {
                dispatch(createProgram(formData)).then((res) => {
                    if (res.meta.requestStatus === "fulfilled" && !res.error) {
                        navigate("/programs");
                        dispatch(refetchProgram());
                    }
                });
            } else {
                dispatch(editProgram({ id: company?.id, formData })).then(
                    (res) => {
                        if (
                            res.meta.requestStatus === "fulfilled" &&
                            !res.error
                        ) {
                            navigate(`/program/${id}/detail`);
                            dispatch(refetchProgram());
                        }
                    }
                );
            }
        },
    });

    useEffect(() => {
        const companiesName = company?.slaveCompaniesName?.map((obj) => obj.id);
        if (company) {
            const currentValues = {
                program_name: company?.program_name || "",
                program_code: company?.program_code || "",
                program_type: company?.program_type || "",
                program_developer: company?.program_developer || "",
                program_external_id: company?.program_external_id || "",
                program_region: company?.program_region || "",
                program_country: company?.program_country || "",
                program_location: company?.program_location || "",
                program_status: company?.program_status || "",
                program_image: company?.program_image || "",
                program_description: company?.program_description || "",
                program_registry: company?.program_registry || "",
                program_standard: company?.program_standard || "",
                program_methodology: company?.program_methodology || "",
                program_methodology_contract:
                    company?.program_methodology_contract || "",
                program_exclusive_farm: company?.program_exclusive_farm || "",
                program_exclusive_field: company?.program_exclusive_field || "",
                program_registration_date:
                    company?.program_registration_date || null,
                program_crediting_period_start:
                    company?.program_crediting_period_start || null,
                program_crediting_period_end:
                    company?.program_crediting_period_end || null,
                program_credit_type: company?.program_credit_type || "",
                program_unit: company?.program_unit || "",
                slaveCompanyName: companiesName || [],
                program_un_goals: company.program_un_goals || [],
                programFieldColor: company?.program_color || "",
            };
            if (isAdmin) {
                currentValues.program_farmer_fees =
                    sanitizedDigits(company?.program_farmer_fees) || "";
                currentValues.program_developer_fees =
                    sanitizedDigits(company?.program_developer_fees) || "";
                currentValues.program_registry_fees =
                    sanitizedDigits(company?.program_registry_fees) || "";
            }
            formik.setValues(currentValues);
        }
    }, [company, edit]);

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
        >
            <ProgramInformation formik={formik} edit={edit} company={company} />
            <Methodology formik={formik} edit={edit} />
            {isAdmin && <Fees formik={formik} edit={edit} />}
            <Box
                sx={{
                    my: "49px",
                    "& .MuiInputLabel-asterisk": {
                        color: "red",
                    },
                }}
            >
                <Typography variant="h4" mb={3}>
                    Sustainable development goals
                    <SustainableGoals
                        disable={
                            edit && company?.program_status === "registered"
                        }
                        formik={formik}
                        program={company?.program_un_goals}
                        edit={edit}
                    />
                </Typography>
            </Box>
            <Stack direction="row" spacing={2} mt="49px">
                <CancelButton
                    address={edit ? `/program/${id}/detail` : `/programs`}
                />
                <Button type="submit" variant="contained" disabled={isLoading}>
                    {!isLoading ? (!edit ? "Add" : "Submit") : "Processing..."}
                </Button>
            </Stack>
        </Box>
    );
}
