import { useEffect, useState } from "react";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Tabs from "../../common/Tabs";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import FieldDetailsHeader from "components/field/details/FieldDetailsHeader";
import FieldDetailsTitle from "components/field/details/FieldDetailsTitle";
import Details from "components/field/details/Details";
import History from "components/field/history/History";
import FieldHistoryHeader from "components/field/history/FieldHistoryHeader";
import FieldHistoryTitle from "components/field/history/FieldHistoryTitle";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";

const FieldDetails = () => {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("details");

    let { id, tab_id: activeRoute } = useParams();

    useEffect(() => {
        if (activeRoute) {
            setActiveTab(activeRoute);
        }
    }, [activeRoute]);

    const ShowActiveExpand = () => {
        switch (activeTab) {
            case "details":
                return {
                    header: <FieldDetailsHeader />,
                    body: <Details fieldID={id} />,
                    pagetitle: <FieldDetailsTitle />,
                };
            case "history":
                return {
                    header: <FieldHistoryHeader />,
                    body: <History fieldID={id} />,
                    pagetitle: <FieldHistoryTitle />,
                };

            default:
                navigate("/404");
                return {
                    header: "",
                    body: "",
                    pagetitle: "",
                };
        }
    };

    const { header, body, pagetitle } = ShowActiveExpand();

    const handleTabChange = (routeName) => {
        navigate(routeName);
    };
    const { cannot } = useAccessPage();
    return (
        <Layout>
            <Box sx={{ width: "100%", mb: "10px" }}>
                <Grid
                    container
                    rowSpacing={{ md: 0, xs: "15px" }}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    {header}
                </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ mt: 0 }}
                >
                    {pagetitle}
                    <Grid item xs={12} md={4}>
                        <Tabs
                            tabItem={[
                                {
                                    name: "Details",
                                    route: `/field/${id}/details`,
                                    disableTabs: cannot(
                                        permissionSlug[0].fields_slug
                                            .field_details
                                    ),
                                },

                                {
                                    name: "History",
                                    route: `/field/${id}/history`,
                                    disableTabs: cannot(
                                        permissionSlug[0].fields_slug
                                            .fields_history
                                    ),
                                },
                            ]}
                            onTabChange={handleTabChange}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ width: "100%" }}>{body}</Box>
        </Layout>
    );
};

export default FieldDetails;
