// import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMemo } from "react";

import FormHelperText from "@mui/material/FormHelperText";
import dayjs from "dayjs";

const CompanyProfile = ({ formik, companylist }) => {
    const options = [
        { value: "developer", label: "Developer" },
        { value: "trader", label: "Trader" },
        { value: "corporation", label: "Corporation" },
    ];
    const wallet_management = [
        { value: "self-management", label: "Self management" },
        { value: "delegated", label: "Delegated" },
    ];
    const status = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "pending", label: "Pending" },
    ];
    const showError = (param) => {
        if (formik.errors?.[param] && formik.touched?.[param]) {
            return formik.errors?.[param];
        } else {
            return null;
        }
    };
    const companyProfile = useMemo(() => {
        return (
            <Box>
                <FormControl
                    variant="filled"
                    fullWidth
                    required
                    // key={name}
                    sx={{ mb: "5px" }}
                    lable="Profile"
                    type="select"
                >
                    <InputLabel id={`${"Profile"}-label`}>Profile</InputLabel>
                    <Select
                        labelId={`Profile`}
                        id="profile"
                        name="profile"
                        value={formik.values.profile}
                        onChange={formik.handleChange}
                        // helperText={"field is requried"}
                        onBlur={formik.handleBlur}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {showError("profile") ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {showError("profile")}
                        </FormHelperText>
                    ) : null}
                </FormControl>
            </Box>
        );
    }, [formik?.values.profile, formik.touched.profile, formik.errors.profile]);

    const company_wallet_management = useMemo(() => {
        return (
            <FormControl
                required
                variant="filled"
                fullWidth
                type="select"
                // key={name}
                sx={{ mb: "5px" }}
                lable="Wallet management"
            >
                <InputLabel id={`${"Wallet management"}-label`}>
                    Wallet management
                </InputLabel>
                <Select
                    labelId={`Wallet management`}
                    id="Wallet_management"
                    name="wallet_management"
                    value={formik.values.wallet_management}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={showError("wallet_management")}
                >
                    {wallet_management.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {showError("wallet_management") ? (
                    <FormHelperText sx={{ color: "red" }}>
                        {showError("wallet_management")}
                    </FormHelperText>
                ) : null}
            </FormControl>
        );
    }, [
        formik.values.wallet_management,
        formik.touched.wallet_management,
        formik.errors.wallet_management,
    ]);
    const companyWalletEmail = useMemo(() => {
        return (
            <Box
                sx={{
                    "@media (min-width: 768px)": {
                        gridColumn: "span 2",
                    },
                }}
            >
                <TextField
                    fullWidth
                    id="wallet_email"
                    name="wallet_email"
                    label="Wallet email"
                    variant="filled"
                    type="text"
                    // key="Wallet email"
                    value={formik.values.wallet_email}
                    onChange={formik.handleChange}
                    sx={{ mb: "5px" }}
                    error={showError("wallet_email")}
                    helperText={showError("wallet_email")}
                    onBlur={formik.handleBlur}
                    disabled={
                        companylist?.wallet?.wallet_public_key &&
                        companylist?.wallet?.wallet_email
                    }
                />
            </Box>
        );
    }, [
        formik.values.wallet_email,
        formik.touched.wallet_email,
        formik.errors.wallet_email,
        companylist?.wallet?.wallet_public_key,
        companylist?.wallet?.wallet_email,
    ]);

    const walletPublicAddress = useMemo(() => {
        return (
            <Box
                sx={{
                    "@media (min-width: 768px)": {
                        gridColumn: "span 2",
                    },
                }}
            >
                <TextField
                    fullWidth
                    autoComplete="off"
                    id="wallet_public_key"
                    name="wallet_public_key"
                    label="Wallet public key"
                    variant="filled"
                    type="text"
                    // key="Wallet email"
                    value={formik.values.wallet_public_key}
                    onChange={formik.handleChange}
                    sx={{ mb: "5px" }}
                    error={showError("wallet_public_key")}
                    helperText={showError("wallet_public_key")}
                    onBlur={formik.handleBlur}
                    disabled={companylist?.wallet?.wallet_public_key}
                />
            </Box>
        );
    }, [
        formik.values.wallet_public_key,
        formik.touched.wallet_public_key,
        formik.errors.wallet_public_key,
        companylist?.wallet?.wallet_public_key,
    ]);

    const contaractSignDate = useMemo(() => {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Contract sign date"
                    name="contractSignDate"
                    format="DD/MM/YYYY"
                    sx={{
                        width: "100%",
                        // borderBottom: formik.errors.contractSignDate && "1px solid red"
                    }}
                    slotProps={{
                        textField: {
                            helperText: showError("contractSignDate") && (
                                <FormHelperText
                                    sx={{
                                        color: "red",
                                        display: "inline",
                                        ml: "0",
                                    }}
                                >
                                    {showError("contractSignDate")}
                                </FormHelperText>
                            ),
                            variant: "filled",
                            required: true,
                        },
                    }}
                    // value={formik.values.contractSignDate}
                    value={
                        formik.values.contractSignDate
                            ? dayjs(formik.values.contractSignDate)
                            : null
                    }
                    onChange={(value) => {
                        formik.setFieldValue(
                            "contractSignDate",
                            value.format("YYYY-MM-DD")
                        );
                    }}
                />
            </LocalizationProvider>
        );
    }, [
        formik.values.contractSignDate,
        formik.touched.contractSignDate,
        formik.errors.contractSignDate,
    ]);

    const renewalDate = useMemo(() => {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Renewal date"
                    name="renewalDate"
                    format="DD/MM/YYYY"
                    sx={{
                        width: "100%",
                    }}
                    slotProps={{
                        textField: {
                            helperText: showError("renewalDate") && (
                                <FormHelperText
                                    sx={{
                                        color: "red",
                                        display: "inline",
                                        ml: "0",
                                    }}
                                >
                                    {showError("renewalDate")}
                                </FormHelperText>
                            ),
                            variant: "filled",
                            required: true,
                        },
                    }}
                    // value={formik.values.renewalDate}
                    // onChange={(value) =>
                    //     formik.setFieldValue("renewalDate", value)
                    // }
                    value={
                        formik.values.renewalDate
                            ? dayjs(formik.values.renewalDate)
                            : null
                    }
                    onChange={(value) =>
                        formik.setFieldValue(
                            "renewalDate",
                            value.format("YYYY-MM-DD")
                        )
                    }
                />
            </LocalizationProvider>
        );
    }, [
        formik.values.renewalDate,
        formik.touched.renewalDate,
        formik.errors.renewalDate,
    ]);

    const companyStatus = useMemo(() => {
        return (
            <Box>
                <FormControl
                    variant="filled"
                    fullWidth
                    sx={{ mb: "5px" }}
                    lable="Status"
                    type="select"
                >
                    <InputLabel id={`${"Status"}-label`}>Status</InputLabel>
                    <Select
                        labelId={`companyStatus`}
                        id="companyStatus"
                        name="companyStatus"
                        value={formik.values.companyStatus}
                        onChange={formik.handleChange}
                        variant="filled"
                    >
                        {status.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    }, [formik.values.companyStatus]);

    return (
        <>
            <Typography variant="h4" mt={1}>
                Company Profile
            </Typography>
            <Box component="form" noValidate autoComplete="off">
                <Box
                    component="form"
                    display="grid"
                    gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                    gap={"10px 30px"}
                    sx={{
                        "@media (max-width: 767px)": {
                            gridTemplateColumns: "repeat( 1, 1fr )",
                        },
                    }}
                >
                    {companyProfile}
                    {company_wallet_management}
                    {companyWalletEmail}
                    {walletPublicAddress}
                    {contaractSignDate}
                    {renewalDate}
                    {companyStatus}
                </Box>
            </Box>
        </>
    );
};

export default CompanyProfile;
