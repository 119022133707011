import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIRBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIRBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIRBASE_MESSAGINGSENDID,
    appId: process.env.REACT_APP_FIRBASEAPPID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase auth
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export default app;
