import Layout from "Layout";
import { Typography } from "@mui/material";
import Breadcrumbs from "common/Breadcrumb";

import Grid from "@mui/material/Grid";
import FormContainer from "components/admin/company/FormContainer";

import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
const CreateCompany = () => {
    const { can } = useAccessPage();

    const routeAccess = {
        routeAccess: can(permissionSlug[0].company_slug.create_company),
        unAuthUserRedirectTo: "/401",
    };
    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1 }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb="10px"
                >
                    <Breadcrumbs
                        xs="auto"
                        sources={[
                            {
                                label: "admin",
                                link: "/companies",
                                canNavigate: true,
                            },
                            {
                                label: "company",
                                link: "/companies",
                                canNavigate: true,
                            },
                            {
                                label: "add a company",
                                link: "",
                            },
                        ]}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "center",
                                sm: "flex-end",
                            },
                            pt: "2px !important",
                        }}
                    ></Grid>
                </Grid>

                <Typography variant="h2">Add a company</Typography>
                <Typography variant="body1" mt={1} mb={3}>
                    Please fill the following form to add a new company under
                    contract.
                </Typography>
                <FormContainer edit={false} />
            </CheckpageAuth>
        </Layout>
    );
};

export default CreateCompany;
