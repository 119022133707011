import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useState } from "react";

const PermissionsForm = ({ items, onUpdatePermissions }) => {
    const itemsArray = Array.isArray(items) ? items : [];

    const [checkedItems, setCheckedItems] = useState(
        itemsArray.reduce((acc, item) => {
            acc[item.id] = item.permission;
            return acc;
        }, {})
    );

    const handleCheckboxChange = (item) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [item.id]: !checkedItems[item.id],
        };

        setCheckedItems(updatedCheckedItems);
        onUpdatePermissions(item, updatedCheckedItems[item.id]);
    };

    return (
        <Box
            sx={{
                mb: "10px",
                "& .MuiInputLabel-asterisk": {
                    color: "red",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >
                {itemsArray.map(
                    (item) =>
                        item.permission === "view" && (
                            <Box
                                key={item.id}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingRight: "24px",
                                    width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "33.3%",
                                    },
                                    marginBottom: "10px",
                                }}
                            >
                                <FormControlLabel
                                    sx={{ marginLeft: "-4px" }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                transform: "scale(0.8)",
                                                padding: "0",
                                                marginRight: "5px",
                                            }}
                                            checked={checkedItems[item.id]}
                                            onChange={() =>
                                                handleCheckboxChange(item)
                                            }
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="span"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        >
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            </Box>
                        )
                )}

                {itemsArray.map(
                    (item) =>
                        item.permission !== "view" && (
                            <Box
                                key={item.id}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingRight: "24px",
                                    width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "33.3%",
                                    },
                                    marginBottom: "10px",
                                }}
                            >
                                <FormControlLabel
                                    sx={{ marginLeft: "-4px" }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                transform: "scale(0.8)",
                                                padding: "0",
                                                marginRight: "5px",
                                            }}
                                            checked={checkedItems[item.id]}
                                            onChange={() =>
                                                handleCheckboxChange(item)
                                            }
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="span"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        >
                                            {item.label}
                                        </Typography>
                                    }
                                />
                            </Box>
                        )
                )}
            </Box>
        </Box>
    );
};

export default PermissionsForm;
