import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage, showSuccessMessage } from "redux/toastSlice";
import { resetStateHander } from "helpers/sanitizedFunction";
import errorHandler from "helpers/errorhandler";

export const fetchAccountDetails = createAsyncThunk(
    "account/fetchAccountDetails",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getAccount();
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const editAccountDetails = createAsyncThunk(
    "account/editAccountDetails",
    async (payload, thunkAPI) => {
        try {
            let response;
            if (payload.user) {
                response = await Services.editAccount(payload.values);
            } else {
                response = await Services.editAdminAccount(payload.values);
            }
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("Account Updated Successfully")
                );
                return { data: response.data.data, user: payload.user };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(
                showErrorMessage(
                    error?.response?.data?.message ||
                        error.message ||
                        error.name
                )
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const toggleSharingOptions = createAsyncThunk(
    "account/toggleSharingOptions",
    async (payload, thunkAPI) => {
        try {
            const response = await Services.toggleSharingOptions(payload);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage(
                        `Sharing ${payload.data.type} data ${
                            payload.data.value ? "enabled" : "disabled"
                        } Successfully`
                    )
                );
                return { data: response.data.data, user: payload.user };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(errorHandler(error));
        }
    }
);

const initialState = {
    accountDetails: {},
    error: null,
    isLoading: false,
    isLoaded: false,
    isToggling: false,
    accessibility: { permissions: [], role: null },
};

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        resetAccount: (state) => {
            resetStateHander(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAccountDetails.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.accessibility.permissions = payload.permissions;
                state.accessibility.role = payload.role.slug;
                delete payload.permissions;
                state.accountDetails = payload;
                state.error = null;
                state.isLoading = false;
                state.isLoaded = true;
            })
            .addCase(fetchAccountDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            })
            .addCase(editAccountDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editAccountDetails.fulfilled, (state, { payload }) => {
                if (!payload.user) {
                    state.accountDetails.user_firstname =
                        payload.data.user_firstname;
                    state.accountDetails.user_lastname =
                        payload.data.user_lastname;
                    state.accountDetails.user_phone = payload.data.user_phone;
                    state.accountDetails.user_email = payload.data.user_email;
                    //
                    state.accountDetails.user_timezone =
                        payload.data.user_timezone;
                    state.accountDetails.user_dateformat =
                        payload.data.user_dateformat;
                    state.accountDetails.user_measurement =
                        payload.data.user_measurement;
                    state.accountDetails.user_language =
                        payload.data.user_language;
                } else {
                    state.accountDetails = {
                        ...state.accountDetails,
                        ...payload.data,
                    };
                }
                state.error = null;
                state.isLoading = false;
            })
            .addCase(editAccountDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            })
            .addCase(toggleSharingOptions.pending, (state) => {
                state.isToggling = true;
            })
            .addCase(toggleSharingOptions.fulfilled, (state) => {
                state.error = null;
                state.isToggling = false;
            })
            .addCase(toggleSharingOptions.rejected, (state) => {
                state.isToggling = false;
            });
    },
});
export const { resetAccount } = accountSlice.actions;
export default accountSlice.reducer;
