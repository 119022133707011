import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage, showSuccessMessage } from "redux/toastSlice";
import { resetStateHander } from "helpers/sanitizedFunction";

import errorHandler from "helpers/errorhandler";
// import errorHandler from "helpers/errorhandler";

export const fetchRegistryCollections = createAsyncThunk(
    "registery/fetchRegistryCollections",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getRegistryCollections(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchRetirementDetails = createAsyncThunk(
    "registery/fetchRetirementDetails",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getRetirementDetails(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchSCCListings = createAsyncThunk(
    "registery/fetchSCCListings",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getSCCListings(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const retireSCCs = createAsyncThunk(
    "registery/retireSCCs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.retireProgramSCC(paramss);
            if (response.data.data.success) {
                thunkAPI.dispatch(
                    showSuccessMessage("Quantity Retired Successfully!")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelUCOs = createAsyncThunk(
    "registery/cancelUCOs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.cancelsUCOs(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("UCO cancelled successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const cancelSelectedUCOs = createAsyncThunk(
    "registery/cancelSelectedUCOs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.cancelSelectedUCOs(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("UCOs cancelled successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelSelectedSCCs = createAsyncThunk(
    "registery/cancelSelectedSCCs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.cancelSelectedSCCs(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("SCCs cancelled successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const freezeSelectedSCCs = createAsyncThunk(
    "registery/freezeSelectedSCCs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.freezeSelectedSCCs(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("SCCs freezed successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelsUCOsWithCsv = createAsyncThunk(
    "registery/cancelsUCOsWithCsv",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.cancelsUCOsWithCsv(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("UCOs cancelled successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelSCCs = createAsyncThunk(
    "registery/cancelSCCs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.cancelsSCCs(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("SCC cancelled successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelsSCCsWithCsv = createAsyncThunk(
    "registery/cancelsSCCsWithCsv",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.cancelsSCCsWithCsv(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("SCCs cancelled successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            let errorss = null;
            var errorString = error.response.data.message;

            // Find the index of the opening quote after "execution reverted: "
            var startIndex =
                errorString.indexOf('execution reverted: "') +
                'execution reverted: "'.length;
            if (startIndex > 20) {
                // Find the index of the closing quote after "SCC: insufficient balance for status change"
                var endIndex = errorString.indexOf('"', startIndex);

                // Extract the desired line
                var desiredLine =
                    "Error: " + errorString.substring(startIndex, endIndex);
                errorss = desiredLine;
            } else {
                errorss = errorHandler(error);
            }
            thunkAPI.dispatch(showErrorMessage(errorss));
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(errorss);
        }
    }
);

export const freezesSCCsWithCsv = createAsyncThunk(
    "registery/freezesSCCsWithCsv",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.freezesSCCsWithCsv(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("SCCs Freezed successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            let errorss = null;
            var errorString = error.response.data.message;

            // Find the index of the opening quote after "execution reverted: "
            var startIndex =
                errorString.indexOf('execution reverted: "') +
                'execution reverted: "'.length;
            if (startIndex > 20) {
                // Find the index of the closing quote after "SCC: insufficient balance for status change"
                var endIndex = errorString.indexOf('"', startIndex);

                // Extract the desired line
                var desiredLine =
                    "Error: " + errorString.substring(startIndex, endIndex);
                errorss = desiredLine;
            } else {
                errorss = errorHandler(error);
            }
            thunkAPI.dispatch(showErrorMessage(errorss));
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(errorss);
        }
    }
);

export const freezeSCCs = createAsyncThunk(
    "registery/freezeSCCs",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.freezesSCCs(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(
                    showSuccessMessage("SCC freezed successfully")
                );
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchUCOSCCSDetail = createAsyncThunk(
    "registery/fetchUCOSCCSDetail",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getUCODetails(paramss);
            if (response.data.data) {
                return { payload: response.data.data, type: paramss.type };
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            if (error.response.status === 409) {
                return thunkAPI.rejectWithValue({ inProgress: true });
            } else {
                return thunkAPI.rejectWithValue(error.response.data.data);
            }
        }
    }
);

export const fetchUCOListings = createAsyncThunk(
    "registery/fetchUCOListings",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.getUCOListings(paramss);
            if (response.data.data) {
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage("Something went wrong"));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
//sco pdf generate
export const getUcoCertificatePdf = createAsyncThunk(
    "registery/getUcoCertificatePdf",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.fetchUcoCertiPdf(paramss);
            if (response.data.data) {
                thunkAPI.dispatch(showSuccessMessage(response.data.message));
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
//uco activity status
export const getUcoActivity = createAsyncThunk(
    "registery/getUcoActivity",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.fetchUcoStatusActivity(paramss);
            if (response.data.data) {
                // thunkAPI.dispatch(showSuccessMessage(response.data.message));
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getSCCActivity = createAsyncThunk(
    "registery/getSCCActivity",
    async (paramss, thunkAPI) => {
        try {
            const response = await Services.fetchsSCCsActivity(paramss);
            if (response.data.data) {
                // thunkAPI.dispatch(showSuccessMessage(response.data.message));
                return response.data.data;
            } else {
                thunkAPI.dispatch(showErrorMessage("Something went wrong"));
                return thunkAPI.rejectWithValue("Something went wrong");
            }
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
const initialState = {
    error: null,
    // isLoading: false,
    // isLoaded: false,
    // isToggling: false,
    // accessibility: { permissions: [], role: null },
    collections: [],
    retiringLoadings: false,
    isLoading: false,
    collectionsCount: 0,
    retirementDetails: {},
    SCCS: [],
    SCCSCounts: 0,
    UCOS: [],
    UCOSCounts: 0,
    UCOSDetails: {},
    SCCSDetails: {},
    // sccSucess: "",
    inProgress: false,
    ucoCertiPDf: "",
    cancellingUCOs: false,
    cancellingSCCs: false,
    freezingSCCs: false,
    isCertiLoading: false,
    sccsActivity: [],
    ucoActivity: [],
    isActivityLoading: null,
    count: null,
};

export const registrySlices = createSlice({
    name: "registry",
    initialState,
    reducers: {
        resetRegistry: (state) => {
            resetStateHander(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRegistryCollections.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchRegistryCollections.fulfilled,
                (state, { payload }) => {
                    // Add user to the state array
                    // state.accessibility.permissions = payload.permissions;
                    // state.accessibility.role = payload.role.slug;
                    // delete payload.permissions;
                    state.collections = payload.programs;
                    state.collectionsCount = payload.count;
                    state.error = null;
                    state.isLoading = false;
                    state.isLoaded = true;
                }
            )
            .addCase(
                fetchRegistryCollections.rejected,
                (state, { payload }) => {
                    state.isLoading = false;
                    showErrorMessage(payload);
                }
            )
            .addCase(fetchRetirementDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchRetirementDetails.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.retirementDetails = payload;
                state.error = null;
                state.isLoading = false;
                // state.isLoaded = true;
            })
            .addCase(fetchRetirementDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            })
            .addCase(fetchSCCListings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSCCListings.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.SCCS = payload.sccs;
                state.SCCSCounts = payload.count;
                state.error = null;
                state.isLoading = false;
            })
            .addCase(fetchSCCListings.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            })
            .addCase(retireSCCs.pending, (state) => {
                state.retiringLoadings = true;
            })
            .addCase(retireSCCs.fulfilled, (state, { payload }) => {
                // Add user to the state array

                state.error = null;
                state.retiringLoadings = false;
                state.isLoaded = true;
                state.sccSucess = payload;
            })
            .addCase(retireSCCs.rejected, (state, { payload }) => {
                state.retiringLoadings = false;
                showErrorMessage(payload);
            })
            .addCase(fetchUCOListings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUCOListings.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.UCOS = payload.ucos;
                state.UCOSCounts = payload.count;
                state.error = null;
                state.isLoading = false;
            })
            .addCase(fetchUCOListings.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            })
            .addCase(fetchUCOSCCSDetail.pending, (state) => {
                state.isLoading = true;
                state.inProgress = false;
            })
            .addCase(fetchUCOSCCSDetail.fulfilled, (state, { payload }) => {
                // Add user to the state array
                if (payload.type === "UCO") {
                    state.UCOSDetails = payload.payload;
                } else if (payload.type === "SCC") {
                    state.SCCSDetails = payload.payload;
                }
                state.error = null;
                state.isLoading = false;
                // state.isLoaded = true;
            })
            .addCase(fetchUCOSCCSDetail.rejected, (state, { payload }) => {
                state.isLoading = false;
                if (payload?.inProgress) {
                    state.inProgress = true;
                }
                showErrorMessage(payload);
            })
            .addCase(getUcoCertificatePdf.pending, (state) => {
                state.isCertiLoading = true;
                state.error = null;
            })
            .addCase(getUcoCertificatePdf.fulfilled, (state, { payload }) => {
                state.ucoCertiPDf = payload;
                state.error = null;
                state.isCertiLoading = false;
            })
            .addCase(getUcoCertificatePdf.rejected, (state, { payload }) => {
                state.isCertiLoading = false;
                showErrorMessage(payload);
            })
            /*  */
            .addCase(getSCCActivity.pending, (state) => {
                state.isActivityLoading = true;
                state.error = null;
            })
            .addCase(getSCCActivity.fulfilled, (state, { payload }) => {
                state.sccsActivity = payload.history;
                state.count = payload.count;
                state.error = null;
                state.isActivityLoading = false;
            })
            .addCase(getSCCActivity.rejected, (state, { payload }) => {
                state.isActivityLoading = false;
                showErrorMessage(payload);
            })
            .addCase(getUcoActivity.pending, (state) => {
                state.isActivityLoading = true;
                state.error = null;
            })
            .addCase(getUcoActivity.fulfilled, (state, { payload }) => {
                state.ucoActivity = payload.history;
                state.count = payload.count;
                state.error = null;
                state.isActivityLoading = false;
            })
            .addCase(getUcoActivity.rejected, (state, { payload }) => {
                state.isActivityLoading = false;
                showErrorMessage(payload);
            })
            .addCase(cancelUCOs.pending, (state) => {
                state.cancellingUCOs = true;
            })
            .addCase(cancelUCOs.fulfilled, (state) => {
                state.error = null;
                state.UCOSDetails.status = "Cancelled";
                state.cancellingUCOs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(cancelUCOs.rejected, (state, { payload }) => {
                state.cancellingUCOs = false;
                showErrorMessage(payload);
            })
            .addCase(cancelSelectedUCOs.pending, (state) => {
                state.cancellingUCOs = true;
            })
            .addCase(cancelSelectedUCOs.fulfilled, (state) => {
                state.error = null;
                state.cancellingUCOs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(cancelSelectedUCOs.rejected, (state, { payload }) => {
                state.cancellingUCOs = false;
                showErrorMessage(payload);
            })
            .addCase(cancelSelectedSCCs.pending, (state) => {
                state.cancellingSCCs = true;
            })
            .addCase(cancelSelectedSCCs.fulfilled, (state) => {
                state.error = null;
                state.cancellingSCCs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(cancelSelectedSCCs.rejected, (state, { payload }) => {
                state.cancellingSCCs = false;
                showErrorMessage(payload);
            })
            .addCase(freezeSelectedSCCs.pending, (state) => {
                state.freezingSCCs = true;
            })
            .addCase(freezeSelectedSCCs.fulfilled, (state) => {
                state.error = null;
                state.freezingSCCs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(freezeSelectedSCCs.rejected, (state, { payload }) => {
                state.freezingSCCs = false;
                showErrorMessage(payload);
            })
            .addCase(cancelsUCOsWithCsv.pending, (state) => {
                state.cancellingUCOs = true;
            })
            .addCase(cancelsUCOsWithCsv.fulfilled, (state) => {
                // Add user to the state array

                state.error = null;
                state.cancellingUCOs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(cancelsUCOsWithCsv.rejected, (state, { payload }) => {
                state.cancellingUCOs = false;
                showErrorMessage(payload);
            })
            .addCase(cancelSCCs.pending, (state) => {
                state.cancellingSCCs = true;
            })
            .addCase(cancelSCCs.fulfilled, (state) => {
                state.error = null;
                // state.UCOSDetails.status = "Cancelled";
                state.cancellingSCCs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(cancelSCCs.rejected, (state, { payload }) => {
                state.cancellingSCCs = false;
                showErrorMessage(payload);
            })
            .addCase(cancelsSCCsWithCsv.pending, (state) => {
                state.cancellingSCCs = true;
            })
            .addCase(cancelsSCCsWithCsv.fulfilled, (state) => {
                // Add user to the state array

                state.error = null;
                state.cancellingSCCs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(cancelsSCCsWithCsv.rejected, (state, payload) => {
                state.cancellingSCCs = false;
                showErrorMessage(payload);
            })
            .addCase(freezesSCCsWithCsv.pending, (state) => {
                state.freezingSCCs = true;
            })
            .addCase(freezesSCCsWithCsv.fulfilled, (state) => {
                // Add user to the state array

                state.error = null;
                state.freezingSCCs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(freezesSCCsWithCsv.rejected, (state, payload) => {
                state.freezingSCCs = false;
                showErrorMessage(payload);
            })
            .addCase(freezeSCCs.pending, (state) => {
                state.freezingSCCs = true;
            })
            .addCase(freezeSCCs.fulfilled, (state) => {
                state.error = null;
                // state.UCOSDetails.status = "Cancelled";
                state.freezingSCCs = false;
                // state.isLoaded = true;
                // state.sccSucess = payload;
            })
            .addCase(freezeSCCs.rejected, (state, { payload }) => {
                state.freezingSCCs = false;
                showErrorMessage(payload);
            });
    },
});
export const { resetRegistry } = registrySlices.actions;
export default registrySlices.reducer;
