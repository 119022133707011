import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Services from "services";
import { showErrorMessage, showSuccessMessage } from "redux/toastSlice";
import errorHandler from "helpers/errorhandler";

export const fetchPermissions = createAsyncThunk(
    "permissions/fetchPermissions",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.getPermissions(params);
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updatePermissions = createAsyncThunk(
    "permissions/updatePermissions",
    async (params, thunkAPI) => {
        try {
            const { data } = await Services.updatePermissions(params);
            if (data.success === true) {
                thunkAPI.dispatch(
                    showSuccessMessage("Permissions updated successfully!")
                );
            }
            return data;
        } catch (error) {
            thunkAPI.dispatch(showErrorMessage(errorHandler(error)));
            if (!error.response) {
                throw error;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
const initialState = {
    permissions: [],
    isLoading: false,
    error: null,
};

const permissionsSlice = createSlice({
    name: "permissions",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(fetchPermissions.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchPermissions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.permissions = payload;
                state.error = null;
            })
            .addCase(fetchPermissions.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            })
            .addCase(updatePermissions.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updatePermissions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.permissions = payload;
                state.error = null;
            })
            .addCase(updatePermissions.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorMessage(payload);
            });
    },
});

export default permissionsSlice.reducer;
