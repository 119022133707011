import Layout from "Layout";
import Breadcrumbs from "common/Breadcrumb";
import Grid from "@mui/material/Grid";
import Cards from "components/program/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, Skeleton, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Searchbar from "common/Searchbar";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchAllPrograms,
    fetchMore,
    // refetchProgram,
} from "redux/programSlice";
import { getCompanyDetaill } from "redux/companiesSlice";
import OpenPreviousPage from "common/OpenPreviousPage";
import permissionSlug from "assets/data/permissionSlug.json";
import Modal from "common/Modal";
import CheckpageAuth from "auth/CheckPageAccess";
import { useAccessPage } from "customHooks/useAccess";
import UnderlineText from "common/UnderlineText";
import { hideWalletModal } from "redux/authentication";
// import { propmtCreateWallet } from "redux/accountSlice";
import { Magic } from "magic-sdk";
import { ethers } from "ethers";
import { storeWalletAddress } from "redux/walletSlice";

const Program = () => {
    const { id } = useParams();
    const location = useLocation();
    const { login } = useSelector((state) => state);
    // const initialQuery = useRef({ page: 1, limit: 6, search: "" });
    const [query, setQuery] = useState({ page: 1, limit: 9, search: "" });
    const [OpenWalletModal, setOpenWalletModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [isMagicLoading, setIsMagicLoading] = useState(false);
    const [address, setAddress] = useState();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { can, cannot, isRoles } = useAccessPage();
    const isPrincipal = isRoles("principal");
    // const isAdmin = isRoles("admin");
    const { accountDetails: users } = useSelector((state) => state.account);

    const routeAccess = {
        routeAccess: can(permissionSlug[0].programSlug.list_programs),
        unAuthUserRedirectTo: "/401",
    };
    const { companylist } = useSelector(
        (state) => ({
            companylist: state.companylist.companyDetail.data,
        }),
        shallowEqual
    );
    useEffect(() => {
        if (id) {
            dispatch(getCompanyDetaill(id));
        }
    }, [id]);
    const { programs, count, isLoading, fetchingMore } = useSelector(
        (state) => state.program,
        shallowEqual
    );
    const handleSearch = (search) => {
        setQuery({ page: 1, limit: 6, search: search });
    };

    const handleLoadMore = () => {
        setQuery((prevQuery) => ({ ...prevQuery, page: prevQuery.page + 1 }));
    };

    useEffect(() => {
        const emptyAddress = !users?.company?.wallet?.wallet_public_key;
        const shouldHaveEmail = users?.company?.wallet?.wallet_email;
        const isDeveloperCompany =
            users?.company?.companyProfile === "developer";
        // const isAdminOrPrincipal = isAdmin || isPrincipal;
        const shouldHaveUser = Object.entries(users)?.length !== 0;
        if (
            shouldHaveUser &&
            emptyAddress &&
            shouldHaveEmail &&
            isPrincipal &&
            isDeveloperCompany
        ) {
            setOpenWalletModal(login?.showCreateWalletModal);
        }
    }, [users?.company?.wallet, login?.showCreateWalletModal]);
    useEffect(() => {
        if (query.page > 1) {
            dispatch(fetchMore(query));
        } else {
            dispatch(fetchAllPrograms({ ...query, ...(id && { id: id }) }));
        }
    }, [dispatch, query, location.pathname]);

    // useEffect(() => {
    //     if (!isLoaded) {
    //         if (query.page > 1) {
    //             dispatch(fetchMore(query));
    //         } else {
    //             dispatch(fetchAllPrograms({ ...query, ...(id && { id: id }) })).then((res) => {
    //                 if (res.meta.requestStatus === "fulfilled" && !res.error) {
    //                     initialQuery.current = query;
    //                 }
    //             });
    //         }
    //     }
    // }, [isLoaded]);

    // useEffect(() => {
    //     dispatch(refetchProgram());
    // }, [query]);
    const handleWalletModal = async () => {
        setIsMagicLoading(true);
        try {
            await launchMagicLink();
        } catch (err) {
            // console.log("error connecting with Magic Link", err);
        }
        setIsMagicLoading(false);
        setOpenWalletModal(false);
        setSuccessModal(true);
    };
    const handleHideModal = () => {
        setOpenWalletModal(false);
        dispatch(hideWalletModal());
    };

    async function launchMagicLink() {
        const magic = new Magic(process.env.REACT_APP_MAGIC_LINK_API_KEY, {
            // this API key is not sensitive information
            network: {
                rpcUrl: process.env.REACT_APP_MAGIC_RPC_URL,
                chainId: process.env.REACT_APP_MAGIC_CHAIN_ID, // or 80001 for polygon testnet
            },
        });
        const email = users?.company?.wallet?.wallet_email;
        // const email = "flo_t@carboncreditregistry.eu" // you can put your own to test

        await magic.auth.loginWithEmailOTP({ email });

        const magicProvider = await magic.wallet.getProvider();
        const provider = new ethers.BrowserProvider(magicProvider);

        // const signer = await provider.getSigner();
        // console.log("signer", signer); // this is the object we will use later to send transactions

        const account = (await provider.listAccounts())[0];
        setAddress(account.address);
        // [TODO]: dispatch to add the account.address as the new company wallet_public_key
        if (account.address && email && users?.company?.id) {
            dispatch(
                storeWalletAddress({
                    companyId: users?.company?.id,
                    address: account.address,
                    email: email,
                })
            );
        }
    }

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box
                    sx={{
                        width: "100%",
                        backgroundColor: "primary",
                        mb: "10px",
                    }}
                >
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        {id ? (
                            <Breadcrumbs
                                xs="auto"
                                sources={[
                                    {
                                        label: "all programs",
                                        link: "/programs",
                                        canNavigate: true,
                                    },
                                    {
                                        label: "company programs",
                                        link: "",
                                    },
                                ]}
                            />
                        ) : (
                            <Breadcrumbs
                                xs="auto"
                                sources={[
                                    {
                                        label: "all programs",
                                        link: "",
                                    },
                                ]}
                            />
                        )}

                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    md: "flex-end",
                                },
                                gap: { xs: "18px", lg: "28px" },
                            }}
                        >
                            <Button
                                disabled={cannot(
                                    permissionSlug[0].programSlug.create_program
                                )}
                                variant="contained"
                                onClick={() => navigate("/new-program")}
                                color="buttonPrimary"
                                sx={{
                                    my: {
                                        xs: "5px",
                                        md: "0",
                                        textTransform: "none",
                                    },
                                }}
                            >
                                {/* <AddIcon sx={{ width: "15px" }} />  */}
                                Add new program
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {/* modal for wallet */}
                <Modal
                    title={
                        <Typography
                            sx={{ linHeight: "36px", fontSize: "28px" }}
                        >
                            <span
                                style={{ color: "#02353C", fontWeight: "700" }}
                            >
                                Welcome
                            </span>{" "}
                            <span
                                style={{
                                    color: "#02353C",
                                    fontWeight: "normal",
                                }}
                            >
                                on FarmRegistry !
                            </span>
                        </Typography>
                    }
                    body={
                        <>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 3 }}
                            >
                                {/**/}
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        fontSize: "20px",
                                    }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ color: "#02353C" }}
                                    >
                                        {" "}
                                        Your{" "}
                                        <UnderlineText>
                                            {" "}
                                            first step{" "}
                                        </UnderlineText>{" "}
                                        with the blockchain :
                                    </Box>
                                    <span
                                        style={{
                                            color: "#02353C",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {" "}
                                        create company’s wallet
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        padding: "19px 0px",
                                    }}
                                >
                                    Your company wallet has not been established
                                    yet. To unleash the full features of our
                                    platform, it is imperative that you create
                                    it promptly.
                                </Typography>
                                {""}
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        "& a": {
                                            color: "#5282B6",
                                        },
                                    }}
                                >
                                    Creating a wallet will allow you to manage
                                    your Token efficiently, ensure secure
                                    transactions, and access various other
                                    exclusive features tailored to enhance your
                                    experience on our platform. It serves as a
                                    gateway to a suite of advanced
                                    functionalities designed to provide you with
                                    optimal control and management over your
                                    assets and transactions.
                                </Typography>
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        marginTop: "8px",
                                    }}
                                >
                                    <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Note :
                                    </span>{" "}
                                    To set up your company wallet, you will
                                    require access to the mailbox associated
                                    with{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                        {users?.company?.wallet?.wallet_email}
                                    </span>
                                    {""}. This mailbox will receive verification
                                    links, and other crucial information
                                    necessary for the wallet creation process.
                                    Ensure that you have the proper permissions
                                    and access rights to this mailbox to avoid
                                    any disruptions in setting up your wallet.
                                </Typography>
                            </Typography>
                        </>
                    }
                    footer={
                        <Stack
                            direction="row"
                            spacing={1}
                            mt="44px"
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="outlined"
                                onClick={handleHideModal}
                                sx={{ fontWeight: "500" }}
                            >
                                Not now
                            </Button>
                            <Button
                                variant="contained"
                                color="buttonPrimary"
                                onClick={handleWalletModal}
                            >
                                {isMagicLoading ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    "Create wallet"
                                )}
                            </Button>
                        </Stack>
                    }
                    open={OpenWalletModal}
                    close={() => setOpenWalletModal(false)}
                />

                {/*  */}
                <Grid item xs={12} md="auto">
                    {isLoading ? (
                        <Skeleton
                            variant="text"
                            sx={{ fontSize: "3rem", width: "20rem" }}
                        />
                    ) : (
                        <Typography
                            variant="h2"
                            sx={{
                                textAlign: { xs: "center", md: "left" },
                            }}
                        >
                            {id ? <OpenPreviousPage /> : ""}
                            {!id && ""}
                            {count}
                            {count > 1 ? " Programs " : " Program "}
                            {id ? "on" : " "}{" "}
                            <Box
                                component="span"
                                sx={{
                                    fontWeight: "normal",
                                    borderBottom: "1px solid #000",
                                    display: "inline-block",
                                    "& a": {
                                        color: "inherit",
                                    },
                                    "&:hover": {
                                        color: "grey",
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <Link
                                    to={`/companies/detail/${id}`}
                                    sx={{
                                        display: "inline-block",
                                    }}
                                >
                                    {" "}
                                    {id ? `${companylist?.companyName}` : ""}
                                </Link>
                            </Box>{" "}
                        </Typography>
                    )}
                </Grid>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                    sx={{
                        width: "100%",
                        md: "auto",
                        justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                            lg: "space-between",
                            xl: "space-between",
                        },
                    }}
                >
                    <Searchbar
                        placeholdertext="Search a program"
                        handleSearch={(search) => {
                            handleSearch(search);
                        }}
                    />
                </Stack>
                {isLoading ? (
                    <p
                        style={{
                            textAlign: "center",
                            color: "#02353C",
                            fontWeight: "bold",
                        }}
                    >
                        Processing...
                    </p>
                ) : (
                    <Grid
                        container
                        spacing={0}
                        sx={{ margin: "40px -15px 0", width: "auto" }}
                    >
                        {programs.map((program) => {
                            return <Cards program={program} key={program.id} />;
                        })}
                    </Grid>
                )}

                {count > programs.length && !isLoading && (
                    <Stack
                        direction="row"
                        spacing={2}
                        mt="49px"
                        sx={{ justifyContent: "center" }}
                    >
                        {fetchingMore ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            <Button
                                variant="contained"
                                onClick={handleLoadMore}
                            >
                                Load more
                            </Button>
                        )}
                    </Stack>
                )}

                {count === 0 && (
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "gray",
                        }}
                    >
                        No programs found
                    </p>
                )}

                {query.page > 1 && count === programs.length && (
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "600",
                            color: "gray",
                            fontSize: "18px",
                        }}
                    >
                        No more programs
                    </p>
                )}

                <Modal
                    title={
                        <Typography
                            sx={{ linHeight: "36px", fontSize: "28px" }}
                        >
                            <span
                                style={{ color: "#02353C", fontWeight: "700" }}
                            >
                                Congrats
                            </span>{" "}
                            <span
                                style={{
                                    color: "#02353C",
                                    fontWeight: "normal",
                                }}
                            >
                                and welcome on board !
                            </span>
                        </Typography>
                    }
                    body={
                        <>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 3 }}
                            >
                                {/**/}
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        fontSize: "20px",
                                    }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ color: "#02353C" }}
                                    >
                                        <UnderlineText>
                                            Your wallet
                                        </UnderlineText>{" "}
                                        has been created on blockchain.
                                    </Box>
                                </Typography>
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        padding: "19px 0px",
                                    }}
                                >
                                    The official public address for your
                                    company&apos;s wallet is:
                                    <span style={{ fontWeight: "bold" }}>
                                        {` ${address}`}
                                    </span>{" "}
                                    . <br />
                                    <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Note :
                                    </span>{" "}
                                    This wallet is exclusively associated with
                                    the email:{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                        {users?.company?.wallet?.wallet_email}
                                    </span>
                                    {""}.
                                </Typography>
                                {""}
                                <Typography
                                    sx={{
                                        lineHeight: "18px",
                                        "& a": {
                                            color: "#5282B6",
                                        },
                                    }}
                                >
                                    This is a significant milestone, and our
                                    entire team is excited to welcome your
                                    company to this new phase of our journey
                                    together.
                                    <br />
                                    Don’t be afraid. The public key is already
                                    saved on your account. On our platform, this
                                    wallet is more than an NFT wallet. It’s your
                                    gateway to a plethora of features and
                                    opportunities. With this wallet, you are now
                                    equipped to engage in seamless transactions
                                    and experience the full breadth of what our
                                    platform has to offer. Whether you’re
                                    looking to transfer assets on this new
                                    blockchain-based services, we have designed
                                    everything with your convenience and
                                    security in mind.
                                    <br />
                                    Our primary goal has always been to provide
                                    our users with a comprehensive,
                                    user-friendly, and secure environment. With
                                    your new wallet, you are now at the heart of
                                    this sustainable and digital ecosystem we
                                    have crafted, and we’re confident you’ll
                                    find it both intuitive and empowering.{" "}
                                    <br /> We want to take this opportunity to
                                    thank you for your trust and for choosing
                                    our platform. Our dedicated team is
                                    continuously working to enhance the user
                                    experience and introduce even more features.
                                    If you have any questions or need
                                    assistance, our support team is always here
                                    to help.
                                </Typography>
                            </Typography>
                        </>
                    }
                    footer={
                        <Stack
                            direction="row"
                            spacing={1}
                            mt="44px"
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="contained"
                                color="buttonPrimary"
                                onClick={() => setSuccessModal(false)}
                            >
                                {"Let's go"}
                            </Button>
                        </Stack>
                    }
                    open={successModal}
                    close={() => setSuccessModal(false)}
                />
            </CheckpageAuth>
        </Layout>
    );
};

export default Program;
