const errorHandler = (error) => {
    const parseError = error?.response?.data?.message.includes("}")
        ? JSON.parse(error?.response?.data?.message)
        : "";
    const serverError = Object.values(error?.response?.data?.errors || {})[0];
    const singleError =
        error?.response?.data?.data?.errors?.length > 0
            ? error?.response?.data?.data?.errors[0]
            : null;
    const errorMessage =
        parseError ||
        serverError ||
        singleError ||
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong!";

    return errorMessage;
};
export default errorHandler;
