import { styled } from "@mui/material/styles";
import Layout from "Layout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "common/Breadcrumb";
import TableCell from "@mui/material/TableCell";
import CustomTable from "common/CustomTable";
import Chip from "@mui/material/Chip";
import OpenPreviousPage from "common/OpenPreviousPage";
import { useAccessPage } from "customHooks/useAccess";
import permissionSlug from "assets/data/permissionSlug.json";
import CheckpageAuth from "auth/CheckPageAccess";
import { Button, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    cancelUCOs,
    fetchUCOSCCSDetail,
    getUcoCertificatePdf,
    getUcoActivity,
} from "redux/registrySlices";
import UCOsStatusColors from "util/UCOsStatusColor";
import { ReactComponent as WaitClocks } from "assets/icons/clocksWait.svg";
import ReaderViewModal from "common/ReaderViewModal";
import { useDateFormatter } from "customHooks/useDateFormatter";
import Modal from "common/Modal";
const StyledChip = styled(Chip)(() => ({
    height: "28px",
    padding: "2px 0px",
    fontWeight: "400",
    width: "100px",
}));

const headCells = [
    // {
    //     id: "event",
    //     numeric: false,
    //     label: "Event",
    // },
    {
        id: "txn_hash",
        numeric: true,
        label: "Txn Hash",
    },
    {
        id: "date",
        numeric: true,
        label: "Date",
    },
    {
        id: "from",
        numeric: true,
        label: "From",
    },
    {
        id: "to",
        numeric: true,
        label: "To",
    },
    // {
    //     id: "old_status",
    //     label: "Old Status",
    // },
    {
        id: "new_status",
        label: "New Status",
    },
    {
        id: "quantity",
        numeric: true,
        label: "Quantity",
    },
    {
        id: "vall",
        label: "",
    },
];

const RegistryUcoDetails = () => {
    const [query, setQuery] = useState({ page: 1, limit: 10 });
    const [refetchings, setRefetchings] = useState(true);
    const { can, cannot } = useAccessPage();
    const [refetch, setRefetch] = useState(true);
    const routeAccess = {
        routeAccess: can(permissionSlug[0].uco_slug.UCO_details),
        unAuthUserRedirectTo: "/401",
    };
    const { formatActivityDate } = useDateFormatter();
    const { address, field_id, program_id } = useParams();
    const dispatch = useDispatch();

    const customStyle = {
        maxWidth: { xs: "90%", sm: "450px" },
    };
    const [openModal, setOpenModal] = useState(false);
    const [openCancelsModal, setOpenCancelsModal] = useState(false);
    const {
        UCOSDetails,
        isLoading,
        inProgress,
        ucoCertiPDf,
        isCertiLoading,
        ucoActivity,
        count,
        isActivityLoading,
        cancellingUCOs,
    } = useSelector((state) => state.registery);
    const {
        blockchain_name,
        contract_address,
        token_id,
        vintage,
        status,
        imageUrl,
    } = UCOSDetails;

    useEffect(() => {
        if (refetch) {
            dispatch(
                fetchUCOSCCSDetail({
                    field_id,
                    program_id,
                    address,
                    type: "UCO",
                })
            ).then(() => {
                setRefetch(false);
            });
        }
    }, [refetch]);
    const handleUcoCertificatePdf = () => {
        if (imageUrl) {
            dispatch(
                getUcoCertificatePdf({
                    field_id,
                    program_id,
                    imageUrl,
                })
            ).then(() => {
                setOpenModal(true);
            });
        }
    };
    const nftDetails = [
        {
            title: "Blockchain",
            info: blockchain_name,
        },
        {
            title: "Contract",
            info: contract_address,
        },
        {
            title: "Token ID",
            info: token_id,
        },
        // {
        //     title: "Creator",
        //     info: "0xbe95c78a8b4b4c38b4a5fce1ad1aa68cdd0b4cd3",
        // },
        {
            title: "Vintage",
            info: vintage,
        },
        // {
        //     title: "SCC",
        //     info: "0x456aff0745024B098A425AB54315F232c0Be933B",
        // },
        {
            title: "Certificate",
            info: (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={handleUcoCertificatePdf}
                >{`certificate_${contract_address}.pdf`}</span>
            ),
            loader: isCertiLoading && (
                <div style={{ textAlign: "center" }}>Processing...</div>
            ),
        },
        {
            title: "Status",
            info: (
                <StyledChip
                    label={status}
                    sx={{
                        backgroundColor: UCOsStatusColors[status],
                        color: "primary.white",
                    }}
                />
            ),
        },
    ];
    useEffect(() => {
        dispatch(
            getUcoActivity({
                page: query.page,
                limit: query.limit,
                program_id,
                field_id,
                // HH
            })
        );
    }, [query, refetchings]);

    const sanitazidTabledData =
        ucoActivity &&
        ucoActivity?.map((item) => ({
            // event: "Transfer",
            txn_hash:
                (
                    <TableCell sx={{ minWidth: "150px" }}>
                        {item?.txHash}
                    </TableCell>
                ) || "-",
            date: (
                <TableCell sx={{ minWidth: "189px" }}>
                    {formatActivityDate(item?.date) || "-"}
                </TableCell>
            ),
            from: "-",
            to: "-",
            // old_status: "-",
            new_status: item?.newStatus || "-",
            quantity: "1",
            vall: <TableCell sx={{ width: "40%" }}></TableCell>,
        }));

    const handleCancel = () => {
        dispatch(
            cancelUCOs({
                fieldId: field_id,
                programId: program_id,
            })
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                setOpenCancelsModal(false);
                setRefetchings((valuess) => {
                    return !valuess;
                });
            }
        });
    };

    return (
        <Layout>
            <CheckpageAuth auth={routeAccess}>
                <Box sx={{ width: "100%", mb: "11px" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Breadcrumbs
                            sources={[
                                {
                                    label: "registry",
                                    link: "/registry/scc",
                                    canNavigate: true,
                                },
                                {
                                    label: "UCO detail",
                                    link: "",
                                },
                            ]}
                        />
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ mt: 0 }}
                    >
                        {/* <Grid item xs={12} md={6}> */}
                        <Grid xs={12} md={8} xl={9}>
                            {isLoading ? (
                                <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "3rem", width: "20rem" }}
                                />
                            ) : (
                                <Typography
                                    variant="h2"
                                    sx={{
                                        wordWrap: "break-word",
                                        fontSize: {
                                            md: "1.6rem",
                                            lg: "1.8rem",
                                            xl: "2.125rem",
                                        },
                                    }}
                                >
                                    <OpenPreviousPage /> UCO #
                                    {`${address}/${token_id}`}
                                </Typography>
                            )}
                        </Grid>
                        {/* </Grid> */}
                        {/* </Grid> */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            xl={3}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "start", md: "flex-end" },
                            }}
                        >
                            {/* <Button
                                variant="contained"  

                                sx={{
                                    mr: "20px",
                                }}
                            >
                                Freeze UCO
                            </Button> */}
                            <Button
                                variant="contained"
                                onClick={() => setOpenCancelsModal(true)}
                                disabled={
                                    inProgress ||
                                    cannot(
                                        permissionSlug[0].uco_slug.cancel_UCO
                                    ) ||
                                    isLoading ||
                                    status === "Cancelled"
                                }
                            >
                                Cancel UCO
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {/*  */}
                {openModal && (
                    <ReaderViewModal
                        pdfFile={ucoCertiPDf}
                        onClose={() => setOpenModal(false)}
                    ></ReaderViewModal>
                )}

                {inProgress && (
                    <Box sx={{ width: "100%" }}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            // mt={2}
                        >
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        maxWidth: "200px", // Adjust the size of the Box containing WaitClocks
                                        margin: "auto", // Center the Box horizontally
                                    }}
                                >
                                    <WaitClocks />
                                </Box>
                                <Typography variant="h2" textAlign="center">
                                    {`Hang in there! We're currently fetching data from the Blockchain. Your patience is appreciated!`}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 2, // Adjust the margin-top for spacing
                                    }}
                                >
                                    Remember to refresh the page in the next 2
                                    minutes!
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 2, // Adjust the margin-top for spacing
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={() => setRefetch(true)}
                                    >
                                        Reload
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1 }}
                        sx={{ mt: 0 }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={9}
                            sx={{
                                pt: "2px !important",
                                mt: "20px",
                                gap: "50px",
                            }}
                            display="flex"
                            flexDirection="column"
                        >
                            {!inProgress && (
                                <Box>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: "left",
                                            color: "#02353C",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "16px",
                                                md: "18px",
                                                lg: "20px",
                                            },
                                        }}
                                    >
                                        NFT Details
                                    </Typography>

                                    {nftDetails.map((res, index) => (
                                        <Grid
                                            key={index}
                                            container
                                            spacing={1}
                                            sx={{
                                                margin: "13px -13px 0",
                                                lineHeight: "1.2",
                                                fontSize: {
                                                    xs: "14px",
                                                    lg: "16px",
                                                },
                                                alignItems: "center",
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: {
                                                        xs: "left",
                                                        lg: "right",
                                                    },
                                                    padding:
                                                        "0px 12px !important",
                                                    maxWidth: {
                                                        xs: "100%",
                                                        sm: "150px",
                                                        md: "180px",
                                                    },
                                                    minWidth: {
                                                        xs: "100%",
                                                        sm: "150px",
                                                        md: "180px",
                                                    },
                                                }}
                                            >
                                                {res.title}
                                            </Grid>
                                            <Grid
                                                item
                                                sx={{
                                                    textAlign: "left",
                                                    fontWeight: "700",
                                                    padding:
                                                        "0px 12px !important",
                                                    width: {
                                                        xs: "100%",
                                                        sm: "calc(100% - 150px)",
                                                        md: "calc(100% - 180px)",
                                                    },
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {res.button ? (
                                                    <Box component="div">
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                maxWidth:
                                                                    "78px",
                                                                background:
                                                                    "#1ED800",
                                                                borderRadius:
                                                                    "40px",
                                                                boxShadow:
                                                                    "none",
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "none",
                                                                color: "#fff",
                                                                padding:
                                                                    "1px 11px",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            {res.iconPath}{" "}
                                                            {res.button}
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        component="div"
                                                        sx={{
                                                            maxWidth: {
                                                                xs: "100%",
                                                            },
                                                        }}
                                                    >
                                                        {isLoading ? (
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize:
                                                                        "1rem",
                                                                }}
                                                            />
                                                        ) : (
                                                            res.info
                                                        )}{" "}
                                                        {res.loader &&
                                                            !isLoading &&
                                                            res.loader}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            )}
                        </Grid>
                        {!inProgress && (
                            <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ pt: "2px !important", gap: "23px" }}
                                display="flex"
                                flexDirection="column"
                            >
                                {isLoading ? (
                                    <Skeleton
                                        sx={{
                                            marginLeft: { md: "auto" },
                                            mt: "20px",
                                        }}
                                        variant="rounded"
                                        animation="wave"
                                        width="100%"
                                        height="100%"
                                        // variant="text"
                                        // sx={{
                                        //     fontSize: "1rem",
                                        // }}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: { md: "flex-end" },
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            alt="Map"
                                            src={imageUrl}
                                            sx={{
                                                width: "100%",
                                                maxWidth: 400,
                                                // maxHeight: 400,
                                                mt: "20px",
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        )}
                    </Grid>

                    {!inProgress && (
                        <Box mt={8}>
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: "left",
                                    color: "#02353C",
                                    fontWeight: "bold",
                                    fontSize: {
                                        xs: "16px",
                                        md: "18px",
                                        lg: "20px",
                                    },
                                }}
                            >
                                Activity
                            </Typography>

                            <CustomTable
                                headCells={headCells}
                                rows={
                                    sanitazidTabledData
                                        ? sanitazidTabledData
                                        : []
                                }
                                noDataFound="No Data Found"
                                query={query}
                                setQuery={setQuery}
                                totalRowCount={count}
                                loadingList={isActivityLoading}
                            />
                        </Box>
                    )}
                </Box>
            </CheckpageAuth>
            <Modal
                customStyle={customStyle}
                title={
                    <Box sx={{ textAlign: "center" }}>
                        {/* <span>
                            <Warning />
                        </span> */}
                        <Typography
                            sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                            Are you sure you want to cancel this UCO ?
                        </Typography>
                    </Box>
                }
                body={
                    <>
                        {/* <Typography
                            id="modal-modal-description"
                            sx={{ mt: 1, textAlign: "center" }}
                        >
                            <Typography sx={{ mb: "25px", fontSize: "16px" }}>
                                Once the program status is withdrawn, you{" "}
                                {`won't`} be able to make any further changes to
                                it.
                            </Typography>
                        </Typography> */}
                    </>
                }
                footer={
                    <Stack
                        direction="row"
                        spacing={1}
                        mt="20px"
                        justifyContent="center"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => setOpenCancelsModal(false)}
                            sx={{ fontWeight: "500" }}
                            disabled={cancellingUCOs}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="buttonPrimary"
                            onClick={() => handleCancel()}
                            disabled={cancellingUCOs}
                        >
                            {cancellingUCOs ? "Processing..." : "Confirm"}
                        </Button>
                    </Stack>
                }
                open={openCancelsModal}
                // close={() => setOpenModal(false)}
            />
        </Layout>
    );
};

export default RegistryUcoDetails;
