import { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import MapExperiments from "common/MapExperiments";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchCompanyYearMapData,
    fetchFarmerMapsDropdowns,
} from "redux/mapsSlice";
import { useParams } from "react-router-dom";
import ProgramDropdown from "./ProgramDropdown";
export default function MapContainer({ fullHeights }) {
    const dispatch = useDispatch();
    const { id: farmer_id } = useParams();

    const [year, setYear] = useState("");
    const [program, setProgram] = useState([]);

    const handleChange = (type) => (event) => {
        if (type == "program") {
            setProgram(event.target.value);
        } else {
            setYear(event.target.value);
            setProgram([]);
        }
    };

    const companyID = useSelector(
        (state) => state.account.accountDetails?.company?.id,
        shallowEqual
    );

    const role = useSelector(
        (state) => state.account.accountDetails?.role?.name,
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchFarmerMapsDropdowns({ farmer_id })).then((res) => {
            if (res.meta.requestStatus === "fulfilled" && !res.error) {
                setYear(res.payload.data.harvestYears[0] || "");
            }
        });
    }, []);

    useEffect(() => {
        if (year) {
            dispatch(fetchFarmerMapsDropdowns({ farmer_id, year }));
            if (role) {
                dispatch(fetchCompanyYearMapData({ companyID, year, role }));
            }
        }
    }, [year, companyID, role]);
    const years = useSelector((state) => state.maps.yearOptions, shallowEqual);

    return (
        <Grid
            item
            xs={12}
            lg={6}
            sx={{ gap: "23px" }}
            display="flex"
            flexDirection="column"
        >
            <Box component="form" sx={{ mt: { xs: "30px" } }}>
                <Stack
                    direction={{ sm: "row", md: "row", lg: "row" }}
                    spacing={{ xs: "15px", md: "15px", lg: "34px" }}
                >
                    <FormControl
                        variant="filled"
                        required
                        fullWidth
                        sx={{
                            "&.MuiFormControl-root": {
                                maxWidth: {
                                    sm: "250px",
                                    md: "100%",
                                    lg: "250px",
                                },
                            },
                        }}
                    >
                        <InputLabel id="demo-simple-select-filled-label">
                            Harvest Year
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={year}
                            onChange={handleChange("year")}
                        >
                            {/* <MenuItem value={2019}>2019</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2025}>2025</MenuItem> */}
                            {years.map((year) => {
                                return (
                                    <MenuItem key={year} value={Number(year)}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <ProgramDropdown
                        programs={program}
                        setProgram={setProgram}
                    />
                    {/* <FormControl
                        variant="filled"
                        required
                        fullWidth
                        sx={{
                            "&.MuiFormControl-root": {
                                maxWidth: {
                                    sm: "250px",
                                    md: "100%",
                                    lg: "250px",
                                },
                            },
                        }}
                    >
                        <InputLabel id="demo-simple-select-filled-label">
                            Program
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={program}
                            onChange={handleChange("program")}
                        >
                            {programs.map((program) => {
                                return (
                                    <MenuItem
                                        key={program.id}
                                        value={Number(program.id)}
                                    >
                                        {program.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl> */}
                </Stack>
            </Box>
            <Box>
                <MapExperiments
                    fieldProgram={program.map((pr) => Number(pr.id))}
                    fullHeights={fullHeights}
                />
            </Box>
        </Grid>
    );
}
