import Breadcrumbs from "common/Breadcrumb";
import { useParams } from "react-router-dom";

export default function ProjectHeader() {
    const { id } = useParams();
    return (
        <>
            <Breadcrumbs
                md="auto"
                sources={[
                    {
                        label: "farmers",
                        link: "/farmers",
                        canNavigate: true,
                    },
                    {
                        label: `farmers #${id}`,
                        link: `/farmer/${id}/details`,
                        canNavigate: true,
                    },
                    {
                        label: "field map",
                        link: "",
                    },
                ]}
            />
        </>
    );
}
