import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import OpenPreviousPage from "common/OpenPreviousPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getFarmers } from "redux/farmersSlice";
import sanitizedString from "util/sanitizedString";
export default function DetailsTitle() {
    const { id } = useParams();
    const { farmersDetails } = useSelector((state) => state.farmers);

    const { isLoading, farmerFieldCount } = useSelector(
        (state) => state.farmers
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (Object.keys(farmersDetails).length === 0) {
            dispatch(getFarmers(id));
        }
    }, [farmersDetails]);
    return (
        <>
            <Grid item xs={12} md="auto">
                {isLoading ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "40rem" }}
                    />
                ) : (
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        <OpenPreviousPage />
                        {farmerFieldCount}
                        {farmerFieldCount > 1 ? " Fields " : " Field "}
                        <span style={{ fontWeight: "normal" }}> on</span>{" "}
                        <Box
                            component="span"
                            sx={{
                                fontWeight: "normal",
                                borderBottom: "1px solid #000",
                                display: "inline-block",
                                "& a": {
                                    color: "inherit",
                                },
                                "&:hover": {
                                    color: "grey",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            {farmersDetails && (
                                <Link
                                    to={`/farmer/${id}/details`}
                                    sx={{
                                        display: "inline-block",
                                    }}
                                >
                                    {sanitizedString(
                                        farmersDetails?.farmerCompanyName
                                    )}
                                </Link>
                            )}
                        </Box>
                    </Typography>
                )}
            </Grid>
        </>
    );
}
