import CustomTable from "common/CustomTable";
import PdfIcon from "common/PdfIcon";
import { ReactComponent as IconFileupload } from "assets/icons/fileupload.svg";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchProjectsByFarmers,
    refetchFarmersProject,
    uploadProjectPdf,
} from "redux/farmersProjectSlice";
import ContractPDFUploader from "common/ContractPDFUploader";
import { useDateFormatter } from "customHooks/useDateFormatter";
import { Link } from "react-router-dom";
import UnderlineText from "common/UnderlineText";
import { useAccessPage } from "customHooks/useAccess";
import CheckpageAuth from "auth/CheckPageAccess";
import permissionSlug from "assets/data/permissionSlug.json";

const headCells = [
    {
        id: "project",
        numeric: true,
        label: "Project",
    },
    {
        id: "programid",
        numeric: false,
        label: "Program Code",
    },
    {
        id: "refcontract",
        numeric: true,
        label: "Ref. Contract",
    },
    {
        id: "referenceyear",
        numeric: true,
        label: "Reference year",
    },
    {
        id: "startdate",
        numeric: true,
        label: "Start date",
    },
    {
        id: "enddate",
        numeric: true,
        label: "End date",
    },
    {
        id: "projectcontract",
        numeric: true,
        label: "Project Contract",
        width: 1,
    },
];

export default function Detail({ farmer_id }) {
    const { can } = useAccessPage();
    const routeAccess = {
        routeAccess: can(permissionSlug[0].project_slug.list_projects),
        unAuthUserRedirectTo: "/401",
    };
    const [query, setQuery] = useState({
        id: farmer_id,
        page: 1,
        limit: 10,
    });
    const { formatDates } = useDateFormatter();
    const [openFarmerModal, setOpenFarmerModal] = useState();
    const [file, setFile] = useState();
    const currentProjectId = useRef({ id: null });
    const dispatch = useDispatch();

    const { projects, isUploading, isLoading, isLoaded, count } = useSelector(
        (state) => state.farmersProject,
        shallowEqual
    );
    useEffect(() => {
        if (!isLoaded) {
            dispatch(fetchProjectsByFarmers(query));
        }
    }, [isLoaded]);

    useEffect(() => {
        dispatch(refetchFarmersProject());
    }, [query]);

    const handlePDFUploads = (id) => {
        currentProjectId.current.id = id;
        setOpenFarmerModal(true);
    };
    const handleSubmit = (id) => {
        const formData = new FormData();
        formData.append("projectPdfFile", file[0]);
        dispatch(uploadProjectPdf({ projectID: id, payload: formData })).then(
            (res) => {
                // if (res.meta.requestStatus === "fulfilled" && !res.error) {
                if (res) {
                    setFile(null);
                    setOpenFarmerModal(false);
                    currentProjectId.current.id = null;
                }
            }
        );
    };

    const sanitazidTabledData =
        projects &&
        projects?.map((item) => ({
            project: item.id,
            programid: (
                <TableCell>
                    <Link to={`/program/${item.projectIdProgram}/detail`}>
                        <UnderlineText>
                            {item.program.program_code}
                        </UnderlineText>
                    </Link>
                </TableCell>
            ),
            refcontract: `#${item.projectReferenceExternalId}`,
            referenceyear:
                item.projectReferenceYear === 0
                    ? "-"
                    : item.projectReferenceYear,
            startdate: formatDates(item.projectStartDate),
            enddate: formatDates(item.projectEndDate),
            projectcontract: can(
                permissionSlug[0].project_slug.view_project_contract
            ) ? (
                item.projectPdfFile ? (
                    <TableCell>
                        <PdfIcon address={item.projectPdfFile} />
                    </TableCell>
                ) : (
                    <TableCell onClick={() => handlePDFUploads(item.id)}>
                        <IconFileupload />
                    </TableCell>
                )
            ) : (
                "-"
            ),
        }));
    return (
        <>
            <CheckpageAuth auth={routeAccess}>
                <Box component="div" sx={{ mt: 7 }}>
                    <CustomTable
                        headCells={headCells}
                        rows={sanitazidTabledData ? sanitazidTabledData : []}
                        loadingList={isLoading}
                        setQuery={setQuery}
                        query={query}
                        totalRowCount={count}
                        noDataFound="No projects found"
                    />
                </Box>
                <ContractPDFUploader
                    file={file}
                    setFile={setFile}
                    openFarmerModal={openFarmerModal}
                    setOpenFarmerModal={setOpenFarmerModal}
                    onClick={handleSubmit}
                    currentID={currentProjectId.current.id}
                    isLoading={isUploading}
                />
            </CheckpageAuth>
        </>
    );
}
