import { Typography, Grid } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";

export default function DetailsTitle() {
    const { company } = useSelector(
        (state) => ({
            company: state?.companylist?.companyList?.data?.count,
        }),
        shallowEqual
    );
    return (
        <>
            <Grid item xs={12} md={6}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    {company && company} {company > 1 ? "Companies" : "Company"}{" "}
                </Typography>
            </Grid>
        </>
    );
}
