import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useMemo } from "react";
import AutocompleteAddress from "common/AutocompleteAddress";

const CompanyAddress = ({ formik }) => {
    const onAddressSelect = (address) => {
        formik.setValues((prev) => ({ ...prev, ...address }));
    };
    const autocompletionAddress = useMemo(() => {
        return (
            <Box
                sx={{
                    "@media (min-width: 768px)": {
                        gridColumn: "span 2",
                    },
                }}
            >
                <AutocompleteAddress
                    formik={formik}
                    name="autocompletionAddress"
                    onAddressSelect={onAddressSelect}
                />
            </Box>
        );
    }, [formik.values.autocompletionAddress]);

    const street = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="street"
                    name="street"
                    label="Street"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    error={formik.touched.street && !!formik.errors.street}
                    helperText={formik.touched.street && formik.errors.street}
                />
            </Box>
        );
    }, [formik.values.street, formik.touched.street, formik.errors.street]);
    const streetTwo = useMemo(() => {
        return (
            <Box>
                <TextField
                    fullWidth
                    id="street_two"
                    name="street_two"
                    label="Address complement"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    value={formik.values.street_two}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.street_two && !!formik.errors.street_two
                    }
                    helperText={
                        formik.touched.street_two && formik.errors.street_two
                    }
                />
            </Box>
        );
    }, [
        formik.values.street_two,
        formik.touched.street_two,
        formik.errors.street_two,
    ]);
    const zipCode = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="zip_code"
                    name="zip_code"
                    label="Zip code"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    type="number"
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    error={formik.touched.zip_code && !!formik.errors.zip_code}
                    helperText={
                        formik.touched.zip_code && formik.errors.zip_code
                    }
                />
            </Box>
        );
    }, [
        formik.values.zip_code,
        formik.touched.zip_code,
        formik.errors.zip_code,
    ]);

    const region = useMemo(() => {
        return (
            <Box>
                <TextField
                    fullWidth
                    id="region"
                    name="region"
                    label="Region"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    error={formik.touched.region && !!formik.errors.region}
                    helperText={formik.touched.region && formik.errors.region}
                />
            </Box>
        );
    }, [formik.values.region, formik.touched.region, formik.errors.region]);
    const city = useMemo(() => {
        return (
            <Box>
                <TextField
                    fullWidth
                    required
                    id="city"
                    name="city"
                    label="City"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && !!formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                />
            </Box>
        );
    }, [formik.values.city, formik.touched.city, formik.errors.city]);
    const country = useMemo(() => {
        return (
            <Box>
                <TextField
                    required
                    fullWidth
                    id="country"
                    name="country"
                    label="Country"
                    variant="filled"
                    sx={{ mb: "5px" }}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={formik.touched.country && !!formik.errors.country}
                    helperText={formik.touched.country && formik.errors.country}
                />
            </Box>
        );
    }, [formik.values.country, formik.touched.country, formik.errors.country]);
    return (
        <>
            <Typography variant="h4" mt={0}>
                Company Address
            </Typography>
            <Box
                display="grid"
                gridTemplateColumns="repeat( 2, minmax(auto, 250px) )"
                gap={"10px 30px"}
                sx={{
                    "@media (max-width: 767px)": {
                        gridTemplateColumns: "repeat( 1, 1fr )",
                    },
                }}
            >
                {autocompletionAddress}
                {street}
                {streetTwo}
                {zipCode}
                {region}
                {city}
                {country}
            </Box>
        </>
    );
};

export default CompanyAddress;
